import { loader } from 'graphql.macro';
import toColorString from 'polished/lib/color/toColorString';
import React, { useCallback, useContext } from 'react';

import { useMutation } from '@apollo/client';

import color from '@decisiv/design-tokens/lib/color';

import LockOpen from '@decisiv/iconix/lib/components/LockOpen';

import Checkbox from '@decisiv/ui-components/lib/components/Checkbox';
import Flex from '@decisiv/ui-components/lib/components/Flex';
import Grid from '@decisiv/ui-components/lib/components/Grid';
import { H2, P } from '@decisiv/ui-components/lib/components/Typography';

import { t, Trans } from '@lingui/macro';

import { isFleet } from 'utils/Customer';
import { CurrentInvitationContext } from 'utils/Invitation';
import { parseDate } from 'utils/dates';
import { buildPropTypes } from 'utils/graphqlPropTypes';
import { nameForUser } from 'utils/User';

import RemoveAccessButton from './RemoveAccessButton';

const updateEnableAllAssetsMutation = loader('./updateEnableAllAssets.graphql');

export default function InvitationHeader({ customer }) {
  const { contact, updatedAt } = useContext(CurrentInvitationContext);
  const contactName = nameForUser(contact);

  const [updateEnableAllAssets] = useMutation(updateEnableAllAssetsMutation);

  const handleChange = useCallback(
    async ({ target: { checked } }) => {
      await updateEnableAllAssets({
        variables: { enableAllAssets: checked },
      });
    },
    [updateEnableAllAssets],
  );

  const customerName = customer.name;
  return (
    <Grid.Container paddingTop={2}>
      <Grid.Row paddingX={2}>
        <Grid.Column
          css={`
            background-color: ${toColorString(color.status.success.medium)};
          `}
          padding={2}
        >
          <Flex>
            <LockOpen
              css={`
                color: ${toColorString(color.base.snowWhite)};
              `}
              size="extraLarge"
            />
            <Flex flex={1} flexDirection="column" marginLeft={2}>
              <H2 color="snowWhite">
                <Trans>Request Accepted to Access Asset Information</Trans>
              </H2>
              <P color="snowWhite">
                <Trans>
                  {contactName} accepted the request to access {customerName}{' '}
                  asset and alert information.
                </Trans>
              </P>
              <P color="snowWhite">
                <Trans>
                  You can enable or disable the assets they can access below.
                </Trans>
              </P>
              <P color="snowWhite" marginTop={0.5} size="small">
                {parseDate(updatedAt).join(' ')}
              </P>
              {isFleet(customer) && (
                <Flex alignItems="center" marginTop={2}>
                  <Checkbox
                    aria-label={t`Enable access`}
                    defaultChecked={customer.enableAllAssets}
                    onChange={handleChange}
                  />
                  <P color="snowWhite" marginLeft={1}>
                    <Trans>
                      Enable access to all assets added in the future
                    </Trans>
                  </P>
                </Flex>
              )}
            </Flex>
            <Flex>
              <RemoveAccessButton customer={customer} />
            </Flex>
          </Flex>
        </Grid.Column>
      </Grid.Row>
    </Grid.Container>
  );
}

InvitationHeader.propTypes = {
  customer: buildPropTypes('customer').isRequired,
};
