import React from 'react';
import PropTypes from 'prop-types';
import { plural, t } from '@lingui/macro';

import Avatar from '@decisiv/ui-components/lib/components/Avatar';
import Flex from '@decisiv/ui-components/lib/components/Flex';

import BeforeAfterRow from 'components/BeforeAfterRow';
import InvitationRow from 'components/InvitationRow';

import { formatName } from 'utils/Contact';

const INVITATION_ACTIONS = ['ACCEPTED', 'REJECTED'];

export function invitationActionText(action, items) {
  const count = items.length;

  switch (action) {
    case 'ACCEPTED':
      return t`accepted the`;
    case 'CANCELED':
      return plural(count, {
        one: 'canceled an',
        other: `canceled ${count}`,
      });
    case 'REJECTED':
      return t`denied an`;
    case 'SENT':
      return t`sent an`;
    default:
      return null;
  }
}

export function invitationAttributeText(items) {
  const accessRequestCount = items.length;
  return plural(accessRequestCount, {
    one: 'access request',
    other: `access requests`,
  });
}

const renderContact = (contact) => (
  <Flex>
    <Flex marginRight={1}>
      <Avatar email={contact.email} name={formatName(contact)} />
    </Flex>
    <InvitationRow contact={contact} />
  </Flex>
);

export default function InvitationHistory({ action, item: { contact } }) {
  if (INVITATION_ACTIONS.includes(action)) {
    return null;
  }
  return <BeforeAfterRow beforeValue={contact} renderData={renderContact} />;
}

InvitationHistory.propTypes = {
  action: PropTypes.string.isRequired,
  item: PropTypes.shape({
    contact: PropTypes.shape({
      email: PropTypes.string,
    }).isRequired,
  }).isRequired,
};
