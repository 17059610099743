import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';
import rem from 'polished/lib/helpers/rem';

import spacing from '@decisiv/design-tokens/lib/spacing';

import Link from '@decisiv/ui-components/lib/components/Link';
import Tooltip from '@decisiv/ui-components/lib/components/Tooltip';

import ExternalLink from '@decisiv/iconix/lib/components/ExternalLink';

import DisabledLink from './DisabledLink';

export default function LinkToCase({
  text,
  to,
  iconPosition,
  iconOnly,
  showIcon,
  ...rest
}) {
  const ref = useRef();

  if (!text && !iconOnly) {
    return null;
  }

  return to ? (
    <Link
      icon={showIcon ? ExternalLink : undefined}
      iconPosition={iconPosition}
      target="_blank"
      text={iconOnly ? undefined : text}
      aria-label={text}
      to={to}
      {...rest}
    />
  ) : (
    <>
      <DisabledLink
        as="span"
        css={`
          overflow-wrap: break-word;
          word-break: break-word;
          hyphens: auto;
          white-space: pre-wrap;
          flex-direction: ${
            iconPosition === 'right' ? undefined : 'row-reverse'
          };
          svg {
            margin-${iconPosition === 'right' ? 'left' : 'right'}: ${rem(
          spacing.base / 2,
        )};
          }
        `}
        ref={ref}
        {...rest}
      >
        {iconOnly ? '' : text}
        {showIcon && (
          <ExternalLink
            css={`
              min-width: ${rem(spacing.base * 1.6)};
            `}
            {...rest}
          />
        )}
      </DisabledLink>
      <Tooltip target={ref} zIndex={10}>
        {t`You do not have permission to access this link.`}
      </Tooltip>
    </>
  );
}

LinkToCase.propTypes = {
  iconPosition: PropTypes.oneOf(['left', 'right']),
  text: PropTypes.string,
  to: PropTypes.string,
  iconOnly: PropTypes.bool,
  showIcon: PropTypes.bool,
};

LinkToCase.defaultProps = {
  iconPosition: 'right',
  text: '',
  to: null,
  iconOnly: false,
  showIcon: true,
};
