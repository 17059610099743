import React from 'react';
import { t } from '@lingui/macro';
import PropTypes from 'prop-types';

import BaseTab from '../index';

export default function AllActivityTab({ onNoData }) {
  return (
    <BaseTab
      emptyResultMessage={t`There is currently no activity for this team.`}
      onNoData={onNoData}
    />
  );
}

AllActivityTab.propTypes = {
  onNoData: PropTypes.func,
};

AllActivityTab.defaultProps = {
  onNoData: undefined,
};
