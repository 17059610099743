import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import spacing from '@decisiv/design-tokens/lib/spacing';

import KDSPopover from '@decisiv/ui-components/lib/components/Popover';

export const Popover = styled(KDSPopover)`
  max-width: ${rem(spacing.base * 33)};
`;
