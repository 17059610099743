import React from 'react';
import PropTypes from 'prop-types';

import { P } from '@decisiv/ui-components/lib/components/Typography';
import Modal from '@decisiv/ui-components/lib/components/Modal';
import Flex from '@decisiv/ui-components/lib/components/Flex';

import { t, Trans } from '@lingui/macro';

export default function MaintenanceEventsModal({
  visible,
  onClose,
  onEnable,
  assetGroupingName,
}) {
  const handleEnable = () => {
    onEnable(true);
    onClose();
  };

  return (
    <Modal
      actions={[
        {
          text: t`Enable`,
          onClick: handleEnable,
          'aria-label': t`Enable Maintenance`,
        },
        {
          text: t`Cancel`,
          onClick: onClose,
          'aria-label': t`Cancel`,
        },
      ]}
      intent="information"
      onClose={onClose}
      title={t`Enable Adding Records Of Maintenance Events`}
      visible={visible}
    >
      <Flex flexDirection="column" marginTop={0.5}>
        <P color="alaskanHusky">
          <Trans>
            By enabling this option for customer {assetGroupingName}, any member
            on your team will be able to manually record maintenance events
            serviced outside of the platform.
          </Trans>
        </P>
        <P marginTop={2} color="alaskanHusky">
          <Trans>
            Once the record is added, the conditions for this maintenance will
            be reset and if the asset details match the criteria, the alert will
            disappear.
          </Trans>
        </P>
        <P marginTop={2} color="alaskanHusky">
          <Trans>
            The data provided will not be editable, and will become part of the
            Service Event history in Case.
          </Trans>
        </P>
      </Flex>
    </Modal>
  );
}

MaintenanceEventsModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onEnable: PropTypes.func.isRequired,
  assetGroupingName: PropTypes.string.isRequired,
};
