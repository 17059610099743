import * as React from 'react';
import PropTypes from 'prop-types';

import { P } from '@decisiv/ui-components/lib/components/Typography';

import { t } from '@lingui/macro';

import ClickToCallButton from 'components/ClickToCallButton';

import AssetGroupingData from './AssetGroupingData';

function AssetGroupingPhone({ assetGrouping }) {
  if (!assetGrouping.primaryPhoneNumber) return null;

  return (
    <AssetGroupingData
      css={`
        ${P}:first-of-type {
          position: relative;
          top: 4px;
        }
      `}
      data={
        <ClickToCallButton
          name={assetGrouping?.name}
          phone={assetGrouping.primaryPhoneNumber}
          size="small"
        />
      }
      titleText={t`Phone`}
    />
  );
}

AssetGroupingPhone.propTypes = {
  assetGrouping: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    primaryPhoneNumber: PropTypes.string,
  }),
};

AssetGroupingPhone.defaultProps = {
  assetGrouping: undefined,
};

export default AssetGroupingPhone;
