import React from 'react';

import { t, Trans } from '@lingui/macro';

import Message from '@decisiv/ui-components/lib/components/Message';
import Flex from '@decisiv/ui-components/lib/components/Flex';

import { P } from '@decisiv/ui-components/lib/components/Typography';

import RoadBlock from '@decisiv/iconix/lib/components/RoadBlock';

import Page from 'components/Page';

export default function NotFoundPage() {
  return (
    <Page css="height: 100vh">
      <Flex
        alignItems="center"
        css="height: 100%"
        justifyContent="center"
        flexDirection="column"
        width="100%"
      >
        <Message
          icon={RoadBlock}
          intent="warning"
          size="extraLarge"
          title={t`Saved Asset View Not Found`}
          variant="vertical"
        />
        <P marginY={0} shade={1}>
          <Trans>Make sure you have the correct URL.</Trans>
        </P>
        <P marginTop={0} shade={1}>
          <Trans>
            This asset view might have been deleted by a team admin.
          </Trans>
        </P>
      </Flex>
    </Page>
  );
}
