import React from 'react';
import { t } from '@lingui/macro';

import useSentinelTeam from 'hooks/useSentinelTeam';

import BaseTab from '../index';

const ASSIGNED_FILTERS = {
  onlyAssignedToMe: true,
};

const messages = {
  fleet: t`There is currently no activity for service events of depots assigned to you.`,
  depot: t`There is currently no activity for service events of customers assigned to you.`,
};

export default function AssignedActivityTab() {
  const { isFleet } = useSentinelTeam();

  const emptyMessage = isFleet ? messages.fleet : messages.depot;

  return (
    <BaseTab emptyResultMessage={emptyMessage} filters={ASSIGNED_FILTERS} />
  );
}
