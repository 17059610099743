import React from 'react';
import isEmpty from 'lodash/isEmpty';

import weights from '@decisiv/design-tokens/lib/typography/weights';

function escapeRegExp(string) {
  return string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export default function highlightString(
  value,
  text,
  options = { includeDash: true },
) {
  const { includeDash } = options;
  if ((!includeDash && isEmpty(value)) || !text) return value;

  if (isEmpty(value) && includeDash) {
    return value || '—';
  }

  const re = new RegExp(`\\b(${escapeRegExp(text)})`, 'gi');
  const parts = value.split(re);
  if (parts.length === 1) {
    return value;
  }

  for (let i = 1; i < parts.length; i += 2) {
    parts[i] = (
      <span
        data-testid="highlighted-string"
        key={`value-${i}-text`}
        css={`
          font-weight: ${weights.alias.extrabold};
        `}
      >
        {parts[i]}
      </span>
    );
  }
  return parts;
}
