/* istanbul ignore file -- @preserve */

import noop from 'lodash/noop';
import bugsnag from '@bugsnag/js';
import { beforeSendFlattenBreadcrumbs } from './bugsnagBreadcrumbLink';

const notifyReleaseStages = (
  import.meta.env.VITE_BUGSNAG_NOTIFY_RELEASE_STAGES || ''
)
  .split(',')
  .filter(Boolean);

const maxBreadcrumbs =
  parseInt(import.meta.env.VITE_BUGSNAG_MAX_BREADCRUMBS, 10) || 20;

function beforeBugsnagSend(report) {
  beforeSendFlattenBreadcrumbs(report);
  // add any other functions for customizing the report...
}

/**
 * Creates an ENV appropriate bugsnagClient. In the test env, bugsnagClient is mocked out.
 * This is required because the @bugsnag/js package must run in a browser, and can not run
 * in node, where our tests are run.
 * @returns {object} the configured bugsnag client.
 */
function create() {
  // The @bugsnag/js library is designed to run exclusively in the browser. However, our test
  // env runs in node. The bugsnag client must be mocked in the test env.
  if (import.meta.env.MODE === 'test') {
    return {
      notify: noop,
      use: noop,
    };
  }

  // const bugsnag = require('@bugsnag/js').default;

  // Configures BugSnag to only send notifications from a production build.
  const bugsnagClient = bugsnag({
    apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
    releaseStage:
      import.meta.env.VITE_BUGSNAG_RELEASE_STAGE || import.meta.env.MODE,
    notifyReleaseStages,
    maxBreadcrumbs,
    beforeSend: beforeBugsnagSend,
  });

  // Sets the app version reported to bugsnag to the version currently in
  // the package.json file.
  bugsnagClient.app.version = import.meta.env.VITE_VERSION;

  // User info is set in ./registerBugsnagUserLink
  bugsnagClient.user = {};

  return bugsnagClient;
}

const bugsnagClientFactory = { create };

export default bugsnagClientFactory;
