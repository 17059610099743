import React, { useCallback, useRef, useMemo } from 'react';

import { t } from '@lingui/macro';

import Plus from '@decisiv/iconix/lib/components/Plus';
import UsersGroup from '@decisiv/iconix/lib/components/UsersGroup';

import Button from '@decisiv/ui-components/lib/components/Button';

import WizardModal from 'components/WizardModal';

import Description from './Description';

import TeamTypeStep from './TeamTypeStep';
import TeamProfileStep from './TeamProfileStep';
import TeamSettingsStep from './TeamSettingsStep';

import { useTeamModalContext } from './contexts/TeamModalContext';

export default function CreateTeamModal() {
  const wizardRef = useRef(null);

  const { canSubmit, createTeam, createLoading, reset } = useTeamModalContext();

  const actions = useMemo(
    () => [
      {
        text: t`Create Team`,
        disabled: !canSubmit || createLoading,
        loading: createLoading,
        onClick: async () => {
          await createTeam();
          wizardRef.current?.handleToggleModalOpen();
          wizardRef.current?.resetModalState();
          reset();
        },
      },
      {
        text: t`Cancel`,
        onClick: () => {
          wizardRef.current?.handleToggleModalOpen();
          wizardRef.current?.resetModalState();
          reset();
        },
      },
    ],
    [canSubmit, createLoading, createTeam, reset],
  );

  const steps = useCallback(
    () => [
      {
        component: TeamTypeStep,
        title: t`TYPE`,
      },
      {
        component: TeamProfileStep,
        title: t`PROFILE`,
      },
      {
        component: TeamSettingsStep,
        title: t`SETTINGS`,
      },
    ],
    [],
  );

  return (
    <WizardModal
      ref={wizardRef}
      title={t`Create Team`}
      description={Description}
      icon={UsersGroup}
      intent="information"
      target={<Button icon={Plus} text={t`Create Team`} />}
      steps={steps}
      actions={actions}
    />
  );
}
