import React, { useMemo, useCallback } from 'react';
import { loader } from 'graphql.macro';
import { t } from '@lingui/macro';

import Question from '@decisiv/iconix/lib/components/Question';
import EmptyResults from 'components/EmptyResults';

import { useQuery } from '@apollo/client';

import Flex from '@decisiv/ui-components/lib/components/Flex';

import useSentinelTeam from 'hooks/useSentinelTeam';
import Loading from 'components/Loading';
import DashboardCard from 'components/DashboardCard';
import { useNavigate } from 'react-router-dom';

import routeNames from 'setup/Routing/routeNames';

import ListChart from './ListChart';
import { mapData } from '../../../Reports/utils';

const alertsByDepot = loader('./mostAlertsByDepot.graphql');

export default function MostAlertsByDepotCard() {
  const navigate = useNavigate();
  const { data: alertData, loading } = useQuery(alertsByDepot);

  const onViewAll = useCallback(
    () => navigate(`${routeNames.alerts}?onlyActives=true`),
    [navigate],
  );

  const mappedAlertData = useMemo(
    () =>
      mapData({
        data: alertData?.alertReports,
        listReports: ['alertsByCustomer'],
      }),
    [alertData],
  );

  const {
    alertsByCustomer: { results = {} },
  } = mappedAlertData;

  const { isFleet } = useSentinelTeam();

  const cardTitle = isFleet
    ? t`Most Alerts by Depot`
    : t`Most Alerts by Customer`;

  const infoText = isFleet
    ? t`View the Depots that require the most attention. Click on the depot links to go
to the Assets page with the depot filters automatically enabled.`
    : t`View the Customers that require the most attention. Click on the depot links to go
to the Assets page with the customer filters automatically enabled.`;

  return (
    <DashboardCard
      text={cardTitle}
      buttonText={t`View All`}
      buttonAriaLabel={t`View All Assets`}
      buttonDisabled={!results?.length}
      infoText={infoText}
      onButtonClick={onViewAll}
      marginTop={0}
    >
      <Flex marginTop={2.5}>
        {loading && <Loading />}
        {!loading && !!results.length && (
          <ListChart data={mappedAlertData.alertsByCustomer} />
        )}

        {!loading && !results?.length && (
          <EmptyResults
            padding={2}
            icon={Question}
            color="licoriceMousse"
            title={t`No Alerts`}
            subtitle={t`There are currently no alerts.`}
          />
        )}
      </Flex>
    </DashboardCard>
  );
}
