import { QueryHookOptions, useQuery } from '@apollo/client';

import query from './buildUrl.graphql';

const useBuildUrl = (
  options: QueryHookOptions<BuildUrlQuery, BuildUrlQueryVariables>,
) => {
  return useQuery<BuildUrlQuery, BuildUrlQueryVariables>(query, options);
};

export default useBuildUrl;
