/* istanbul ignore file -- @preserve */

import { flow, getOr, map, filter, flatten } from 'lodash/fp';

export const getMutationNames = flow(
  getOr([], 'query.definitions'),
  filter(({ operation: op }) => op === 'mutation'),
  map('selectionSet.selections'),
  flatten,
  map('name.value'),
);
