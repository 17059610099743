import React from 'react';
import PropTypes from 'prop-types';
import compact from 'lodash/compact';
import { Helmet } from 'react-helmet';

import useSentinelTeam from 'hooks/useSentinelTeam';

export default function PageHead({ titleParts }) {
  const { name } = useSentinelTeam();
  const title = compact([...titleParts, name]).join(' • ');
  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  );
}

PageHead.propTypes = {
  titleParts: PropTypes.arrayOf(PropTypes.string).isRequired,
};
