import {
  createContext,
  useState,
  useMemo,
  useContext,
  useCallback,
} from 'react';

import useSentinelTeamForm from 'components/SentinelTeamForm/hooks/useSentinelTeamForm';

import {
  SOURCE_NAME,
  isFleet as isFleetSource,
  SourceValidationError,
} from 'utils/SentinelTeam';

import useCreateSentinelTeamMutation from '../../hooks/useCreateSentinelTeamMutation';
import { TeamSource } from '../TeamSettingsStep/SourceItem/types';

export const CONTEXT_MODE = {
  EDIT: 'edit',
  CREATE: 'create',
} as const;

interface TeamModalValues {
  teamName: string;
  applicationName: string;
  teamKey: string;
  teamType?: SentinelTeamSourceEnum;
  sourceIds: TeamSource[];
  sources: any[];
  username: string;
  password: string;
  ownerEmail: string;
  mode: ObjectValues<typeof CONTEXT_MODE>;
  teamPurpose: InputMaybe<SentinelTeamPurposeEnum>;
  teamSegment: InputMaybe<SentinelTeamSegmentEnum>;
}

interface TeamModalContextProviderType {
  values: TeamModalValues;
  setValues: React.Dispatch<React.SetStateAction<TeamModalValues>>;
  isFleet: boolean;
  sourceName?: string;
  sourceIdsWithErrors: TeamSource[];
  invalidEmailSources: TeamSource[];
  isEditMode: boolean;
  sourceIdError: SourceValidationError[];
  sourceIdsValid: boolean;
  setSourceIdError: React.Dispatch<
    React.SetStateAction<SourceValidationError[]>
  >;
  canGoNext: (page: number) => boolean;
  reset: () => void;
}

export const defaultValue = {
  teamName: '',
  applicationName: '',
  teamKey: '',
  teamType: undefined,
  sourceIds: [],
  sources: [],
  username: '',
  password: '',
  ownerEmail: '',
  mode: CONTEXT_MODE.CREATE,
  teamPurpose: undefined,
  teamSegment: undefined,
} satisfies TeamModalValues;

const INITIAL_VALUES: TeamModalContextProviderType = {
  values: defaultValue,
  setValues: () => null,
  isFleet: false,
  sourceName: undefined,
  sourceIdsWithErrors: [],
  invalidEmailSources: [],
  isEditMode: false,
  sourceIdError: [],
  sourceIdsValid: false,
  setSourceIdError: () => null,
  canGoNext: (_number) => false,
  reset: () => null,
};

export const TeamModalContext =
  createContext<TeamModalContextProviderType>(INITIAL_VALUES);

interface TeamModalProviderType {
  initialValue: TeamModalValues;
}

export default function TeamModalProvider({
  children,
  initialValue,
}: React.PropsWithChildren<TeamModalProviderType>) {
  const [values, setValues] = useState(initialValue || defaultValue);
  const [{ logoUrl }, { uploadImage, onSaveImage }] = useSentinelTeamForm({});
  const [sourceIdError, setSourceIdError] = useState<SourceValidationError[]>(
    [],
  );

  const {
    teamName,
    applicationName,
    teamKey,
    teamType,
    sourceIds,
    username,
    password,
    ownerEmail,
    mode,
    teamPurpose,
    teamSegment,
  } = values;

  // Determine wether source is valid or not based on sourceIds array
  const sourceIdsValid = useMemo(
    () => sourceIds.every((src) => src.valid === true),
    [sourceIds],
  );

  // Determine wether ownerEmail is valid or not based on sourceIds array
  const emailValid = useMemo(
    () => sourceIds.some((src) => src.emailValid === true),
    [sourceIds],
  );

  // Return an array of validated sourceIds which have invalid email
  const invalidEmailSources = useMemo(
    () =>
      sourceIds.filter(
        (src) => src.emailValid === false && src.validated === true,
      ),
    [sourceIds],
  );

  // Return all sourceIds with errors
  const sourceIdsWithErrors = useMemo(
    () =>
      sourceIds.filter((src) => src.valid === false && src.validated === true),
    [sourceIds],
  );

  // Determine if user can submit the form or not
  const canSubmit = useMemo(
    () =>
      !!teamName &&
      !!teamKey &&
      !!username &&
      !!password &&
      !!ownerEmail &&
      sourceIdsValid &&
      emailValid,
    [
      emailValid,
      ownerEmail,
      password,
      sourceIdsValid,
      teamKey,
      teamName,
      username,
    ],
  );

  // Determine if sourceType is equal FLEET
  const isFleet = useMemo(() => isFleetSource(teamType), [teamType]);

  // Build translated sourceType name
  const sourceName = useMemo(() => {
    if (!teamType) return undefined;

    return SOURCE_NAME[teamType];
  }, [teamType]);

  // Determine if user can navigate to the next page based on given page number
  const canGoNext = useCallback(
    (pageNumber: number) => {
      if (pageNumber === 1) return !!teamType;

      if (pageNumber === 2) return !!teamKey && !!teamName;

      return false;
    },
    [teamType, teamKey, teamName],
  );

  // Determine whether is edit mode for context
  const isEditMode = useMemo(() => mode === CONTEXT_MODE.EDIT, [mode]);

  const [createTeamMutation, { loading: createLoading }] =
    useCreateSentinelTeamMutation({
      onCompleted: ({ createSentinelTeam }) => {
        if (createSentinelTeam?.sentinelTeam?.id && uploadImage)
          uploadImage(createSentinelTeam.sentinelTeam.id);
      },
    });

  // Triggers API to create a team
  const createTeam = useCallback(async () => {
    if (!teamType) return;

    await createTeamMutation({
      variables: {
        teamKey,
        applicationName,
        name: teamName,
        teamType,
        sources: sourceIds.map(({ value, sourceType }) => {
          return {
            sourceId: value,
            sourceType,
          };
        }),
        ownerEmail,
        username,
        password,
        teamPurpose,
        teamSegment,
      },
    });
  }, [
    applicationName,
    createTeamMutation,
    ownerEmail,
    password,
    sourceIds,
    teamType,
    teamKey,
    teamName,
    username,
    teamPurpose,
    teamSegment,
  ]);

  const reset = useCallback(() => {
    setValues(initialValue || defaultValue);
  }, [initialValue]);

  // The current context values
  const contextValue = useMemo(
    () => ({
      values,
      setValues,
      canSubmit,
      createTeam,
      createLoading,
      canGoNext,
      isFleet,
      sourceName,
      invalidEmailSources,
      sourceIdsWithErrors,
      logoUrl,
      isEditMode,
      onSaveImage,
      reset,
      sourceIdsValid,
      sourceIdError,
      setSourceIdError,
    }),
    [
      canGoNext,
      canSubmit,
      createLoading,
      createTeam,
      invalidEmailSources,
      isEditMode,
      isFleet,
      logoUrl,
      onSaveImage,
      reset,
      sourceIdsValid,
      sourceIdsWithErrors,
      sourceName,
      values,
      sourceIdError,
    ],
  );

  return (
    <TeamModalContext.Provider value={contextValue}>
      {children}
    </TeamModalContext.Provider>
  );
}

export function useTeamModalContext() {
  const contextValues = useContext(TeamModalContext);

  return contextValues;
}
