/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

/**
 * Returns `true` if the given link destination should be handled via
 * normal browser link handling; false if we should treat it as internal
 * navigation.
 */
function isNormalUrl(url) {
  return ['http', 'mailto:', 'tel:'].some((value) => url.startsWith(value));
}

const LinkTo = forwardRef(({ to, children, ...otherProps }, ref) => {
  if (!to) return <>{children}</>;

  if (isNormalUrl(to)) {
    return (
      <a ref={ref} href={to} {...otherProps}>
        {children}
      </a>
    );
  }

  return (
    <Link ref={ref} to={to} {...otherProps}>
      {children}
    </Link>
  );
});

LinkTo.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node.isRequired,
};

LinkTo.defaultProps = {
  to: null,
};

export default LinkTo;
