import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import toColorString from 'polished/lib/color/toColorString';
import { plural, t } from '@lingui/macro';

import color from '@decisiv/design-tokens/lib/color';

import assetTypeText from 'utils/Asset';

import {
  alertColors,
  alertIcons,
  alertFilterIcons,
  MAX_ALERTS,
  severityFilterIcons,
  SEVERITY_TEXT,
  TIME_TEXT,
  alertBadgeProps,
} from './constants';

export {
  FAULT_COLORS,
  FAULT_TIMES,
  MAINTENANCE_COLORS,
  WARRANTY_TIMES,
  BUILTIN_ALERT_IDS,
} from './constants';

export const iconForAlertSourceTypename = (__typename) =>
  alertIcons[__typename];

export const iconForAlert = ({ type }) =>
  alertIcons[type] || alertFilterIcons[type] || alertIcons.CustomAlert;

export const iconForAlertBadge = ({ type }) =>
  severityFilterIcons[type] || alertIcons.CustomAlert;

export const badgeStyleForSeverity = (severity) =>
  severity
    ? alertColors[severity.toUpperCase() || 'UNKNOWN']
    : 'licoriceMousse';

export const badgeStyleForAlert = (alert) => {
  return isEmpty(alert)
    ? 'licoriceMousse'
    : alertColors[alert?.severity?.toUpperCase() || 'UNKNOWN'];
};
export const badgeColorForAlert = (alert) => {
  const colorString = badgeStyleForAlert(alert);

  return toColorString(
    (color.visualInterest[colorString] || color.status[colorString]).medium,
  );
};

export function badgeColorBySeverity(severity) {
  return badgeColorForAlert(alertBadgeProps[(severity || 1) - 1]);
}

export const alertTypeText = (alert, count = 1, title = undefined) => {
  const alertType = alert?.source?.__typename || alert;
  switch (alertType) {
    case 'preventative_maintenance': // This keeps tracks of the __typename and the filter type value
    case 'ScheduledMaintenance':
      return plural(count, {
        one: 'Scheduled Maintenance',
        other: 'Scheduled Maintenance',
      });
    case 'faults':
    case 'Fault':
      return plural(count, {
        one: 'Recent Fault',
        other: 'Recent Faults',
      });
    case 'recalls':
    case 'Recall':
      return plural(count, {
        one: 'Active Recall',
        other: 'Active Recalls',
      });
    case 'warranties':
    case 'Warranty':
      return plural(count, {
        one: 'Expiring Warranty',
        other: 'Expiring Warranties',
      });
    case 'CustomAlert':
      return alert?.source?.customAlertConfig?.alertType;
    default:
      return title || null;
  }
};

export const advancedFiltersTypes = {
  assetTypes: t`Type`,
  makes: t`Make`,
  models: t`Model`,
  workInProgress: t`Work in Progress`,
  years: t`Year`,
  assignees: t`Assignee`,
};

export const withSort = (alerts) =>
  sortBy(alerts, (a) => {
    switch (a.severity?.toUpperCase()) {
      case 'SEVERE':
      case 'OVERDUE':
        return 1;
      case 'ATTENTION':
      case 'DUE':
        return 2;
      case 'MODERATE':
        return 3;
      default:
        return 4;
    }
  });

export const textForSeverity = (alert) =>
  SEVERITY_TEXT[alert.severity?.toUpperCase()] || t`Unknown`;

export const textForTime = (time) => TIME_TEXT.get(time);

export const truncatedValue = (arrLength) =>
  Math.max(0, arrLength - MAX_ALERTS);

export const wipFilterLabels = {
  APPOINTMENTS: t`Service Events`,
  CASES: t`Cases`,
  SERVICE_REQUESTS: t`Service Requests`,
};

export const wipFilterKeys = Object.keys(wipFilterLabels).reduce((acc, key) => {
  acc[key] = key;
  return acc;
}, {});

export function buildAssetFilterItems(filters) {
  return filters?.map((filter) => ({
    id: filter,
    label: assetTypeText(filter),
    value: filter,
  }));
}

export function buildFilterItems(filters) {
  return filters?.map((filter) => ({
    id: filter,
    label: wipFilterLabels[filter] || filter,
    value: filter,
  }));
}

export function buildFilterOptions(filters) {
  if (isEmpty(filters)) return [];

  return filters?.map((filter) => ({
    id: filter,
    label: filter,
    value: filter,
  }));
}

export function getActiveFilter(filters) {
  const obj = Object.entries(filters).find(([_key, value]) => value.length > 0);

  const alertType = obj.reduce((acc) => ({
    key: acc,
  })).key;

  return advancedFiltersTypes[alertType];
}

export const alertConfigurationMapper = {
  preventative_maintenance: 'ScheduledMaintenance',
  faults: 'Fault',
  warranties: 'Warranty',
  recalls: 'Recall',
};

export function findConfigById(id, alertConfig) {
  return omit(
    alertConfig.find(({ id: alertId }) => id === alertId),
    ['id', '__typename'],
  );
}

export const ALERT_TYPES = {
  WARRANTY: 'Warranty',
  SCHEDULED_MAINTENANCE: 'ScheduledMaintenance',
  FAULT: 'Fault',
  RECALL: 'Recall',
};

export function mapSeverityNumbers(alerts) {
  return alerts.map((alert) => {
    const { severity } = alert;

    const numericSeverity = Number(severity);
    const severityNumber = Number.isNaN(numericSeverity)
      ? { severe: 4, attention: 3, moderate: 2 }[severity] || 1
      : numericSeverity;

    return { ...alert, severityNumber };
  });
}

export const CUSTOM_ALERT_SEVERITY = {
  1: t`Trivial`,
  2: t`Low`,
  3: t`Moderate`,
  4: t`Critical`,
};

// List of filters which are tracked for `change` in asset page/view
export const TRACKED_ASSET_FILTERS = [
  'alertTypes',
  'alertsText',
  'customAlerts',
  'assetTypes',
  'makes',
  'years',
  'models',
  'workInProgress',
  'assignees',
  'platformCaseFleetStatus',
  'platformCaseRepairStatus',
  'serviceEventStatus',
  'serviceRequestStatus',
  'scheduleMaintenanceStatus',
  'faultStatus',
  'customAlertStatus',
  'serviceEventCreatedBetween',
  'serviceRequestCreatedBetween',
  'serviceRequestUpdatedBetween',
  'platformCaseCreatedBetween',
  'platformCaseUpdatedBetween',
  'serviceProviderUuids',
  'serviceProviderCityIds',
  'costCenters',
];
