/* istanbul ignore file -- @preserve */
/* eslint-disable react/sort-comp */

import React from 'react';
import PropTypes from 'prop-types';
import loadJS from 'loadjs';
import find from 'lodash/find';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import includes from 'lodash/includes';
import noop from 'lodash/noop';
import pull from 'lodash/pull';
import trim from 'lodash/trim';
import union from 'lodash/union';

import { parseQueryParams } from 'utils/url';

import isConfigEnabled from 'utils/isConfigEnabled';
import {
  CONNECT_ENDPOINTS,
  CONVERSATION_STATES,
  DEFAULT_CONNECT_STREAMS_JS,
  JS_LOADING_STATES,
  QUERY_PARAM_OUTGOING_CALL_OVERRIDE,
  STATE_NAMES,
  UI_STATES,
  UI_STATE_BUTTONS,
} from './constants';
import { connectURLForInstanceEndpoint, showPopup } from './utils';
import AmazonConnectHelper from './AmazonConnectHelper';

// exported so we can pass to the React.useContext hook
export const AmazonConnectContext = React.createContext({});

const enableOutboundCalls = () =>
  isConfigEnabled(import.meta.env.VITE_ENABLE_OUTBOUND_CALLS);

const DEBUG = false;

// Names of events that context consumers can register interest in:
const EVENT_CONTACT_CONNECTING = 'contactConnecting';

/**
 * Context provider (actually, _EVERYTHING_ provider) for the
 * Amazon Connect stuff.
 */
export class AmazonConnectProvider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      jsLoadingState: JS_LOADING_STATES.NONE,
      uiState: UI_STATES.DISCONNECTED,
      callState: STATE_NAMES.DISCONNECTED,
      agent: null,
      contact: null,
    };

    // look for the special "outgoing call override" query-string param
    const { location } = props;
    if (location && location.search) {
      const queryParams = parseQueryParams(location.search);
      const outgoingCallOverride =
        queryParams[QUERY_PARAM_OUTGOING_CALL_OVERRIDE] || '';

      if (outgoingCallOverride) {
        // eslint-disable-next-line no-console
        console.warn(
          '[OUTGOING CALL OVERRIDE]',
          `Outgoing calls will be ENABLED and all outbound calls will be routed to "${outgoingCallOverride}".`,
        );
        this.state.outgoingCallOverride = outgoingCallOverride;
      }
    }
  }

  static propTypes = {
    children: PropTypes.node.isRequired,
    jsURL: PropTypes.string,
    location: PropTypes.shape({
      search: PropTypes.string.isRequired,
    }),
  };

  static defaultProps = {
    jsURL: DEFAULT_CONNECT_STREAMS_JS,
    location: undefined,
  };

  /**
   * Helper to get the connect-streams built-in logger.
   */
  logger = () => this.connect && this.connect.getLog();

  /*=======================================================================
   * INITIALIZATION
   *=======================================================================*/

  initialized = () => !!(this.connect && this.connect.core.initialized);

  /**
   * Initialize the Amazon Connect CCP. Loads the connect-streams
   * javascript, initializes via `initCCP`, then sets up the Amazon
   * Connect login window.
   *
   * Initialization is only allowed once. The first call to this will
   * setup the CCP to render into the given `container`. Any subsequent
   * calls will be ignored.
   *
   * @param {string} instanceName The Amazon Connect instance alias
   * @param {HTMLElement} container The DOM node to load the CCP into.
   */
  initializeConnect = (instanceName, container) => {
    const { jsLoadingState } = this.state;

    if (
      jsLoadingState === JS_LOADING_STATES.LOADED &&
      this.connect &&
      instanceName
    ) {
      const initialized = this.initialized();
      if (container && !initialized) {
        this.setState({ callState: STATE_NAMES.INITIALIZING }, () => {
          this.logger().info(
            '🐣🐣🐣🐣🐣 Initializing Amazon Connect... 🐣🐣🐣🐣🐣',
          );
          this.connect.core.initCCP(container.current, {
            ccpUrl: connectURLForInstanceEndpoint(
              instanceName,
              CONNECT_ENDPOINTS.CCP,
            ),
            loginPopup: false,
            softphone: {
              allowFramedSoftphone: true,
              disableRingtone: false,
            },
          });

          this.onAckTimeout(this.makeShowLoginWindow(instanceName));
          this.onAcknowledge(this.closeLoginWindow);
          this.onTerminated(() => {
            this.setCallState({
              uiState: UI_STATES.DISCONNECTED,
              callState: STATE_NAMES.DISCONNECTED,
            });
          });

          if (DEBUG) {
            this.connect.core
              .getEventBus()
              .subscribeAll((data, eventName, eventBus) => {
                if (
                  !includes(
                    ['log', 'master_response', 'api_response'],
                    eventName,
                  )
                ) {
                  // eslint-disable-next-line no-console
                  console.log(
                    '[EVENT RECEIVED] => \n\teventName:',
                    eventName,
                    '\n\tdata:',
                    data,
                    '\n\teventBus:',
                    eventBus,
                  );
                }
              });
          }

          this.connect.agent(this.setupAgent);
          this.connect.contact(this.setupContact);
        });
      }
    } else {
      // load the javascript then try again to initialize...
      this.loadAmazonConnectJS(() =>
        this.initializeConnect(instanceName, container),
      );
    }
  };

  terminateConnect = () => {
    const { jsLoadingState } = this.state;

    if (jsLoadingState === JS_LOADING_STATES.LOADED && this.connect) {
      this.connect = window && window.connect;
      this.logger().info('☠️☠️☠️☠️☠️ terminating Amazon Connect ☠️☠️☠️☠️☠️');
      connect.core.terminate();
      this.setState({
        jsLoadingState: JS_LOADING_STATES.TERMINATED,
        callState: STATE_NAMES.DISCONNECTED,
        uiState: UI_STATES.DISCONNECTED,
        stateStartTime: null,
        thirdParty: {},
        contactNumber: null,
      });
    }
  };

  /**
   * Asynchronously inject the connect-streams javascript into
   * the current document. The `callback` will be executed after
   * the javascript has been loaded.
   *
   * @param {function} callback
   */
  loadAmazonConnectJS = (callback) => {
    const { jsLoadingState } = this.state;
    if (
      !includes(
        [JS_LOADING_STATES.NONE, JS_LOADING_STATES.TERMINATED],
        jsLoadingState,
      )
    ) {
      return;
    }

    this.setState(
      {
        jsLoadingState: JS_LOADING_STATES.STARTED,
        callState: STATE_NAMES.INITIALIZING,
      },
      () => {
        // async load the JS file...
        const { jsURL } = this.props;
        loadJS(jsURL, {
          success: () => {
            this.setState(
              {
                jsLoadingState: JS_LOADING_STATES.LOADED,
                callState: STATE_NAMES.INITIALIZING,
              },
              () => {
                this.connect = window && window.connect;
                this.helper =
                  this.connect && new AmazonConnectHelper(this.connect);
                // then try again to initialize...
                callback();
              },
            );
          },
          error: (pathsNotFound) => {
            // eslint-disable-next-line no-console
            console.error(
              `Amazon Connect javascript could not be loaded from ${pathsNotFound}`,
            );
            this.setState({
              jsLoadingState: JS_LOADING_STATES.NONE,
              callState: STATE_NAMES.ERROR,
            });
          },
        });
      },
    );
  };

  /**
   * Register a callback to be executed on the `ACK_TIMEOUT` event.
   * Used to trigger the display of the login window.
   * @param {function} callback
   */
  onAckTimeout = (callback) => {
    this.connect.core
      .getEventBus()
      .subscribe(this.connect.EventType.ACK_TIMEOUT, callback);
  };

  /**
   * Register a callback to be executed on the `ACKNOWLEDGE` event.
   * Used to trigger the removal of the login window.
   * @param {function} callback
   */
  onAcknowledge = (callback) => {
    this.connect.core
      .getEventBus()
      .subscribe(this.connect.EventType.ACKNOWLEDGE, callback);
  };

  onTerminated = (callback) => {
    this.connect.core
      .getEventBus()
      .subscribe(this.connect.EventType.TERMINATED, callback);
  };

  /*=======================================================================
   * LOGIN
   *=======================================================================*/

  makeShowLoginWindow = (instanceName) => () => {
    this.logger().info('Opening the Amazon Connect login window');

    if (!instanceName) {
      throw new Error(
        'Amazon Connect login URL not defined. Please set the VITE_AMAZON_CONNECT_LOGIN_URL environment variable.',
      );
    }

    try {
      const popupUrl = connectURLForInstanceEndpoint(
        instanceName,
        CONNECT_ENDPOINTS.LOGIN,
      );
      this.loginWindow = showPopup(popupUrl);
    } catch (e) {
      this.logger()
        .error('Unable to open Amazon Connect login window')
        .withException(e);
      throw e; // let Bugsnag report the error
    }
  };

  closeLoginWindow = () => {
    if (this.loginWindow) {
      this.loginWindow.close();
      this.loginWindow = null;
    }
  };

  /*=======================================================================
   * COMPONENT STATE UPDATES
   *=======================================================================*/

  setCallState = (updates, callback = noop) => {
    const stateUpdates = {
      contactNumber: this.helper.getContactNumber(),
      ...updates,
    };

    const contact = this.helper.getVoiceContact();
    if (contact) {
      // TODO: better understanding of the following logic
      // I'm not sure what the following logic (lifted from the default CCP)
      // is intended to capture... so, I'll just leave it as-is...
      const initialConnection = contact.getActiveInitialConnection();
      const thirdPartyConnection =
        contact.getSingleActiveThirdPartyConnection();
      const primaryConnection = initialConnection || thirdPartyConnection;
      if (primaryConnection && primaryConnection === thirdPartyConnection) {
        stateUpdates.contactNumber = this.helper.getThirdPartyContactNumber();
      }
    }

    this.setState(
      (state) => ({
        stateStartTime: this.helper.getStateStartTime(
          stateUpdates.callState || state.callState,
        ),
        contact,
        agent: this.agent,
        ...stateUpdates,
      }),
      callback,
    );
  };

  setThirdPartyState = (updates) => {
    this.setState({
      thirdParty: {
        contactNumber: this.helper.getThirdPartyContactNumber(),
        ...updates,
      },
    });
  };

  /*=======================================================================
   * AGENT EVENT HANDLING
   *=======================================================================*/

  /**
   * Setup all the event handlers for the agent.
   * @param {Agent} agent
   */
  setupAgent = (agent) => {
    /*
    Some things we can find from the `agent`:

    console.log('agent.getPermissions():', agent.getPermissions());
    console.log('agent.getRoutingProfile():', agent.getRoutingProfile());
    console.log('agent.getContacts():', agent.getContacts());
    console.log('agent.getAgentStates():', agent.getAgentStates());
    console.log('agent.getName():', agent.getName());
    console.log('agent.getExtension():', agent.getExtension());
    console.log('agent.isSoftphoneEnabled():', agent.isSoftphoneEnabled());
    console.log('agent.getConfiguration():', agent.getConfiguration());
    */

    this.agent = agent;
    agent.onStateChange(this.agentStateChange);
    agent.onRefresh(this.agentRefresh);
    agent.onRoutable(this.agentRoutable);
    agent.onNotRoutable(this.agentNotRoutable);
    agent.onMuteToggle(this.muteToggle);
    agent.onOffline(this.agentOffline);
    // TODO: agent.onSoftphoneError
    agent.onError(this.agentError);
  };

  agentStateChange = ({ oldState, newState }) => {
    // The oldState and newState are just the status names.

    this.logger().info(
      `agent state changing from '${oldState}' to '${newState}'`,
    );
  };

  agentRefresh = (agent) => {
    const stateUpdate = {};

    const { name: agentStatusName, type: agentStatusType } = agent.getStatus();

    const { uiState } = this.state;
    if (uiState === UI_STATES.AUX) {
      stateUpdate.callState = agentStatusName;
    }

    if (!this.helper.getVoiceContact()) {
      stateUpdate.callState = agentStatusName;
      if (agentStatusType === this.connect.AgentStatusType.ROUTABLE) {
        stateUpdate.uiState = UI_STATES.AVAILABLE;
      } else if (uiState !== UI_STATES.AUX) {
        // we do not want to change ALL unroutable statuses to the OFFLINE
        // UI state since the UI states are also used to determine whether
        // outbound calls can be made -see MICONCALL-1827
        stateUpdate.uiState = UI_STATES.OFFLINE;
      }
    }

    this.setCallState(stateUpdate);
  };

  agentRoutable = (_agent) => {
    this.setCallState({
      uiState: UI_STATES.AVAILABLE,
      lastAgentState: this.helper.getCurrentAgentState(),
    });
  };

  agentNotRoutable = (agent) => {
    const agentState = agent.getStatus().name;
    this.setCallState({
      uiState: UI_STATES.AUX,
      callState: agentState,
      lastAgentState: this.helper.getCurrentAgentState(),
    });
  };

  muteToggle = ({ muted }) => {
    this.setState({ muted });
  };

  agentOffline = (agent) => {
    this.setCallState({
      uiState: UI_STATES.OFFLINE,
      callState: agent.getStatus().name,
      lastAgentState: this.helper.getCurrentAgentState(),
    });
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  setAgentStateAfterTimeout = (stateSetter) => {
    this.stateSettingTimer = setTimeout(() => {
      clearTimeout(this.stateSettingTimer);
      stateSetter();
    }, 3000);
  };

  agentError = (agent) => {
    const agentStatus = agent.getStatus().name;

    if (agentStatus === this.connect.AgentErrorStates.MISSED_CALL_AGENT) {
      // the agent missed an incoming call - show the "call missed status"
      this.setCallState({
        uiState: UI_STATES.MISSED,
        callState: this.helper.getCallError(agentStatus),
      });
    } else if (
      agentStatus === this.connect.AgentErrorStates.FAILED_CONNECT_AGENT
    ) {
      // Switch to "Working On Case"
      // https://decisiv.atlassian.net/browse/MICONCALL-1930
      this.helper.changeAgentState(this.helper.getWorkingOnCaseState());
    } else if (
      this.helper.getQueueCallBackContact() != null &&
      agentStatus === this.connect.AgentErrorStates.DEFAULT
    ) {
      // the agent missed a queued call-back
      this.setCallState({
        uiState: UI_STATES.MISSED,
        callState: this.helper.getCallError(
          this.connect.AgentErrorStates.MISSED_QUEUE_CALLBACK,
        ),
      });
    } else {
      // some other error
      this.setCallState({
        uiState: UI_STATES.ERROR,
        callState: this.helper.getCallError(agentStatus),
      });
    }
  };

  /*=======================================================================
   * CONTACT EVENT HANDLING
   *=======================================================================*/

  /**
   * Setup all the event handlers for the Contact.
   * @param {Contact} contact
   */
  setupContact = (contact) => {
    this.setState({ contactId: contact.getContactId() });

    contact.onIncoming(this.contactIncoming);
    contact.onConnecting(this.contactConnecting);
    contact.onMissed(this.contactMissed);
    contact.onACW(this.contactAfterCallWork);
    contact.onAccepted(this.contactAccepted);
    contact.onConnected(this.contactConnected);
    contact.onEnded(this.contactEnded);
    contact.onRefresh(this.contactRefreshed);
  };

  contactIncoming = (_contact) => {
    // TODO: remove this? Looks like onIncoming is not actually being triggered
    this.setCallState({
      uiState: UI_STATES.INCOMING,
      callState: STATE_NAMES.INCOMING,
    });
  };

  contactConnecting = (contact) => {
    const eventNotify = () => {
      this.triggerListeners(EVENT_CONTACT_CONNECTING, this.state);
    };

    if (contact.isInbound()) {
      this.setCallState(
        {
          uiState: UI_STATES.INBOUND,
          callState: STATE_NAMES.INBOUND,
        },
        eventNotify,
      );
    } else {
      this.setCallState(
        {
          uiState: UI_STATES.OUTBOUND,
          callState: STATE_NAMES.OUTBOUND,
        },
        eventNotify,
      );
    }
  };

  contactMissed = (_contact) => {
    // TODO: remove this? Looks like onMissed is not actually being triggered
    this.setCallState({
      uiState: UI_STATES.MISSED,
      callState: STATE_NAMES.MISSED_CALL,
    });
  };

  contactAfterCallWork = (_contact) => {
    this.setCallState({ uiState: UI_STATES.ACW, callState: STATE_NAMES.ACW });
  };

  contactAccepted = (_contact) => {
    this.setCallState({
      uiState: UI_STATES.CONNECTING,
      callState: STATE_NAMES.CONNECTING,
    });
  };

  contactConnected = (contact) => {
    const initialConnection = contact.getActiveInitialConnection();
    if (
      initialConnection &&
      initialConnection.getType() === this.connect.ConnectionType.MONITORING
    ) {
      // we are monitoring some other agent's call
      this.setCallState({
        uiState: UI_STATES.MONITORING,
        callState: STATE_NAMES.MONITORING,
      });
    } else {
      // we're directly connected
      this.setCallState({
        uiState: UI_STATES.CONNECTED,
        callState: STATE_NAMES.CONNECTED,
      });
    }
  };

  contactEnded = (_contact) => {
    this.setState({ thirdParty: {} });
  };

  contactRefreshed = (contact) => {
    this.setState({ thirdParty: {} });

    if (contact.isConnected()) {
      const agentConnection = contact.getAgentConnection();
      const initialConnection = contact.getInitialConnection();
      const thirdPartyConnection =
        contact.getSingleActiveThirdPartyConnection();

      const primaryConnection = initialConnection || thirdPartyConnection;

      if (primaryConnection) {
        if (primaryConnection.isConnected()) {
          this.setCallState({
            uiState: UI_STATES.CONNECTED,
            callState: STATE_NAMES.CONNECTED,
          });
        } else if (primaryConnection.isOnHold()) {
          this.setCallState({
            uiState: UI_STATES.ON_HOLD,
            callState: STATE_NAMES.ON_HOLD,
          });
        } else {
          this.setCallState({
            uiState: UI_STATES.DISCONNECTED,
            callState: STATE_NAMES.DISCONNECTED,
          });
        }

        if (initialConnection && thirdPartyConnection) {
          if (thirdPartyConnection.isConnecting()) {
            // currently calling out to a third party
            this.setThirdPartyState({
              uiState: UI_STATES.OUTBOUND,
              callState: STATE_NAMES.OUTBOUND,
            });
          } else if (
            initialConnection.isConnected() &&
            thirdPartyConnection.isConnected()
          ) {
            if (agentConnection.isOnHold()) {
              // on a three-way call, but I am on hold
              this.setCallState({
                uiState: UI_STATES.AGENT_HOLD,
                callState: STATE_NAMES.AGENT_HOLD,
              });
            } else {
              // we're on a three-way call
              this.setThirdPartyState({
                uiState: UI_STATES.CONNECTED,
                callState: STATE_NAMES.JOINED,
              });
              this.setCallState({
                uiState: UI_STATES.CONNECTED,
                callState: STATE_NAMES.JOINED,
              });
            }
          } else if (thirdPartyConnection.isConnected()) {
            this.setThirdPartyState({
              uiState: UI_STATES.CONNECTED,
              callState: STATE_NAMES.CONNECTED,
            });
          } else if (thirdPartyConnection.isOnHold()) {
            this.setThirdPartyState({
              uiState: UI_STATES.ON_HOLD,
              callState: STATE_NAMES.ON_HOLD,
            });
          }
        }
      }
    }
  };

  /*=======================================================================
   * Event notifications. Allows consumer child components to register
   * interest in certain events.
   *=======================================================================*/

  eventListeners = {
    [EVENT_CONTACT_CONNECTING]: [],
  };

  /**
   * Find all listener callback functions for a given event.
   * @param key {string} the name of the event
   * @return {Array<function>}
   */
  getListeners = (key) => get(this.eventListeners, key, []);

  /**
   * Add an event listener callback function for a given event type.
   * @param {string} key - the name of an event type
   * @param {function} callback - the function to call when the event is triggered
   * @return {function} a function that can later be called to un-register the listener
   */
  addListener = (key, callback) => {
    // add this callback to the event listeners for the given event type:
    this.eventListeners[key] = union(this.getListeners(key), [callback]);

    // return a function that can be used to later remove the listener
    return () => pull(this.getListeners(key), callback);
  };

  /**
   * Fire all the event listener callbacks for the event event type.
   * @param {string} key - the name of an event
   * @param {*} data - data to be passed to the event listeners
   */
  triggerListeners = (key, data) => {
    forEach(this.getListeners(key), (listener) => listener && listener(data));
  };

  /**
   * A function provided to context consumers to all registration of interest
   * in the "contact connected" event.
   * @param {function} callback - the function to call when the event is triggered
   * @return {function} a function that can later be called to un-register the listener
   */
  addContactConnectingListener = (callback) =>
    this.addListener(EVENT_CONTACT_CONNECTING, callback);

  /*=======================================================================
   * HELPERS for the action triggers
   *=======================================================================*/

  /**
   * Look for the current contact matching the contactId stored in state.
   */
  getContact = (agent) => {
    const { contactId } = this.state;
    if (!contactId) {
      return null;
    }

    const contact = agent
      .getContacts()
      .filter((c) => c.getContactId() === contactId)[0];

    if (!contact) {
      this.setState({ contactId: null });
    }

    return contact;
  };

  /**
   * Callback type for `withAgent`.
   * @callback agentAction
   * @param {Agent} agent
   */

  /**
   * Execute a callback, providing it the current agent. If there is
   * no current agent, the callback will be delayed until an agent
   * becomes available.
   * @param {agentAction} callback
   */
  withAgent = (callback) => {
    const { jsLoadingState } = this.state;

    if (jsLoadingState === JS_LOADING_STATES.LOADED && this.connect) {
      this.connect.agent(callback);
    }
  };

  /**
   * Callback type for `withContact`.
   * @callback contactAction
   * @param {Contact} contact
   * @param {Agent} agent
   */

  /**
   * Execute a callback, providing it the current contact. If there is
   * no current contact, the callback will be delayed until a contact
   * becomes available.
   * @param {contactAction} callback
   */
  withContact = (callback) => {
    this.withAgent((agent) => {
      const contact = this.getContact(agent);
      callback(contact, agent);
    });
  };

  /*=======================================================================
   * ACTION TRIGGERS - provided via context to children
   *=======================================================================*/

  goAvailable = () => {
    this.withAgent(() => {
      this.helper.changeAgentState(this.helper.getAvailableState());
    });
  };

  goOffline = () => {
    this.withAgent(() => {
      this.helper.changeAgentState(this.helper.getOfflineState());
    });
  };

  acceptContact = () => {
    this.withContact((contact) => {
      contact.accept({
        success() {
          this.logger().info('Contact successfully accepted');
        },
        failure(data) {
          this.logger().error('Failed to accept contact').withObject(data);
        },
      });
    });
  };

  disconnectContact = () => {
    this.withContact((contact) => {
      contact.getAgentConnection().destroy({
        success() {
          this.logger().info('Contact successfully disconnected');
        },
        failure(data) {
          this.logger().error('Failed to disconnect contact').withObject(data);
        },
      });
    });
  };

  rejectCall = () => {
    this.withContact((contact) => {
      contact.getAgentConnection().destroy({
        success() {
          this.logger().info('Contact successfully rejected');
        },
        failure(data) {
          this.logger().error('Failed to reject contact').withObject(data);
        },
      });
    });
  };

  /**
   * Make an outbound call to the given phone number.
   *
   * If an "outbound call override" was specified via the secret QA
   * query string parameter, then the override number will be dialed
   * instead of the given `number`. This will happen whether or not
   * the "enable outbound calls" environment variable is set to true).
   *
   * If no override was given, then the call will only be placed if
   * outbound calls are enabled via the environment variable.
   *
   * The environment variable which must be true to generally enable
   * outbound calls is `VITE_ENABLE_OUTBOUND_CALLS`.
   *
   * @param {String} number The number to dial (no validation is performed)
   * @param {String, null} name An optional name to associate with the endpoint
   */
  makeOutboundCall = (number, name = null) => {
    const { outgoingCallOverride } = this.state;
    const actualNumber = trim(outgoingCallOverride || number);
    if (outgoingCallOverride) {
      // eslint-disable-next-line no-console
      console.info(
        '[OUTGOING CALL OVERRIDE]',
        `Outbound call to "${number}" will instead be routed to "${actualNumber}"...`,
      );
    } else if (!enableOutboundCalls()) {
      // eslint-disable-next-line no-console
      console.info(
        `VITE_ENABLE_OUTBOUND_CALLS is not enabled; outgoing call to "${number}" will NOT be dialed.`,
      );
      return;
    }

    if (this.inConversationState()) {
      this.initiateThirdParty(actualNumber, name);
    } else {
      this.dial(actualNumber, name);
    }
  };

  inConversationState = () => this.isValidAction(CONVERSATION_STATES);

  dial = (phoneNumber, name) => {
    this.withAgent((agent) => {
      // eslint-disable-next-line no-console
      console.log(`☎️ Initiating outbound call to "${phoneNumber}"...`);
      agent.connect(this.connect.Endpoint.byPhoneNumber(phoneNumber, name), {
        // if we need to use a specific queue for outbound calls,
        // set that here:
        // queueARN: QUEUE_ARN,
        success: (_data) => {
          // this callback is fired when the connection is setup, but
          // before the other party has answered
          // eslint-disable-next-line no-console
          console.log(`Outbound call succeeded: ${phoneNumber}`);
        },
        failure: (_data) => {
          // eslint-disable-next-line no-console
          console.log(`⚠️ Outbound call failed!: ${phoneNumber}`);
        },
      });
    });
  };

  initiateThirdParty = (phoneNumber, name) => {
    const voiceContact = this.helper.getVoiceContact();
    // eslint-disable-next-line no-console
    console.log(
      `☎️☎️ Initiating third-party connection to "${phoneNumber}"...`,
    );
    voiceContact.addConnection(
      this.connect.Endpoint.byPhoneNumber(phoneNumber, name),
      {
        success: (_data) => {
          // eslint-disable-next-line no-console
          console.log(`Third-party call succeeded: ${phoneNumber}`);
        },
        failure: (_data) => {
          // eslint-disable-next-line no-console
          console.log(`⚠️ Third-party call failed!: ${phoneNumber}`);
        },
      },
    );
  };

  transferToQuickConnect = (
    quickConnectName,
    { success = noop, failure = noop },
  ) => {
    this.withAgent((agent) => {
      agent.getEndpoints(agent.getAllQueueARNs(), {
        success: (data) => {
          const quickConnect = find(data.endpoints, { name: quickConnectName });
          if (quickConnect) {
            const voiceContact = this.helper.getVoiceContact();
            voiceContact.addConnection(quickConnect, { success, failure });
          } else {
            // eslint-disable-next-line no-console
            console.error(
              `Cannot find quick connect named ${quickConnectName} among available endpoints:`,
              data.endpoints,
            );
            failure();
          }
        },
        failure: (data) => {
          // eslint-disable-next-line no-console
          console.error('Failed to fetch endpoints for quick connect.', data);
          failure();
        },
      });
    });
  };

  /*=======================================================================
   * ACTION TRIGGER ENABLE/DISABLE
   *=======================================================================*/

  canInitialize = () =>
    !this.initialized() && this.state.callState !== STATE_NAMES.INITIALIZING;

  canTerminate = () => this.initialized();

  isValidAction = (validUiStates) =>
    this.agent && includes(validUiStates, this.state.uiState);

  canGoAvailable = () => this.isValidAction(UI_STATE_BUTTONS.GO_AVAILABLE);

  canGoOffline = () => this.isValidAction(UI_STATE_BUTTONS.GO_OFFLINE);

  canAcceptContact = () => this.isValidAction(UI_STATE_BUTTONS.ACCEPT_CALL);

  canDisconnectContact = () => this.isValidAction(UI_STATE_BUTTONS.END_CALL);

  canRejectCall = () => this.isValidAction(UI_STATE_BUTTONS.REJECT_CALL);

  canMakeThirdPartyCall = () =>
    this.agent &&
    this.state.uiState === UI_STATES.ON_HOLD &&
    !get(this.state.thirdParty, 'uiState', false);

  canMakeOutboundCall = () =>
    (this.isValidAction(UI_STATE_BUTTONS.CALL_OUTBOUND) ||
      this.canMakeThirdPartyCall()) &&
    (enableOutboundCalls() || this.state.outgoingCallOverride);

  canTransferToQuickConnect = () =>
    this.isValidAction(UI_STATE_BUTTONS.TRANSFER_TO_QUICK_CONNECT) &&
    this.helper &&
    this.helper.getVoiceContact();

  /*=======================================================================
   * COMPONENT RENDERING
   *=======================================================================*/

  render() {
    const contextValue = {
      initialized: this.initialized(),
      initialize: this.canInitialize() && this.initializeConnect,
      terminate: this.canTerminate() && this.terminateConnect,
      goAvailable: this.canGoAvailable() && this.goAvailable,
      goOffline: this.canGoOffline() && this.goOffline,
      acceptContact: this.canAcceptContact() && this.acceptContact,
      disconnectContact: this.canDisconnectContact() && this.disconnectContact,
      rejectCall: this.canRejectCall() && this.rejectCall,
      makeOutboundCall: this.canMakeOutboundCall()
        ? this.makeOutboundCall
        : null,
      transferToQuickConnect:
        this.canTransferToQuickConnect() && this.transferToQuickConnect,
      addContactConnectingListener: this.addContactConnectingListener,
      ...this.state,
    };
    return (
      <AmazonConnectContext.Provider value={contextValue}>
        {this.props.children}
      </AmazonConnectContext.Provider>
    );
  }
}
