import { useLoadScript } from '@react-google-maps/api';

const libraries = ['places'];

export default function useConfigureGoogleMaps() {
  // Loading the Google Maps script should only happen once, when the application is loaded.

  const { loadError } = useLoadScript({
    googleMapsApiKey: import.meta.env.VITE_GOOGLE_API_KEY,
    libraries,
  });

  if (loadError) {
    // eslint-disable-next-line no-console
    console.error(loadError);
  }
}
