import React from 'react';
import { Trans, t } from '@lingui/macro';
import rem from 'polished/lib/helpers/rem';

import spacing from '@decisiv/design-tokens/lib/spacing';

import Flex from '@decisiv/ui-components/lib/components/Flex';
import Tabs, { Tab } from '@decisiv/ui-components/lib/components/Tabs';
import { H3 } from '@decisiv/ui-components/lib/components/Typography';

import Card from 'components/DashboardCard';

import AllNotes from './AllNotes';

const tabs = [
  { id: 'all', text: t`All` },
  { id: 'onlyWatching', text: t`Watching` },
  { id: 'onlyAssignedToMe', text: t`Assigned to Me` },
  { id: 'onlyMyNotes', text: t`My Notes` },
];

function TeamNotesCard() {
  return (
    <Card
      css={`
        display: flex;
        max-height: ${rem(spacing.base * 78)};
      `}
      marginTop={0}
      marginBottom={0}
    >
      <Flex flex={1} flexDirection="column">
        <H3>
          <Trans>Team Notes</Trans>
        </H3>
        <Flex
          marginTop={2}
          flex={1}
          css={`
            overflow: hidden;
          `}
          flexDirection="column"
        >
          <Tabs
            defaultSelectedId="all"
            css={`
              flex: 1;
              overflow: hidden;
              width: 100%;

              & > [role='tabpanel'] {
                display: flex;
                overflow: hidden;
                height: 100%;

                &[hidden] {
                  display: none;
                }
              }
            `}
          >
            {tabs.map(({ id, text }) => (
              <Tab id={id} title={text} key={id}>
                <AllNotes key={id} tabId={id} />
              </Tab>
            ))}
          </Tabs>
        </Flex>
      </Flex>
    </Card>
  );
}

export default React.memo(TeamNotesCard);
