import React from 'react';
import { t } from '@lingui/macro';

import BaseTab from '../index';

const MY_ACTIVITY_FILTERS = {
  onlyMyActivities: true,
};

export default function MyActivityTab() {
  return (
    <BaseTab
      emptyResultMessage={t`There is currently no activity.`}
      filters={MY_ACTIVITY_FILTERS}
    />
  );
}
