import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Trans, t } from '@lingui/macro';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';

import Button from '@decisiv/ui-components/lib/components/Button';
import Flex from '@decisiv/ui-components/lib/components/Flex';
import Grid from '@decisiv/ui-components/lib/components/Grid';
import TextField from '@decisiv/ui-components/lib/components/TextField';
import { P } from '@decisiv/ui-components/lib/components/Typography';

import useDebounce from 'utils/useDebounce';

import useUpdateEffect from 'hooks/useUpdateEffect';

const ERRORS = {
  INVALID: t`Key must be alphanumeric only (no special characters).`,
  TAKEN: t`Key already taken. Try another or choose from the available keys.`,
};
const MAX_LENGTH = 4;

const teamKeyValidQuery = loader('./teamKeyValid.graphql');

export default function TeamKeyForm({ handleTeamKeyChange, teamKey, stretch }) {
  const [editedTeamKey, setEditedTeamKey] = useState(teamKey);
  const debounceText = useDebounce(editedTeamKey, 500);
  const {
    loading,
    data: {
      teamKeyValid: { errors, valid = true, suggestions = [] } = {},
    } = {},
  } = useQuery(teamKeyValidQuery, {
    variables: { teamKey: debounceText },
    skip: !debounceText,
  });

  const translatedErrors = useMemo(
    () =>
      valid ? '' : errors?.map((e) => ERRORS[e]).join(', ') || ERRORS.TAKEN,
    [errors, valid],
  );

  const validDebouceText = useMemo(() => {
    if (!(!loading && editedTeamKey === debounceText && valid))
      return undefined;

    return debounceText;
  }, [debounceText, editedTeamKey, loading, valid]);

  useUpdateEffect(() => {
    handleTeamKeyChange(validDebouceText);
  }, [validDebouceText]);

  return (
    <Grid.Container padding={0}>
      <Grid.Row>
        <Grid.Column
          css={
            !stretch &&
            `
            > div:first-child {
              > div:first-child {
                width: 50%;
              }
            }
          `
          }
          marginBottom={1}
        >
          <TextField
            helpMessage={t`Max ${MAX_LENGTH} characters (alphanumeric only, no special characters)`}
            label={t`Team Key`}
            maxLength={MAX_LENGTH}
            onChange={(e) => setEditedTeamKey(e.target.value)}
            required
            value={editedTeamKey}
            warningMessage={translatedErrors}
          />
        </Grid.Column>
      </Grid.Row>
      {isEmpty(suggestions) || (
        <Grid.Row marginBottom={0.5} marginTop={1.5}>
          <Grid.Column>
            <Flex alignItems="center">
              <P>
                <Trans>Available Keys</Trans>
              </P>
              {suggestions.map((teamKeySuggestion) => (
                <Flex key={teamKeySuggestion} marginLeft={1}>
                  <Button
                    aria-label={t`Click ${teamKeySuggestion}`}
                    onClick={() => setEditedTeamKey(teamKeySuggestion)}
                    text={teamKeySuggestion}
                    variant="ghost"
                  />
                </Flex>
              ))}
            </Flex>
          </Grid.Column>
        </Grid.Row>
      )}
    </Grid.Container>
  );
}

TeamKeyForm.propTypes = {
  handleTeamKeyChange: PropTypes.func.isRequired,
  teamKey: PropTypes.string,
  stretch: PropTypes.bool,
};

TeamKeyForm.defaultProps = {
  teamKey: '',
  stretch: false,
};
