import PropTypes from 'prop-types';
import { loader } from 'graphql.macro';
import isNull from 'lodash/isNull';
import noop from 'lodash/noop';
import React, { useCallback, useMemo, useState } from 'react';

import { useMutation } from '@apollo/client';

import MinusCircle from '@decisiv/iconix/lib/components/MinusCircle';

import Flex from '@decisiv/ui-components/lib/components/Flex';
import Modal from '@decisiv/ui-components/lib/components/Modal';
import RadioGroup from '@decisiv/ui-components/lib/components/RadioGroup';
import { P } from '@decisiv/ui-components/lib/components/Typography';

import { Plural, t, Trans } from '@lingui/macro';

import loadingGif from 'components/Loading/loading.gif';

import { buildPropTypes } from 'utils/graphqlPropTypes';

import useSentinelTeam from 'hooks/useSentinelTeam';

const removeCustomerMutation = loader('./removeCustomer.graphql');

function Loading() {
  return <img height="32" width="32" src={loadingGif} alt={t`loading`} />;
}

export default function RemoveCustomerModal({
  customer,
  handleClose,
  onComplete,
  visible,
}) {
  const { name: teamName } = useSentinelTeam();

  const [cancelActiveAppointments, setCancelActiveAppointments] =
    useState(null);
  const [removeCustomer, { loading }] = useMutation(removeCustomerMutation, {
    refetchQueries: [
      'AllAssetGroupings',
      'AssetGroupingsCount',
      'CustomersFilters',
    ],
  });

  const handleRemove = useCallback(async () => {
    await removeCustomer({
      variables: {
        id: customer.id,
        cancelActiveAppointments: isNull(cancelActiveAppointments)
          ? cancelActiveAppointments
          : cancelActiveAppointments === 'true',
      },
    });
    handleClose();
    onComplete();
  }, [
    cancelActiveAppointments,
    customer,
    handleClose,
    removeCustomer,
    onComplete,
  ]);

  const modalProps = useMemo(() => {
    if (loading) {
      return {
        icon: Loading,
        intent: 'information',
        title: t`Disabling Customer...`,
      };
    }
    return {
      actions: [
        {
          disabled:
            customer.activeAppointmentsCount > 0 &&
            cancelActiveAppointments === null,
          intent: 'danger',
          text: t`Remove Customer`,
          onClick: handleRemove,
        },
        { text: 'Keep Customer', onClick: handleClose },
      ],
      icon: MinusCircle,
      intent: 'danger',
      onClose: handleClose,
      title: t`Remove Customer?`,
    };
  }, [cancelActiveAppointments, customer, handleClose, handleRemove, loading]);

  const customerName = customer.name;
  const activeApptCount = customer.activeAppointmentsCount;
  return (
    <Modal {...modalProps} visible={visible}>
      {loading ? (
        <P color="alaskanHusky" marginTop={0.5}>
          <Trans>
            Please wait while we disable {customerName} from the {teamName}{' '}
            team.
          </Trans>
        </P>
      ) : (
        <Flex flexDirection="column">
          <P color="alaskanHusky">
            <Trans>
              You are about to remove{' '}
              <P as="span" weight="semibold">
                {customerName}
              </P>{' '}
              and all their assets from the{' '}
              <P as="span" weight="semibold">
                {teamName}
              </P>{' '}
              team. Any Draft service events for this customer will be canceled
              and team members will no longer be able to select it for new
              service events.
            </Trans>
          </P>
          {activeApptCount > 0 && (
            <Flex flexDirection="column">
              <P color="alaskanHusky" marginY={2}>
                <Plural
                  value={activeApptCount}
                  one={
                    <Trans>
                      This customer has{' '}
                      <P as="span" weight="semibold">
                        # In Progress service event.
                      </P>
                      . It can remain active or you can cancel it.
                    </Trans>
                  }
                  other={
                    <Trans>
                      This customer has{' '}
                      <P as="span" weight="semibold">
                        # In Progress service events.
                      </P>
                      . They can remain active or you can cancel them.
                    </Trans>
                  }
                />
              </P>
              <RadioGroup
                items={[
                  {
                    label: t`Keep Appointments Active`,
                    value: 'false',
                  },
                  { label: t`Cancel Appointments`, value: 'true' },
                ]}
                name="keep-appointments-active"
                onClick={(e) => e.stopPropagation()}
                onChange={(e) => {
                  setCancelActiveAppointments(e.target.value);
                }}
                value={cancelActiveAppointments}
                vertical
              />
            </Flex>
          )}
        </Flex>
      )}
    </Modal>
  );
}

RemoveCustomerModal.propTypes = {
  customer: buildPropTypes('customer').isRequired,
  handleClose: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  onComplete: PropTypes.func,
};

RemoveCustomerModal.defaultProps = {
  onComplete: noop,
};
