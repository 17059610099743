import React from 'react';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';

import Flex from '@decisiv/ui-components/lib/components/Flex';
import Tag from '@decisiv/ui-components/lib/components/Tag';
import { P } from '@decisiv/ui-components/lib/components/Typography';

import { formatName } from 'utils/Contact';
import { parseDate } from 'utils/dates';

export default function InvitationRow({ contact, selected }) {
  const name = formatName(contact);
  const { email, invitations = [] } = contact;
  const invitation = invitations.slice(-1)[0];
  return (
    <Flex
      css={`
        && {
          margin-right: 0;
        }
      `}
      flex={1}
      justifyContent="space-between"
    >
      <Flex flexDirection="column">
        <P>{name || email}</P>
        {name && (
          <P color={selected ? 'snowWhite' : 'alaskanHusky'} size="small">
            {email}
          </P>
        )}
      </Flex>
      {invitation && (
        <Tag
          text={`${t`Requested`} ${parseDate(invitation.createdAt).join(' ')}`}
          variant="outline"
        />
      )}
    </Flex>
  );
}

InvitationRow.propTypes = {
  contact: PropTypes.shape({
    email: PropTypes.string.isRequired,
    invitations: PropTypes.arrayOf(
      PropTypes.shape({ createdAt: PropTypes.string.isRequired }),
    ),
  }).isRequired,
  selected: PropTypes.bool,
};

InvitationRow.defaultProps = {
  selected: false,
};
