/* istanbul ignore file -- @preserve */

import { HttpLink } from '@apollo/client/link/http';
import { bffAPILink } from 'bff';

function buildHttpLink() {
  return new HttpLink({
    uri: bffAPILink,
    credentials: 'include',
  });
}
export default buildHttpLink;
