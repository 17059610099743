import React from 'react';
import PropTypes from 'prop-types';

import PlainTextCell from 'components/PlainTextCell';

export default function TeamKeyCell({
  rowData: { teamKey, scheduledForDeletionAt },
}) {
  return (
    <PlainTextCell
      color={scheduledForDeletionAt ? 'alaskanHusky' : undefined}
      topLine={teamKey}
    />
  );
}

TeamKeyCell.propTypes = {
  rowData: PropTypes.shape({
    teamKey: PropTypes.string,
    scheduledForDeletionAt: PropTypes.string,
  }).isRequired,
};
