import toColorString from 'polished/lib/color/toColorString';
import rem from 'polished/lib/helpers/rem';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import find from 'lodash/find';

import { t } from '@lingui/macro';

import color from '@decisiv/design-tokens/lib/color';
import spacing from '@decisiv/design-tokens/lib/spacing';

import Avatar from '@decisiv/ui-components/lib/components/Avatar';
import Flex from '@decisiv/ui-components/lib/components/Flex';
import Menu from '@decisiv/ui-components/lib/components/Menu';
import Tag from '@decisiv/ui-components/lib/components/Tag';
import KDSTopNav from '@decisiv/ui-components/lib/components/TopNav';
import { P } from '@decisiv/ui-components/lib/components/Typography';

import { AmazonConnectContext, uiStateColors } from 'features/amazonConnect';
import { useRBAC, SUPER_USER_ROLES } from 'features/rbac';

import { logoutURL } from 'bff';

import ROUTE_NAMES from 'setup/Routing/routeNames';

import isDemoMode from 'utils/isDemoMode';
import { isDecisivEmail, useCurrentUserContext } from 'utils/User';
import useSentinelTeam from 'hooks/useSentinelTeam';

function UserMenu() {
  const { callState, uiState } = useContext(AmazonConnectContext);
  const { needsSentinelTeam, email, name, roleLabel, sentinelTeams } =
    useCurrentUserContext();
  const { logout, ...sentinelTeam } = useSentinelTeam();

  const [popover, setPopover] = useState({});
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const popoverRef = useCallback(setPopover, [popover]);
  const { isAuthorized: isSuperuser } = useRBAC({ allow: SUPER_USER_ROLES });

  const isSentinelTeamMember = useMemo(
    () => find(sentinelTeams, (sg) => sg.id === sentinelTeam?.id),
    [sentinelTeam, sentinelTeams],
  );

  const canViewNotification = sentinelTeam && isSentinelTeamMember;

  const menuItems = useMemo(() => {
    const items = [
      {
        id: 'logout',
        text: t`Log Out`,
        linkTo: logoutURL(),
      },
    ];

    if (isDecisivEmail(email)) {
      items.unshift({
        id: 'feature-flag',
        text: t`Feature Flags`,
        onSelect: () => {
          popover.hide();
        },
        linkTo: ROUTE_NAMES.feature_flags,
      });
    }

    if (!needsSentinelTeam) {
      if (canViewNotification) {
        items.unshift({
          id: 'notification-preferences',
          text: t`Notification Preferences`,
          linkTo: ROUTE_NAMES.notification_preferences,
          onSelect: () => {
            popover.hide();
          },
        });
      }

      if (isSuperuser) {
        items.unshift({
          id: 'all-teams',
          text: t`Back to All Teams`,
          onSelect: () => {
            popover.hide();
            logout();
          },
        });
      }
    }

    return items;
  }, [
    canViewNotification,
    email,
    isSuperuser,
    needsSentinelTeam,
    popover,
    logout,
  ]);

  const emblem = (emblemProps) => (
    <Avatar
      {...emblemProps}
      email={email}
      indicatorColor={
        sentinelTeam?.amazonConnectInstance
          ? uiStateColors(uiState).kdsColorName
          : undefined
      }
    />
  );
  const primaryText = name || email;
  const secondaryText =
    !isDemoMode() && sentinelTeam?.amazonConnectInstance && callState;
  return (
    <KDSTopNav.Menu
      emblem={emblem}
      primaryText={primaryText}
      popoverRef={popoverRef}
      secondaryText={secondaryText}
      zIndex={10}
    >
      <Flex
        css={`
          > * {
            border-bottom: 1px solid ${toColorString(color.opacity.charcoal15)};
          }
          ul li:not(:last-child) {
            border-bottom: 1px solid ${toColorString(color.opacity.charcoal15)};
          }
        `}
        flexDirection="column"
      >
        <Flex flexDirection="column" padding={1}>
          <P> {name} </P>
          <P shade={1} size="small">
            {email}
          </P>
          <Tag
            css={`
              width: fit-content;
              margin-top: ${rem(spacing.base / 2)};
            `}
            text={roleLabel}
            variant="outline"
          />
        </Flex>
        <Menu items={menuItems} />
      </Flex>
    </KDSTopNav.Menu>
  );
}

export default UserMenu;
