import React, { createContext, useContext, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';

import Loading from 'components/Loading';

import ROUTE_NAMES from 'setup/Routing/routeNames';
import useFindAssetViewQuery from './useFindAssetViewQuery';

import NotFoundPage from '../NotFoundPage';

export const AssetViewContext = createContext();

export default function AssetViewProvider({ children }) {
  const { slug } = useParams();
  const navigate = useNavigate();

  const {
    data: {
      savedAssetView: {
        appliedFilters = [],
        name,
        id,
        visibleToAllMembers,
      } = {},
    } = {},
    loading,
    error,
  } = useFindAssetViewQuery({
    fetchPolicy: 'cache-and-network',
    variables: { slug },
    onCompleted: ({ savedAssetView: { slug: fetchedSlug } }) => {
      if (slug !== fetchedSlug) {
        navigate(ROUTE_NAMES.forAssetView(fetchedSlug));
      }
    },
  });

  const {
    groupedAssetGroupingsIds,
    assetGroupingIds,
    platformAccountIds,
    ...defaultFiltersState
  } = omit(appliedFilters, ['__typename']);

  const globalFiltersState = useMemo(
    () => ({
      groupedAssetGroupingsIds,
      assetGroupingIds,
      platformAccountIds,
    }),
    [groupedAssetGroupingsIds, assetGroupingIds, platformAccountIds],
  );

  const values = useMemo(
    () => ({
      id,
      name,
      slug,
      visibleToAllMembers,
      defaultFiltersState,
      globalFiltersState,
    }),
    [
      id,
      name,
      slug,
      visibleToAllMembers,
      defaultFiltersState,
      globalFiltersState,
    ],
  );

  if (error) return <NotFoundPage />;

  if (loading) return <Loading />;

  return (
    <AssetViewContext.Provider value={values}>
      {children}
    </AssetViewContext.Provider>
  );
}

AssetViewProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useAssetViewContext = () => {
  return useContext(AssetViewContext) ?? {};
};
