import React from 'react';
import { t } from '@lingui/macro';

import BaseTab from '../index';

const WATCHING_FILTERS = {
  onlyWatching: true,
};

export default function WatchingActivityTab() {
  return (
    <BaseTab
      emptyResultMessage={t`There is currently no activity for service events that you are watching.`}
      filters={WATCHING_FILTERS}
    />
  );
}
