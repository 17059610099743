import PropTypes from 'prop-types';
import * as React from 'react';

import Flex from '@decisiv/ui-components/lib/components/Flex';
import { P } from '@decisiv/ui-components/lib/components/Typography';

function AssetGroupingData({ decoration, titleText, data, ...rest }) {
  return (
    <Flex {...rest}>
      {decoration && <Flex marginRight={1}>{decoration}</Flex>}
      <Flex flexDirection="column">
        <P
          as="span"
          css={`
            display: block;
          `}
          shade={1}
          size="small"
        >
          {titleText}
        </P>
        {data}
      </Flex>
    </Flex>
  );
}

AssetGroupingData.propTypes = {
  decoration: PropTypes.node,
  titleText: PropTypes.string.isRequired,
  data: PropTypes.node.isRequired,
};

AssetGroupingData.defaultProps = {
  decoration: undefined,
};

export default AssetGroupingData;
