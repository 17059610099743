import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';
import toColorString from 'polished/lib/color/toColorString';
import { useLocation } from 'react-router-dom';

import color from '@decisiv/design-tokens/lib/color';

import Coffee from '@decisiv/iconix/lib/components/CoffeeHot';

import Grid from '@decisiv/ui-components/lib/components/Grid';

import EmptyResults from 'components/EmptyResults';
import PageHead from 'components/PageHead';
import Button from '@decisiv/ui-components/lib/components/Button';
import Refresh from '@decisiv/iconix/lib/components/Refresh';

export default function MaintenancePage({ target, refreshAfter }) {
  const location = useLocation();

  const redirectTo = target === location.pathname ? '/' : target;

  const refreshPage = useCallback(() => {
    // using location.replace instead of router navigation to force full reload
    window.location.replace(redirectTo);
  }, [redirectTo]);

  useEffect(() => {
    const timer = setTimeout(refreshPage, refreshAfter);
    return () => clearTimeout(timer);
  }, [refreshPage, refreshAfter]);

  return (
    <Grid.Container
      css={`
        background-color: ${toColorString(color.base.halfMoon)};

        top: 0;
        bottom: 0;
        position: fixed;
      `}
    >
      <PageHead titleParts={[t`Maintenance Mode`]} />
      <Grid.Row alignment={['center', 'middle']} height="90%">
        <Grid.Column>
          <EmptyResults
            color="warning"
            maxWidth="500px"
            icon={Coffee}
            subtitle={t`Decisiv is performing an update to the Sentinel application backend service and architecture. We expect to be back shortly, thanks for your patience and understanding.`}
            title={t`Planned Maintenance in Progress.`}
          >
            <Grid.Row alignment={['center']} marginTop={2}>
              <Grid.Column maxWidth="150px">
                <Button
                  icon={Refresh}
                  text={t`REFRESH`}
                  variant="ghost"
                  onClick={refreshPage}
                />
              </Grid.Column>
            </Grid.Row>
          </EmptyResults>
        </Grid.Column>
      </Grid.Row>
    </Grid.Container>
  );
}

MaintenancePage.propTypes = {
  target: PropTypes.string.isRequired,
  refreshAfter: PropTypes.number,
};

MaintenancePage.defaultProps = {
  refreshAfter: 10000,
};
