import React, { useMemo } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { isDecisivEmail, useCurrentUserContext } from 'utils/User';
import ROUTE_NAMES from 'setup/Routing/routeNames';

export default function DecisivRoute({ children }) {
  const { email } = useCurrentUserContext();
  const location = useLocation();

  const isDecisivUser = useMemo(() => isDecisivEmail(email), [email]);

  if (!isDecisivUser) {
    return (
      <Navigate
        to={ROUTE_NAMES.access_denied}
        state={{ from: location }}
        replace
      />
    );
  }

  return children;
}

DecisivRoute.propTypes = {
  children: PropTypes.node,
};

DecisivRoute.defaultProps = {
  children: null,
};
