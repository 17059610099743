import React, { useCallback, useMemo } from 'react';
import { useMutation } from '@apollo/client';
import { Trans, t } from '@lingui/macro';
import { loader } from 'graphql.macro';
import PropTypes from 'prop-types';

import Trash from '@decisiv/iconix/lib/components/Trash';

import Modal from '@decisiv/ui-components/lib/components/Modal';
import Flex from '@decisiv/ui-components/lib/components/Flex';
import { P } from '@decisiv/ui-components/lib/components/Typography';
import { useNotifications } from '@decisiv/ui-components/lib/components/Notifications';

const deleteSentinelTeamMutation = loader(
  './deleteSentinelTeamMutation.graphql',
);

export default function DeleteTeamModal({ teamId, teamName, onClose }) {
  const { notify } = useNotifications();

  const [deleteTeam, { loading }] = useMutation(deleteSentinelTeamMutation, {
    onError: (err) =>
      notify(
        {
          duration: 5000,
          onClose: () => undefined,
          intent: 'danger',
          title: t`Something Went Wrong`,
        },
        err.message,
      ),
  });

  const handleDeleteTeam = useCallback(async () => {
    await deleteTeam({ variables: { id: teamId } });
    onClose();
  }, [onClose, deleteTeam, teamId]);

  const actions = useMemo(
    () => [
      {
        text: t`Delete Team`,
        disabled: loading,
        onClick: handleDeleteTeam,
      },
      { text: t`Cancel`, onClick: onClose },
    ],
    [loading, onClose, handleDeleteTeam],
  );

  return (
    <Modal
      actions={actions}
      intent="danger"
      icon={Trash}
      onClose={onClose}
      title={t`Delete Team`}
      visible
    >
      <Flex flexDirection="column">
        <P color="alaskanHusky" marginTop={0.5}>
          <Trans>
            By clicking &quot;DELETE TEAM&quot; &lt;
            <P as="span" weight="semibold">
              {teamName}
            </P>
            &gt; will be disabled. The team will be removed from this list and
            all the data associated with the team will be lost.
          </Trans>
        </P>
        <P color="alaskanHusky" marginTop={2}>
          <Trans>This action can be reverted within the next hour.</Trans>
        </P>
      </Flex>
    </Modal>
  );
}

DeleteTeamModal.propTypes = {
  teamId: PropTypes.string.isRequired,
  teamName: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};
