import React, { useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import toColorString from 'polished/lib/color/toColorString';
import rem from 'polished/lib/helpers/rem';
import styled from 'styled-components';

import color from '@decisiv/design-tokens/lib/color';
import spacing from '@decisiv/design-tokens/lib/spacing';

import ArrowUp from '@decisiv/iconix/lib/components/ArrowUp';

import Button from '@decisiv/ui-components/lib/components/Button';

import { t } from '@lingui/macro';

const Wrapper = styled.div`
  flex: 1;
  position: relative;
  width: 100%;
`;

export const boundaryElement = 'boundaryElement';
export const scrollElement = 'scrollElement';

export default function ScrollingContent({ children, ...rest }) {
  const [showScrollToTop, setScrollToTop] = useState(false);

  const ref = useRef(null);

  const onClick = () => {
    ref.current?.scrollTo?.({ top: 0, behavior: 'smooth' });
  };

  const display = useMemo(
    () => (showScrollToTop ? 'block' : 'none'),
    [showScrollToTop],
  );

  return (
    <Wrapper className={boundaryElement}>
      <Button
        aria-label={t`scroll to top`}
        css={`
          font-size: 0;
          background-color: ${toColorString(color.base.snowWhite)};
          bottom: 10px;
          box-shadow: 0px 1px 4px ${toColorString(color.shadow.light.default)};
          display: ${display};
          padding: 0 ${rem(spacing.base)};
          position: absolute;
          right: 20px;
          z-index: 2;
          &:hover,
          &:focus {
            font-size: inherit;
          }
        `}
        icon={ArrowUp}
        iconPosition="right"
        onClick={onClick}
        text={t`back to top`}
        variant="ghost"
      />
      <div
        id="scroll-element"
        className={scrollElement}
        css={`
          bottom: 0;
          left: 0;
          overflow-y: auto;
          position: absolute;
          right: 0;
          top: 0;
          display: flex;
          flex-direction: column;
        `}
        ref={ref}
      >
        {React.cloneElement(children, {
          ...rest,
          setScrollToTop,
        })}
      </div>
    </Wrapper>
  );
}

ScrollingContent.propTypes = {
  children: PropTypes.element.isRequired,
};
