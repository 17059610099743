/* istanbul ignore file -- @preserve */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import bugsnagReact from '@bugsnag/plugin-react';

/**
 * This event listener blocks specific errors from being reported. This approach should be used
 * sparingly, and only for errors that have no available fix.
 */
window.addEventListener('error', (e) => {
  // `ResizeObserver` can throw this error even when nothing is actually going wrong.
  // https://github.com/WICG/resize-observer/issues/38
  if (e.message === 'ResizeObserver loop limit exceeded') {
    e.stopImmediatePropagation();
    e.stopPropagation();
  }
});

class TestEnvErrorBoundary extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  componentDidCatch(error, info) {
    // eslint-disable-next-line no-console
    console.error(
      'TestEnvErrorBoundary has caught the following error:',
      error,
      info,
    );
  }

  render() {
    const { children } = this.props;
    return <>{children}</>;
  }
}

/**
 * Creates an error boundary component, with reporting to bugsnag if not in the test env.
 * @param {object} bugsnagClient the configured bugsnagClient object
 * @returns {component} an ErrorBoundary React Component
 */
function create(bugsnagClient) {
  if (import.meta.env.MODE === 'test') {
    return TestEnvErrorBoundary;
  }

  bugsnagClient.use(bugsnagReact, React);

  return bugsnagClient.getPlugin('react');
}

const errorBoundaryFactory = { create };

export default errorBoundaryFactory;
