import isEmpty from 'lodash/isEmpty';
import without from 'lodash/without';
import * as React from 'react';

import useSentinelTeam from 'hooks/useSentinelTeam';

import { updateQueryParams } from 'utils/url';

import {
  setSessionValue,
  getSessionValue,
  sessionIncludes,
  persistSelectValue,
  persistFilterValue,
  removeFilterValue,
  getCustomerIds,
  getFilters,
} from 'utils/sessionStorage';

export const CUSTOMER_KEY = 'assetGroupingIds';

const ACTIONS = {
  TOGGLE_FILTER: 'TOGGLE_FILTER',
  SET_SELECT: 'SET_SELECT',
};

const SORT_OPTIONS = {
  preventative_maintenance: 'SCHEDULED_MAINTENANCES',
  recalls: 'RECALLS',
  warranties: 'WARRANTIES',
  faults: 'FAULTS',
};

const setSelectValue = (state, action) => ({
  ...state,
  [action.selectAttr]: action.value,
});

const toggleFilters = (state, action) => {
  const filters = state.filters.includes(action.filter)
    ? without(state.filters, action.filter)
    : state.filters.concat(action.filter);

  if (isEmpty(filters)) {
    persistSelectValue('sort', null, action.teamId);
    setSessionValue('filter-alerts-sort', null, action.teamId);

    return {
      ...state,
      sort: null,
      filters,
    };
  }

  return {
    ...state,
    filters,
  };
};

export function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.TOGGLE_FILTER:
      return toggleFilters(state, action);
    case ACTIONS.SET_SELECT:
      return setSelectValue(state, action);
    default:
      return state;
  }
}

const init = (initialState) => initialState;

export default function useFilters(page) {
  const { id: teamId } = useSentinelTeam();

  const pageKey = `filter-${page}`;
  const initialState = React.useMemo(
    () => ({
      filters: getFilters(pageKey, 'filters', false, teamId),
      customerIds: getCustomerIds(teamId),
      alertType: getFilters(`${pageKey}-alertType`, 'alertType', false, teamId),
      appointmentStatus: getFilters(
        `${pageKey}-appointmentStatus`,
        'appointmentStatus',
        false,
        teamId,
      ),
      sort: getFilters(`${pageKey}-sort`, 'sort', true, teamId),
      groupBy: getFilters(`${pageKey}-groupBy`, 'groupBy', true, teamId),
      watchedStatus: getFilters(
        `${pageKey}-watchedStatus`,
        'watchedStatus',
        true,
        teamId,
      ),
    }),
    [pageKey, teamId],
  );

  const [state, dispatch] = React.useReducer(reducer, initialState, init);

  const filterSelected = (filter) =>
    state.filters.includes(filter) || sessionIncludes(pageKey, filter, teamId);

  const toggleFilter = (filter) => {
    dispatch({ type: ACTIONS.TOGGLE_FILTER, filter, teamId });

    // update session and query params
    if (sessionIncludes(pageKey, filter, teamId)) {
      removeFilterValue(pageKey, filter, teamId);
      const sortSession = getSessionValue('filter-alerts-sort', teamId);
      if (SORT_OPTIONS[filter] === sortSession) {
        persistSelectValue('sort', null, teamId);
        setSessionValue('filter-alerts-sort', null, teamId);
        dispatch({
          type: ACTIONS.SET_SELECT,
          selectAttr: 'sort',
          value: '',
          teamId,
        });
      }
    } else {
      persistFilterValue(pageKey, [filter], teamId);
      const persistedFilters = getSessionValue(pageKey, teamId) || [];
      updateQueryParams(window.location.search, 'filters', persistedFilters);
    }
  };

  const changeSelectValue = (selectAttr, value) => {
    dispatch({
      type: ACTIONS.SET_SELECT,
      selectAttr,
      value,
      teamId,
    });
  };
  const selectedValue = (selectAttr) => state[selectAttr];

  const findSelected = (selectAttr, multiple) => {
    const stored = getSessionValue(
      selectAttr === CUSTOMER_KEY ? selectAttr : `${pageKey}-${selectAttr}`,
      teamId,
    );
    const storedValue = !multiple && Array.isArray(stored) ? stored[0] : stored;

    return storedValue || selectedValue(selectAttr) || 'ALL';
  };

  const getFilterButtonsProps = (filter) => ({
    active: filterSelected(filter),
    onClick: () => toggleFilter(filter),
    checked: filterSelected(filter),
    onChange: () => toggleFilter(filter),
  });

  const getSelectProps = (selectAttr, multiple = false) => {
    return {
      onChange: (value) => {
        const parsedValue = value === 'ALL' ? null : value;
        changeSelectValue(selectAttr, parsedValue, teamId);
        if (selectAttr === CUSTOMER_KEY) {
          return persistSelectValue(
            selectAttr,
            value === 'ALL' ? '' : value,
            teamId,
          );
        }
        if (['reports', 'alerts', 'appointments'].includes(page)) {
          setSessionValue(`${pageKey}-${selectAttr}`, parsedValue, teamId);
          updateQueryParams(window.location.search, selectAttr, parsedValue);
          return null;
        }

        return null;
      },
      selected: findSelected(selectAttr, multiple),
      ...(page === 'alerts'
        ? {
            flags: {
              onlyEnabledCustomers: true,
            },
          }
        : {}),
    };
  };

  return [state, { getFilterButtonsProps, getSelectProps }];
}
