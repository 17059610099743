import React from 'react';
import PropTypes from 'prop-types';
import isNumber from 'lodash/isNumber';
import toColorString from 'polished/lib/color/toColorString';

import color from '@decisiv/design-tokens/lib/color';

import CircleF from '@decisiv/iconix/lib/components/CircleF';

import Flex from '@decisiv/ui-components/lib/components/Flex';
import Grid from '@decisiv/ui-components/lib/components/Grid';
import { P } from '@decisiv/ui-components/lib/components/Typography';
import Link from '@decisiv/ui-components/lib/components/Link';

import routeNames from 'setup/Routing/routeNames';
import { alertTypeText } from 'utils/Alerts';

import { buildActiveFiltersParams as activeAlertsParams } from '../AssetsByStatusCard/buildParams';

export default function AlertsByTypeDataList({ alerts, showDividers }) {
  const navigateToAssetsWithAlert = (alertTypeId) => {
    return `${routeNames.alerts}${activeAlertsParams([alertTypeId])}`;
  };

  return (
    <Grid.Container paddingX={0} marginTop={2}>
      {alerts.map(
        ({ color: alertColor, count, id, percentage, semibold, title }, i) => (
          <Grid.Row
            key={`bar-chart-key-${title}`}
            css={`
              border-top: ${showDividers && i > 0
                ? `1px solid ${toColorString(color.opacity.charcoal15)}`
                : undefined};
            `}
            marginX={1}
            paddingX={0}
            paddingY={showDividers ? 1 : 0.5}
          >
            <Grid.Column padding={0}>
              <Flex flex={1}>
                <CircleF color={alertColor} />
                <Flex flex={1} justifyContent="space-between" marginLeft={1}>
                  {count > 0 ? (
                    <Link
                      to={navigateToAssetsWithAlert(id)}
                      color={toColorString(color.interaction.pacificOcean)}
                      text={alertTypeText(id, count, title)}
                    />
                  ) : (
                    <P
                      weight={semibold ? 'semibold' : undefined}
                      shade={count === 0 ? 1 : 0}
                    >
                      {alertTypeText(id, count, title)}
                    </P>
                  )}
                </Flex>
                <Flex marginLeft={0.5}>
                  <P
                    weight={semibold ? 'semibold' : undefined}
                    shade={count === 0 ? 1 : 0}
                  >
                    {count}
                  </P>
                </Flex>
                <Flex marginLeft={0.5}>
                  {isNumber(percentage) && (
                    <P color="alaskanHusky" weight="regular">
                      {`• ${percentage}%`}
                    </P>
                  )}
                </Flex>
              </Flex>
            </Grid.Column>
          </Grid.Row>
        ),
      )}
    </Grid.Container>
  );
}

AlertsByTypeDataList.propTypes = {
  alerts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      color: PropTypes.string,
      count: PropTypes.number,
      percentage: PropTypes.number,
      title: PropTypes.string,
    }),
  ),
  showDividers: PropTypes.bool,
};

AlertsByTypeDataList.defaultProps = {
  alerts: [],
  showDividers: false,
};
