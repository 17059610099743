/* istanbul ignore file -- @preserve */
import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import { ConfigProvider } from '@decisiv/ui-components/lib/providers/ConfigProvider';
import NotificationsPanel from '@decisiv/ui-components/lib/components/Notifications/NotificationsPanel';

import { AmazonConnectProvider } from 'features/amazonConnect';

import AutoReload from 'components/AutoReload';
import LinkTo from 'components/LinkTo';

import ApolloProvider from 'setup/ApolloGraphQL/ApolloProvider';

import { ErrorBoundary } from 'setup/Bugsnag';
import { GlobalStyle } from 'setup/styles/globalStyle';
import I18n from 'setup/I18n';
import InitializationErrorMessage from 'setup/InitializationErrorMessage';
import { RootNavigate, RootNavigateRef } from 'setup/Routing/RootNavigate';
import Routes from 'setup/Routing/Routes';
import useConfigureGoogleMaps from 'setup/GoogleMaps';

export default function App() {
  useConfigureGoogleMaps();

  return (
    <I18n>
      <ErrorBoundary FallbackComponent={InitializationErrorMessage}>
        <BrowserRouter>
          <ConfigProvider linkRenderer={LinkTo}>
            <GlobalStyle />
            <NotificationsPanel marginTop={6} />
            <AutoReload url="/index.html" tryDelay={5 * 60 * 1000} />
            <RootNavigate ref={RootNavigateRef} />
            <AmazonConnectProvider>
              <ApolloProvider>
                <Routes />
              </ApolloProvider>
            </AmazonConnectProvider>
          </ConfigProvider>
        </BrowserRouter>
      </ErrorBoundary>
    </I18n>
  );
}
