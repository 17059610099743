import React from 'react';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';
import { useNavigate } from 'react-router-dom';
import Button from '@decisiv/ui-components/lib/components/Button';

import Check from '@decisiv/iconix/lib/components/Check';

import Grid from '@decisiv/ui-components/lib/components/Grid';

import EmptyResults from 'components/EmptyResults';
import PageHead from 'components/PageHead';
import ROUTE_NAMES from 'setup/Routing/routeNames';

export default function LoggedOut() {
  const navigate = useNavigate();
  const onClickLogin = () => navigate(ROUTE_NAMES.dashboard);

  return (
    <Grid.Container
      css={`
        position: fixed;
        top: 0;
        bottom: 0;
      `}
    >
      <PageHead titleParts={[t`You've Successfully Logged Out`]} />
      <Grid.Row alignment={['center', 'middle']} height="100%">
        <Grid.Column>
          <EmptyResults
            color="success"
            maxWidth="500px"
            icon={Check}
            subtitle={t`To continue using the application please log in again.`}
            title={t`You've Successfully Logged Out`}
          />
          <Grid.Row alignment="center">
            <Button
              text={t`log in to sentinel`}
              size="medium"
              onClick={onClickLogin}
            />
          </Grid.Row>
        </Grid.Column>
      </Grid.Row>
    </Grid.Container>
  );
}

LoggedOut.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      error: PropTypes.shape({
        message: PropTypes.string.isRequired,
      }),
    }),
  }),
};

LoggedOut.defaultProps = {
  location: undefined,
};
