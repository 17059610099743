import React, { useMemo, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import intersectionWith from 'lodash/intersectionWith';
import snakeCase from 'lodash/snakeCase';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { t, Trans } from '@lingui/macro';
import styled from 'styled-components';
import toColorString from 'polished/lib/color/toColorString';
import rem from 'polished/lib/helpers/rem';

import color from '@decisiv/design-tokens/lib/color';

import Badge from '@decisiv/ui-components/lib/components/Badge';
import Button from '@decisiv/ui-components/lib/components/Button';
import Flex from '@decisiv/ui-components/lib/components/Flex';
import { P } from '@decisiv/ui-components/lib/components/Typography';
import Tooltip from '@decisiv/ui-components/lib/components/Tooltip';
import spacing from '@decisiv/design-tokens/lib/spacing';
import DashboardCard from 'components/DashboardCard';

import Cog from '@decisiv/iconix/lib/components/Cog';
import Bullhorn from '@decisiv/iconix/lib/components/Bullhorn';
import CalendarCheck from '@decisiv/iconix/lib/components/CalendarCheck';
import ServiceProvider from '@decisiv/iconix/lib/components/ServiceProvider';
import Check from '@decisiv/iconix/lib/components/Check';
import Send from '@decisiv/iconix/lib/components/Send';

import LinkTo from 'components/LinkTo';
import Loading from 'components/Loading';
import Divider from 'components/Divider';

import { AlertsContext } from 'pages/Alerts/AlertsContext';

import routeNames from 'setup/Routing/routeNames';

import useSentinelTeam from 'hooks/useSentinelTeam';
import { useCurrentUserContext } from 'utils/User';
import { buildActiveFiltersParams as activeAlertsParams } from './buildParams';

const assetsByStatusCountQuery = loader('./assetsByStatusCount.graphql');
const allAlertTypesQuery = loader('./allAlertTypes.graphql');

const assetsStatusesMap = new Map([
  [
    'withActiveAlerts',
    {
      color: 'mamboMango',
      icon: Bullhorn,
      label: t`With Active Alerts`,
    },
  ],
  [
    'attemptingContact',
    {
      color: 'carrotCake',
      icon: Send,
      label: t`Attempting Contact`,
    },
  ],
  [
    'scheduled',
    {
      color: 'information',
      icon: CalendarCheck,
      label: t`Scheduled`,
    },
  ],
  [
    'atService',
    {
      color: 'cookieMonster',
      icon: ServiceProvider,
      label: t`At Service`,
    },
  ],
  [
    'ready',
    {
      color: 'success',
      icon: Check,
      label: t`Ready`,
    },
  ],
]);

const StatusDivider = styled(Flex).attrs({
  justifyContent: 'space-between',
  marginLeft: 1,
  marginY: 0.5,
  paddingTop: 1,
  paddingBottom: 1,
})`
  width: 100%;
  border-bottom: 1px solid ${toColorString(color.opacity.charcoal15)};
`;

const cogIcon = () => <Cog size="small" />;

const InfoText = () => (
  <Trans>
    View assets by higher-level statuses. Click on the specific status to view
    the corresponding assets. Click on the {cogIcon()} icon to map case
    repair/fleet statuses to these higher-level statuses.
  </Trans>
);

function HeaderButtons({ onClickViewAll }) {
  const navigate = useNavigate();
  const buttonRef = useRef();

  const { isAdmin } = useCurrentUserContext();

  const handleOnAssetConfigurationClick = () => {
    navigate(`${routeNames.admin.preferences}?editAssetPreferences=true`);
  };

  return (
    <Flex>
      <Button
        text={t`View All`}
        aria-label={t`View All Assets`}
        variant="ghost"
        onClick={onClickViewAll}
      />
      {isAdmin && (
        <>
          <Divider
            css={`
              height: ${rem(spacing.base * 2.4)};
              align-self: stretch;
              flex: none;
              margin-top: ${rem(spacing.base * 0.4)};
            `}
            marginX={1}
          />
          <Button
            aria-label={t`Configure Asset Status`}
            ref={buttonRef}
            variant="ghost"
            icon={Cog}
            size="small"
            onClick={handleOnAssetConfigurationClick}
            css={`
              right-position: absolute;
              margin-top: ${rem(spacing.base * 0.45)};
            `}
          />
          <Tooltip placement="right-start" target={buttonRef}>
            {t`Configure Asset Status`}
          </Tooltip>
        </>
      )}
    </Flex>
  );
}

HeaderButtons.propTypes = {
  onClickViewAll: PropTypes.func.isRequired,
};

export default function AssetsByStatusCard() {
  const navigate = useNavigate();
  const { resetAppliedFilters } = useContext(AlertsContext); // using this for the moment as we find a better approach to reset filters
  const { loading: activeFiltersLoading, data: activeFiltersData } = useQuery(
    allAlertTypesQuery,
    { variables: { enabled: 'ENABLED' } },
  );
  const { loading: loadingCounts, data: statusCountData } = useQuery(
    assetsByStatusCountQuery,
    { fetchPolicy: 'network-only' },
  );

  const { assetsByStatusConfiguration } = useSentinelTeam();
  const { scheduled, atService, fieldStatus } = assetsByStatusConfiguration;

  const isLoading = activeFiltersLoading || loadingCounts;

  const {
    assetsFilters: {
      alerts = [],
      platformCaseRepairStatus = [],
      platformCaseFleetStatus = [],
      serviceRequestStatus = [],
    } = {},
  } = activeFiltersData || {};

  // scheduled repair status filters
  const transform = (v) => [snakeCase(v).toUpperCase(), v];
  const transformedPlatformCaseFieldStatusConfiguration =
    fieldStatus === 'FLEET_STATUS'
      ? platformCaseFleetStatus.map(transform)
      : platformCaseRepairStatus.map(transform);

  const transformedScheduledConfiguration = scheduled.map(transform);

  const scheduledFilters = intersectionWith(
    transformedPlatformCaseFieldStatusConfiguration,
    transformedScheduledConfiguration,
    ([comp1], [comp2]) => comp1 === comp2,
  );

  // at service filters
  const transformedAtServiceConfiguration = atService.map(transform);
  const atServiceFilters = intersectionWith(
    transformedPlatformCaseFieldStatusConfiguration,
    transformedAtServiceConfiguration,
    ([comp1], [comp2]) => comp1 === comp2,
  );

  const activeAlertsIds = alerts.map(({ id }) => id);

  const availableSRStatuses = serviceRequestStatus
    .filter((s) => ['pending', 'declined'].includes(s))
    .join(',');

  const attemptingContactLink = useMemo(
    () =>
      isEmpty(availableSRStatuses)
        ? undefined
        : `${routeNames.alerts}?workInProgress=SERVICE_REQUESTS&serviceRequestStatus=${availableSRStatuses}`,
    [availableSRStatuses],
  );

  const fieldStatusMap = {
    FLEET_STATUS: 'platformCaseFleetStatus',
    REPAIR_STATUS: 'platformCaseRepairStatus',
  };

  const queryField = fieldStatusMap[fieldStatus];

  const scheduledLink = useMemo(
    () =>
      isEmpty(scheduledFilters)
        ? undefined
        : `${
            routeNames.alerts
          }?workInProgress=CASES&${queryField}=${scheduledFilters
            .map(([_, v]) => v)
            .join(',')}`,
    [scheduledFilters, queryField],
  );

  const atServiceLink = useMemo(
    () =>
      isEmpty(atServiceFilters)
        ? undefined
        : `${
            routeNames.alerts
          }?workInProgress=CASES&${queryField}=${atServiceFilters
            .map(([_, v]) => v)
            .join(',')}`,
    [atServiceFilters, queryField],
  );

  const statusLink = useMemo(
    () => ({
      withActiveAlerts: `${routeNames.alerts}${activeAlertsParams(
        activeAlertsIds,
      )}`,
      attemptingContact: attemptingContactLink,
      scheduled: scheduledLink,
      atService: atServiceLink,
    }),
    [activeAlertsIds, atServiceLink, attemptingContactLink, scheduledLink],
  );

  const onClickViewAll = () => {
    resetAppliedFilters();

    navigate(routeNames.alerts);
  };

  return (
    <DashboardCard
      text={t`Assets by Status`}
      infoText={InfoText}
      marginTop={0}
      renderButton={() => <HeaderButtons onClickViewAll={onClickViewAll} />}
    >
      {isLoading && <Loading />}
      {!isLoading && (
        <Flex flexDirection="column" marginTop={1}>
          {[...assetsStatusesMap].map(([status, statusValues]) => {
            const { color: badgeColor, icon, label } = statusValues;
            const count = statusCountData?.dashboardAssetsByStatus[status];

            return (
              <Flex
                alignItems="center"
                key={status}
                css={`
                  &:last-child > div:last-child {
                    border-bottom: 0;
                  }
                `}
              >
                <Badge icon={icon} color={badgeColor} variant="square" />
                <StatusDivider>
                  <LinkTo to={count > 0 ? statusLink[status] : null}>
                    <P>{label}</P>
                  </LinkTo>
                  <P>{count}</P>
                </StatusDivider>
              </Flex>
            );
          })}
        </Flex>
      )}
    </DashboardCard>
  );
}
