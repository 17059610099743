import { UI_STATES } from './constants';

const connectColors = {
  // available, inbound, outbound, incoming:
  blue: {
    normal: '#4393CA',
    // alternative dark blue: '#3F718E',
    dark: '#3D6F92',
    // if represented with a token from `@decisiv/design-tokens`, use this color name
    kdsColorName: 'information',
  },

  // offline:
  grey: {
    normal: '#666666',
    dark: '#535353',
    kdsColorName: 'licoriceMousse',
  },

  // connected:
  green: {
    normal: '#109B2B',
    dark: '#1F742D',
    kdsColorName: 'success',
  },

  // on hold, monitoring:
  orange: {
    normal: '#E07725',
    dark: '#946031',
    kdsColorName: 'carrotCake',
  },

  // error:
  red: {
    normal: '#CE0B24',
    dark: '#8A2634',
    kdsColorName: 'danger',
  },

  // missed call:
  yellow: {
    normal: '#F5A623',
    dark: '#9E763B',
    kdsColorName: 'warning',
  },
};

export const modifiers = {
  available: 'available',
  inbound: 'inbound',
  outbound: 'outbound',
  incoming: 'incoming',
  offline: 'offline',
  connected: 'connected',
  onhold: 'onhold',
  monitoring: 'monitoring',
  error: 'error',
  missed: 'missed',
};

const uiStateModifiers = {
  // blue
  [UI_STATES.AVAILABLE]: modifiers.available,
  [UI_STATES.CONNECTING]: modifiers.inbound,
  [UI_STATES.INBOUND]: modifiers.inbound,
  [UI_STATES.OUTBOUND]: modifiers.outbound,
  [UI_STATES.INCOMING]: modifiers.incoming,

  // grey
  [UI_STATES.OFFLINE]: modifiers.offline,
  [UI_STATES.AUX]: modifiers.offline,
  [UI_STATES.ACW]: modifiers.offline,
  [UI_STATES.INITIALIZING]: modifiers.offline,
  [UI_STATES.DISCONNECTED]: modifiers.offline,

  // green
  [UI_STATES.CONNECTED]: modifiers.connected,

  // orange
  [UI_STATES.ON_HOLD]: modifiers.onhold,
  [UI_STATES.AGENT_HOLD]: modifiers.onhold,
  [UI_STATES.MONITORING]: modifiers.monitoring,

  // red
  [UI_STATES.ERROR]: modifiers.error,

  // yellow
  [UI_STATES.MISSED]: modifiers.missed,
};

const modifierColors = {
  available: connectColors.blue,
  inbound: connectColors.blue,
  outbound: connectColors.blue,
  incoming: connectColors.blue,
  offline: connectColors.grey,
  connected: connectColors.green,
  onhold: connectColors.orange,
  monitoring: connectColors.orange,
  error: connectColors.red,
  missed: connectColors.yellow,
};

export function modifierToColors(modifier) {
  return modifierColors[modifier] || modifierColors[modifiers.offline];
}

export function uiStateToModifier(uiState) {
  return uiStateModifiers[uiState] || modifiers.offline;
}

export function uiStateColors(uiState) {
  return modifierToColors(uiStateToModifier(uiState));
}
