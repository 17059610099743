import { FormEventHandler } from 'react';
import { t } from '@lingui/macro';
import isEmpty from 'lodash/isEmpty';

import { color } from '@decisiv/design-tokens';
import { toColorString } from 'polished';
import styled from 'styled-components';

import InfoCircleF from '@decisiv/iconix/lib/components/InfoCircleF';
import Flex from '@decisiv/ui-components/lib/components/Flex';
import TextField, {
  TextFieldProps,
} from '@decisiv/ui-components/lib/components/TextField';
import { P } from '@decisiv/ui-components/lib/components/Typography';

import Select from '@decisiv/ui-components/lib/components/Select';
import { DropdownListItem } from '@decisiv/ui-components';

import loadingGif from 'components/Loading/loading.gif';

function Loading() {
  return <img height="16" width="16" src={loadingGif} alt={t`loading`} />;
}

const WarningInputsContainer = styled(Flex)<{ showWarning: boolean }>`
  ${({ showWarning = false }) =>
    showWarning &&
    `& div > div > div {
    border-color: ${toColorString(color.status.warning.medium)}
  }`}
`;

export interface TextFieldSelectProps extends TextFieldProps {
  loading?: boolean;
  loadingMessage?: string;
  options: DropdownListItem[]; // #TODO: export SelectProps
  selectedOptionId: string;
  onChangeSelectedOptionId: FormEventHandler<HTMLButtonElement> &
    ((value: string) => void);
}

const TextFieldSelect = (props: TextFieldSelectProps) => {
  const {
    options,
    label,
    name = '',
    value,
    onChange,
    selectedOptionId,
    onChangeSelectedOptionId,
    warningMessage,
    loading,
    loadingMessage,
  } = props;

  return (
    <Flex flex={1} flexDirection="column">
      <WarningInputsContainer showWarning={!isEmpty(warningMessage)}>
        <Flex flex={1}>
          <Select
            hideLabel
            hideClearButton
            zIndex={100}
            onChange={onChangeSelectedOptionId}
            value={selectedOptionId}
            label={label}
            options={options}
            name={`${name}-select`}
          />
        </Flex>
        <Flex flex={2} marginLeft={1}>
          <TextField
            hideLabel
            label={label}
            value={value}
            onChange={onChange}
            name={`${name}-textfield`}
          />
        </Flex>
      </WarningInputsContainer>
      {loading && (
        <Flex marginTop={0.5}>
          <Loading />
          <P shade={1} size="small" marginLeft={0.5}>
            {loadingMessage}
          </P>
        </Flex>
      )}
      {warningMessage && !loading && (
        <Flex marginTop={0.5}>
          <InfoCircleF
            color={toColorString(color.status.warning.medium)}
            size="medium"
          />
          <P shade={1} size="small" marginLeft={0.5}>
            {warningMessage}
          </P>
        </Flex>
      )}
    </Flex>
  );
};

export default TextFieldSelect;
