import PropTypes from 'prop-types';
import React from 'react';

import { t } from '@lingui/macro';

import PageHead from 'components/PageHead';

export default function AssetGroupingPageHead({ assetGrouping }) {
  return assetGrouping?.name ? (
    <PageHead titleParts={[assetGrouping.name, t`Admin`]} />
  ) : (
    <PageHead titleParts={[t`Customer`, t`Admin`]} />
  );
}

AssetGroupingPageHead.propTypes = {
  assetGrouping: PropTypes.shape({ name: PropTypes.string }),
};

AssetGroupingPageHead.defaultProps = {
  assetGrouping: undefined,
};
