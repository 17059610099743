/* istanbul ignore file -- @preserve */

import { ApolloClient } from '@apollo/client';

import isConfigEnabled from 'utils/isConfigEnabled';
import buildApolloLink from './apolloLinks';
import buildCache from './apolloCache';

function buildApolloClient(config = {}) {
  return new ApolloClient({
    link: buildApolloLink(config),
    cache: buildCache(config),
    connectToDevTools: isConfigEnabled(import.meta.env.VITE_ENABLE_DEV_TOOLS),
  });
}

export default buildApolloClient;
