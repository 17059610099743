import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { Trans } from '@lingui/macro';

import toColorString from 'polished/lib/color/toColorString';

import color from '@decisiv/design-tokens/lib/color';

import Flex from '@decisiv/ui-components/lib/components/Flex';
import { P } from '@decisiv/ui-components/lib/components/Typography';

import { useCurrentUserContext } from 'utils/User';

import ClampableText from 'components/ClampableText';

import AuditBadge from '../../components/AuditBadge';
import AuditTimestamp from '../../components/AuditTimestamp';
import AuditAsset from '../../components/AuditAsset';

function NoteRow({ addSeparator, note }) {
  const currentUser = useCurrentUserContext();
  const editable = currentUser.email === note.createdBy?.email;

  if (!note || !note.text) {
    return null;
  }

  const { email, name } = note.createdBy || {};
  const nameOrEmail = name || email || '–';

  return (
    <Flex marginBottom={1}>
      <Flex marginTop={addSeparator ? 1 : 0}>
        <AuditBadge audit={note} kind={editable ? 'secondary' : 'primary'} />
      </Flex>
      <Flex
        css={`
          width: 100%;
          border-top: ${addSeparator
            ? `1px solid ${toColorString(color.opacity.charcoal15)}`
            : undefined};
        `}
        paddingTop={addSeparator ? 1 : 0}
        flexDirection="column"
        marginLeft={1}
      >
        <Flex flexDirection="column">
          <AuditTimestamp audit={note} />

          <P weight="regular">
            <Trans>
              <P as="span" weight="semibold">
                {nameOrEmail}
              </P>{' '}
              posted a note
            </Trans>
          </P>

          <AuditAsset
            audit={{
              asset: get(note, ['appointment', 'asset'], {}),
              appointment: get(note, ['appointment'], {}),
            }}
          />
        </Flex>
        <Flex
          css={`
            background-color: ${toColorString(color.base.fullMoon)};
            width: 100%;
          `}
          justifyContent="space-between"
          padding={1}
          marginTop={0.5}
        >
          <ClampableText direction="column" linesToClamp={3} shade={1}>
            {note.text}
          </ClampableText>
        </Flex>
      </Flex>
    </Flex>
  );
}

NoteRow.propTypes = {
  addSeparator: PropTypes.bool,
  note: PropTypes.shape({
    createdAt: PropTypes.string.isRequired,
    createdBy: PropTypes.shape({
      email: PropTypes.string,
    }).isRequired,
    text: PropTypes.string,
  }).isRequired,
};

NoteRow.defaultProps = {
  addSeparator: false,
};

export default NoteRow;
