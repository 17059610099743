import React from 'react';
import PropTypes from 'prop-types';

import PlainTextCell from 'components/PlainTextCell';

import { parseDate } from 'utils/dates';

export default function CreatedAtCell({
  rowData: { createdAt, scheduledForDeletionAt },
}) {
  const [topLine, bottomLine] = parseDate(createdAt);

  return (
    <PlainTextCell
      color={scheduledForDeletionAt ? 'alaskanHusky' : undefined}
      {...{ topLine, bottomLine }}
    />
  );
}

CreatedAtCell.propTypes = {
  rowData: PropTypes.shape({
    createdAt: PropTypes.string,
    scheduledForDeletionAt: PropTypes.string,
  }).isRequired,
};
