/* istanbul ignore file -- @preserve */
import { ApolloLink } from '@apollo/client';
import { get, omit } from 'lodash';

/**
 * Checks the props for expected user and role data and, if present, sets
 * the `user` field on the Bugsnag client object.
 *
 * Exported for testing only.
 *
 * @param {{user}} bugsnagClient The previously-configured Bugsnag client
 * @param {object} props The props from Apollo Link forwarding
 */
export function setBugsnagUser(bugsnagClient, props) {
  const user = get(props, 'data.currentUserProfile.user');
  if (user) {
    const role = get(props, 'data.currentUserProfile.user.role.name');
    // eslint-disable-next-line no-param-reassign
    bugsnagClient.user = {
      ...bugsnagClient.user,
      ...omit(user, '__typename'),
      role: role || bugsnagClient.user.role,
    };
  }

  const sentinelTeam = get(props, 'data.sentinelTeam');
  if (sentinelTeam) {
    // eslint-disable-next-line no-param-reassign
    bugsnagClient.user = {
      ...bugsnagClient.user,
      sentinelTeamID: sentinelTeam.id,
      sentinelTeamName: sentinelTeam.name,
    };
  }
}

/**
 * Creates an Apollo Link after-ware that analyzes response data for
 * a `user`, then adds/updates the user info with the Bugsnag client.
 *
 * @param {object} bugsnagClient the configured Bugsnag client object
 * @returns {ApolloLink} Apollo Link after-ware
 */
const create = (bugsnagClient) =>
  new ApolloLink((operation, forward) =>
    forward(operation).map((props) => {
      setBugsnagUser(bugsnagClient, props);
      return props;
    }),
  );

const registerBugsnagUserLinkFactory = { create };

export default registerBugsnagUserLinkFactory;
