import { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { t } from '@lingui/macro';

import toColorString from 'polished/lib/color/toColorString';
import rem from 'polished/lib/helpers/rem';

import color from '@decisiv/design-tokens/lib/color';
import spacing from '@decisiv/design-tokens/lib/spacing';

import Times from '@decisiv/iconix/lib/components/Times';
import InfoCircleF from '@decisiv/iconix/lib/components/InfoCircleF';

import Button from '@decisiv/ui-components/lib/components/Button';
import Flex from '@decisiv/ui-components/lib/components/Flex';
import { P } from '@decisiv/ui-components/lib/components/Typography';

import useApiUserValidQuery from 'pages/Teams/hooks/useApiUserValidQuery';

import {
  generateUserValidationError,
  generatePlatformAccountName,
} from 'utils/SentinelTeam';
import { useTeamModalContext } from '../../contexts/TeamModalContext';

import loadingImg from './loading.gif';
import { TeamSource } from './types';

const Container = styled(Flex)<{ valid: boolean }>`
  align-items: center;
  justify-content: space-between;

  background: ${({ valid }) =>
    valid
      ? toColorString(color.base.fullMoon)
      : toColorString(color.status.warning.light)};
`;

const Loading = styled.img`
  width: ${rem(spacing.base * 2.1)};
  height: ${rem(spacing.base * 2.1)};

  margin-right: ${rem(spacing.base * 0.8)};
`;

export default function SourceItem({ source }: { source: TeamSource }) {
  const { values, setValues, isEditMode } = useTeamModalContext();

  const { dealerName, sourceType, value, valid, validated, errors } = source;
  const { teamType, username, password, ownerEmail } = values;

  const handleValidationCompleted = useCallback(
    (data) => {
      // sometimes, in testing at least, data is `undefined`
      if (!data) return;

      const { apiUserValid, userEmailValid } = data;
      if (!apiUserValid || !userEmailValid) return;

      const { valid: apiValid, errors: apiErrors } = apiUserValid;
      const { valid: emailValid } = userEmailValid;

      setValues((oldState) => ({
        ...oldState,
        sourceIds: oldState.sourceIds.map((src) =>
          src.value === value
            ? {
                ...src,
                valid: apiValid,
                validated: true,
                errors: apiErrors,
                emailValid,
              }
            : src,
        ),
      }));
    },
    [setValues, value],
  );

  const shouldSkip = useMemo(
    () =>
      isEditMode
        ? validated
        : validated || !username || !password || !ownerEmail,
    [isEditMode, password, username, validated, ownerEmail],
  );

  const { loading } = useApiUserValidQuery({
    variables: {
      username: isEditMode ? undefined : username,
      password: isEditMode ? undefined : password,
      email: ownerEmail,
      teamType: teamType ?? 'DEALER_TEAM',
      sourceId: value,
      sourceType,
    },
    fetchPolicy: 'network-only',
    onCompleted: handleValidationCompleted,
    skip: shouldSkip,
    onError: () => {
      const error = 'INTERNAL_ERROR';

      setValues((oldState) => ({
        ...oldState,
        sourceIds: oldState.sourceIds.map((src) =>
          src.value === value
            ? { ...src, valid: false, validated: true, errors: [error] }
            : src,
        ),
      }));
    },
  });

  const validationError = useMemo(
    () =>
      generateUserValidationError(errors?.[0], sourceType, value) ||
      t`Internal Error`,
    [errors, sourceType, value],
  );

  const isInvalid = useMemo(() => !valid && validated, [valid, validated]);

  const handleRemoveItem = useCallback(() => {
    setValues((oldState) => ({
      ...oldState,
      sourceIds: [...oldState.sourceIds.filter((i) => i.value !== value)],
    }));
  }, [setValues, value]);

  const label = dealerName || generatePlatformAccountName(sourceType, value);

  return (
    <Container paddingX={1} paddingY={1.1} valid={!isInvalid}>
      <Flex alignItems="center">
        {loading && <Loading src={loadingImg} alt={t`Validation loading...`} />}
        <P marginRight={0.8}>{label}</P>
        {errors.length > 0 && !loading && (
          <>
            <InfoCircleF
              size="medium"
              color={toColorString(color.status.warning.medium)}
            />
            <P color="alaskanHusky" size="small" marginLeft={0.5}>
              {validationError}
            </P>
          </>
        )}
      </Flex>
      <Button
        icon={Times}
        variant="ghost"
        onClick={handleRemoveItem}
        aria-label={t`Remove ${value}`}
      />
    </Container>
  );
}
