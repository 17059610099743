import { useContext, useEffect } from 'react';

import useSentinelTeam from 'hooks/useSentinelTeam';

import { CurrentInvitationContext } from 'utils/Invitation';

const defaultFaviconLink = document.querySelector('link[rel="icon"]');
const defaultFaviconId = defaultFaviconLink?.id;
const defaultFaviconHref = defaultFaviconLink?.href;

const dynamicFaviconId = 'dynamic-favicon';

/**
 * Unsets the favicon by removing the link element with the provided ID from the head.
 *
 * NOTE: This must be extracted from `useEffect` to work as expected.
 */
function unsetFavicon(id) {
  const links = document.querySelectorAll(`#${id}`);
  if (links.length) {
    links.forEach((link) => {
      document.head.removeChild(link);
    });
  }
}

/**
 * Sets a new favicon by creating a new link[rel="icon"] element in the head with the
 * provided id & url.
 *
 * NOTE: This must be extracted from `useEffect` to work as expected.
 */
function setFavicon(id, url) {
  const link = document.createElement('link');
  link.id = id;
  link.rel = 'icon';
  link.href = url;
  document.head.appendChild(link);
}

function FaviconManager() {
  const { sentinelTeam: invitationSentinelTeam } = useContext(
    CurrentInvitationContext,
  );
  const userSentinelTeam = useSentinelTeam();

  const sentinelTeam = invitationSentinelTeam || userSentinelTeam;

  useEffect(() => {
    if (sentinelTeam?.favicon?.url) {
      // deactivate/ delete default icon
      unsetFavicon(defaultFaviconId);

      // activate/ create dynamic icon
      setFavicon(dynamicFaviconId, sentinelTeam.favicon.url);
    } else {
      // deactivate/ delete dynamic icon
      unsetFavicon(dynamicFaviconId);

      // activate/ create default icon
      setFavicon(defaultFaviconId, defaultFaviconHref);
    }
  }, [sentinelTeam]);

  return null;
}

export default FaviconManager;
