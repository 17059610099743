import { QueryHookOptions, useQuery } from '@apollo/client';
import query from './unreadNotificationsCount.graphql';

const useUnreadNotificationsCount = (
  options?: QueryHookOptions<
    UnreadNotificationsCountQuery,
    UnreadNotificationsCountQueryVariables
  >,
) => {
  return useQuery<
    UnreadNotificationsCountQuery,
    UnreadNotificationsCountQueryVariables
  >(query, options);
};

export default useUnreadNotificationsCount;
