import React from 'react';
import PropTypes from 'prop-types';

import Badge from '@decisiv/ui-components/lib/components/Badge';
import Flex from '@decisiv/ui-components/lib/components/Flex';
import Link from '@decisiv/ui-components/lib/components/Link';

import {
  badgeColorForAppointment,
  badgeIconForAppointment,
} from 'utils/Appointments';

import ROUTE_NAMES from 'setup/Routing/routeNames';
import ServiceRequestTag from 'components/ServiceRequestTag';
import CaseTag from 'components/CaseTag';

export default function ServiceRequest({ value }) {
  if (!value?.serviceRequest) return null;

  const { serviceRequest } = value;
  const { status, appointment: tmpAppointment } = serviceRequest;

  const appointment = {
    ...tmpAppointment,
    status: 'REQUESTED',
    id: tmpAppointment.beforeId || tmpAppointment.afterId,
  };

  return (
    <Flex data-testid="service-request-audit">
      <Badge
        variant="square"
        aria-label={status}
        color={badgeColorForAppointment(appointment)}
        icon={badgeIconForAppointment(appointment)}
      />
      <Flex flexDirection="column" marginLeft={1} alignSelf="center">
        <Link
          to={ROUTE_NAMES.forServiceEvent(appointment)}
          text={appointment.id}
        />
        {status === 'ACCEPTED' ? (
          <CaseTag caseInfo={appointment.platformCase} data-testid="case" />
        ) : (
          <ServiceRequestTag
            serviceRequest={serviceRequest}
            data-testid="servicerequest"
          />
        )}
      </Flex>
    </Flex>
  );
}

ServiceRequest.propTypes = {
  value: PropTypes.shape({
    serviceRequest: PropTypes.shape({
      status: PropTypes.string.isRequired,
      appointmentDate: PropTypes.string,
      declinedReason: PropTypes.string,
      note: PropTypes.string,
      appointment: PropTypes.shape({
        beforeId: PropTypes.string,
        afterId: PropTypes.string,
      }),
    }),
    changedAt: PropTypes.string,
  }).isRequired,
};
