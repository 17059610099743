import get from 'lodash/get';

import removeBlankProperties from 'utils/removeBlankProperties';

function makeSentinelTeamIdentity(sentinelTeam) {
  return removeBlankProperties({
    // TODO: validate this is actually useful or if there are better options
    id: get(sentinelTeam, 'id'),
    name: get(sentinelTeam, 'name'),
    applicationName: get(sentinelTeam, 'applicationName'),
    dealerGroupId: get(sentinelTeam, 'dealerGroupId'),
    teamKey: get(sentinelTeam, 'teamKey'),
    ownerEmail: get(sentinelTeam, 'owner.email'),
    //   planLevel:    // Optional
    //   planPrice:    // Optional
    //   creationDate: // Optional
    //   // You can add any additional account level key-values here,
    //   // as long as it's not one of the above reserved names.
    // },
  });
}

export default makeSentinelTeamIdentity;
