import React, { useRef, useMemo, useState, useCallback } from 'react';
import { t } from '@lingui/macro';
import PropTypes from 'prop-types';

import Flex from '@decisiv/ui-components/lib/components/Flex';

import useIsTruncated from 'hooks/useIsTruncated';

import { TextClamp, ExpandButton } from './styles';

export default function ClampableText({
  children,
  direction,
  linesToClamp,
  ...props
}) {
  const textRef = useRef(null);

  const [isExpanded, setIsExpanded] = useState(false);

  const isTruncated = useIsTruncated(textRef, [children, isExpanded]);

  const expandButtonText = useMemo(
    () => (isExpanded ? t`Read Less` : t`Read More`),
    [isExpanded],
  );

  const handleOnExpandButtonClick = useCallback(() => {
    setIsExpanded((oldState) => !oldState);
  }, []);

  return (
    <Flex flexDirection={direction} flex={1}>
      <TextClamp
        size="small"
        ref={textRef}
        clamp={!isExpanded}
        linesToClamp={linesToClamp}
        marginRight={isTruncated ? 0.5 : undefined}
        {...props}
      >
        {children}
      </TextClamp>
      {(isExpanded || isTruncated) && (
        <ExpandButton
          size="small"
          variant="ghost"
          text={expandButtonText}
          onClick={handleOnExpandButtonClick}
          direction={direction}
        />
      )}
    </Flex>
  );
}

ClampableText.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  direction: PropTypes.oneOf(['column', 'row']),
  linesToClamp: PropTypes.number,
};

ClampableText.defaultProps = {
  direction: 'row',
  linesToClamp: 3,
};
