import Flag from '@decisiv/iconix/lib/components/Flag';
import Send from '@decisiv/iconix/lib/components/Send';
import SendCheck from '@decisiv/iconix/lib/components/SendCheck';
import SendTimes from '@decisiv/iconix/lib/components/SendTimes';
import Truck from '@decisiv/iconix/lib/components/Truck';

import { t } from '@lingui/macro';

export const CUSTOMER_TYPE = {
  DEPOT: 'DEPOT',
  LOCAL: 'LOCAL',
} as const;

export const ASSET_GROUPING_TYPE = {
  DEPOT: 'DEPOT',
  CUSTOMER: 'CUSTOMER',
} as const;

export const CUSTOMER_STATUS = {
  NEEDS_ACTION: 'NEEDS_ACTION',
  REQUEST_PENDING: 'REQUEST_PENDING',
  REQUEST_ACCEPTED: 'REQUEST_ACCEPTED',
  WITH_ASSETS_ENABLED: 'WITH_ASSETS_ENABLED',
  REQUEST_DENIED: 'REQUEST_DENIED',
} as const;

export const CUSTOMER_ACCEPTED_STATUSES = [
  CUSTOMER_STATUS.REQUEST_ACCEPTED,
  CUSTOMER_STATUS.WITH_ASSETS_ENABLED,
] as const;

export const CUSTOMER_PENDING_STATUSES = [
  CUSTOMER_STATUS.REQUEST_PENDING,
  CUSTOMER_STATUS.REQUEST_DENIED,
  CUSTOMER_STATUS.NEEDS_ACTION,
] as const;

export const customerColors = {
  [CUSTOMER_STATUS.NEEDS_ACTION]: 'danger',
  [CUSTOMER_STATUS.REQUEST_PENDING]: 'warning',
  [CUSTOMER_STATUS.REQUEST_ACCEPTED]: 'information',
  [CUSTOMER_STATUS.WITH_ASSETS_ENABLED]: 'success',
  [CUSTOMER_STATUS.REQUEST_DENIED]: 'licoriceMousse',
} as const;

export const customerIcons = {
  [CUSTOMER_STATUS.NEEDS_ACTION]: Flag,
  [CUSTOMER_STATUS.REQUEST_PENDING]: Send,
  [CUSTOMER_STATUS.REQUEST_ACCEPTED]: SendCheck,
  [CUSTOMER_STATUS.WITH_ASSETS_ENABLED]: Truck,
  [CUSTOMER_STATUS.REQUEST_DENIED]: SendTimes,
} as const;

export const customerStatusLabelText = {
  [CUSTOMER_STATUS.NEEDS_ACTION]: t`Needs Action`,
  [CUSTOMER_STATUS.REQUEST_PENDING]: t`Request Pending`,
  [CUSTOMER_STATUS.REQUEST_ACCEPTED]: t`Request Accepted`,
  [CUSTOMER_STATUS.WITH_ASSETS_ENABLED]: t`With Assets Enabled`,
  [CUSTOMER_STATUS.REQUEST_DENIED]: t`Request Denied`,
} as const;
