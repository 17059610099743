import { useContext, useMemo } from 'react';

import { CurrentInvitationContext } from 'utils/Invitation';

export default function useInvitation() {
  const invitation = useContext(CurrentInvitationContext);

  const isInvitation = useMemo(() => !!invitation.id, [invitation.id]);

  return {
    invitation,
    isInvitation,
  };
}
