import { isEmpty } from 'lodash';
import { t, Trans } from '@lingui/macro';

import { Link, Alert } from '@decisiv/ui-components';
import InfoCircle from '@decisiv/iconix/lib/components/InfoCircle';
import { P } from '@decisiv/ui-components/lib/components/Typography';

import { hasDealerAccess, useCurrentUserContext } from 'utils/User';
import ROUTE_NAMES from 'setup/Routing/routeNames';

import useSentinelTeam from 'hooks/useSentinelTeam';

const LackOfPermissionsAlert = () => {
  const currentUser = useCurrentUserContext();
  const { isDealerTeam, platformAccounts } = useSentinelTeam();

  if (!isDealerTeam) return null;

  const invalidPlatformAccountsList = platformAccounts
    .map((platformAccount) => {
      return !hasDealerAccess(currentUser, platformAccount);
    })
    .filter(Boolean);

  if (isEmpty(invalidPlatformAccountsList)) return null;

  const message = currentUser?.isAdmin ? (
    <Trans>
      You need Portal Access to some Dealer IDs in this team.{' '}
      <Link size="small" text={t`See More`} to={ROUTE_NAMES.admin.members} />
    </Trans>
  ) : (
    <Trans>
      You need Portal access to some Dealer IDs in this team. Contact a team
      admin.
    </Trans>
  );

  return (
    <Alert title="" intent="warning" icon={InfoCircle}>
      <P weight="semibold" size="small">
        {message}
      </P>
    </Alert>
  );
};

export default LackOfPermissionsAlert;
