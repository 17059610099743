import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import ROUTE_NAMES from 'setup/Routing/routeNames';
import useSentinelTeam from 'hooks/useSentinelTeam';

export default function FleetRoute({ children }) {
  const { isFleet } = useSentinelTeam();
  const location = useLocation();

  if (!isFleet) {
    return (
      <Navigate
        to={ROUTE_NAMES.access_denied}
        state={{ from: location }}
        replace
      />
    );
  }

  return children;
}

FleetRoute.propTypes = {
  children: PropTypes.node,
};

FleetRoute.defaultProps = {
  children: null,
};
