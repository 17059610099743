import { QueryHookOptions, useQuery } from '@apollo/client';

import query from './currentSentinelTeamQuery.graphql';

export default function useCurrentSentinelTeamQuery(
  opts?: QueryHookOptions<
    CurrentSentinelTeamQueryQuery,
    CurrentSentinelTeamQueryQueryVariables
  >,
) {
  return useQuery<
    CurrentSentinelTeamQueryQuery,
    CurrentSentinelTeamQueryQueryVariables
  >(query, opts);
}
