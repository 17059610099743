import {
  createContext,
  forwardRef,
  useMemo,
  useImperativeHandle,
  useCallback,
  createRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import Loading from 'components/Loading';
import TeamChanger from 'components/TeamChanger';

import {
  isDealerTeam as isDealerTeamType,
  isFleet as isFleetType,
  isDealerGroup as isDealerGroupType,
  isSingleLocation as isSingleLocationType,
} from 'utils/SentinelTeam';
import { useCurrentUserContext } from 'utils/User';

import { FetchResult, MutationFunctionOptions } from '@apollo/client';
import useCurrentSentinelTeamQuery from './useCurrentSentinelTeamQuery';
import useSetCurrentSentinelTeamMutation from './useSetCurrentSentinelTeamMutation';

type SetSentinelTeamMutationOpts = MutationFunctionOptions<
  SetCurrentSentinelTeamMutation,
  SetCurrentSentinelTeamMutationVariables
>;

type SetSentinelTeamType = (
  options?: SetSentinelTeamMutationOpts,
) => Promise<FetchResult<SetCurrentSentinelTeamMutation>> | Promise<void>;

interface SentinelTeamProviderProps {
  children: React.ReactNode;
}

interface SentinelTeamProviderHandles {
  setSentinelTeam: SetSentinelTeamType;
}

export interface SentinelTeamContextProps extends CurrentSentinelTeam {
  isFleet: boolean;
  isDealerTeam: boolean;
  isDealerGroup: boolean;
  isSingleLocation: boolean;
  isTeamMember: boolean;
  hasSentinelTeam: boolean;
  isTeamEnabled: boolean;
  setSentinelTeam: SetSentinelTeamType;
  logout: () => Promise<void>;
}

export const SentinelTeamContext = createContext<SentinelTeamContextProps>(
  {} as SentinelTeamContextProps,
);

const SentinelTeamProvider = forwardRef(
  (
    { children }: SentinelTeamProviderProps,
    ref: React.Ref<SentinelTeamProviderHandles>,
  ) => {
    const [isSettingTeam, setSettingTeam] = useState(false);

    const { sentinelTeams = [] } = useCurrentUserContext();

    const {
      data: {
        currentUserProfile: {
          user: { sentinelTeam = {} as CurrentSentinelTeam } = {},
        } = {},
      } = {},
      loading,
    } = useCurrentSentinelTeamQuery();

    const [updateSentinelTeam, { loading: setSentinelTeamLoading }] =
      useSetCurrentSentinelTeamMutation();

    const { teamType, id: teamId, enabled } = sentinelTeam || {};

    const isFleet = useMemo(() => isFleetType(teamType), [teamType]);
    const isDealerTeam = useMemo(
      () =>
        isDealerTeamType(teamType) ||
        isDealerGroupType(teamType) ||
        isSingleLocationType(teamType),
      [teamType],
    );

    const isDealerGroup = false;
    const isSingleLocation = false;

    const isTeamMember = useMemo(
      () => sentinelTeams.map(({ id }) => id).includes(teamId),
      [sentinelTeams, teamId],
    );

    const hasSentinelTeam = useMemo(() => !!teamId, [teamId]);

    const isTeamEnabled = useMemo(() => !!enabled, [enabled]);

    const setCurrentSentinelTeam = useCallback(
      async (options) => {
        setSettingTeam(true);
        await updateSentinelTeam(options);
        window.location.href = '/';
      },
      [updateSentinelTeam],
    );

    const logout = useCallback(async () => {
      setSettingTeam(true);
      await updateSentinelTeam({
        variables: { id: null as unknown as any },
      });
      window.location.reload();
    }, [updateSentinelTeam]);

    useImperativeHandle(
      ref,
      () => ({
        setSentinelTeam: updateSentinelTeam,
      }),
      [updateSentinelTeam],
    );

    const values = useMemo(
      () => ({
        ...sentinelTeam,
        isFleet,
        isDealerTeam,
        isDealerGroup,
        isSingleLocation,
        isTeamMember,
        hasSentinelTeam,
        isTeamEnabled,
        setSentinelTeam: setCurrentSentinelTeam,
        logout,
      }),
      [
        sentinelTeam,
        isFleet,
        isDealerTeam,
        isDealerGroup,
        isSingleLocation,
        isTeamMember,
        hasSentinelTeam,
        isTeamEnabled,
        setCurrentSentinelTeam,
        logout,
      ],
    );

    if (setSentinelTeamLoading || isSettingTeam) {
      return <TeamChanger />;
    }

    if (loading) {
      return <Loading />;
    }

    return (
      <SentinelTeamContext.Provider value={values}>
        {children}
      </SentinelTeamContext.Provider>
    );
  },
);

SentinelTeamProvider.displayName = 'SentinelTeamProvider';

SentinelTeamProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const sentinelTeamProviderRef = createRef<SentinelTeamProviderHandles>();

export async function setSentinelTeam(opts?: SetSentinelTeamMutationOpts) {
  return sentinelTeamProviderRef?.current?.setSentinelTeam?.(opts);
}

export default SentinelTeamProvider;
