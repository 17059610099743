import { PropsWithChildren, useMemo } from 'react';
import rem from 'polished/lib/helpers/rem';
import styled from 'styled-components';
import { t } from '@lingui/macro';

import Badge, { BadgeProps } from '@decisiv/ui-components/lib/components/Badge';
import Grid from '@decisiv/ui-components/lib/components/Grid';
import {
  H2,
  H3,
  H4,
  P,
} from '@decisiv/ui-components/lib/components/Typography';
import spacing from '@decisiv/design-tokens/lib/spacing';

interface EmptyResultsProps extends Pick<BadgeProps, 'color' | 'icon'> {
  title: string;
  subtitle: string;
  size?: 'large' | 'medium' | 'small';
  iconSize?: 'large' | 'medium' | 'small';
  maxWidth?: string;
  padding?: number;
}

const FullRow = styled(Grid.Row)`
  flex: 1;
  height: 100%;
  margin: auto;
  justify-content: center;
`;

const InnerColumn = styled(Grid.Column)`
  text-align: center;
`;

const TITLE = {
  large: H2,
  medium: H3,
  small: H4,
} as const;

export default function EmptyResults({
  color = 'information',
  icon,
  size = 'large',
  iconSize,
  subtitle = t`Try removing or editing some of your filters to expand your search.`,
  title,
  maxWidth = rem(spacing.base * 34),
  children,
  padding,
  ...rest
}: PropsWithChildren<EmptyResultsProps>) {
  const Title = useMemo(() => TITLE[size], [size]);
  const defaultPadding = useMemo(() => (size === 'large' ? 5 : 3), [size]);

  return (
    <FullRow {...rest}>
      <InnerColumn
        maxWidth={maxWidth}
        paddingY={padding ?? defaultPadding}
        style={{ textAlign: 'center' }}
      >
        <div style={{ margin: 'auto' }}>
          <Badge
            aria-label="" // the <h3> below is sufficient to describe the state
            color={color}
            icon={icon}
            size={iconSize ?? size}
          />
        </div>
        <Title marginTop={2} marginBottom={0.5}>
          {title}
        </Title>
        <P shade={1} size={size === 'small' ? 'small' : undefined}>
          {subtitle}
        </P>
        {children}
      </InnerColumn>
    </FullRow>
  );
}
