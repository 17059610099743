/* istanbul ignore file -- @preserve */

import kebabCase from 'lodash/kebabCase';
import toUpper from 'lodash/toUpper';
import MemoryStorage from 'memorystorage';

const CONFIG_NAME = 'Managed Care';

/**
 * This function sets up a browser-local memory DB, accessible via `global.memoryDB`.  It
 * first determines if `localStorage` is available and properly functional, and if so, sets
 * the `memoryDB` to be a reference to `localStorage`.  If `localStorage` is broken (for
 * example, in Safari Private Browsing mode), then it sets `memoryDB` to refer to an instance
 * of `MemoryStorage`, which supports the same API as `localStorage`, but does not persist
 * its keys/values across full page refresh.
 */
export function initLocalStorage(configName = CONFIG_NAME) {
  let actualStorage = localStorage;
  try {
    const x = `test-localstorage-${Date.now()}`;
    actualStorage.setItem(x, x);
    const y = actualStorage.getItem(x);
    actualStorage.removeItem(x);
    if (y !== x) {
      throw new Error();
    }
  } catch (e) {
    // fall back to a memory-based implementation
    // (for a config.name 'My App', the memory storage will be DECISIV-MY-APP)
    const memoryStorageName = `DECISIV-${toUpper(kebabCase(configName))}`;
    actualStorage = new MemoryStorage(memoryStorageName);
  }

  global.memoryDB = actualStorage;
  return actualStorage;
}

export function initSessionStorage(configName = CONFIG_NAME) {
  const memoryStorageName = `DECISIV-${toUpper(kebabCase(configName))}-SESSION`;
  global.sessionDB = new MemoryStorage(memoryStorageName);
}
