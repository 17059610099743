import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import ROUTE_NAMES from 'setup/Routing/routeNames';
import useSentinelTeam from 'hooks/useSentinelTeam';

export default function DealerGroupOrDealerTeamRoute({ children }) {
  const { isDealerTeam, isDealerGroup } = useSentinelTeam();
  const location = useLocation();

  if (!isDealerTeam && !isDealerGroup) {
    return (
      <Navigate
        to={ROUTE_NAMES.access_denied}
        state={{ from: location }}
        replace
      />
    );
  }

  return children;
}

DealerGroupOrDealerTeamRoute.propTypes = {
  children: PropTypes.node,
};

DealerGroupOrDealerTeamRoute.defaultProps = {
  children: null,
};
