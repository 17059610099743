import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { t, Trans } from '@lingui/macro';
import find from 'lodash/find';
import { loader } from 'graphql.macro';
import partition from 'lodash/partition';
import isEmpty from 'lodash/isEmpty';

import Send from '@decisiv/iconix/lib/components/Send';

import Flex from '@decisiv/ui-components/lib/components/Flex';
import { P } from '@decisiv/ui-components/lib/components/Typography';
import Modal from '@decisiv/ui-components/lib/components/Modal';
import Message from '@decisiv/ui-components/lib/components/Message';
import Asterisk from '@decisiv/iconix/lib/components/Asterisk';
import Select from '@decisiv/ui-components/lib/components/Select';
import TextArea from '@decisiv/ui-components/lib/components/TextArea';

import InvitationRow from 'components/InvitationRow';

import { formatName } from 'utils/Contact';
import { updateCustomerFilters } from 'utils/Invitations';

const sendInvitationMutation = loader('./sendInvitation.graphql');

const mapItems = (contacts) =>
  contacts.map((contact) => ({
    id: contact.id,
    contact,
    decoration: {
      type: 'Avatar',
      email: contact.email,
      name: contact.name,
    },
    label: formatName(contact) || contact.email,
    value: contact.id,
  }));

export default function SendInvitationModal({
  customer,
  handleClose,
  visible,
}) {
  const [sendInvitation] = useMutation(sendInvitationMutation, {
    refetchQueries: ['AssetGroupingHistory'],
    update: (cache, { data }) =>
      updateCustomerFilters(
        customer,
        cache,
        data.sendInvitation.invitation.customer.status,
      ),
  });
  const [contactId, setContactId] = useState(
    customer.contacts.length === 1 ? String(customer.contacts[0].id) : null,
  );
  const [primaryContacts, customerContacts] = useMemo(
    () =>
      partition(
        customer.contacts.filter(({ email }) => !isEmpty(email?.trim())),
        'primary',
      ),
    [customer],
  );
  const [note, setNote] = useState(null);

  const handleSelect = useCallback(
    (value) => {
      setContactId(String(value));
    },
    [setContactId],
  );

  const submitForm = useCallback(async () => {
    await sendInvitation({ variables: { contactId, note } });
    handleClose();
  }, [contactId, handleClose, note, sendInvitation]);

  const options = useMemo(
    () =>
      [
        primaryContacts.length && {
          label: t`Customer`,
          items: mapItems(primaryContacts),
        },
        customerContacts.length && {
          label: t`Contacts`,
          items: mapItems(customerContacts),
        },
      ].filter(Boolean),
    [primaryContacts, customerContacts],
  );

  const handleOptionLabel = useCallback(
    ({ id }) => (
      <InvitationRow
        contact={find(customer.contacts, { id })}
        selected={id === contactId}
      />
    ),
    [contactId, customer],
  );

  const customerName = customer.name;
  return (
    <Modal
      actions={[
        {
          disabled: !contactId,
          text: t`Send Request`,
          onClick: submitForm,
        },
        { text: t`Cancel`, onClick: handleClose },
      ]}
      icon={Send}
      intent="information"
      onClose={handleClose}
      title={t`Send Request`}
      visible={visible}
    >
      <Flex flexDirection="column">
        <P color="alaskanHusky" marginBottom={3}>
          <Trans>
            Select a contact to send the request to for access to {customerName}{' '}
            asset information. An email will be sent to the contact you select.
          </Trans>
        </P>
        <Flex justifyContent="flex-end">
          <Message color="information" icon={Asterisk}>
            <Trans>Required Fields</Trans>
          </Message>
        </Flex>
        <Flex marginY={1}>
          <Select
            label={t`Contact`}
            name="customer-contact"
            onChange={handleSelect}
            options={options}
            renderOptionLabel={handleOptionLabel}
            required
            value={contactId}
            zIndex={101}
          />
        </Flex>
        <TextArea
          label={t`Note`}
          maxLength={200}
          onChange={(e) => setNote(e.target.value)}
          showCharacterCount
          value={note || ''}
        />
      </Flex>
    </Modal>
  );
}

SendInvitationModal.propTypes = {
  customer: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    contacts: PropTypes.arrayOf(
      PropTypes.shape({ id: PropTypes.string.isRequired }),
    ),
  }).isRequired,
  handleClose: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};
