import flatten from 'lodash/flatten';
import intersection from 'lodash/intersection';

import ROLES from './roles';

export function isValidRoleOrRoles(roleOrRoles) {
  const validRoles = Object.keys(ROLES);
  const rolesArr = flatten([roleOrRoles]);
  const int = intersection(validRoles, rolesArr);
  return int.length === rolesArr.length;
}
