import toColorString from 'polished/lib/color/toColorString';
import React, { useContext } from 'react';

import { Trans } from '@lingui/macro';

import color from '@decisiv/design-tokens/lib/color';

import Microphone from '@decisiv/iconix/lib/components/Microphone';
import MicrophoneSlash from '@decisiv/iconix/lib/components/MicrophoneSlash';
import PhoneHangup from '@decisiv/iconix/lib/components/PhoneHangup';

import Grid from '@decisiv/ui-components/lib/components/Grid';
import { P } from '@decisiv/ui-components/lib/components/Typography';

import {
  AmazonConnectContext,
  contactDetailsEnabled,
  isConversationState,
} from 'features/amazonConnect';

import Vr from 'components/Vr';

import { CurrentInvitationContext } from 'utils/Invitation';

function getMicrophoneIcon(uiState, muted) {
  if (isConversationState(uiState)) {
    return muted ? <MicrophoneSlash /> : <Microphone />;
  }
  return <PhoneHangup />;
}

export default function CurrentCallInfo() {
  const { authenticationKey } = useContext(CurrentInvitationContext);

  const { agent, contact, contactNumber, muted, uiState } =
    useContext(AmazonConnectContext);

  if (
    authenticationKey ||
    !agent ||
    !contact ||
    !contactDetailsEnabled(uiState)
  ) {
    return null;
  }

  const { dialedNumber } = contact.getAttributes();
  const agentNumber = dialedNumber && dialedNumber.value;

  const agentDisplayNumber = agentNumber || <Trans>(unknown)</Trans>;

  return (
    <Grid.Container>
      <Grid.Row alignment="middle" height="100%">
        <Grid.Column paddingX={1.5} span="0">
          <Vr
            color={toColorString(color.opacity.fullMoon50)}
            data-testid="vr"
            height="35px"
          />
        </Grid.Column>
        <Grid.Column paddingX={0} span="0">
          {getMicrophoneIcon(uiState, muted)}
        </Grid.Column>
        <Grid.Column>
          <Grid.Row>
            <Grid.Column>
              <P as="span" size="small" shade={2} weight="semibold">
                {contact.isInbound() ? (
                  <Trans>Inbound Call</Trans>
                ) : (
                  <Trans>Outbound Call</Trans>
                )}
              </P>
            </Grid.Column>
          </Grid.Row>
          <P as="span" color="halfMoon" size="small" weight="regular">
            {contact.isInbound() ? (
              <Trans>
                from {contactNumber} to {agentDisplayNumber}
              </Trans>
            ) : (
              <Trans>to {contactNumber}</Trans>
            )}
          </P>
        </Grid.Column>
      </Grid.Row>
    </Grid.Container>
  );
}
