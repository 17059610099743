import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import colors from '@decisiv/design-tokens/lib/color';

import InfoCircleF from '@decisiv/iconix/lib/components/InfoCircleF';

import Button from '@decisiv/ui-components/lib/components/Button';
import Popover from '@decisiv/ui-components/lib/components/Popover';
import { H3, P } from '@decisiv/ui-components/lib/components/Typography';

import { toColorString } from 'polished';
import { Flex } from '@decisiv/ui-components';
import {
  Container,
  Header,
  HeaderTextContent,
  InfoTextContainer,
} from './styles';

export default function DashboardCard({
  text,
  infoText: InfoText,
  buttonText,
  buttonDisabled,
  onButtonClick,
  buttonAriaLabel,
  children,
  renderButton: RenderButton,
  ...props
}) {
  const iconRef = useRef(null);

  const shouldShowButton = !!buttonText || !!RenderButton;

  const ButtonComponent = RenderButton || Button;

  return (
    <Container {...props}>
      {!!text && (
        <Header
          alignItems="center"
          justifyContent="space-between"
          data-testid="dashboardcard-header"
        >
          <HeaderTextContent alignItems="center">
            <H3>{text}</H3>
            {!!InfoText && (
              <>
                <Flex
                  marginLeft={1}
                  ref={iconRef}
                  data-testid="dashboardcard-icon"
                >
                  <InfoCircleF
                    size="small"
                    color={toColorString(colors.status.information.medium)}
                  />
                </Flex>
                <Popover target={iconRef} manageEvents="hover">
                  <InfoTextContainer padding={1}>
                    <P color="alaskanHusky">
                      {typeof InfoText === 'function' ? <InfoText /> : InfoText}
                    </P>
                  </InfoTextContainer>
                </Popover>
              </>
            )}
          </HeaderTextContent>
          {!!shouldShowButton && (
            <ButtonComponent
              variant="ghost"
              text={buttonText}
              disabled={buttonDisabled}
              onClick={onButtonClick}
              aria-label={buttonAriaLabel}
            />
          )}
        </Header>
      )}
      {children}
    </Container>
  );
}

DashboardCard.propTypes = {
  text: PropTypes.string,
  infoText: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  buttonText: PropTypes.string,
  buttonDisabled: PropTypes.bool,
  buttonAriaLabel: PropTypes.string,
  onButtonClick: PropTypes.func,
  renderButton: PropTypes.func,
  children: PropTypes.node,
};

DashboardCard.defaultProps = {
  text: undefined,
  infoText: undefined,
  buttonText: undefined,
  buttonDisabled: false,
  buttonAriaLabel: undefined,
  onButtonClick: () => undefined,
  renderButton: undefined,
  children: undefined,
};
