/* istanbul ignore file -- @preserve */

import bugsnagClientFactory from './bugsnagClientFactory';
import errorBoundaryFactory from './errorBoundaryFactory';
import bugsnagBreadcrumbLinkFactory from './bugsnagBreadcrumbLink';
import registerBugsnagUserLinkFactory from './registerBugsnagUserLinkFactory';
import reportGraphQLErrorsFactory from './reportGraphQLErrors';

// Creates an ENV appropriate bugsnagClient. In the test env, bugsnagClient is mocked out.
// This is required because the @bugsnag/js package must run in a browser, and can not run
// in node, where our tests are run.
const bugsnagClient = bugsnagClientFactory.create();

// Creates an error boundary component with reporting to bugsnag.
const ErrorBoundary = errorBoundaryFactory.create(bugsnagClient);

// Creates an ApolloLink that registers the current user in bugsnag;
const registerBugsnagUserLink =
  registerBugsnagUserLinkFactory.create(bugsnagClient);

// Creates an ApolloLink that creates Bugsnag breadcrumbs for every
// GraphQL query/mutation request and response.
const bugsnagBreadcrumbLink =
  bugsnagBreadcrumbLinkFactory.create(bugsnagClient);

const reportGraphQLErrors = reportGraphQLErrorsFactory.create(bugsnagClient);

export {
  bugsnagClient,
  ErrorBoundary,
  registerBugsnagUserLink,
  bugsnagBreadcrumbLink,
  reportGraphQLErrors,
};
