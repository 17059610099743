// Compares equal keys between iterator and base and
// returns the base object merged with equal keys
const cloneEqualKeys = (iterator, base) => {
  const clonedObject = Object.entries(iterator).reduce((acc, [key, value]) => {
    const included = Object.keys(base).includes(key);

    if (!included) return acc;

    acc[key] = value;

    return acc;
  }, {});

  return { ...base, ...clonedObject };
};

export default cloneEqualKeys;
