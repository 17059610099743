import qs from 'qs';
import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';

/**
 * Returns an object after parsing a query string of a url
 * @param {string} queryString
 * @returns {object} parsed object
 * @example parseQueryParams('?id=1234&param=hello') will return { id: '1234', param: 'hello' }
 */
export function parseQueryParams(queryString) {
  return qs.parse(queryString, { ignoreQueryPrefix: true });
}

function setQueryParams(currentQueryParams, key, value) {
  const newParams = isEmpty(value)
    ? omit(currentQueryParams, key)
    : {
        ...currentQueryParams,
        [key]: value,
      };

  const newQueryParams = qs.stringify(newParams, { arrayFormat: 'comma' });
  const newQueryString = newQueryParams ? `?${newQueryParams}` : '';
  window.history.replaceState(
    {},
    '',
    `${window.location.pathname}${newQueryString}`,
  );
}

export function removeQueryParamsArrayValue(queryString, key, value) {
  const currentQueryParams = parseQueryParams(queryString);
  const newValue = currentQueryParams[key]
    ?.split(',')
    ?.filter((v) => v !== value);

  setQueryParams(currentQueryParams, key, newValue);
}

export function updateQueryParams(queryString, key, value) {
  const currentQueryParams = parseQueryParams(queryString);
  setQueryParams(currentQueryParams, key, value);
}

/**
 * Some older versions of various browsers do not support window.location.origin.
 * This is a re-implementation that should work cross-browser to return the same
 * result.
 * @returns {string}
 */
// export function locationOrigin() {
//   if (window.location.origin) {
//     return window.location.origin;
//   }
//   const port = window.location.port && `:${window.location.port}`;
//   return `${window.location.protocol}//${window.location.hostname}${port}`;
// }
