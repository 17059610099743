/* eslint-disable no-constructor-return */
import qs from 'qs';

import { stringifyLocationSearch } from './utils';

class LocationController {
  constructor() {
    if (LocationController.instance) {
      return LocationController.instance;
    }
    LocationController.instance = this;

    // Registered search values.  This values will
    // be parsed to the URL search parameters
    this.searchValues = {};
  }

  // Returns an object with all defaultValues
  #registeredDefaultValues() {
    return (
      Object.entries(this.searchValues).reduce((acc, [_key, value]) => {
        if (typeof value === 'object') {
          Object.entries(value.defaultValues).reduce(
            (_innerAcc, [innerKey, innerValue]) => {
              acc[innerKey] = innerValue;
              return acc;
            },
            {},
          );
        }

        return acc;
      }, {}) || {}
    );
  }

  // Returns an object with all persisted/watched params
  #buildPersistedParams() {
    return (
      Object.entries(this.searchValues).reduce((acc, [_key, value]) => {
        if (typeof value === 'object') {
          Object.entries(value.persistedValues).reduce(
            (_innerAcc, [innerKey, innerValue]) => {
              acc[innerKey] = innerValue;
              return acc;
            },
            {},
          );
        }

        return acc;
      }, {}) || {}
    );
  }

  // Gets the actual URL state and compare with the desired persisted values
  // and return the difference which represents the untrackedValues
  #objectDiff() {
    const urlSearchParams = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
      comma: true,
    });
    const persistedParams = this.#buildPersistedParams();

    return Object.entries(urlSearchParams).reduce((acc, [key, value]) => {
      if (!Object.keys(persistedParams).includes(key)) {
        acc[key] = value;
      }

      return acc;
    }, {});
  }

  // Returns a boolean if the untracked value should be in the url comparing
  // it through the registered values
  #shouldPersistUntrackedKey(key) {
    return !Object.keys(this.#registeredDefaultValues()).includes(key);
  }

  // Return the untracked values
  #buildUntrackedParams() {
    return (
      Object.entries(this.#objectDiff()).reduce((acc, [key, value]) => {
        if (this.#shouldPersistUntrackedKey(key)) {
          acc[key] = value;
        }

        return acc;
      }, {}) || {}
    );
  }

  #buildSearchParams() {
    return { ...this.#buildUntrackedParams(), ...this.#buildPersistedParams() };
  }

  register(key, defaultValues, persistedValues) {
    this.searchValues[key] = {
      defaultValues,
      persistedValues,
    };
  }

  unregister(key) {
    delete this.searchValues[key];
  }

  replaceLocationState() {
    const queryString = stringifyLocationSearch(this.#buildSearchParams());

    window.history.replaceState(
      {},
      '',
      `${window.location.pathname}${queryString}`,
    );
  }
}

export default new LocationController();
