import React from 'react';
import PropTypes from 'prop-types';
import { t, Trans } from '@lingui/macro';
import toColorString from 'polished/lib/color/toColorString';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import moment from 'moment-timezone';
import uniq from 'lodash/uniq';

import color from '@decisiv/design-tokens/lib/color';

import Ban from '@decisiv/iconix/lib/components/Ban';
import DoorExit from '@decisiv/iconix/lib/components/DoorExit';

import Grid from '@decisiv/ui-components/lib/components/Grid';
import Link from '@decisiv/ui-components/lib/components/Link';
import { P } from '@decisiv/ui-components/lib/components/Typography';

import EmptyResults from 'components/EmptyResults';
import PageHead from 'components/PageHead';

import { logoutURL } from 'bff';

const userInfoQuery = loader('./userInfo.graphql');

export default function AccessDenied() {
  const { data: { userInfo: { username, email, lastLoginAt } = {} } = {} } =
    useQuery(userInfoQuery);
  const date = moment.tz(lastLoginAt, moment.tz.guess());
  const loginTime = date.format('HH:mm:ss z');
  const loginDate = date.format('dddd, D MMMM YYYY');

  const userNameOrEmail = uniq([username, email]).join('/');
  return (
    <Grid.Container
      css={`
        position: fixed;
        top: 0;
        bottom: 0;
        background-color: ${toColorString(color.base.halfMoon)};
      `}
    >
      <PageHead titleParts={[t`Access Denied`]} />
      <Grid.Row alignment={['center', 'middle']} height="100%">
        <Grid.Column>
          <EmptyResults
            color="warning"
            maxWidth="500px"
            icon={Ban}
            subtitle={t`You are logged in as ${userNameOrEmail} and you do not have permission to access this page. Try logging in with a different account or ask a team admin to grant permissions.`}
            title={t`Access Denied`}
          />
          <Grid.Row alignment="center" marginBottom={2}>
            <P color="alaskanHusky" size="small">
              <Trans>
                Logged in at {loginTime} on {loginDate}
              </Trans>
            </P>
          </Grid.Row>
          <Grid.Row alignment="center">
            <Link
              icon={DoorExit}
              text={t`Log Out`}
              to={logoutURL(window.location.origin)}
            />
          </Grid.Row>
        </Grid.Column>
      </Grid.Row>
    </Grid.Container>
  );
}

AccessDenied.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      error: PropTypes.shape({
        message: PropTypes.string.isRequired,
      }),
    }),
  }),
};

AccessDenied.defaultProps = {
  location: undefined,
};
