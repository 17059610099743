import React, { useMemo } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useCurrentUserContext } from 'utils/User';

import ROUTE_NAMES from 'setup/Routing/routeNames';

import useSentinelTeam from 'hooks/useSentinelTeam';

export default function SentinelAdminRoute({ children }) {
  const { isAdmin } = useCurrentUserContext();
  const { hasSentinelTeam } = useSentinelTeam();
  const location = useLocation();

  const shouldRedirect = useMemo(
    () => !isAdmin || !hasSentinelTeam,
    [isAdmin, hasSentinelTeam],
  );

  if (shouldRedirect) {
    return (
      <Navigate
        to={ROUTE_NAMES.access_denied}
        state={{ from: location }}
        replace
      />
    );
  }

  return <>{children}</>;
}

SentinelAdminRoute.propTypes = {
  children: PropTypes.node,
};

SentinelAdminRoute.defaultProps = {
  children: null,
};
