import React from 'react';
import PropTypes from 'prop-types';

import PlainTextCell from 'components/PlainTextCell';

export default function OwnerCell({
  rowData: { owner, scheduledForDeletionAt },
}) {
  return (
    <PlainTextCell
      color={scheduledForDeletionAt ? 'alaskanHusky' : undefined}
      topLine={owner?.email}
    />
  );
}

OwnerCell.propTypes = {
  rowData: PropTypes.shape({
    owner: PropTypes.shape({ email: PropTypes.string }),
    scheduledForDeletionAt: PropTypes.string,
  }).isRequired,
};
