import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
  useImperativeHandle,
  forwardRef,
  JSXElementConstructor,
  ReactElement,
} from 'react';
import { t } from '@lingui/macro';

import { ButtonProps } from '@decisiv/ui-components/lib/components/Button';
import Flex from '@decisiv/ui-components/lib/components/Flex';
import Modal from '@decisiv/ui-components/lib/components/Modal';

import StepNumber, {
  StepTitleContainer,
  StepTitle,
} from 'components/StepNumber';

import useStorage from 'hooks/useStorage';

import { useTeamModalContext } from 'pages/Teams/CreateTeamModal/contexts/TeamModalContext';

import { ModalProps } from '@decisiv/ui-components/lib/components/Modal/types';
import { HeaderContainer } from './styles';

interface StepProps {
  handleNextPage: () => void;
  handlePrevPage: () => void;
  handleToggleModalOpen: () => void;
  modalState: any;
  resetModalState: () => void;
}

interface StepType {
  description: () => JSX.Element;
  component: () => JSX.Element;
  title?: string;
  actions?: ButtonProps[];
}

interface WizardModalProps<T extends Record<PropertyKey, any>>
  extends Omit<ModalProps, 'actions'> {
  title: string;
  description: () => JSX.Element;
  target: ReactElement<any, string | JSXElementConstructor<any>>;
  steps: (props: StepProps) => StepType[];
  initialValue: T;
  actions: ButtonProps[];
  visible?: boolean;
}

interface WizardModalHandles<T extends Record<PropertyKey, any>> {
  modalState: {
    currentPage: number;
  } & T;
  setModalState: React.Dispatch<
    React.SetStateAction<
      {
        currentPage: number;
      } & T
    >
  >;
  modalOpen?: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  handleNextPage: () => void;
  handlePrevPage: () => void;
  handleToggleModalOpen: () => void;
  resetModalState: () => void;
}

export const WizardContext = createContext<WizardModalHandles<any>>(
  {} as WizardModalHandles<any>,
);

const WizardModal = <T extends Record<PropertyKey, any>>(
  {
    title,
    description,
    target,
    steps,
    actions,
    initialValue = {} as T,
    visible,
    ...modalProps
  }: WizardModalProps<T>,
  ref: React.ForwardedRef<WizardModalHandles<T>>,
) => {
  const { isEditMode } = useTeamModalContext() as { isEditMode: boolean };

  const [modalOpen, setModalOpen] = useState(false || visible);
  const [modalState, setModalState, resetModalState] = useStorage(
    'createTeamModal',
    {
      currentPage: 1,
      ...initialValue,
    },
  );

  const handleNextPage = useCallback(() => {
    setModalState((oldState) => ({
      ...oldState,
      currentPage: oldState.currentPage + 1,
    }));
  }, [setModalState]);

  const handlePrevPage = useCallback(() => {
    setModalState((oldState) => ({
      ...oldState,
      currentPage: oldState.currentPage - 1,
    }));
  }, [setModalState]);

  const handleToggleModalOpen = useCallback(() => {
    setModalOpen((oldState) => !oldState);
    setModalState((oldState) => ({
      ...oldState,
      currentPage: 1,
    }));
  }, [setModalState]);

  const renderedSteps = useMemo(
    () =>
      steps({
        handleNextPage,
        handlePrevPage,
        handleToggleModalOpen,
        modalState,
        resetModalState,
      }),
    [
      handleNextPage,
      handlePrevPage,
      handleToggleModalOpen,
      modalState,
      resetModalState,
      steps,
    ],
  );

  const currentIndex = useMemo(
    () => modalState.currentPage - 1,
    [modalState.currentPage],
  );

  const currentTitle = useMemo(
    () =>
      isEditMode ? renderedSteps[currentIndex].title || title : t`Create Team`,
    [currentIndex, renderedSteps, title, isEditMode],
  );

  const CurrentDescriptionComponent = useMemo(
    () => renderedSteps[currentIndex].description || description,
    [currentIndex, description, renderedSteps],
  );

  const currentActions = useMemo(
    () => renderedSteps[currentIndex].actions || actions,
    [actions, currentIndex, renderedSteps],
  );

  const CurrentComponent = useMemo(
    () => renderedSteps[currentIndex].component,
    [currentIndex, renderedSteps],
  );

  const Target = useMemo(() => {
    if (!target) return undefined;

    return React.cloneElement(target, { onClick: handleToggleModalOpen });
  }, [handleToggleModalOpen, target]);

  const values = useMemo(
    () => ({
      modalState,
      setModalState,
      modalOpen,
      setModalOpen,
      handleNextPage,
      handlePrevPage,
      handleToggleModalOpen,
      resetModalState,
    }),
    [
      handleNextPage,
      handlePrevPage,
      handleToggleModalOpen,
      modalOpen,
      modalState,
      resetModalState,
      setModalState,
    ],
  );

  useImperativeHandle(ref, () => values, [values]);

  return (
    <WizardContext.Provider value={values}>
      {Target}
      <Modal
        title={currentTitle}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        actions={currentActions}
        visible={modalOpen}
        {...modalProps}
      >
        <Flex flexDirection="column">
          {!isEditMode && CurrentDescriptionComponent && (
            <CurrentDescriptionComponent />
          )}
          {!isEditMode && (
            <HeaderContainer marginTop={2}>
              {renderedSteps.map(({ title: stepTitle }, index) => (
                <Flex key={stepTitle} flex={1}>
                  <StepNumber
                    active={currentIndex === index}
                    completed={currentIndex > index}
                  >
                    {index + 1}
                  </StepNumber>
                  <StepTitleContainer
                    active={currentIndex === index}
                    completed={currentIndex > index}
                  >
                    <StepTitle>{stepTitle}</StepTitle>
                  </StepTitleContainer>
                </Flex>
              ))}
            </HeaderContainer>
          )}
          <CurrentComponent />
        </Flex>
      </Modal>
    </WizardContext.Provider>
  );
};

const ForwardedRefWizardModal = forwardRef(WizardModal);

ForwardedRefWizardModal.displayName = 'WizardModal';

export default ForwardedRefWizardModal;

export function useWizardModal() {
  return useContext(WizardContext);
}
