import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { plural, t, Trans } from '@lingui/macro';

import Button from '@decisiv/ui-components/lib/components/Button';
import Grid from '@decisiv/ui-components/lib/components/Grid';
import Link from '@decisiv/ui-components/lib/components/Link';
import { P } from '@decisiv/ui-components/lib/components/Typography';

import useSentinelTeam from 'hooks/useSentinelTeam';
import ROUTE_NAMES from 'setup/Routing/routeNames';

export default function BulkChange({
  source,
  notification,
  handleOpenModal,
  setModalData,
}) {
  const currentSentinelTeam = useSentinelTeam();

  const {
    affectedAssetsCount,
    canceledAppointmentsCount,
    customer,
    invitationChange,
  } = source;

  const { sentinelTeam } = notification;

  const assetCount = plural(affectedAssetsCount, {
    one: `${affectedAssetsCount} Asset`,
    other: `${affectedAssetsCount} Assets`,
  });
  const appointmentCount = plural(canceledAppointmentsCount, {
    one: `${canceledAppointmentsCount} SERVICE EVENT`,
    other: `${canceledAppointmentsCount} SERVICE EVENTS`,
  });

  const customerName = customer.name;
  const text = invitationChange
    ? t`Removed Access to ${customerName}.`
    : t`Disabled ${assetCount} From ${customerName}.`;

  const actionElement = useMemo(() => {
    if (canceledAppointmentsCount === 0) return null;

    if (currentSentinelTeam?.id === sentinelTeam?.id) {
      return (
        <>
          <Trans>Canceling </Trans>
          <Link
            to={`${ROUTE_NAMES.service_events}?tab=archived&archived=ONLY_ARCHIVED`}
            text={appointmentCount}
            aria-label={t`Link for ${notification.id}`}
          />
        </>
      );
    }

    return (
      <>
        <Trans>Canceling </Trans>
        <Button
          aria-label={t`Link for ${notification.id}`}
          css={`
            display: inherit;
            height: fit-content;
            text-decoration: underline;
            padding: 0;
          `}
          onClick={() => {
            setModalData(notification);
            handleOpenModal();
          }}
          text={appointmentCount}
          variant="ghost"
        />
      </>
    );
  }, [
    canceledAppointmentsCount,
    sentinelTeam?.id,
    currentSentinelTeam?.id,
    appointmentCount,
    notification,
    setModalData,
    handleOpenModal,
  ]);

  return (
    <Grid.Container padding={0}>
      <Grid.Row>
        <Grid.Column>
          <P as="span">
            <Trans>{text}</Trans>
            &nbsp;
            {actionElement}
          </P>
        </Grid.Column>
      </Grid.Row>
    </Grid.Container>
  );
}

BulkChange.propTypes = {
  handleOpenModal: PropTypes.func.isRequired,
  source: PropTypes.shape({
    affectedAssetsCount: PropTypes.number.isRequired,
    canceledAppointmentsCount: PropTypes.number.isRequired,
    customer: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    invitationChange: PropTypes.shape({
      status: PropTypes.string,
    }),
  }).isRequired,
  notification: PropTypes.shape({
    id: PropTypes.string,
    sentinelTeam: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  }).isRequired,
  setModalData: PropTypes.func.isRequired,
};
