/* istanbul ignore file -- @preserve */

import toColorString from 'polished/lib/color/toColorString';
import styled, { css } from 'styled-components';

import color from '@decisiv/design-tokens/lib/color';

import Tag from '@decisiv/ui-components/lib/components/Tag';

const disabledColor = toColorString(color.base.quarterMoon);

const disabledActionStyle = css`
  svg {
    color: ${disabledColor};

    &:hover {
      color: ${disabledColor};
    }

    &:hover:not(:active) {
      color: ${disabledColor};
    }
  }
`;

export default styled(Tag)`
  ${({ disableTag }) => disableTag && disabledActionStyle}
  align-self: flex-start;
`;
