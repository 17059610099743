import { t } from '@lingui/macro';

import ExclamationTriangle from '@decisiv/iconix/lib/components/ExclamationTriangle';
import Target from '@decisiv/iconix/lib/components/Target';
import ExclamationShield from '@decisiv/iconix/lib/components/ExclamationShield';
import ExclamationCircle from '@decisiv/iconix/lib/components/ExclamationCircle';
import TimesShield from '@decisiv/iconix/lib/components/TimesShield';
import Revert from '@decisiv/iconix/lib/components/Revert';
import Tools from '@decisiv/iconix/lib/components/Tools';

export const FAULT_COLORS = {
  SEVERE: 'danger',
  ATTENTION: 'carrotCake',
  MODERATE: 'warning',
  UNKNOWN: 'information',
};

export const MAINTENANCE_COLORS = {
  OVERDUE: 'danger',
  DUE: 'warning',
};

export const CUSTOM_ALERT_COLORS = {
  4: 'danger',
  3: 'carrotCake',
  2: 'warning',
  1: 'information',
};

export const alertColors = {
  ...FAULT_COLORS,
  ...MAINTENANCE_COLORS,
  ...CUSTOM_ALERT_COLORS,
};

export const alertIcons = {
  Fault: ExclamationTriangle,
  Recall: Revert,
  ScheduledMaintenance: Tools,
  Warranty: ExclamationShield,
  CustomAlert: Target,
};

export const alertFilterIcons = {
  faults: ExclamationTriangle,
  recalls: Revert,
  preventative_maintenance: Tools,
  warranties: ExclamationShield,
  disabledWarranties: TimesShield,
};

export const severityFilterIcons = {
  levelfour: ExclamationTriangle,
  underfour: ExclamationCircle,
};

export const SEVERITY_TEXT = {
  OVERDUE: t`Overdue`,
  DUE: t`Due`,
  SEVERE: t`Severe`,
  ATTENTION: t`Attention`,
  MODERATE: t`Moderate`,
  UNKNOWN: t`Unknown`,
  SEVERITY_4: t`Severity 4`,
  SEVERITY_3: t`Severity 3`,
  SEVERITY_2: t`Severity 2`,
  SEVERITY_1: t`Severity 1`,
};

export const SCHEDULED_MAINTENANCE_SEVERITIES = {
  SEVERE: t`Overdue`,
  MODERATE: t`Due`,
};

export const WARRANTY_TIMES = new Map();
WARRANTY_TIMES.set('DAYS_30', t`30 Days`);
WARRANTY_TIMES.set('DAYS_60', t`60 Days`);
WARRANTY_TIMES.set('DAYS_90', t`90 Days`);

export const FAULT_TIMES = new Map();
FAULT_TIMES.set('HOURS_24', t`1 Day`);
FAULT_TIMES.set('HOURS_48', t`2 Days`);
FAULT_TIMES.set('HOURS_72', t`3 Days`);
FAULT_TIMES.set('DAYS_4', t`4 Days`);
FAULT_TIMES.set('DAYS_5', t`5 Days`);

export const TIME_TEXT = new Map([...WARRANTY_TIMES, ...FAULT_TIMES]);

export const MAX_ALERTS = 10;

// NOTE: Array is sorted as filers are intended to show
export const ALERT_TYPES = [
  'ScheduledMaintenance',
  'Recall',
  'Warranty',
  'Fault',
];

// NOTE: Array is sorted as filers are intended to show
export const ALERT_FILTER_TYPES = [
  'preventative_maintenance',
  'recalls',
  'warranties',
  'faults',
];

export const alertBadgeProps = [
  {
    severity: 'unknown',
  },
  {
    severity: 'moderate',
  },
  {
    severity: 'attention',
  },
  {
    severity: 'severe',
  },
];

export const VIEW_MODE = {
  LIST_VIEW: 'list_view',
  TABLE_VIEW: 'table_view',
};

export const ASSET_TABS = {
  ALERT: 'alert',
  WORK_IN_PROGRESS: 'wip',
  HISTORY: 'history',
  WARRANTY: 'warranty',
  LOCATION: 'location',
};

export const BUILTIN_ALERT_IDS = [
  'preventative_maintenance',
  'recalls',
  'warranties',
  'faults',
];
