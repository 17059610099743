import styled from 'styled-components';
import toColorString from 'polished/lib/color/toColorString';

import color from '@decisiv/design-tokens/lib/color';

import { P } from '@decisiv/ui-components/lib/components/Typography';

const DisabledLink = styled(P)`
  align-items: center;
  border: 1px solid transparent;
  color: ${toColorString(color.opacity.charcoal40)};
  cursor: not-allowed;
  display: flex;
  justify-content: center;
  text-decoration: underline;
  white-space: nowrap;
  width: fit-content;
`;

export default DisabledLink;
