import React from 'react';

import { Trans, t } from '@lingui/macro';

import PageHead from 'components/PageHead';

export default function ErrorPage() {
  return (
    <>
      <PageHead titleParts={[t`Error`]} />
      <div>
        <Trans>Error Page</Trans>
      </div>
    </>
  );
}
