import React from 'react';

import RoadBlock from '@decisiv/iconix/lib/components/RoadBlock';

import Flex from '@decisiv/ui-components/lib/components/Flex';

import { t } from '@lingui/macro';

import EmptyResults from 'components/EmptyResults';
import Page from 'components/Page';

import AssetGroupingPageHead from './AssetGroupingPageHead';

function NotFound() {
  return (
    <>
      <AssetGroupingPageHead />
      <Page
        css={`
          height: 100%;
        `}
      >
        <Flex
          css={`
            height: 100%;
          `}
          justifyContent="center"
          alignItems="center"
        >
          <EmptyResults
            color="warning"
            icon={RoadBlock}
            maxWidth="480px"
            subtitle={t`Make sure you have the correct URL or try searching for it from the Customers page.`}
            title={t`Customer not Found`}
          />
        </Flex>
      </Page>
    </>
  );
}

export default NotFound;
