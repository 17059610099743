import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import every from 'lodash/every';
import toColorString from 'polished/lib/color/toColorString';
import rem from 'polished/lib/helpers/rem';
import { plural, t, Trans } from '@lingui/macro';
import without from 'lodash/without';
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';

import color from '@decisiv/design-tokens/lib/color';
import spacing from '@decisiv/design-tokens/lib/spacing';

import Truck from '@decisiv/iconix/lib/components/Truck';

import { P } from '@decisiv/ui-components/lib/components/Typography';
import Button from '@decisiv/ui-components/lib/components/Button';
import Checkbox from '@decisiv/ui-components/lib/components/Checkbox';
import Flex from '@decisiv/ui-components/lib/components/Flex';
import Grid from '@decisiv/ui-components/lib/components/Grid';
import Table from '@decisiv/ui-components/lib/components/Table';

import EmptyResults from 'components/EmptyResults';
import Loading from 'components/Loading';
import Paper from 'components/Paper';
import PaginationFooter from 'components/PaginationFooter';
import UpdatingOverlay from 'components/UpdatingOverlay';

import { buildPropTypes } from 'utils/graphqlPropTypes';

import AssetRow from './AssetRow';
import ConfirmDisableAssetModal from './ConfirmDisableAssetModal';

const assetGroupingAssetsQuery = loader('../assetGroupingAssets.graphql');
const assetGroupingQuery = loader('../assetGrouping.graphql');
const updateAssetStatusMutation = loader('./updateAssetStatus.graphql');

export default function AssetList({
  canUpdate,
  collection,
  loading,
  pagination,
  query,
  setCurrentPage,
  setPerPage,
  variables,
}) {
  const [checkedAssets, setCheckedAssets] = useState([]);
  const [viewedAsset, setViewedAsset] = useState();
  const [showModal, setShowModal] = useState(false);

  const { assetGroupingId } = variables;
  const [updateStatus, { loading: updateStatusLoading }] = useMutation(
    updateAssetStatusMutation,
  );

  const checkAll = useCallback(
    ({ target: { checked } }) => {
      setCheckedAssets(checked ? collection : []);
    },
    [collection, setCheckedAssets],
  );

  const CheckboxHeaderCell = useCallback(
    ({ headerChecked }) => (
      <Checkbox size="small" onChange={checkAll} checked={headerChecked} />
    ),
    [checkAll],
  );

  const handleCheck = useCallback(
    (asset, checked) => {
      setCheckedAssets(
        checked ? checkedAssets.concat(asset) : without(checkedAssets, asset),
      );
    },
    [checkedAssets, setCheckedAssets],
  );

  const updateAssets = useCallback(
    async (assets, enable, cancelActiveAppointments = null) => {
      setShowModal(false);

      await updateStatus({
        variables: {
          ids: assets.map(({ id }) => id),
          enable,
          cancelActiveAppointments,
        },
        refetchQueries: [
          'AssetGroupingHistory',
          { query: assetGroupingAssetsQuery, variables },
          { query: assetGroupingQuery, variables: { assetGroupingId } },
        ],
      });
      setViewedAsset(null);
    },
    [assetGroupingId, updateStatus, variables],
  );

  const enableAssets = useCallback(
    (assets) => updateAssets(assets, true),
    [updateAssets],
  );

  const handleDisableAssets = useCallback(
    (assets, totalAppointments, disableActiveAppointments) => {
      updateAssets(
        assets,
        false,
        totalAppointments ? disableActiveAppointments === 'true' : false,
      );
    },
    [updateAssets],
  );

  const handleShowDisableModal = useCallback(
    (asset) => {
      setViewedAsset(asset);
      setShowModal(true);
    },
    [setViewedAsset],
  );

  const handleClose = () => {
    setShowModal(false);
    setViewedAsset(null);
  };

  /* eslint-disable react/prop-types */
  const columns = useMemo(
    () =>
      [
        canUpdate && {
          DataCell: ({ rowData: asset }) => (
            <Checkbox
              checked={checkedAssets.includes(asset)}
              onChange={({ target: { checked } }) =>
                handleCheck(asset, checked)
              }
              size="small"
            />
          ),
          HeaderCell: CheckboxHeaderCell,
          name: 'CHECKBOX',
          title: t`FIX`,
          getCellKey: ({ id }) => `${id}-actions`,
        },
        {
          DataCell: ({ rowData: asset }) => (
            <AssetRow
              asset={asset}
              canUpdate={canUpdate}
              enableAssets={enableAssets}
              handleShowDisableModal={handleShowDisableModal}
            />
          ),
          name: 'ASSET',
          title: t`Asset`,
          getCellKey: ({ id }) => `${id}-asset`,
        },
      ].filter(Boolean),
    [
      canUpdate,
      CheckboxHeaderCell,
      checkedAssets,
      enableAssets,
      handleCheck,
      handleShowDisableModal,
    ],
  );
  /* eslint-enable react/prop-types */

  useEffect(() => {
    setCheckedAssets([]);
  }, [collection]);

  if (loading && isEmpty(collection)) {
    return (
      <Paper>
        <Loading />
      </Paper>
    );
  }

  if (isEmpty(collection)) {
    const title = query ? t`No Matches for "${query}"` : t`No Assets Found`;
    const subtitle = query
      ? t`Try removing or editing some of your filters to expand the search, or search by a different asset.`
      : t`Try removing or editing some of your filters to expand your search`;
    return (
      <Paper>
        <EmptyResults
          color="licoriceMousse"
          icon={Truck}
          subtitle={subtitle}
          title={title}
        />
      </Paper>
    );
  }

  const checkedAssetCount = checkedAssets.length;
  const totalAssetCount = pagination.totalEntries;
  const totalAssetText = plural(totalAssetCount, {
    one: `Asset`,
    other: `Assets`,
  });

  return (
    <Paper
      css={`
        table thead tr th,
        > :last-child {
          background-color: ${toColorString(color.base.snowWhite)};
          z-index: 0;
        }
        table tbody tr:last-child {
          td {
            border-bottom: none;
          }
        }
      `}
      padding={0}
    >
      <UpdatingOverlay updating={updateStatusLoading || loading}>
        <Grid.Row
          alignment="middle"
          css={`
            height: ${rem(spacing.base * 3.3)};
            border-bottom: 1px solid ${toColorString(color.opacity.charcoal15)};
          `}
          margin={0}
          paddingX={2}
        >
          <P color="alaskanHusky" size="small">
            {checkedAssetCount ? (
              <Trans>
                Selected{' '}
                <P size="small" as="span" weight="semibold">
                  {checkedAssetCount}
                </P>{' '}
                of{' '}
                <P size="small" as="span" weight="semibold">
                  {totalAssetCount}
                </P>{' '}
                {totalAssetText}
              </Trans>
            ) : (
              <Trans>
                <P size="small" as="span" weight="semibold">
                  {totalAssetCount}
                </P>{' '}
                {totalAssetText}
              </Trans>
            )}{' '}
          </P>
          {!!checkedAssets.length && (
            <Flex
              marginLeft={2}
              css={`
                button {
                  margin-right: ${rem(spacing.base * 0.5)};
                }
              `}
            >
              <Button
                disabled={every(checkedAssets, 'enabled')}
                kind="secondary"
                onClick={() => enableAssets(checkedAssets)}
                size="small"
                text={t`Enable`}
              />
              <Button
                disabled={every(checkedAssets, ['enabled', false])}
                kind="secondary"
                onClick={() => setShowModal(true)}
                size="small"
                text={t`Disable`}
              />
            </Flex>
          )}
        </Grid.Row>
        <Table
          kind="secondary"
          columns={columns}
          data={collection}
          getRowKey={({ id }) => id}
          footer={() => (
            <PaginationFooter
              setCurrentPage={setCurrentPage}
              setPerPage={setPerPage}
              textColor="alaskanHusky"
              title={t`Assets`}
              totalOnPage={collection.length}
              color="alaskanHusky"
              {...pagination}
            />
          )}
        />
        <ConfirmDisableAssetModal
          assets={viewedAsset ? [viewedAsset] : checkedAssets}
          handleClose={handleClose}
          handleDisableAssets={handleDisableAssets}
          visible={showModal}
        />
      </UpdatingOverlay>
    </Paper>
  );
}

AssetList.propTypes = {
  canUpdate: PropTypes.bool,
  collection: PropTypes.arrayOf(buildPropTypes('asset')).isRequired,
  loading: PropTypes.bool.isRequired,
  pagination: PropTypes.shape({ totalEntries: PropTypes.number.isRequired }),
  query: PropTypes.string.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  setPerPage: PropTypes.func.isRequired,
  variables: PropTypes.shape({
    assetGroupingId: PropTypes.string.isRequired,
  }).isRequired,
};

AssetList.defaultProps = {
  canUpdate: false,
  pagination: {
    totalEntries: 0,
  },
};
