export const SERVICE_GROUPS_SORT_COLUMNS = {
  ID: 'ID',
  API_USER: 'API_USER',
  CREATED_AT: 'CREATED_AT',
  DEALER_GROUP_ID: 'DEALER_GROUP_ID',
  ENABLED_ASSET_COUNT: 'ENABLED_ASSET_COUNT',
  NAME: 'NAME',
  OWNER_EMAIL: 'OWNER_EMAIL',
  STATE: 'STATE',
  TEAM_KEY: 'TEAM_KEY',
  TEAM_TYPE: 'TEAM_TYPE',
};
