import rem from 'polished/lib/helpers/rem';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useRef } from 'react';
import styled from 'styled-components';

import Flex from '@decisiv/ui-components/lib/components/Flex';

import { AmazonConnectContext } from 'features/amazonConnect';

import DisabledSoftPhone from './DisabledSoftPhone';

const PANEL_HEIGHT_PX = 510;
const PANEL_WIDTH_PX = 320;

const PANEL_HEIGHT_REM = rem(PANEL_HEIGHT_PX);
const PANEL_WIDTH_REM = rem(PANEL_WIDTH_PX);

/* istanbul ignore next -- @preserve */
const ConnectContainer = styled.div`
  height: ${(props) => (props.initialized ? PANEL_HEIGHT_REM : '0')};
  width: ${(props) => (props.initialized ? PANEL_WIDTH_REM : '0')};

  iframe {
    border: none;
  }
`;

function SoftPhone({ amazonConnectInstance }) {
  const { initialized, initialize, terminate } =
    useContext(AmazonConnectContext);

  const containerRef = useRef(null);
  const prevAmazonConnectInstance = useRef(amazonConnectInstance);

  const setContainerRef = useCallback(
    (node) => {
      containerRef.current = node;

      if (prevAmazonConnectInstance.current !== amazonConnectInstance) {
        prevAmazonConnectInstance.current = amazonConnectInstance;

        if (initialized && terminate) {
          terminate();
        }
      }

      if (node && initialize && amazonConnectInstance) {
        // we've got a container node now, and the initialization function
        // is available...
        initialize(amazonConnectInstance, containerRef);
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [amazonConnectInstance, initialized],
  );

  useEffect(() => {
    // on unmount, terminate the CCP:
    return () => {
      /* istanbul ignore next -- @preserve */
      if (terminate) {
        if (import.meta.env.MODE !== 'test') {
          // eslint-disable-next-line no-console
          console.info(
            'Amazon Connect Soft Phone unmounting & terminating CCP...',
          );
        }
        terminate();
      }
    };
  }, [terminate]);

  return (
    <>
      <ConnectContainer
        id="CCPContainer"
        initialized={initialized}
        ref={setContainerRef}
      />
      {!initialized && <DisabledSoftPhone />}
    </>
  );
}

SoftPhone.propTypes = {
  amazonConnectInstance: PropTypes.string.isRequired,
};

export default function AmazonConnectView({ amazonConnectInstance }) {
  return (
    <Flex style={{ width: PANEL_WIDTH_REM, height: PANEL_HEIGHT_REM }}>
      <SoftPhone amazonConnectInstance={amazonConnectInstance} />
    </Flex>
  );
}

AmazonConnectView.propTypes = {
  amazonConnectInstance: PropTypes.string.isRequired,
};
