import {
  CONNECT_ENDPOINTS,
  CONNECT_BASE_URL,
  INSTANCE_NAME,
} from './constants';

export function connectURLForInstanceEndpoint(instanceName, endpoint) {
  const connectBaseUrl = CONNECT_BASE_URL.replace(INSTANCE_NAME, instanceName);
  const templates = {
    [CONNECT_ENDPOINTS.CCP]: `${connectBaseUrl}/ccp`,
    [CONNECT_ENDPOINTS.LOGIN]: `${connectBaseUrl}/login`,
    [CONNECT_ENDPOINTS.LOGOUT]: `${connectBaseUrl}/logout`,
    [CONNECT_ENDPOINTS.GET_RECORDING]: `${connectBaseUrl}/get-recording`,
  };
  return templates[endpoint];
}

export function showPopup(
  url,
  { target = 'AmazonConnectPopup', width = 500, height = 600 } = {},
) {
  const left = window.screen.width / 2 - width / 2;
  const top = window.screen.height / 2 - height / 2;

  // eslint-disable-next-line no-console
  console.log(`opening in a popup "${target}": ${url}`);

  return window.open(
    url,
    target,
    `width=${width},height=${height},menubar=no,status=no,toolbar=no,left=${left},top=${top}`,
  );
}
