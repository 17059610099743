import React, { useState } from 'react';
import styled from 'styled-components';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

import Flex from '@decisiv/ui-components/lib/components/Flex';
import Pagination from '@decisiv/ui-components/lib/components/Pagination';

import Loading from 'components/Loading';

import History from '../History';

const recentActivityQuery = loader('./recentActivity.graphql');

const ACTIVITIES_PER_PAGE = 20;

const ScrollingHistoryList = styled(Flex).attrs({
  marginTop: 1,
})`
  overflow-y: scroll;
`;

export default function ActivityTab({ filters, emptyResultMessage, onNoData }) {
  const [page, setPage] = useState(1);

  const { loading, data } = useQuery(recentActivityQuery, {
    variables: { page, perPage: ACTIVITIES_PER_PAGE, filters },
    fetchPolicy: 'network-only',
    onCompleted: () => {
      const collection = data?.dashboardRecentActivities?.collection;

      if (!isNil(onNoData) && isEmpty(collection)) onNoData();
    },
  });

  if (loading) return <Loading />;

  const {
    dashboardRecentActivities: {
      collection,
      pagination: { totalPages },
    },
  } = data;

  const onPageChange = (newPage) => setPage(newPage);

  const getPaginationProps = { showPagination: false };

  return (
    <>
      <ScrollingHistoryList>
        <History
          emptyResultMessage={emptyResultMessage}
          getPaginationProps={getPaginationProps}
          history={collection}
        />
      </ScrollingHistoryList>
      <Flex
        alignSelf="end"
        css={`
          margin-top: auto;
        `}
        paddingTop={0.5}
      >
        <Pagination
          activePage={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      </Flex>
    </>
  );
}

ActivityTab.propTypes = {
  emptyResultMessage: PropTypes.string.isRequired,
  filters: PropTypes.shape({
    onlyAssignedToMe: PropTypes.bool,
    onlyWatching: PropTypes.bool,
    onlyMyActivities: PropTypes.bool,
  }),
  onNoData: PropTypes.func,
};

ActivityTab.defaultProps = {
  filters: {},
  onNoData: undefined,
};
