/* istanbul ignore file -- @preserve */

import { forwardRef, useImperativeHandle, createRef } from 'react';
import { useNavigate } from 'react-router-dom';

export const RootNavigateRef = createRef(null);

export const RootNavigate = forwardRef((_, ref) => {
  const nav = useNavigate();

  useImperativeHandle(ref, () => ({ navigate: nav }), [nav]);

  return null;
});

export const navigate = (to, options = {}) => {
  RootNavigateRef.current?.navigate(to, options);
};
