import { MutationHookOptions, useMutation } from '@apollo/client';

import mutation from './setCurrentSentinelTeamMutation.graphql';

export default function useSetCurrentSentinelTeamMutation(
  opts?: MutationHookOptions<
    SetCurrentSentinelTeamMutation,
    SetCurrentSentinelTeamMutationVariables
  >,
) {
  return useMutation<
    SetCurrentSentinelTeamMutation,
    SetCurrentSentinelTeamMutationVariables
  >(mutation, opts);
}
