import React from 'react';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';
import { useNavigate } from 'react-router-dom';

import Badge from '@decisiv/ui-components/lib/components/Badge';
import Button from '@decisiv/ui-components/lib/components/Button';
import Flex from '@decisiv/ui-components/lib/components/Flex';
import { H3 } from '@decisiv/ui-components/lib/components/Typography';

import Building from '@decisiv/iconix/lib/components/Building';

import useSentinelTeam from 'hooks/useSentinelTeam';

export default function TotalAssetGroupings({ count }) {
  const { isFleet } = useSentinelTeam();
  const navigate = useNavigate();

  const navigateTo = isFleet ? '/depots' : '/customers';

  const onViewAll = () => navigate(navigateTo);

  const assetGroupingCount = count === 0 ? '―' : count;

  const totalAssetGroupingsText = isFleet
    ? t`Total Depots`
    : t`Total Customers`;

  const viewAllLabel = isFleet ? t`View All Depots` : t`View All Customers`;

  return (
    <Flex flex={1} flexDirection="column">
      <Flex flexDirection="row" alignItems="center">
        <Badge
          variant="square"
          icon={Building}
          color={count === 0 ? 'licoriceMousse' : 'information'}
        />
        <Flex marginLeft={1} alignItems="center">
          <H3 weight="semibold">{assetGroupingCount}</H3>
          <H3
            weight="medium"
            css={`
              margin-left: 10px;
              white-space: nowrap;
            `}
          >
            {totalAssetGroupingsText}
          </H3>
        </Flex>
      </Flex>
      <Flex justifyContent="end">
        <Button
          aria-label={viewAllLabel}
          text={t`View All`}
          variant="ghost"
          onClick={onViewAll}
        />
      </Flex>
    </Flex>
  );
}

TotalAssetGroupings.propTypes = {
  count: PropTypes.number.isRequired,
};
