import React from 'react';
import PropTypes from 'prop-types';
import toColorString from 'polished/lib/color/toColorString';

import color from '@decisiv/design-tokens/lib/color';

import Building from '@decisiv/iconix/lib/components/Building';
import InfoCircle from '@decisiv/iconix/lib/components/InfoCircle';

import Flex from '@decisiv/ui-components/lib/components/Flex';
import { P } from '@decisiv/ui-components/lib/components/Typography';

import { useAlertsContext } from 'pages/Alerts/AlertsContext';

import Separator from 'components/Separator';

import highlightString from 'utils/highlightString';

export default function AssetListLine({
  assetConfig,
  lineColor,
  size,
  margin,
  infoCircle,
}) {
  const { appliedFilters: { search } = {} } = useAlertsContext();

  const colorBase =
    lineColor === 'alaskanHusky'
      ? color.base.alaskanHusky
      : color.base.charcoal;

  const assetConfigToShow = assetConfig.filter(({ text }) => !!text);

  const configIndexCount = assetConfigToShow.length - 1;

  return (
    <Flex
      marginBottom={margin}
      css={`
        color: ${toColorString(colorBase)};
        flex-flow: wrap;
      `}
    >
      {assetConfigToShow.map(({ id, label, text }, index) => (
        <Flex key={id} alignItems="center">
          {id === 'FLEET_INFO' && infoCircle ? (
            <Flex marginRight={0.5}>
              <InfoCircle size="small" />
            </Flex>
          ) : (
            <>
              {id === 'GROUPING' && (
                <Flex marginRight={0.5}>
                  <Building size="small" />
                </Flex>
              )}
              <P size={size}>
                {highlightString(label, search, { includeDash: false })}
              </P>
              {id !== 'CITY' && id !== 'GROUPING' ? (
                <P size={size}>:&nbsp;</P>
              ) : (
                ''
              )}
            </>
          )}
          <P size={size}>
            {highlightString(text, search, { includeDash: false })}
          </P>
          {configIndexCount !== index && (
            <Separator as="span" color={lineColor} />
          )}
        </Flex>
      ))}
    </Flex>
  );
}

AssetListLine.propTypes = {
  assetConfig: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  lineColor: PropTypes.string.isRequired,
  size: PropTypes.string,
  margin: PropTypes.number,
  infoCircle: PropTypes.bool,
};

AssetListLine.defaultProps = {
  size: undefined,
  margin: undefined,
  infoCircle: false,
};
