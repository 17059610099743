/* istanbul ignore file -- @preserve */

import { ApolloLink } from '@apollo/client';

import { initializePendoLink } from 'setup/Pendo';
import { bugsnagBreadcrumbLink, registerBugsnagUserLink } from 'setup/Bugsnag';

// import queryLoggingLink from './queryLoggingLink';
import buildHttpLink from './httpLink';
import invitationAuthLink from './invitationAuthLink';
import buildErrorLink from './errorLink';
import mutationsObserverLink from './mutationsObserverLink';

export default function buildApolloLink(config) {
  return ApolloLink.from([
    // queryLoggingLink, // <-- add this to get console logging of query lifecycle
    bugsnagBreadcrumbLink,
    registerBugsnagUserLink,
    initializePendoLink,
    buildErrorLink(config),
    mutationsObserverLink,
    invitationAuthLink,
    buildHttpLink(),
  ]);
}
