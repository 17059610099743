import React from 'react';

import TeamsPage from './TeamsPage';
import TeamsProvider from './contexts/TeamsContext';

export default function Teams(props) {
  return (
    <TeamsProvider>
      <TeamsPage {...props} />
    </TeamsProvider>
  );
}
