import toColorString from 'polished/lib/color/toColorString';
import styled from 'styled-components';

import color from '@decisiv/design-tokens/lib/color';

import Grid from '@decisiv/ui-components/lib/components/Grid';

const Page = styled(Grid.Container)`
  background-color: ${toColorString(color.base.halfMoon)};
`;

export default Page;
