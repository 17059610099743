import toColorString from 'polished/lib/color/toColorString';
import rem from 'polished/lib/helpers/rem';
import { useState, useRef, useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

import { useMutation } from '@apollo/client';

import color from '@decisiv/design-tokens/lib/color';
import spacing from '@decisiv/design-tokens/lib/spacing';

import Bell from '@decisiv/iconix/lib/components/Bell';

import Flex from '@decisiv/ui-components/lib/components/Flex';
import Grid from '@decisiv/ui-components/lib/components/Grid';
import Button from '@decisiv/ui-components/lib/components/Button';
import Message from '@decisiv/ui-components/lib/components/Message';
import KDSTopNav from '@decisiv/ui-components/lib/components/TopNav';
import { H4 } from '@decisiv/ui-components/lib/components/Typography';
import { InstanceRef } from '@decisiv/ui-components/lib/components/Popover';

import { Trans, t } from '@lingui/macro';

import useFeatureFlag, { FEATURE_FLAGS } from 'utils/useFeatureFlag';

import { NOTIFICATION_REFRESH_INTERVAL } from './constants';
import ChangeTeamConfirmationModal from './ChangeTeamConfirmationModal';
import BulkChangeTeamChangeConfirmationModal from './BulkChangeTeamChangeConfirmationModal';
import GroupedNotifications from './GroupedNotifications';

import notificationsQuery from './hooks/notifications.graphql';
import updateAllNotificationsMutation from './updateAllNotifications.graphql';
import unreadNotificationsCountQuery from './hooks/unreadNotificationsCount.graphql';
import useUnreadNotificationsCountQuery from './hooks/useUnreadNotificationsCountQuery';

function NotificationCenter() {
  const notificationsModalRef = useRef<InstanceRef>(null);
  const disablePolling = useFeatureFlag(
    FEATURE_FLAGS.DISABLE_NOTIFICATION_POLLING,
  );

  const { data } = useUnreadNotificationsCountQuery({
    pollInterval:
      disablePolling || notificationsModalRef?.current?.isVisible
        ? 0
        : NOTIFICATION_REFRESH_INTERVAL,
  });

  const [updateAllNotifications] = useMutation<
    UpdateAllNotificationsMutation,
    UpdateAllNotificationsMutationVariables
  >(updateAllNotificationsMutation, {
    update(cache, { data: mutationData }) {
      const notificationsData = cache.readQuery({
        query: notificationsQuery,
      }) as NotificationsQuery;

      cache.writeQuery({
        query: notificationsQuery,
        data: {
          notifications: {
            ...notificationsData?.notifications,
            collection: notificationsData?.notifications?.collection?.map(
              (i) => {
                return { ...i, read: true };
              },
            ),
            countUnread: mutationData?.updateAllNotifications?.countUnread,
          },
        },
      });

      cache.writeQuery({
        query: unreadNotificationsCountQuery,
        data: {
          notifications: {
            ...notificationsData?.notifications,
            countUnread: mutationData?.updateAllNotifications?.countUnread,
          },
        },
      });
    },
  });

  const countUnread = data?.notifications?.countUnread;

  const [modalVisible, setModalVisible] = useState(false);
  const [modalData, setModalData] = useState(null);
  const handleOpen = () => setModalVisible(true);

  const changeTeamModal = useMemo(() => {
    if (!modalVisible || isEmpty(modalData)) return null;

    if (get(modalData, 'source.__typename', null) === 'BulkChange')
      return (
        <BulkChangeTeamChangeConfirmationModal
          onClose={() => setModalVisible(false)}
          notification={modalData}
          visible={modalVisible}
        />
      );

    return (
      <ChangeTeamConfirmationModal
        onClose={() => setModalVisible(false)}
        notification={modalData}
        visible={modalVisible}
      />
    );
  }, [modalVisible, modalData]);

  return (
    <>
      <KDSTopNav.Menu
        counter={countUnread}
        emblem={() => <Bell aria-label={t`Notifications`} />}
        tooltipText={t`Notifications`}
        zIndex={10}
        popoverRef={notificationsModalRef}
      >
        <Grid.Container
          css={`
            width: ${rem(spacing.base * 34)};
          `}
          paddingX={0}
        >
          <Grid.Row
            alignment="middle"
            distribution="between"
            paddingY={1}
            paddingX={2}
            margin={0}
            css={`
              border-bottom: 1px solid
                ${toColorString(color.opacity.charcoal15)};
            `}
          >
            <div>
              <H4>
                <Trans>Notifications</Trans>
              </H4>
            </div>
            <div>
              <Button
                disabled={countUnread === 0}
                onClick={updateAllNotifications}
                text={t`Mark All As Read`}
                size="small"
                variant="ghost"
              />
            </div>
          </Grid.Row>
          {data?.notifications?.pagination.totalEntries === 0 ? (
            <Grid.Row alignment="center">
              <Flex paddingY={2}>
                <Message
                  intent="help"
                  size="medium"
                  title={t`No Notifications`}
                  variant="vertical"
                >
                  <Trans>
                    You will be notified of changes in appointments you are
                    watching.
                  </Trans>
                </Message>
              </Flex>
            </Grid.Row>
          ) : (
            <GroupedNotifications
              handleOpenModal={handleOpen}
              setModalData={setModalData}
            />
          )}
        </Grid.Container>
      </KDSTopNav.Menu>
      {changeTeamModal}
    </>
  );
}

export default NotificationCenter;
