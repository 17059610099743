import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import MinusCircle from '@decisiv/iconix/lib/components/MinusCircle';

import Button from '@decisiv/ui-components/lib/components/Button';
import Flex from '@decisiv/ui-components/lib/components/Flex';

import { t } from '@lingui/macro';

import RemoveCustomerModal from 'components/RemoveCustomerModal';

import ROUTE_NAMES from 'setup/Routing/routeNames';
import { useCurrentUserContext } from 'utils/User';

// TODO: UI-1679 Refine implementation of remove customer modal
function RemoveCustomerAction({ customer }) {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const { isAdmin } = useCurrentUserContext();

  const handleClose = useCallback(() => {
    setVisible(false);
  }, []);

  if (!isAdmin) return null;

  return (
    <Flex data-testid="remove-customer-action">
      <Button
        icon={MinusCircle}
        kind="secondary"
        intent="danger"
        text={t`Remove Customer`}
        onClick={() => {
          setVisible(true);
        }}
      />
      <RemoveCustomerModal
        customer={customer}
        handleClose={handleClose}
        onComplete={() => {
          // This customer should no longer exist, so navigate back to /admin/customers
          navigate(ROUTE_NAMES.admin.customers);
        }}
        visible={visible}
      />
    </Flex>
  );
}

RemoveCustomerAction.propTypes = {
  customer: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
};

RemoveCustomerAction.defaultProps = {
  customer: undefined,
};

export default RemoveCustomerAction;
