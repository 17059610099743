/* istanbul ignore file -- @preserve */
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useNotifications } from '@decisiv/ui-components';
import Rocket from '@decisiv/iconix/lib/components/Rocket';
import noop from 'lodash/noop';
import { t, Trans } from '@lingui/macro';

export default function AutoReload({ url, tryDelay, forceDelay }) {
  const [previousHash, setPreviousHash] = useState();
  const { notify } = useNotifications();

  function findHash(htmlStr) {
    return htmlStr?.match(/main\.[aA-zZ0-9]*\.chunk\.js/gm)?.[0];
  }

  function reloadApp(e) {
    window.location.reload(true);
    e.preventDefault();
  }

  const fetchSource = useCallback(() => {
    async function fetchData() {
      const ms = Date.now();
      const response = await fetch(`${url}?w=${ms}`, {
        headers: {
          'cache-control': 'no-store',
          pragma: 'no-cache',
        },
      });

      if (response.status !== 200) {
        throw new Error('offline');
      }

      const html = await response.text();
      const hash = findHash(html);
      if (!previousHash) {
        setPreviousHash(hash);
        return;
      }
      if (hash && previousHash !== hash) {
        notify(
          {
            title: t`New version of Sentinel available`,
            intent: 'information',
            icon: Rocket,
            onClose: noop,
            actions: [
              {
                text: t`RELOAD TO UPDATE NOW`,
                onClick: reloadApp,
              },
            ],
          },
          <Trans>
            Update to the latest enhancements and feature improvements. It only
            takes a few seconds.
          </Trans>,
        );
        setPreviousHash(hash);
      }
    }

    fetchData();
  }, [notify, previousHash, url]);

  useEffect(() => {
    const scriptTag = document.querySelector('script[src^="/static/js/main."]');
    const hash = findHash(scriptTag?.outerHTML);
    setPreviousHash(hash);
  }, []);

  useEffect(() => {
    const interval = setInterval(fetchSource, tryDelay);

    return () => clearInterval(interval);
  }, [fetchSource, forceDelay, tryDelay]);
  return null;
}

AutoReload.propTypes = {
  url: PropTypes.string,
  tryDelay: PropTypes.number,
  forceDelay: PropTypes.number,
};

AutoReload.defaultProps = {
  url: '/',
  tryDelay: 5 * 60 * 1000, // 5 minutes
  forceDelay: 24 * 60 * 60 * 1000, // 1 day
};
