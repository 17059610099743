import React from 'react';
import PropTypes from 'prop-types';

import PlainTextCell from 'components/PlainTextCell';

export default function EnabledAssetsCell({
  rowData: { totals, scheduledForDeletionAt },
}) {
  return (
    <PlainTextCell
      textAlign="end"
      color={scheduledForDeletionAt ? 'alaskanHusky' : undefined}
      topLine={totals?.assetGroupings?.enabledAssets}
    />
  );
}

EnabledAssetsCell.propTypes = {
  rowData: PropTypes.shape({
    totals: PropTypes.shape({
      assetGroupings: PropTypes.shape({
        enabledAssets: PropTypes.number,
      }),
    }),
    scheduledForDeletionAt: PropTypes.string,
  }).isRequired,
};
