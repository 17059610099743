/* istanbul ignore file -- @preserve */
import React from 'react';
import PropTypes from 'prop-types';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { en as enPlurals } from 'make-plural/plurals';

import { messages as enMessages } from 'locales/en/messages';

i18n.loadLocaleData('en', { plurals: enPlurals });
i18n.load('en', enMessages);
i18n.activate('en');

export default function I18n({ children }) {
  return <I18nProvider i18n={i18n}>{children}</I18nProvider>;
}

I18n.propTypes = {
  children: PropTypes.node.isRequired,
};
