import { useCallback, useReducer } from 'react';
import { setSessionValue, getSessionValue } from 'utils/sessionStorage';
import useSentinelTeam from 'hooks/useSentinelTeam';

const UPDATE_SORT = 'UPDATE_SORT';

const updateSort = (state, sort) => ({
  ...state,
  sort,
});

export function reducer(state, action) {
  switch (action.type) {
    case UPDATE_SORT:
      return updateSort(state, action.sort);
    default:
      return state;
  }
}

export default function useSort(storageKey) {
  const { id: teamId } = useSentinelTeam();
  const storedSort = getSessionValue(storageKey, teamId) || { sort: null };
  const [state, dispatch] = useReducer(reducer, storedSort);

  const getSortProps = useCallback(() => {
    const changeSort = (sort) => {
      dispatch({ type: UPDATE_SORT, sort });
      if (storageKey) setSessionValue(storageKey, { sort }, teamId);
    };

    const defaultSort = state.sort
      ? {
          sortingColumn: state.sort.column,
          sortingType:
            state.sort.direction === 'ASC' ? 'ascending' : 'descending',
        }
      : {};

    return {
      defaultSort,
      onSort: (column, direction) => {
        if (direction === 'unsorted') {
          changeSort(null);
        } else {
          changeSort({
            column,
            direction: direction === 'ascending' ? 'ASC' : 'DESC',
          });
        }
      },
    };
  }, [state.sort, storageKey, teamId]);

  return [state, { getSortProps }];
}
