import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';

import Grid from '@decisiv/ui-components/lib/components/Grid';

import useInvitation from 'hooks/useInvitation';
import { ENABLED_FILTER } from 'utils/enabledCustomersFilters';
import { useCurrentUserContext } from 'utils/User';

import AssetList from '../AssetList';
import AssetsFilterColumn from '../AssetsFilterColumn';
import StatusMessageCard from './StatusMessageCard';

import useFilters from '../hooks/useFilters';

const assetGroupingAssetsQuery = loader('../assetGroupingAssets.graphql');

const AssetsFiltersAndList = ({
  assetGroupingId, // invitation.customer.id or assetGroupingId
  assetGrouping,
  viewAssets,
  canUpdate,
}) => {
  const { isAdmin } = useCurrentUserContext();
  const { isInvitation } = useInvitation();

  const [
    { appliedFilters, query, page, perPage },
    { getAssetFiltersColumnProps, getAssetsListProps },
  ] = useFilters();

  const enabledDisabledFilter = useMemo(() => {
    if (isInvitation) return appliedFilters.enabled;

    return isAdmin
      ? appliedFilters.enabled || ENABLED_FILTER.ALL
      : ENABLED_FILTER.ENABLED;
  }, [appliedFilters.enabled, isInvitation, isAdmin]);

  const variables = {
    page,
    perPage,
    query,
    assetGroupingId,
    filters: {
      ...appliedFilters,
      enabled: enabledDisabledFilter,
      assetTypes: appliedFilters.assetTypes?.map((type) =>
        type.includes('unknown-') ? '' : type,
      ),
      models: appliedFilters.models?.map((model) =>
        model.includes('unknown-') ? '' : model,
      ),
    },
  };

  const {
    loading,
    data: {
      assetGrouping: { assets: { collection = [], pagination } = {} } = {},
    } = {},
  } = useQuery(assetGroupingAssetsQuery, {
    variables,
    fetchPolicy: 'cache-and-network',
    skip: !viewAssets,
  });

  return (
    <Grid.Row padding={0}>
      {viewAssets ? (
        <>
          <Grid.Column paddingRight={3} span="3">
            <AssetsFilterColumn
              canUpdate={canUpdate}
              filters={assetGrouping.assetsFilters}
              {...getAssetFiltersColumnProps()}
            />
          </Grid.Column>
          <Grid.Column padding={0}>
            <AssetList
              canUpdate={canUpdate}
              collection={collection}
              loading={loading}
              pagination={pagination}
              variables={variables}
              {...getAssetsListProps()}
            />
          </Grid.Column>
        </>
      ) : (
        <StatusMessageCard assetGrouping={assetGrouping} />
      )}
    </Grid.Row>
  );
};

AssetsFiltersAndList.propTypes = {
  canUpdate: PropTypes.bool.isRequired,
  viewAssets: PropTypes.bool.isRequired,
  assetGroupingId: PropTypes.string.isRequired,
  assetGrouping: PropTypes.shape({
    assetsFilters: PropTypes.shape({}),
  }).isRequired,
};

export default AssetsFiltersAndList;
