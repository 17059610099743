import qs from 'qs';
import isEqual from 'lodash/isEqual';

import coercionDecoder from 'utils/coercionDecoder';
import cloneEqualKeys from 'utils/cloneEqualKeys';

export const parseArrayValues = (defaultValue, currentValue) => {
  const shouldParse =
    Array.isArray(defaultValue) && typeof currentValue === 'string';
  if (!shouldParse) return currentValue;

  return currentValue.split(',').filter((v) => v);
};

export const sanitizeDefaultValues = (subject, defaultValue) =>
  Object.entries(subject).reduce((acc, [k, v]) => {
    const parsedValue = parseArrayValues(defaultValue[k], v);
    if (isEqual(defaultValue[k], parsedValue)) return acc;

    acc[k] = parsedValue;

    return acc;
  }, {});

/* Parses current window location */
export const parseLocationSearch = (options = { typeCoercion: false }) => {
  const values = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
    comma: true,
    decoder: options.typeCoercion ? coercionDecoder : undefined,
  });

  return values;
};

export const stringifyLocationSearch = (values) =>
  qs.stringify(values, {
    addQueryPrefix: true,
    arrayFormat: 'comma',
    encode: false,
  });

/* This function will generate an object from the search object */
export const generateObjectFromSearch = (searchValues, defaultValue) => {
  // Given a defaultValue like this:
  //   {
  //     "sort": "ALL",
  //     "groupBy": "ALL",
  //     "alertTypes": [],
  //     "customAlerts": [],
  //     "assetTypes": [],
  //     "makes": [],
  //     "models": [],
  //     "years": [],
  //     "workInProgress": [],
  //     "assignees": []
  // }

  // cloneEqualKeys will return equal keys from searchValues trying to match the defaultValue
  // because of the comma parser.
  //   {
  //     "sort": "ALL",
  //     "groupBy": "ALL",
  //     "alertTypes": [],
  //     "customAlerts": [],
  //     "assetTypes": "1,2", <--- This is the problem (this means an array)
  //     "makes": [],
  //     "models": [],
  //     "years": [],
  //     "workInProgress": [],
  //     "assignees": []
  // }
  const equalSearchKeys = cloneEqualKeys(searchValues, defaultValue);

  // Now for handle with that error we're comparing each type of value against the defaultValue
  // and it should return an object semantically equal as the defaulValue.
  return Object.entries(equalSearchKeys).reduce((acc, [k, v]) => {
    const parsedValue = parseArrayValues(defaultValue[k], v);

    acc[k] = parsedValue;

    return acc;
  }, {});
};
