// These roles need to match the values available in the GraphQL API
// enumeration `RoleName`:
const ROLES = {
  SUPERUSER: 'SUPERUSER',
  ADMINISTRATOR: 'ADMINISTRATOR',
  USER: 'USER',
  OWNER: 'OWNER',
} as const;
export default ROLES;

export const ADMIN_ROLES = [ROLES.SUPERUSER, ROLES.ADMINISTRATOR, ROLES.OWNER];

export const SUPER_USER_ROLES = [ROLES.SUPERUSER];
