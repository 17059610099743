import { useEffect, useRef, useState } from 'react';

import spacing from '@decisiv/design-tokens/lib/spacing';

export default function useFooter() {
  const [height, setHeight] = useState(32);
  const ref = useRef(null);

  useEffect(() => {
    setHeight(ref.current?.getBoundingClientRect().height);
  }, [ref]);

  // add the padding of the footer & the border top to the height
  return {
    footerHeight: height + spacing.base * 2 + 2,
    footerRef: ref,
  };
}
