import { loader } from 'graphql.macro';
import React, { useCallback, useState, useRef, useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import { t } from '@lingui/macro';
import PropTypes from 'prop-types';

import Send from '@decisiv/iconix/lib/components/Send';

import Flex from '@decisiv/ui-components/lib/components/Flex';
import Message from '@decisiv/ui-components/lib/components/Message';
import Pagination from '@decisiv/ui-components/lib/components/Pagination';

import { useQuery } from '@apollo/client';

import Loading from 'components/Loading';
import useSentinelTeam from 'hooks/useSentinelTeam';
import NoteRow from './NoteRow';

const appointmentsNotes = loader('./appointmentsNotes.graphql');

const PER_PAGE = 20;

function AllNotes({ tabId }) {
  const [activePage, setActivePage] = useState(1);
  const ref = useRef();

  const { isFleet } = useSentinelTeam();

  const assetGroupingKind = isFleet ? t`depots` : t`customers`;

  const TEXT_BY_TAB_ID = {
    all: t`There are currently no notes for this team.`,
    onlyWatching: t`There are currently no notes for service events that you are watching.`,
    onlyAssignedToMe: t`There are currently no notes for service events of ${assetGroupingKind} assigned to you.`,
    onlyMyNotes: t`There are currently no notes created by you.`,
  };

  const onlyWatching = useMemo(() => tabId === 'onlyWatching', [tabId]);
  const onlyAssignedToMe = useMemo(() => tabId === 'onlyAssignedToMe', [tabId]);
  const onlyMyNotes = useMemo(() => tabId === 'onlyMyNotes', [tabId]);

  const {
    data: { sentinelTeam: { appointmentsNotes: pageData = {} } = {} } = {},
    loading,
    refetch,
  } = useQuery(appointmentsNotes, {
    fetchPolicy: 'cache-and-network',
    variables: {
      page: 1,
      perPage: PER_PAGE,
      onlyWatching,
      onlyAssignedToMe,
      onlyMyNotes,
    },
  });

  const handlePageChange = useCallback(
    (newPage, _) => {
      setActivePage(newPage);
      refetch({ page: newPage, perPage: PER_PAGE });
    },
    [refetch],
  );

  if (loading)
    return (
      <Flex flex={1} alignItems="center">
        <Loading />
      </Flex>
    );

  const { collection: allNotes, pagination } = pageData;

  if (isEmpty(allNotes))
    return (
      <Flex flex={1} justifyContent="center" marginY={5} width="100%">
        <Message icon={Send} intent="help" size="large" title={t`No Notes`}>
          {TEXT_BY_TAB_ID[tabId]}
        </Message>
      </Flex>
    );

  return (
    <Flex flexDirection="column" flex={1}>
      <Flex
        flex={1}
        css={`
          overflow: auto;
        `}
        flexDirection="column"
        paddingRight={1}
        ref={ref}
        marginTop={1}
      >
        {allNotes.map((note, index) => (
          <NoteRow key={note.id} addSeparator={index > 0} note={note} />
        ))}
      </Flex>
      <Flex
        justifyContent="end"
        css={`
          margin-top: auto;
        `}
        paddingTop={0.5}
      >
        <Pagination
          activePage={activePage}
          defaultActivePage={1}
          totalPages={pagination.totalPages}
          onPageChange={handlePageChange}
        />
      </Flex>
    </Flex>
  );
}

AllNotes.propTypes = {
  tabId: PropTypes.string.isRequired,
};

export default AllNotes;
