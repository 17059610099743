import styled from 'styled-components';
import spacing from '@decisiv/design-tokens/lib/spacing';
import rem from 'polished/lib/helpers/rem';

import Flex from '@decisiv/ui-components/lib/components/Flex';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
  ChartData,
  ChartDataset,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import AlertsByTypeDataList from './AlertsByTypeDataList';
import barChartColorMap from './barCharColorMap';

export const ChartContainer = styled(Flex)`
  height: ${rem(spacing.base * 10)};
  max-height: ${rem(spacing.base * 10)};
`;

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const options: ChartOptions<'bar'> = {
  plugins: {
    legend: {
      display: false,
    },
    subtitle: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  events: [],
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: { display: false, grid: { display: false } },
    y: { display: false, grid: { display: false } },
  },
};

interface BarChartComponentProps {
  alerts: AlertsCountDashboardAsset[];
  showDividers?: boolean;
}

export default function BarChartComponent({
  alerts,
  showDividers,
}: BarChartComponentProps) {
  const data = alerts.reduce<ChartData<'bar'>>(
    (acc, currentValue, idx) => {
      const dataset = acc.datasets[0];

      const datasets = [
        {
          ...dataset,
          data: [...dataset.data, currentValue.count],
          backgroundColor: [
            ...(dataset.backgroundColor as string),
            barChartColorMap(idx),
          ],
          inflateAmount: ({ parsed: { y } }) => {
            if (y === 0) {
              return 2;
            }

            return 3;
          },
        },
      ] satisfies ChartDataset<'bar'>[];

      return {
        ...acc,
        labels: [...(acc.labels as unknown as string), currentValue.title],
        datasets,
      };
    },
    {
      labels: [],
      datasets: [{ label: 'Alerts', data: [], backgroundColor: [] }],
    },
  );

  return (
    <Flex flexDirection="column" justifyContent="center">
      <ChartContainer>
        <Bar options={options} data={data} />
      </ChartContainer>
      <AlertsByTypeDataList alerts={alerts} showDividers={showDividers} />
    </Flex>
  );
}
