import get from 'lodash/get';
import omit from 'lodash/omit';

import removeBlankProperties from 'utils/removeBlankProperties';

function makeVisitorIdentity(user) {
  const role = get(user, 'role', {});

  return removeBlankProperties({
    ...omit(user, ['__typename', 'role', 'sentinelTeam']),
    id: get(user, 'uuid'),
    role: get(role, 'name'),
    // You can add any additional visitor level key-values here,
    // as long as it's not one of the above reserved names.
  });
}

export default makeVisitorIdentity;
