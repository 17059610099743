import React, { forwardRef, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import toColorString from 'polished/lib/color/toColorString';

import color from '@decisiv/design-tokens/lib/color';

import Flex from '@decisiv/ui-components/lib/components/Flex';
import CaretUp from '@decisiv/iconix/lib/components/CaretUp';
import CaretDown from '@decisiv/iconix/lib/components/CaretDown';
import Phone from '@decisiv/iconix/lib/components/Phone';

import { AmazonConnectContext, uiStateColors } from 'features/amazonConnect';

const Wrapper = styled.button`
  align-items: center;
  background-color: ${({ ccpState, isVisible }) =>
    uiStateColors(ccpState)[isVisible ? 'dark' : 'normal']};
  border: none;
  color: ${toColorString(color.base.snowWhite)};
  cursor: pointer;
  display: flex;
  padding: 1rem;
  text-align: left;

  &:hover,
  &:active {
    background-color: ${({ ccpState }) => uiStateColors(ccpState).dark};
  }
`;

const AmazonConnectButton = forwardRef(({ isVisible, onClick }, ref) => {
  const { uiState } = useContext(AmazonConnectContext);
  return (
    <Wrapper
      onClick={onClick}
      ref={ref}
      ccpState={uiState}
      isVisible={isVisible}
    >
      <Flex marginRight={0.5}>
        <Phone />
      </Flex>
      {isVisible ? <CaretUp size="small" /> : <CaretDown size="small" />}
    </Wrapper>
  );
});

AmazonConnectButton.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default AmazonConnectButton;
