/* istanbul ignore file -- @preserve */

import { t } from '@lingui/macro';

export const CUSTOM_UI_STATES = {
  WORKING_ON_CASE: 'Working On Case',
};

export const UI_STATES = {
  ACW: 'ACW',
  AGENT_HOLD: 'AGENT_HOLD',
  AUX: 'AUX',
  AVAILABLE: 'AVAILABLE',
  CONNECTED: 'CONNECTED',
  CONNECTING: 'CONNECTING',
  DISCONNECTED: 'DISCONNECTED',
  ERROR: 'ERROR',
  INBOUND: 'INBOUND',
  INCOMING: 'INCOMING',
  INITIALIZING: 'INITIALIZING',
  MISSED: 'MISSED',
  MONITORING: 'MONITORING',
  OFFLINE: 'OFFLINE',
  ON_HOLD: 'ON_HOLD',
  OUTBOUND: 'OUTBOUND',
};

export const STATE_NAMES = {
  ACW: t`After Call Work`,
  AGENT_HOLD: t`Agent On Hold`,
  CONNECTED: t`Connected`,
  CONNECTING: t`Connecting`,
  DISCONNECTED: t`Disconnected`,
  ERROR: t`Error`,
  INBOUND: t`Inbound`,
  INCOMING: t`Incoming`,
  INCOMING_CALLBACK: t`Incoming Callback`,
  INITIALIZATION_FAILURE: t`Initialization Failure`,
  INITIALIZING: t`Initializing`,
  JOINED: t`Joined`,
  MISSED_CALL: t`Missed Call`,
  MONITORING: t`Monitoring`,
  MUTED: t`Muted`,
  ON_HOLD: t`On Hold`,
  OUTBOUND: t`Outbound`,
};

export const CONVERSATION_STATE_NAMES = [
  STATE_NAMES.CONNECTED,
  STATE_NAMES.ON_HOLD,
];

export const ACTIVE_CONNECTION_STATE_NAMES = [
  ...CONVERSATION_STATE_NAMES,
  STATE_NAMES.INBOUND,
  STATE_NAMES.INCOMING,
  STATE_NAMES.JOINED,
  STATE_NAMES.MONITORING,
];

/**
 * Mapping of various buttons we might present to users, and which
 * UI states are valid for each button to be enabled.
 */
export const UI_STATE_BUTTONS = {
  END_CALL: [
    UI_STATES.AGENT_HOLD,
    UI_STATES.CONNECTED,
    UI_STATES.CONNECTING,
    UI_STATES.MONITORING,
    UI_STATES.ON_HOLD,
    UI_STATES.OUTBOUND,
  ],
  ACCEPT_CALL: [UI_STATES.INCOMING, UI_STATES.INBOUND],
  REJECT_CALL: [UI_STATES.INCOMING, UI_STATES.INBOUND],
  GO_AVAILABLE: [
    UI_STATES.ACW,
    UI_STATES.AUX,
    UI_STATES.ERROR,
    UI_STATES.MISSED,
    UI_STATES.OFFLINE,
  ],
  GO_OFFLINE: [
    UI_STATES.ACW,
    UI_STATES.AUX,
    UI_STATES.AVAILABLE,
    UI_STATES.ERROR,
    UI_STATES.MISSED,
  ],
  CALL_OUTBOUND: [
    UI_STATES.ACW,
    UI_STATES.AUX,
    UI_STATES.AVAILABLE,
    UI_STATES.ERROR,
    UI_STATES.MISSED,
    UI_STATES.OFFLINE,
  ],
  TRANSFER_TO_QUICK_CONNECT: [UI_STATES.CONNECTED],
};

export const CONVERSATION_STATES = [UI_STATES.CONNECTED, UI_STATES.ON_HOLD];

export const DISABLED_STATE_NAMES = [
  STATE_NAMES.DISCONNECTED,
  STATE_NAMES.ERROR,
  STATE_NAMES.INITIALIZATION_FAILURE,
  STATE_NAMES.INITIALIZING,
];

// INSTANCE_NAME is exported as a value for simple matching in the CONNECT_BASE_URL string.
export const INSTANCE_NAME = 'INSTANCE_NAME';
export const CONNECT_BASE_URL = `https://${INSTANCE_NAME}.awsapps.com/connect`;
export const CONNECT_ENDPOINTS = {
  CCP: 'CCP',
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  GET_RECORDING: 'GET_RECORDING',
};

export const DEFAULT_CONNECT_STREAMS_JS = `${import.meta.env.BASE_URL}/${
  import.meta.env.VITE_AMAZON_CONNECT_JS
}`;

export const JS_LOADING_STATES = {
  NONE: 'NONE',
  STARTED: 'STARTED',
  LOADED: 'LOADED',
  TERMINATED: 'TERMINATED',
};

/**
 * Build a map of agent error states to display text.
 * @param connect The current Amazon Connect instance
 * @return {object}
 */
export function makeErrorStates(connect) {
  /* istanbul ignore next -- @preserve */
  return {
    [connect.AgentErrorStates.AGENT_HUNG_UP]: t`Agent hung up`,
    [connect.AgentErrorStates.BAD_ADDRESS_CUSTOMER]: t`Bad address`,
    [connect.AgentErrorStates.BAD_ADDRESS_AGENT]: t`Bad address`,
    [connect.AgentErrorStates.FAILED_CONNECT_AGENT]: t`Failed connection`,
    [connect.AgentErrorStates
      .FAILED_CONNECT_CUSTOMER]: t`Call no longer available`,
    [connect.AgentErrorStates.LINE_ENGAGED_AGENT]: t`Line engaged`,
    [connect.AgentErrorStates.LINE_ENGAGED_CUSTOMER]: t`Line engaged`,
    [connect.AgentErrorStates.MISSED_CALL_CUSTOMER]: t`Missed call`,
    [connect.AgentErrorStates.MISSED_CALL_AGENT]: t`Missed call`,
    [connect.AgentErrorStates.MISSED_QUEUE_CALLBACK]: t`Callback missed`,
    [connect.AgentErrorStates
      .REALTIME_COMMUNICATION_ERROR]: t`Real-time communication error`,
    default: STATE_NAMES.ERROR,
  };
}

/**
 * This is the name of a "secret" query-string parameter that
 * QA can add to the ONCall URL to enable outbound-call phone
 * number overriding. The value of the parameter should be a
 * ten-digit phone number, digits only.
 *
 * If the query string is set on the application's URL at initial
 * application bootstrap, then any outbound call placed via
 * Amazon Connect will dial the given phone number instead of
 * the phone number requested by the application.
 *
 * @see AmazonConnectProvider#makeOutboundCall
 * @type {string}
 */
export const QUERY_PARAM_OUTGOING_CALL_OVERRIDE = 'outboundphone';
