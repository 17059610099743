import React, { useMemo, useContext } from 'react';
import { t } from '@lingui/macro';
import { useMutation, useQuery } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';
import PropTypes, { ReactElementLike } from 'prop-types';

import { RBACProvider, ROLES, ADMIN_ROLES } from 'features/rbac';

import moment from 'moment-timezone';
import { INVITATION_KEY } from 'utils/Invitation';

import Loading from 'components/Loading';

import userInfoQuery from './currentUserProfile.graphql';
import updateUserTimezoneMutation from './updateUserTimezone.graphql';

const ROLE_LABEL = {
  [ROLES.SUPERUSER]: t`Super Admin`,
  [ROLES.ADMINISTRATOR]: t`Admin`,
  // NOTE: The `Editor` role is still called `USER` in the BE
  // TODO: Change `USER` key to `EDITOR` when BE updated
  [ROLES.USER]: t`Editor`,
  [ROLES.OWNER]: t`Owner`,
};

export const isAdmin = (user?: CurrentUserQueryUser) => {
  if (user?.superuser) return true;
  if (!user?.role) return false;

  return !!ADMIN_ROLES.includes(user.role.name);
};

export const hasDealerAccess = (
  user?: Pick<User, 'id'>,
  platformAccount?: { permittedMembers?: Pick<User, 'id'>[] },
) => {
  if (!platformAccount || !user) return false;

  return platformAccount?.permittedMembers?.some(({ id }) => id === user.id);
};

export const isDecisivEmail = (email?: string) =>
  email?.match(/@decisiv\.(com|net)$/);

export const isSuperUser = (user?: CurrentUserQueryUser) => user?.superuser;
const usersMatch = (
  user1?: CurrentUserQueryUser,
  user2?: CurrentUserQuerySentinelTeamOwner,
) => user1?.email && user1.email === user2?.email;

export const nameForUser = (
  user?: Pick<User, 'name' | 'firstName' | 'lastName' | 'username' | 'email'>,
) => {
  if (!user) return null;

  return (
    user.name ||
    [user.firstName ?? '', user.lastName ?? ''].join(' ').trim() ||
    user.username ||
    user.email
  );
};

export const userAvatarName = (email?: string) =>
  email?.split('', 2)?.join(' ');

export const assigneeLabelForFilters = (user: CurrentUserQueryUser) => {
  const name = user.name ? ` · ${user.name}` : '';

  return `${user.email}${name}`;
};

interface CurrentUserContextProps extends CurrentUserQueryUser {
  needsSentinelTeam?: boolean;
  isLoggedIn: boolean;
  isAdmin: boolean;
  isSuperUser?: boolean;
  admin: boolean;
  roleLabel?: string;
}

export const CurrentUserContext = React.createContext<CurrentUserContextProps>(
  {} as CurrentUserContextProps,
);

interface CurrentUserProviderProps {
  children: ReactElementLike;
}

const CurrentUserProvider = ({ children }: CurrentUserProviderProps) => {
  const [updateUserTimezone] = useMutation(updateUserTimezoneMutation);

  const {
    data: {
      currentUserProfile: { user = {} as CurrentUserQueryUser } = {},
    } = {},
    loading,
  } = useQuery<UserInfoQuery>(userInfoQuery, {
    skip: memoryDB.getItem(INVITATION_KEY),
    onCompleted: (data) => {
      const {
        currentUserProfile: {
          user: { lastKnownTimezone, lastKnownTimeOffset } = {},
        },
      } = data;

      const timezone = moment.tz.guess();
      const offset = moment.tz(timezone).utcOffset();
      if (lastKnownTimezone !== timezone || lastKnownTimeOffset !== offset) {
        updateUserTimezone({
          variables: {
            lastKnownTimezone: timezone,
            lastKnownTimeOffset: offset,
          },
        });
      }
    },
  });

  const { sentinelTeam } = user;

  const { owner } = sentinelTeam || {};
  const admin = useMemo(() => isAdmin(user), [user]);

  const superUserProps = useMemo(() => {
    if (!isSuperUser(user)) {
      return {};
    }
    return {
      needsSentinelTeam: isEmpty(sentinelTeam),
    };
  }, [user, sentinelTeam]);

  const roleName = useMemo(() => {
    const userRole = user?.role?.name;
    if (isSuperUser(user)) return ROLES.SUPERUSER;
    if (usersMatch(user, owner)) return ROLES.OWNER;
    return userRole;
  }, [user, owner]);

  const roleLabel = roleName && ROLE_LABEL[roleName];

  const isLoggedIn = !isEmpty(user);

  const isUserAdmin = useMemo(() => isAdmin(user), [user]);

  const isUserSuperUser = useMemo(() => isSuperUser(user), [user]);

  if (loading) {
    return <Loading />;
  }

  return (
    <CurrentUserContext.Provider
      value={{
        ...user,
        ...superUserProps,
        isLoggedIn,
        isAdmin: isUserAdmin,
        isSuperUser: isUserSuperUser,
        admin,
        roleLabel,
      }}
    >
      <RBACProvider role={roleName}>{children}</RBACProvider>
    </CurrentUserContext.Provider>
  );
};

CurrentUserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CurrentUserProvider;

export function useCurrentUserContext() {
  return useContext(CurrentUserContext);
}
