import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const RecursiveNestedWrapper = ({ components }) => {
  const [Wrapper = Fragment, ...restChildren] = components;

  if (!restChildren || restChildren.length === 0) return <Wrapper />;

  return (
    <Wrapper>
      <RecursiveNestedWrapper components={restChildren} />
    </Wrapper>
  );
};

RecursiveNestedWrapper.propTypes = {
  components: PropTypes.arrayOf(PropTypes.func),
};

RecursiveNestedWrapper.defaultProps = {
  components: [],
};

export default RecursiveNestedWrapper;
