import React, { useContext } from 'react';

import KDSTopNav from '@decisiv/ui-components/lib/components/TopNav';

import { t } from '@lingui/macro';

import { CurrentInvitationContext } from 'utils/Invitation';
import { useCurrentUserContext } from 'utils/User';

import useSentinelTeam from 'hooks/useSentinelTeam';
import decisivLogo from './decisiv.png';

function BrandInfo() {
  const { sentinelTeam: invitationSentinelTeam } = useContext(
    CurrentInvitationContext,
  );
  const { needsSentinelTeam } = useCurrentUserContext();
  const userSentinelTeam = useSentinelTeam();

  const sentinelTeam = invitationSentinelTeam || userSentinelTeam;

  return (
    <KDSTopNav.BrandInfo
      logo={needsSentinelTeam ? decisivLogo : sentinelTeam?.logo?.url}
      primaryText={
        needsSentinelTeam || !userSentinelTeam.hasSentinelTeam
          ? t`Sentinel Managed Care`
          : sentinelTeam?.name
      }
      secondaryText={
        needsSentinelTeam ? t`Decisiv` : sentinelTeam?.applicationName ?? ''
      }
    />
  );
}

export default BrandInfo;
