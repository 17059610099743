import React, { useState, useCallback } from 'react';
import { t } from '@lingui/macro';
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import PropTypes from 'prop-types';

import Flex from '@decisiv/ui-components/lib/components/Flex';
import Button from '@decisiv/ui-components/lib/components/Button';

import ConfirmDisableDepotModal from '../admin/components/ConfirmDisableDepotModal';

const updateAssetGroupingStatusMutation = loader(
  '../admin/components/ToggleStatusPopover/updateAssetGroupingStatus.graphql',
);

const ToggleDepotStatusButton = ({ depot }) => {
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = useCallback(() => {
    setShowModal(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const [updateStatus, { loading }] = useMutation(
    updateAssetGroupingStatusMutation,
    {
      onCompleted: () => {
        handleCloseModal();
      },
    },
  );

  const handleDisableProvider = useCallback(() => {
    updateStatus({
      variables: {
        id: depot.id,
        enabled: false,
      },
    });
  }, [depot, updateStatus]);

  const handleEnableProvider = useCallback(() => {
    updateStatus({
      variables: {
        id: depot.id,
        enabled: true,
      },
    });
  }, [depot, updateStatus]);

  return (
    <Flex justifyContent="flex-end" flex={1}>
      <Button
        kind="secondary"
        onClick={depot.enabled ? handleOpenModal : handleEnableProvider}
        aria-label={t`Toggle Depot Status`}
        text={depot.enabled ? t`Disable` : t`Enable`}
      />
      <ConfirmDisableDepotModal
        visible={showModal}
        depot={depot}
        loading={loading}
        handleClose={handleCloseModal}
        onDisableClick={handleDisableProvider}
      />
    </Flex>
  );
};

ToggleDepotStatusButton.propTypes = {
  depot: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    city: PropTypes.string,
    enabled: PropTypes.bool,
    state: PropTypes.string,
    primaryPhoneNumber: PropTypes.string,
  }).isRequired,
};

export default ToggleDepotStatusButton;
