import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Trans, t } from '@lingui/macro';

import Image from '@decisiv/iconix/lib/components/Image';
import Trash from '@decisiv/iconix/lib/components/Trash';

import Button from '@decisiv/ui-components/lib/components/Button';
import Flex from '@decisiv/ui-components/lib/components/Flex';
import Grid from '@decisiv/ui-components/lib/components/Grid';
import Message from '@decisiv/ui-components/lib/components/Message';
import TextField from '@decisiv/ui-components/lib/components/TextField';
import { P } from '@decisiv/ui-components/lib/components/Typography';

import LogoImage from 'components/LogoImage';

const MAX_FILE_SIZE = 5000000; // 5MB
const MAX_LENGTH = 50;
const VALID_FILE_TYPES = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'];
const LARGE_SIZE_ERROR = t`File must be smaller than 5MB.`;
const INVALID_TYPE_ERROR = t`File must be a JPG, GIF, or PNG.`;

const fileSizeTooLarge = (file) => file.size > MAX_FILE_SIZE;
const invalidType = (file) => !VALID_FILE_TYPES.includes(file.type);

export const NEW_LOGO_INPUT = 'new-logo-image';

export default function SentinelTeamForm({
  applicationName,
  name,
  handleApplicationNameChange,
  handleNameChange,
  handleSaveImage,
  logoUrl,
  stretch,
}) {
  const [imageError, setImageError] = useState(null);

  const handleSelectImage = () => {
    setImageError(null);
    document.getElementById(NEW_LOGO_INPUT).click();
  };

  const validateImage = useCallback(
    ({ target: { files } }) => {
      const file = files[0];

      if (invalidType(file)) {
        setImageError(INVALID_TYPE_ERROR);
      } else if (fileSizeTooLarge(file)) {
        setImageError(LARGE_SIZE_ERROR);
      } else {
        handleSaveImage(file);
      }
    },
    [handleSaveImage, setImageError],
  );

  return (
    <Grid.Container
      css={`
        display: flex;
        flex-flow: column;
      `}
      padding={0}
    >
      <Grid.Row marginBottom={1}>
        <Grid.Column>
          <Flex alignItems="center">
            <LogoImage
              size="large"
              src={logoUrl}
              alt={t`Logo for ${name} Team`}
            />
            <Flex marginLeft={1.5} marginRight={1} flexDirection="column">
              <P>
                <Trans>Profile Picture</Trans>
              </P>
              <P color="alaskanHusky" size="small" marginBottom={0.5}>
                <Trans>JPG, GIF or PNG. Max size of 5MB.</Trans>
              </P>
              <Flex>
                {logoUrl ? (
                  <Button
                    icon={Trash}
                    intent="danger"
                    kind="secondary"
                    onClick={() => handleSaveImage(null)}
                    size="small"
                    text={t`Remove Image`}
                  />
                ) : (
                  <Button
                    icon={Image}
                    kind="secondary"
                    onClick={handleSelectImage}
                    size="small"
                    text={t`Select Image`}
                  />
                )}
              </Flex>
            </Flex>
            {imageError && <Message intent="warning">{imageError}</Message>}
            <input
              data-testid="logo-input"
              hidden
              id={NEW_LOGO_INPUT}
              onClick={(e) => e.stopPropagation()}
              onChange={validateImage}
              type="file"
              accept="image/*"
            />
          </Flex>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row marginBottom={1}>
        <Grid.Column span={stretch ? '12' : '6'}>
          <TextField
            helpMessage={t`Max ${MAX_LENGTH} characters`}
            label={t`Team Name`}
            maxLength={MAX_LENGTH}
            onChange={handleNameChange}
            required
            value={name}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column span={stretch ? '12' : '6'}>
          <TextField
            onChange={handleApplicationNameChange}
            helpMessage={t`Max ${MAX_LENGTH} characters`}
            label={t`Application Name`}
            maxLength={MAX_LENGTH}
            value={applicationName || ''}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid.Container>
  );
}

SentinelTeamForm.propTypes = {
  applicationName: PropTypes.string,
  handleApplicationNameChange: PropTypes.func.isRequired,
  handleNameChange: PropTypes.func.isRequired,
  handleSaveImage: PropTypes.func.isRequired,
  logoUrl: PropTypes.string,
  name: PropTypes.string.isRequired,
  stretch: PropTypes.bool,
};

SentinelTeamForm.defaultProps = {
  applicationName: '',
  logoUrl: null,
  stretch: false,
};
