/* istanbul ignore file -- @preserve */
import React, { Component } from 'react';
import { Observable } from '@apollo/client';
import { castArray, isEmpty } from 'lodash';

import { mutationType, mutationStatus } from './constants';
import { getMutationNames } from './utils';

export const subscriptionsByType = new Map();

const getSubscriptions = (type) => subscriptionsByType.get(type) || new Set();

const addSubscription = (type, cb) =>
  subscriptionsByType.set(type, getSubscriptions(type).add(cb));

const removeSubscription = (type, cb) => getSubscriptions(type).delete(cb);

/**
 * Auto registers/unregisters a listener for the lifecycle of
 * mutations of the provided names, and injects a prop with the
 * combined status of said mutations.
 * @param {[String]} types the types/names of the mutations to listen for
 * @param {String} [propName = 'mutationStatus'] the name of the prop to inject
 */
export const withMutationsStatus =
  (types, propName = 'mutationsStatus') =>
  (WrappedComponent) =>
    class WrappedComponentWithMutationStatus extends Component {
      constructor(props) {
        super(props);

        this.state = {};
        types.forEach((t) => addSubscription(t, this.updateMutationStatus));
      }

      componentWillUnmount() {
        types.forEach((t) => removeSubscription(t, this.updateMutationStatus));
      }

      updateMutationStatus = (type, status, errors) => {
        if (!types.includes(type)) {
          return;
        }

        this.setState({
          [type]: {
            isLoading: status === mutationStatus.loading,
            errors: status === mutationStatus.error ? errors : null,
          },
        });
      };

      render() {
        return (
          <WrappedComponent {...{ ...this.props, [propName]: this.state }} />
        );
      }
    };

/**
 * Subscribes to lifecycle events of a mutation, and notifies any
 * registered listeners about those events.
 */
const observeMutation = (operation, forward) => {
  const triggerWithStatus = (status, ...rest) => {
    getMutationNames(operation).forEach((type) => {
      const subscribers = getSubscriptions(type);

      subscribers.forEach((trigger) => trigger(type, status, ...rest));
    });
  };

  return new Observable((observer) => {
    triggerWithStatus(mutationStatus.loading);

    const sub = forward(operation).subscribe({
      next: (data, ...rest) => {
        if (!isEmpty(data.errors)) {
          triggerWithStatus(mutationStatus.error, castArray(data.errors));
        }

        observer.next(data, ...rest);
      },

      error: (error, ...rest) => {
        triggerWithStatus(mutationStatus.error, castArray(error));

        observer.error(error, ...rest);
      },

      complete: (...args) => {
        triggerWithStatus(mutationStatus.complete);

        observer.complete(...args);
      },
    });

    return () => sub.unsubscribe();
  });
};

export { mutationType, mutationStatus };

export default observeMutation;
