import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { t, Trans } from '@lingui/macro';

import { P } from '@decisiv/ui-components/lib/components/Typography';

import BeforeAfterRow from 'components/BeforeAfterRow';

import Paginated from './Paginated';

import Status from './Audits/Status';
import ServiceRequest from './Audits/ServiceRequest';

const FIELDS = {
  STATUS: { component: Status, title: t`service event` },
  SERVICE_REQUESTS: {
    component: ServiceRequest,
    title: t`service request`,
  },
};

const action = ({ beforeValue, field }) => {
  switch (field) {
    case 'SERVICE_REQUESTS':
      return t`updated a`;

    default:
      return beforeValue ? t`updated a` : t`created a`;
  }
};

const getAttribute = ({ field }) => field === 'STATUS' && FIELDS[field].title;

const renderActionText = (datum) => {
  const { field } = datum;

  const baseActionText = (
    <>
      {action(datum)}{' '}
      <P as="span" weight="semibold">
        {getAttribute(datum)}.
      </P>
    </>
  );

  if (field === 'SERVICE_REQUESTS' && datum.beforeValue) {
    const { serviceRequest: afterServiceRequest } = datum.afterValue;

    const serviceRequstsStatuses = {
      ACCEPTED: <Trans>accepted a service request.</Trans>,
      DECLINED: <Trans>declined a service request.</Trans>,
      CANCELED: <Trans>canceled a service request.</Trans>,
    };

    return serviceRequstsStatuses[afterServiceRequest.status];
  }

  if (field === 'STATUS' && datum.beforeValue) {
    const { status: beforeStatus } = datum.beforeValue;
    const { appointment: afterAppt, status: afterStatus } = datum.afterValue;

    // service request was created
    if (
      beforeStatus === 'DRAFT' &&
      afterStatus === 'REQUESTED' &&
      afterAppt.platformServiceRequest
    ) {
      return (
        <Trans>
          sent a{' '}
          <P as="span" weight="semibold">
            service request
          </P>{' '}
          and {baseActionText}
        </Trans>
      );
    }

    // service event was canceled
    if (afterStatus === 'CANCELED') {
      return (
        <Trans>
          canceled a{' '}
          <P as="span" weight="semibold">
            service event.
          </P>
        </Trans>
      );
    }

    // case was created
    if (
      beforeStatus === 'DRAFT' &&
      afterStatus === 'REQUESTED' &&
      afterAppt.platformCase
    ) {
      return (
        <Trans>
          created a{' '}
          <P as="span" weight="semibold">
            case
          </P>{' '}
          and {baseActionText}
        </Trans>
      );
    }
  }

  if (field === 'SERVICE_REQUESTS') {
    const {
      afterValue: {
        serviceRequest: {
          platformServiceLocation: { name },
        },
      },
    } = datum;
    return (
      <Trans>
        at {name} {baseActionText}
      </Trans>
    );
  }

  return baseActionText;
};

export default function History({ history, timezone, emptyResultMessage }) {
  const renderDatum = useCallback(
    ({ afterValue, beforeValue, field }) => {
      const Component = FIELDS[field]?.component;
      return Component ? (
        <BeforeAfterRow
          field={field}
          afterValue={afterValue}
          beforeValue={beforeValue}
          paddingRight={0}
          renderData={(value) => (
            <Component timezone={timezone} value={value} />
          )}
        />
      ) : null;
    },
    [timezone],
  );

  return (
    <Paginated
      actionText={renderActionText}
      collection={history}
      emptyResultMessage={emptyResultMessage}
      renderDatum={renderDatum}
    />
  );
}

History.propTypes = {
  emptyResultMessage: PropTypes.string.isRequired,
  getPaginationProps: PropTypes.shape({}).isRequired,
  history: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  timezone: PropTypes.string,
};

History.defaultProps = {
  timezone: null,
};
