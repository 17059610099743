import React, {
  useEffect,
  createContext,
  useMemo,
  useContext,
  useCallback,
  useState,
  useRef,
} from 'react';
import omit from 'lodash/omit';
import PropTypes from 'prop-types';

import useQueryParams from 'hooks/useQueryParams';
import useSentinelTeam from 'hooks/useSentinelTeam';
import useUpdateEffect from 'hooks/useUpdateEffect';

import {
  ASSET_TABS,
  VIEW_MODE,
  BUILTIN_ALERT_IDS,
} from 'utils/Alerts/constants';
import { useGlobalFiltersContext } from 'contexts/GlobalFiltersContext';

import { useAssetViewContext } from 'pages/AssetView/AssetViewContext';

import useAlertsQuery from './useAlertsQuery';
import useAssetsFiltersQuery from './useAssetsFiltersQuery';

export const AlertsContext = createContext({});

const PAGE_KEY = 'alerts';

const defaultAppliedFilters = {
  search: '',
  sort: 'ALL',
  sortDirection: undefined,
  groupBy: 'ALL',
  alertTypes: [],
  alertsText: [],
  scheduleMaintenanceStatus: [],
  faultStatus: [],
  customAlertStatus: [],
  customAlerts: [],
  assetTypes: [],
  makes: [],
  models: [],
  years: [],
  costCenters: [],
  workInProgress: [],
  assignees: [],
  platformCaseFleetStatus: [],
  platformCaseRepairStatus: [],
  serviceEventStatus: [],
  serviceRequestStatus: [],
  onlyActives: false,
  viewMode: VIEW_MODE.LIST_VIEW,
  selectedAsset: undefined,
  selectedTab: ASSET_TABS.ALERT,
  serviceEventCreatedBetween: [],
  serviceEventUpdatedBetween: [],
  serviceRequestCreatedBetween: [],
  serviceRequestUpdatedBetween: [],
  platformCaseCreatedBetween: [],
  platformCaseUpdatedBetween: [],
  serviceProviderUuids: [],
  serviceProviderCityIds: [],
};

export default function AlertsProvider({ children }) {
  // We can render AlertsProvider without AssetViewContext
  const { defaultFiltersState = undefined } = useAssetViewContext();

  // This is a state to set the initial default state of the filters.
  // We use this to compare the actual state over the default loaded one.
  const [defaultFilters, setDefaultFilters] = useState(
    defaultFiltersState || defaultAppliedFilters,
  );

  const { id: teamId } = useSentinelTeam();
  const { assetGroupingIds, platformAccountIds, groupedAssetGroupingsIds } =
    useGlobalFiltersContext();

  const [appliedFilters, setAppliedFilters, resetAppliedFilters] =
    useQueryParams(`${teamId}-${PAGE_KEY}-appliedFilters`, defaultFilters, {
      typeCoercion: false,
    });

  const [pagination, setPagination, resetPagination] = useQueryParams(
    `${teamId}-${PAGE_KEY}-pagination`,
    {
      page: 1,
    },
    {
      typeCoercion: true,
    },
  );

  // Only search if the string is more than three characters
  const searchText =
    appliedFilters.search.length >= 3 ? appliedFilters.search : '';

  const { filters: availableFilters, queryResult } = useAssetsFiltersQuery(
    assetGroupingIds,
    platformAccountIds,
    groupedAssetGroupingsIds,
    searchText,
  );

  const {
    alertsData,
    pagination: { perPage, totalEntries, totalPages },
    exportUrl,
    queryResult: { loading },
  } = useAlertsQuery({
    alertTypes: appliedFilters.alertTypes,
    alertsText: appliedFilters.alertsText,
    scheduleMaintenanceStatus: appliedFilters?.scheduleMaintenanceStatus,
    faultStatus: appliedFilters.faultStatus,
    customAlertStatus: appliedFilters.customAlertStatus,
    customAlerts: appliedFilters.customAlerts,
    sort: appliedFilters.sort,
    sortDirection: appliedFilters.sortDirection,
    groupBy: appliedFilters.groupBy,
    assetTypes: appliedFilters.assetTypes,
    makes: appliedFilters.makes,
    models: appliedFilters.models,
    years: appliedFilters.years,
    costCenters: appliedFilters.costCenters,
    workInProgress: appliedFilters.workInProgress,
    customerIds: assetGroupingIds,
    platformAccountIds,
    groupedAssetGroupingsIds,
    enabled: 'ENABLED',
    currentPage: pagination.page,
    assignees: appliedFilters.assignees,
    platformCaseFleetStatus: appliedFilters.platformCaseFleetStatus,
    platformCaseRepairStatus: appliedFilters.platformCaseRepairStatus,
    serviceEventStatus: appliedFilters.serviceEventStatus,
    serviceRequestStatus: appliedFilters.serviceRequestStatus,
    serviceEventCreatedBetween: appliedFilters.serviceEventCreatedBetween,
    serviceEventUpdatedBetween: appliedFilters.serviceEventUpdatedBetween,
    serviceRequestCreatedBetween: appliedFilters.serviceRequestCreatedBetween,
    serviceRequestUpdatedBetween: appliedFilters.serviceRequestUpdatedBetween,
    platformCaseCreatedBetween: appliedFilters.platformCaseCreatedBetween,
    platformCaseUpdatedBetween: appliedFilters.platformCaseUpdatedBetween,
    serviceProviderUuids: appliedFilters.serviceProviderUuids,
    serviceProviderCityIds: appliedFilters.serviceProviderCityIds,
    text: searchText,
  });

  useEffect(() => {
    // enabling all, because the fiters are dynamic
    // Ex: The dynamic maintenance could have 0 active alerts and should not be selected

    if (!appliedFilters.onlyActives || queryResult.loading) return;

    setAppliedFilters((old) => ({
      ...old,
      onlyActives: false,
      alertTypes: availableFilters.alertFilters
        .filter(
          (alert) =>
            alert.count > 0 && BUILTIN_ALERT_IDS.includes(alert.attribute),
        )
        .map((alert) => alert.id),
      customAlerts: availableFilters.alertFilters
        .filter(
          (alert) =>
            alert.count > 0 && !BUILTIN_ALERT_IDS.includes(alert.attribute),
        )
        .map((alert) => alert.id),
    }));
  }, [
    appliedFilters.onlyActives,
    availableFilters.alertFilters,
    queryResult.loading,
    setAppliedFilters,
  ]);

  const filtersToWatchPagination = useMemo(
    () =>
      JSON.stringify(
        omit(appliedFilters, ['selectedAsset', 'viewMode', 'selectedTab']),
      ),
    [appliedFilters],
  );

  // Reset pagination whenever advancedFilters change
  useUpdateEffect(() => {
    resetPagination();
  }, [filtersToWatchPagination, setPagination]);

  const advancedFiltersFormRef = useRef(null);

  const value = useMemo(
    () => ({
      advancedFiltersFormRef,
      alertsData,
      exportUrl,
      loading,
      availableFilters,
      appliedFilters,
      setAppliedFilters,
      resetAppliedFilters,
      pagination: { ...pagination, perPage, totalEntries, totalPages },
      setPagination,
      defaultFilters,
      defaultAppliedFilters,
      setDefaultFilters,
    }),
    [
      alertsData,
      exportUrl,
      loading,
      availableFilters,
      appliedFilters,
      setAppliedFilters,
      resetAppliedFilters,
      pagination,
      perPage,
      totalEntries,
      totalPages,
      setPagination,
      defaultFilters,
    ],
  );

  return (
    <AlertsContext.Provider value={value}>{children}</AlertsContext.Provider>
  );
}

AlertsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

AlertsProvider.displayName = 'AlertsProvider';

export function useAlertsContext() {
  const contextValue = useContext(AlertsContext);

  const {
    appliedFilters,
    setAppliedFilters,
    setPagination,
    setDefaultFilters,
  } = contextValue;

  const setGroupBy = useCallback(
    (groupBy) => {
      setAppliedFilters((oldState) => ({
        ...oldState,
        selectedAsset: undefined,
        groupBy,
      }));
    },
    [setAppliedFilters],
  );

  const setSort = useCallback(
    (column, sortDirection = undefined) => {
      setAppliedFilters((oldState) => ({
        ...oldState,
        selectedAsset: undefined,
        sort: column,
        sortDirection,
      }));
    },
    [setAppliedFilters],
  );

  const toggleBasicFilter = useCallback(
    (key, filterValue) => {
      setAppliedFilters((oldState) => {
        const values = oldState[key];

        return {
          ...oldState,
          selectedAsset: undefined,
          [key]: values.includes(filterValue)
            ? values.filter((i) => i !== filterValue)
            : [...values, filterValue],
        };
      });
    },
    [setAppliedFilters],
  );

  const setPage = useCallback(
    (newPage) => {
      setPagination((oldState) => ({
        ...oldState,
        selectedAsset: undefined,
        page: newPage,
      }));
    },
    [setPagination],
  );

  const handleOnBadgeViewAllClick = useCallback(
    (assetId, tabId) => {
      setAppliedFilters((oldState) => ({
        ...oldState,
        selectedAsset: assetId,
        selectedTab: tabId,
      }));
    },
    [setAppliedFilters],
  );

  const updateDefaultFilters = useCallback(() => {
    setDefaultFilters(appliedFilters);
  }, [appliedFilters, setDefaultFilters]);

  return {
    ...contextValue,
    setSort,
    setGroupBy,
    toggleBasicFilter,
    setPage,
    handleOnBadgeViewAllClick,
    updateDefaultFilters,
  };
}
