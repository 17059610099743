import { useCallback } from 'react';
import { t } from '@lingui/macro';

import Phone from '@decisiv/iconix/lib/components/Phone';
import PhoneArrow from '@decisiv/iconix/lib/components/PhoneArrow';
import PhoneArrowIn from '@decisiv/iconix/lib/components/PhoneArrowIn';

import Button, {
  ButtonProps,
} from '@decisiv/ui-components/lib/components/Button';

import isDemoMode from 'utils/isDemoMode';

import useSentinelTeam from 'hooks/useSentinelTeam';
import useClickToCall from 'features/amazonConnect/useClickToCall';

interface ClickToCallButtonProps extends ButtonProps {
  phone?: string;
  name: string;
  withIcon?: boolean;
}

/**
 * Given a phone number and a name, show a button which when clicked
 * will initiate an outbound phone call to the given number. The name
 * will only be used for the aria-label.
 */
export default function ClickToCallButton({
  phone = '',
  name,
  withIcon = true,
  variant = 'ghost',
  ...rest
}: ClickToCallButtonProps) {
  const { amazonConnectInstance } = useSentinelTeam();

  const { placeCall, isConnected, isInbound, enabled } = useClickToCall({
    phone,
  });

  const handleClick = useCallback(
    (event) => {
      if (isDemoMode() || !amazonConnectInstance || !enabled) {
        event.preventDefault();
        window.location.href = `tel:${phone}`;
      } else {
        placeCall();
      }
    },
    [amazonConnectInstance, enabled, phone, placeCall],
  );

  if (!phone) {
    return null;
  }

  let phoneIcon = Phone;
  if (isConnected) {
    phoneIcon = PhoneArrow;
    if (isInbound) phoneIcon = PhoneArrowIn;
  }

  const nameOrPhoneNumber = name || phone;
  return (
    <Button
      aria-label={t`Call ${nameOrPhoneNumber}`}
      onClick={handleClick}
      text={phone}
      icon={withIcon ? phoneIcon : undefined}
      variant={variant}
      {...rest}
    />
  );
}
