import React from 'react';
import PropTypes from 'prop-types';

import Flex from '@decisiv/ui-components/lib/components/Flex';
import Modal from '@decisiv/ui-components/lib/components/Modal';

import { t } from '@lingui/macro';

import Bullhorn from '@decisiv/iconix/lib/components/Bullhorn';
import BarChartComponent from './BarChart';

export default function AlertsByTypeViewAllModal({
  alerts,
  open,
  onClose,
  actions,
}) {
  const totalAlerts = alerts.reduce((sum, { count = 0 }) => sum + count, 0);

  return (
    <Modal
      intent="information"
      visible={open}
      onClose={onClose}
      title={t`Assets by Active Alert Types`}
      icon={Bullhorn}
      actions={actions}
    >
      <Flex marginRight={1} css="width:100%">
        <BarChartComponent alerts={alerts} totalAlertCount={totalAlerts} />
      </Flex>
    </Modal>
  );
}

AlertsByTypeViewAllModal.propTypes = {
  alerts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      color: PropTypes.string,
      count: PropTypes.number,
      percentage: PropTypes.number,
      title: PropTypes.string,
    }),
  ),
  open: PropTypes.bool,
  actions: PropTypes.arrayOf(
    PropTypes.shape({ text: PropTypes.string, onClick: PropTypes.func }),
  ).isRequired,
  onClose: PropTypes.func.isRequired,
};

AlertsByTypeViewAllModal.defaultProps = {
  alerts: [],
  open: false,
};
