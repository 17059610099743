/* istanbul ignore file -- @preserve */

import isConfigEnabled from 'utils/isConfigEnabled';
import initializePendoLinkFactory from './initializePendoLink';

function enablePendo() {
  return isConfigEnabled(import.meta.env.VITE_PENDO_ENABLED);
}

const initializePendoLink = initializePendoLinkFactory.create(enablePendo());

export { initializePendoLink };
