import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@decisiv/ui-components/lib/components/Grid';

import ListChartAsset from './ListChartAsset';

export default function ListChart({ data }) {
  return (
    <Grid.Container padding={0}>
      {data?.results?.map((assetData, i) => (
        <React.Fragment key={assetData.id}>
          <ListChartAsset {...assetData} ranking={i + 1} />
        </React.Fragment>
      ))}
    </Grid.Container>
  );
}

ListChart.propTypes = {
  data: PropTypes.shape({
    results: PropTypes.arrayOf(
      PropTypes.shape({
        percentage: PropTypes.number,
        subtitle: PropTypes.string,
        title: PropTypes.string,
        count: PropTypes.number,
      }),
    ),
  }),
};

ListChart.defaultProps = {
  data: {},
};
