import React from 'react';
import { Trans } from '@lingui/macro';

import Loading from 'components/Loading';

export default function TeamChanger() {
  return (
    <Loading>
      <Trans>Switching team...</Trans>
    </Loading>
  );
}
