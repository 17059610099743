import React from 'react';
import { t, Trans } from '@lingui/macro';
import styled from 'styled-components';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

import Badge from '@decisiv/ui-components/lib/components/Badge';
import Flex from '@decisiv/ui-components/lib/components/Flex';
import { H3 } from '@decisiv/ui-components/lib/components/Typography';

import Card from 'components/DashboardCard';
import Loading from 'components/Loading';
import LinkTo from 'components/LinkTo';
import Divider from 'components/Divider';

import {
  appointmentColors,
  appointmentIcons,
  appointmentStatusLabelText,
} from 'utils/Appointments/constants';

import { Grid } from './styles';

const serviceEventsByStatus = loader('./serviceEventsByStatus.graphql');

const Paper = styled(Flex).attrs({ padding: 2 })`
  width: 100%;
  box-shadow: 0 1px 4px rgba(37, 38, 46, 0.4);
  border-radius: 2px;
`;

const infoText = t`View events requiring attention. Click on the panel links to view the Service Events
page with the event status filters automatically enabled.`;

export default function ServiceEventsCard() {
  const navigate = useNavigate();
  const {
    data: {
      dashboardServiceEventsByStatus: {
        draft,
        draftWithActiveAlerts,
        inProgress,
        inProgressDueSoon,
        inProgressOverdue,
      } = {},
    } = {},
    loading,
  } = useQuery(serviceEventsByStatus);

  const onClickViewAll = () => navigate('/service_events');

  return (
    <Card
      text={t`Service Events`}
      buttonText={t`View All`}
      buttonAriaLabel={t`View All Service Events`}
      infoText={infoText}
      onButtonClick={onClickViewAll}
      marginTop={0}
    >
      {loading && <Loading />}
      {!loading && (
        <>
          <Flex flexDirection="column" marginBottom={1}>
            <Paper marginY={1}>
              <Grid>
                <Flex alignItems="center">
                  <Badge
                    variant="square"
                    color={appointmentColors.DRAFT}
                    icon={appointmentIcons.DRAFT}
                  />
                </Flex>
                <Flex flexDirection="column" marginLeft={1}>
                  <Trans>
                    <H3 weight="regular">{draft}</H3>
                  </Trans>
                  <LinkTo to="/service_events?status=DRAFT">
                    <Trans>{appointmentStatusLabelText.DRAFT} Events</Trans>
                  </LinkTo>
                </Flex>
                <Divider
                  css={`
                    height: auto;
                  `}
                  marginX={1}
                />
                <Flex
                  flexDirection="column"
                  marginLeft={1}
                  css={`
                    grid-column: 4 / span 2;
                  `}
                >
                  <Trans>
                    <H3 weight="regular">{draftWithActiveAlerts}</H3>
                  </Trans>
                  <LinkTo to="/service_events?status=DRAFT&serviceEvents=WITH_ACTIVE_ALERTS">
                    <Trans>Draft Events with Active Alerts</Trans>
                  </LinkTo>
                </Flex>
              </Grid>
            </Paper>
          </Flex>
          <Flex flexDirection="column" marginBottom={1}>
            <Paper marginY={1}>
              <Grid>
                <Flex alignItems="center">
                  <Badge
                    variant="square"
                    color={appointmentColors.REQUESTED}
                    icon={appointmentIcons.REQUESTED}
                  />
                </Flex>
                <Flex flexDirection="column" marginLeft={1}>
                  <Trans>
                    <H3 weight="regular">{inProgress}</H3>
                  </Trans>
                  <LinkTo to="/service_events?status=REQUESTED">
                    <Trans>{appointmentStatusLabelText.REQUESTED} Events</Trans>
                  </LinkTo>
                </Flex>
                <Divider
                  css={`
                    height: auto;
                  `}
                  marginX={1}
                />
                <Flex flexDirection="column" marginLeft={1}>
                  <Trans>
                    <H3 weight="regular">{inProgressDueSoon}</H3>
                  </Trans>
                  <LinkTo to="/service_events?status=REQUESTED&serviceEvents=DUE_SOON">
                    <Trans>Due Soon</Trans>
                  </LinkTo>
                </Flex>
                <Flex flexDirection="column" marginLeft={1}>
                  <Trans>
                    <H3 weight="regular">{inProgressOverdue}</H3>
                  </Trans>
                  <LinkTo to="/service_events?status=REQUESTED&serviceEvents=OVERDUE">
                    <Trans>Overdue</Trans>
                  </LinkTo>
                </Flex>
              </Grid>
            </Paper>
          </Flex>
        </>
      )}
    </Card>
  );
}
