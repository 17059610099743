import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { t } from '@lingui/macro';
import toColorString from 'polished/lib/color/toColorString';
import rem from 'polished/lib/helpers/rem';

import spacing from '@decisiv/design-tokens/lib/spacing';
import color from '@decisiv/design-tokens/lib/color';

import Question from '@decisiv/iconix/lib/components/Question';

import Flex from '@decisiv/ui-components/lib/components/Flex';
import Grid from '@decisiv/ui-components/lib/components/Grid';
import { P } from '@decisiv/ui-components/lib/components/Typography';

import EmptyResults from 'components/EmptyResults';

import AuditTag from '../../../components/AuditTag';
import AuditBadge from '../../../components/AuditBadge';
import AuditTimestamp from '../../../components/AuditTimestamp';
import AuditAsset from '../../../components/AuditAsset';

export default function Paginated({
  actionText,
  collection,
  emptyResultMessage,
  renderDatum,
}) {
  if (isEmpty(collection)) {
    return (
      <Flex
        alignItems="center"
        css={`
          width: 100%;
        `}
        justifyContent="center"
        marginRight={1}
      >
        <EmptyResults
          icon={Question}
          color="licoriceMousse"
          title={t`No Recent Activity`}
          subtitle={emptyResultMessage}
          maxWidth={rem(spacing.base * 48)}
        />
      </Flex>
    );
  }

  return (
    <Grid.Container padding={0} marginRight={1}>
      {collection.map((audit, index) => {
        const { id: auditId } = audit;
        const { email, name } =
          audit.createdBy || audit.changedBy || audit.performedBy || {};

        const renderDivider = index > 0;

        return (
          <Flex marginBottom={1} key={auditId}>
            <Flex marginTop={renderDivider ? 1 : 0}>
              <AuditBadge audit={audit} />
            </Flex>
            <Flex
              flex={1}
              flexDirection="column"
              marginLeft={1}
              paddingTop={renderDivider ? 1 : 0}
              css={`
                width: 100%;
                border-top: ${renderDivider
                  ? `1px solid ${toColorString(color.opacity.charcoal15)}`
                  : undefined};
              `}
            >
              <AuditTimestamp audit={audit} />
              <Flex>
                <P marginRight={1}>
                  <P as="span" weight="semibold">
                    {name || email || '–'}&nbsp;
                  </P>
                  {actionText(audit)}
                </P>
                <AuditTag audit={audit} />
              </Flex>
              <AuditAsset audit={audit} />
              <Flex marginTop={0.5}>{renderDatum(audit)}</Flex>
            </Flex>
          </Flex>
        );
      })}
    </Grid.Container>
  );
}

Paginated.propTypes = {
  actionText: PropTypes.func.isRequired,
  collection: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      createdAt: PropTypes.string,
      createdBy: PropTypes.shape({
        email: PropTypes.string,
        name: PropTypes.string,
      }),
      editedAt: PropTypes.string,
    }),
  ).isRequired,
  renderDatum: PropTypes.func.isRequired,
  emptyResultMessage: PropTypes.string.isRequired,
};
