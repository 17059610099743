import { get, omit, isNil } from 'lodash';

// Some operations should not trigger bugsnag reporting if they have an error.
const noReportOperationsWithCodes = {
  FindAssetView: {
    codes: ['NOT_FOUND'],
  },
  currentInvitation: {
    codes: ['410'],
  },
};

const noReportError = ['SITE_MAINTENANCE'];

const reportedErrors = new Set();

// Paths of query variables that should be
// ignored for the operation id generation.
const ignoredVarsPaths = [
  // 'cacheBuster'
];

/**
 * Regular expression for matching a UUID e.g. "af098fb8-7235-468e-9724-b22ab616fddc".
 */
// const UUID_REGEX = /([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/;

/**
 * Customize the Bugsnag error grouping, otherwise all GraphQL
 * errors are grouped together into one Bugsnag error group.
 */
export function makeBugsnagGroupingHash(operation, error) {
  const errorMessage = get(error, 'message', '');

  // We may eventually want to strip UUID from the error message so that
  // the same error, for different entities, get grouped together - just
  // uncomment UUID_REGEX above and append this to the previous line:
  // .replace(UUID_REGEX, '');

  return [get(operation, 'operationName', ''), errorMessage].join(' - ');
}

const create =
  (bugsnagClient) =>
  ({ response, operation, graphQLErrors: [error] }) => {
    const {
      variables: vars,
      operationName,
      query: {
        loc: {
          source: { body: query },
        },
      },
    } = operation;

    /* istanbul ignore if -- @preserve */
    if (
      !isNil(get(noReportOperationsWithCodes, operationName)) &&
      noReportOperationsWithCodes[operationName].codes.includes(
        error?.extensions?.code,
      )
    ) {
      return;
    }

    /* istanbul ignore if -- @preserve */
    if (noReportError.includes(error?.extensions?.detail)) {
      return;
    }

    const variables = omit(vars, ignoredVarsPaths);
    const operationId = `${operationName}-${JSON.stringify(variables)}`;

    if (!reportedErrors.has(operationId)) {
      reportedErrors.add(operationId);

      /* istanbul ignore if -- @preserve */
      if (!['production', 'test'].includes(import.meta.env.MODE)) {
        // eslint-disable-next-line no-console
        console.warn(
          'The following error would be reported to Bugsnag in production. Please ensure it is caught to prevent double reporting: ',
          error,
        );
      }

      bugsnagClient.notify(new Error(error.message), {
        beforeSend: (report) => {
          /* eslint-disable no-param-reassign */
          report.context = `${operationName} - error in GraphQL response: ${error.message}`;
          report.updateMetaData('graphql', {
            operationName,
            query,
            variables,
            response,
            error,
          });
          report.groupingHash = makeBugsnagGroupingHash(operation, error);
          /* eslint-enable no-param-reassign */
        },
      });
    }
  };

const reportGraphQLErrorsFactory = { create };

export default reportGraphQLErrorsFactory;
