import moment from 'moment-timezone';
import toColorString from 'polished/lib/color/toColorString';
import styled, { css } from 'styled-components';

import { useMutation } from '@apollo/client';

import color from '@decisiv/design-tokens/lib/color';

import Robot from '@decisiv/iconix/lib/components/Robot';
import Avatar from '@decisiv/ui-components/lib/components/Avatar';
import Badge from '@decisiv/ui-components/lib/components/Badge';
import Grid from '@decisiv/ui-components/lib/components/Grid';
import Button from '@decisiv/ui-components/lib/components/Button';
import { P } from '@decisiv/ui-components/lib/components/Typography';

import { t } from '@lingui/macro';

import { parseDate } from 'utils/dates';
import { nameForUser } from 'utils/User';

import AppointmentInfo from './AppointmentInfo';
import BulkChangeInfo from './BulkChangeInfo';
import InvitationInfo from './InvitationInfo';
import ServiceRequestInfo from './ServiceRequestInfo';

import unreadNotificationsCountQuery from './hooks/unreadNotificationsCount.graphql';
import updateNotificationsMutation from './updateNotifications.graphql';

function labelForDate(date: string) {
  if (moment.tz(date, moment.tz.guess()).isSame(moment.tz(), 'day')) {
    return t`Today`;
  }
  if (
    moment
      .tz(date, moment.tz.guess())
      .isSame(moment.tz().subtract(1, 'day'), 'day')
  ) {
    return t`Yesterday`;
  }
  const [dayMonthYear] = parseDate(date);
  return dayMonthYear;
}

const getUserLabel = ({
  source,
  user,
}: {
  source: TNotification['source'];
  user?: Pick<User, 'name' | 'firstName' | 'lastName' | 'username' | 'email'>;
}): string => {
  if (user?.name === 'System') return t`System`;

  const realUserName = nameForUser(user) ?? '';
  const userName = user?.name === 'System' ? t`System` : realUserName;

  switch (source.__typename) {
    case 'Appointment': {
      const assetGroupName = source?.asset?.assetGrouping?.name;
      return t`${userName} at ${assetGroupName}`;
    }

    case 'PlatformServiceRequest': {
      const assetGroupName = source?.appointment?.asset?.assetGrouping?.name;
      return `${userName} at ${assetGroupName}`;
    }

    default:
      return userName;
  }
};

const WrapperColumn = styled(Grid.Column)<{ seen: boolean }>`
  ${({ seen }) =>
    !seen &&
    css`
      background-color: ${toColorString(color.interaction.pacificOcean15)};
      border-radius: 2px;
    `}

  transition: background-color 500ms linear;
`;

const UserLabel = styled(P).attrs({ weight: 'semibold' })`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const UserLabelColumn = styled(Grid.Column)`
  overflow: hidden;
`;

interface NotificationGroupProps {
  date: string;
  notifications: TNotification[];
  handleOpenModal: () => void;
  setModalData: () => void;
}

function NotificationGroup({
  date,
  notifications,
  handleOpenModal,
  setModalData,
}: NotificationGroupProps) {
  const [updateNotifications, { loading }] = useMutation<
    UpdateNotificationsMutation,
    UpdateNotificationsMutationVariables
  >(updateNotificationsMutation, {
    update(cache, { data }) {
      const { notifications: cacheNotifications } =
        cache.readQuery<UnreadNotificationsCountQuery>({
          query: unreadNotificationsCountQuery,
        }) || { notifications: {} };

      cache.writeQuery({
        query: unreadNotificationsCountQuery,
        data: {
          notifications: {
            ...cacheNotifications,
            countUnread: data?.updateNotifications?.countUnread,
          },
        },
      });
    },
  });

  return (
    <>
      <Grid.Row paddingX={1}>
        <Grid.Column paddingY={1}>
          <P color="alaskanHusky" size="small">
            {labelForDate(date)}
          </P>
        </Grid.Column>
      </Grid.Row>
      {notifications.map((notification) => {
        const { user, source } = notification;
        const notificationColor =
          !notification.seen || notification.read ? 'alaskanHusky' : 'charcoal';

        const NotificationComponent = () => {
          switch (source.__typename) {
            case 'Appointment':
              return (
                <AppointmentInfo
                  key={notification.id}
                  source={source}
                  notification={notification}
                  notificationColor={notificationColor}
                  handleOpenModal={handleOpenModal}
                  setModalData={setModalData}
                />
              );

            case 'BulkChange':
              return (
                <BulkChangeInfo
                  key={notification.id}
                  source={source}
                  notification={notification}
                  handleOpenModal={handleOpenModal}
                  setModalData={setModalData}
                />
              );

            case 'PlatformServiceRequest':
              return (
                <ServiceRequestInfo
                  key={notification.id}
                  source={source}
                  notification={notification}
                  notificationColor={notificationColor}
                  handleOpenModal={handleOpenModal}
                  setModalData={setModalData}
                />
              );

            case 'CustomerInvitationChanges':
              return (
                <InvitationInfo
                  key={notification.id}
                  source={source}
                  notificationColor={notificationColor}
                />
              );
            default:
              return null;
          }
        };

        const realUserName = nameForUser(user) ?? '';
        const userLabel = getUserLabel({ user, source });

        return (
          <Grid.Row key={notification.id} paddingBottom={0.2} paddingX={1}>
            <WrapperColumn seen={notification.seen} paddingY={1}>
              <Grid.Row>
                <Grid.Column paddingRight={0} span="0">
                  {user?.name === 'System' ? (
                    <Badge aria-label="" color="licoriceMousse" icon={Robot} />
                  ) : (
                    <Avatar
                      email={user?.email ?? undefined}
                      name={realUserName}
                    />
                  )}
                </Grid.Column>
                <Grid.Column span="10">
                  <Grid.Row alignment="middle">
                    <UserLabelColumn>
                      <UserLabel color={notificationColor}>
                        {' '}
                        {userLabel}
                      </UserLabel>
                    </UserLabelColumn>
                    <Grid.Column span="0">
                      <P as="span" color="alaskanHusky" size="small" truncate>
                        {parseDate(notification.createdAt)[1]}
                      </P>
                    </Grid.Column>
                  </Grid.Row>
                  <NotificationComponent />
                  <Grid.Row>
                    <Grid.Column>
                      <Button
                        onClick={async () => {
                          await updateNotifications({
                            variables: {
                              ids: [notification.id],
                              attributes: { read: !notification.read },
                            },
                          });
                        }}
                        text={
                          notification.read
                            ? t`Mark as Unread`
                            : t`Mark As Read`
                        }
                        size="small"
                        variant="ghost"
                        loading={loading}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid.Column>
              </Grid.Row>
            </WrapperColumn>
          </Grid.Row>
        );
      })}
    </>
  );
}

export default NotificationGroup;
