import React from 'react';
import { t } from '@lingui/macro';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';

import Badge from '@decisiv/ui-components/lib/components/Badge';
import Flex from '@decisiv/ui-components/lib/components/Flex';
import { H3 } from '@decisiv/ui-components/lib/components/Typography';

import Building from '@decisiv/iconix/lib/components/Building';

import Card from 'components/DashboardCard';
import Loading from 'components/Loading';
import Divider from 'components/Divider';
import useSentinelTeam from 'hooks/useSentinelTeam';
import { useCurrentUserContext } from 'utils/User';

import TotalAssetGroupings from './TotalAssetGroupings';
import TotalAssignedToMe from './TotalAssignedToMe';

const totalAssetGroupingsQuery = loader('./totalAssetGrouping.graphql');
const totalAssignedToMeQuery = loader('./totalAssignedToMe.graphql');

export default function TotalAssetGroupingsCard() {
  const { id } = useCurrentUserContext();
  const { isFleet } = useSentinelTeam();

  const {
    loading: loadingAssetGroupings,
    data: {
      allAssetGroupings: {
        pagination: { totalEntries: assetGroupingsCount } = {},
      } = {},
    } = {},
  } = useQuery(totalAssetGroupingsQuery, { fetchPolicy: 'network-only' });

  const {
    loading: loadingAssignedToMe,
    data: {
      sentinelTeam: {
        assignees: {
          pagination: { totalEntries: assignedToMeCount } = {},
        } = {},
      } = {},
    } = {},
  } = useQuery(totalAssignedToMeQuery, {
    fetchPolicy: 'network-only',
    variables: { userId: id },
  });

  const headerText = isFleet ? t`Total Depots` : t`Total Customers`;

  const loadingMessage = isFleet
    ? t`Loading Depot Information...`
    : t`Loading Customer Information...`;

  if (loadingAssetGroupings || loadingAssignedToMe)
    return (
      <Card marginTop={0}>
        <Flex flexDirection="column" flex={1}>
          <Flex alignItems="center">
            <Badge variant="square" icon={Building} color="information" />
            <Flex marginLeft={1} alignItems="center">
              <H3
                weight="medium"
                css={`
                  white-space: nowrap;
                `}
              >
                {headerText}
              </H3>
            </Flex>
            <Loading inline imgDim={25}>
              {loadingMessage}
            </Loading>
          </Flex>
        </Flex>
      </Card>
    );

  return (
    <Card marginTop={0}>
      <Flex marginTop={1.25}>
        <TotalAssetGroupings count={assetGroupingsCount} />
        <Divider
          css={`
            height: auto;
          `}
          marginX={1}
        />
        <TotalAssignedToMe count={assignedToMeCount} />
      </Flex>
    </Card>
  );
}
