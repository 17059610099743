import React from 'react';
import PropTypes from 'prop-types';

import Badge from '@decisiv/ui-components/lib/components/Badge';
import { P } from '@decisiv/ui-components/lib/components/Typography';

import {
  badgeColorForCustomer,
  badgeIconForCustomer,
  labelForCustomerStatus,
} from 'utils/Customer';
import { parseDate } from 'utils/dates';

import { useCurrentUserContext } from 'utils/User';
import AssetGroupingData from './AssetGroupingData';

function AssetGroupingStatus({ assetGrouping }) {
  const { isAdmin } = useCurrentUserContext();

  if (!assetGrouping || !isAdmin) return null;

  return (
    assetGrouping?.status && (
      <AssetGroupingData
        decoration={
          <Badge
            color={badgeColorForCustomer({
              status: assetGrouping.status,
            })}
            icon={badgeIconForCustomer({ status: assetGrouping.status })}
            variant="square"
          />
        }
        titleText={labelForCustomerStatus({ status: assetGrouping.status })}
        data={
          <P as="span" truncate>
            {parseDate(assetGrouping.statusUpdatedAt).join(', ')}
          </P>
        }
      />
    )
  );
}

AssetGroupingStatus.propTypes = {
  assetGrouping: PropTypes.shape({
    id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    statusUpdatedAt: PropTypes.string.isRequired,
  }),
};

AssetGroupingStatus.defaultProps = {
  assetGrouping: undefined,
};

export default AssetGroupingStatus;
