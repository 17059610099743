import React from 'react';
import PropTypes from 'prop-types';

import Flex from '@decisiv/ui-components/lib/components/Flex';
import { H1, P } from '@decisiv/ui-components/lib/components/Typography';

export default function PageHeader({ subtitle, title }) {
  return (
    <Flex justifyContent="space-between" paddingRight={2}>
      <Flex alignItems="left" flexDirection="row">
        <Flex
          alignItems="flex-end"
          paddingY={1}
          paddingLeft={2}
          paddingRight={1}
        >
          <H1 weight="regular">{title}</H1>
        </Flex>
      </Flex>
      <Flex alignItems="center">
        <P as="span" color="alaskanHusky" marginLeft={1} size="small">
          {subtitle}
        </P>
      </Flex>
    </Flex>
  );
}

PageHeader.propTypes = {
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
};

PageHeader.defaultProps = {
  subtitle: '',
};
