/* eslint-disable react/prop-types */
/* istanbul ignore file -- @preserve */

import React, { useContext } from 'react';
import { Routes as Router, Route } from 'react-router-dom';

import Layout from 'components/Layout';
import Loading from 'components/Loading';

import AccessDenied from 'pages/AccessDenied';

import SiteMaintenancePage from 'pages/SiteMaintenance';
import LoggedOut from 'pages/LoggedOut';

import CurrentUserProvider, { useCurrentUserContext } from 'utils/User';
import CurrentInvitationProvider, {
  CurrentInvitationContext,
} from 'utils/Invitation';
import SentinelTeamProvider, {
  sentinelTeamProviderRef,
} from 'contexts/SentinelTeamContext';

import ROUTE_NAMES from '../routeNames';

import RootPathRoute from './RootPathRoute';

import { REDIRECTS, ROUTES } from './routeMap';

const AuthenticatedWrapper = ({ children }) => {
  const { id: userId } = useCurrentUserContext();
  const { authenticationKey } = useContext(CurrentInvitationContext);

  // Prevent redirection to access-denied page when signing out from application.
  if (!userId && !authenticationKey) return <Loading />;

  return children;
};

const ProvidersWrapper = ({ children }) => {
  return (
    <CurrentInvitationProvider>
      <CurrentUserProvider>
        <SentinelTeamProvider ref={sentinelTeamProviderRef}>
          <AuthenticatedWrapper>{children}</AuthenticatedWrapper>
        </SentinelTeamProvider>
      </CurrentUserProvider>
    </CurrentInvitationProvider>
  );
};

export default function Routes() {
  return (
    <Router>
      <Route path={ROUTE_NAMES.logged_out} element={<LoggedOut />} />
      <Route
        element={
          <ProvidersWrapper>
            <Layout />
          </ProvidersWrapper>
        }
      >
        {REDIRECTS}
        <Route path="/" element={<RootPathRoute />} />
        {ROUTES}
        <Route
          path={ROUTE_NAMES.site_maintenance}
          element={
            <SiteMaintenancePage target={ROUTE_NAMES.site_maintenance} />
          }
        />
        <Route path="*" element={<AccessDenied />} />
      </Route>
    </Router>
  );
}
