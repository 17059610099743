import React from 'react';
import PropTypes from 'prop-types';

import Link from '@decisiv/ui-components/lib/components/Link';

import routeNames from 'setup/Routing/routeNames';

export default function ActiveAlertLink({
  assetGroupingIds,
  disabled,
  ...rest
}) {
  const serviceGroupingAssetsUrl = `${
    routeNames.alerts
  }?assetGroupingIds=${assetGroupingIds.join(',')}&onlyActives=true`;

  return <Link to={serviceGroupingAssetsUrl} disabled={disabled} {...rest} />;
}

ActiveAlertLink.propTypes = {
  text: PropTypes.string.isRequired,
  assetGroupingIds: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
};

ActiveAlertLink.defaultProps = {
  assetGroupingIds: [],
  disabled: false,
};
