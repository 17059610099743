import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import Truck from '@decisiv/iconix/lib/components/Truck';

import Flex from '@decisiv/ui-components/lib/components/Flex';
import Grid from '@decisiv/ui-components/lib/components/Grid';
import Link from '@decisiv/ui-components/lib/components/Link';
import Message from '@decisiv/ui-components/lib/components/Message';

import { Trans, t } from '@lingui/macro';

import Paper from 'components/Paper';

import { CUSTOMER_STATUS } from 'utils/Customer';

export const customerStatusMessageProps = {
  [CUSTOMER_STATUS.NEEDS_ACTION]: {
    intent: 'help',
    size: 'large',
    title: t`No Assets Yet`,
    children: (
      <Trans>
        Contact{' '}
        <Link to="mailto:uxresearch@decisiv.com" text={t`Customer Support`} />{' '}
        to add assets to your team.
      </Trans>
    ),
  },
  [CUSTOMER_STATUS.REQUEST_PENDING]: {
    intent: 'help',
    size: 'large',
    title: t`Request Pending`,
    children: t`An access request was sent to the customer contact. They have not responded yet.`,
  },
  [CUSTOMER_STATUS.REQUEST_ACCEPTED]: {
    color: 'licoriceMousse',
    size: 'large',
    icon: Truck,
    title: t`No Assets Enabled`,
    children: t`The customer contact accepted the access request. They have not enabled any assets yet.`,
  },
  [CUSTOMER_STATUS.REQUEST_DENIED]: {
    intent: 'help',
    size: 'large',
    title: t`Send Another Request?`,
    children: t`The customer contact denied the access request. Do you want to send another request?`,
  },
};

function StatusMessageCard({ assetGrouping }) {
  const messagePropsForStatus = useMemo(
    () => customerStatusMessageProps[assetGrouping.status],
    [assetGrouping.status],
  );

  return assetGrouping.customerType === 'DEPOT' && messagePropsForStatus ? (
    <Grid.Row paddingY={1}>
      <Grid.Column>
        <Paper id="status-message">
          <Flex justifyContent="center" padding={4}>
            <Message {...messagePropsForStatus} />
          </Flex>
        </Paper>
      </Grid.Column>
    </Grid.Row>
  ) : null;
}

StatusMessageCard.propTypes = {
  assetGrouping: PropTypes.shape({
    id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    customerType: PropTypes.string.isRequired,
  }).isRequired,
};

export default StatusMessageCard;
