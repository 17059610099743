import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import { P } from '@decisiv/ui-components/lib/components/Typography';

export default function AuditTimestamp({ audit }) {
  const dateString = audit.createdAt || audit.changedAt || audit.performedAt;
  const date = moment.tz(dateString || moment(), moment.tz.guess());

  return (
    <P shade={1} size="small">
      {date.format('D MMM YYYY [•] hh:mm A')}
    </P>
  );
}

AuditTimestamp.propTypes = {
  audit: PropTypes.shape({
    createdAt: PropTypes.string,
    changedAt: PropTypes.string,
    performedAt: PropTypes.string,
  }).isRequired,
};
