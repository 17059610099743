import React from 'react';
import isEmpty from 'lodash/isEmpty';

import { isValidRoleOrRoles } from './utils';
import { RBACContext } from './context';

/**
 * Evaluates user authorization based on provided allowed _OR_ excluded roles.
 *
 * The returned object contains a single key, `isAuthorized`. Possible values:
 * - `true`: The user's role is allowed or _NOT_ excluded based on the options.
 * - `false`: The user's role is _NOT_ allowed or is excluded based on the options.
 * -  Error: The user's role can not be validated based on the options provided
 *    or user's role. The descriptive message may help you narrow down the source.
 */
export default function useRBAC({ allow, exclude }) {
  const { role } = React.useContext(RBACContext);

  const isAuthorized = React.useMemo(() => {
    if (!isEmpty(allow) && !isEmpty(exclude)) {
      return new Error(`[useRBAC] Can not use both \`allow\` and \`exclude\`.`);
    }

    if (!isValidRoleOrRoles(role)) {
      return new Error(`[useRBAC] User's role is not a valid role.`);
    }

    if (allow) {
      if (!isValidRoleOrRoles(allow)) {
        return new Error(
          '[useRBAC] `allow` is not a valid role or array of roles.',
        );
      }

      if (Array.isArray(allow)) {
        return Boolean(role && allow.includes(role));
      }

      return allow === role;
    }

    if (exclude) {
      if (!isValidRoleOrRoles(exclude)) {
        return new Error(
          '[useRBAC] `exclude` is not a valid role or array of roles.',
        );
      }

      if (Array.isArray(exclude)) {
        return !(role && exclude.includes(role));
      }

      return exclude !== role;
    }

    return false;
  }, [allow, exclude, role]);

  return React.useMemo(() => ({ isAuthorized }), [isAuthorized]);
}
