import { loader } from 'graphql.macro';
import isEmpty from 'lodash/isEmpty';
import * as React from 'react';

import { useMutation } from '@apollo/client';

import MinusCircle from '@decisiv/iconix/lib/components/MinusCircle';

import Button from '@decisiv/ui-components/lib/components/Button';
import Modal from '@decisiv/ui-components/lib/components/Modal';
import { P } from '@decisiv/ui-components/lib/components/Typography';

import { t, Trans } from '@lingui/macro';

import { buildPropTypes } from 'utils/graphqlPropTypes';
import useSentinelTeam from 'hooks/useSentinelTeam';

const removeAccessMutation = loader('./removeAccess.graphql');

function RemoveAccessButton({ customer }) {
  const { name: sentinelTeamName } = useSentinelTeam();

  const [modalVisible, setModalVisible] = React.useState(false);

  const toggleModalVisible = React.useCallback(() => {
    setModalVisible((v) => !v);
  }, [setModalVisible]);

  const [removeAccess, { data, loading }] = useMutation(removeAccessMutation);

  const confirmed = React.useMemo(
    () => loading || !isEmpty(data),
    [data, loading],
  );

  const customerName = customer.name;
  return (
    <>
      <Button
        icon={MinusCircle}
        intent="danger"
        kind="secondary"
        onClick={toggleModalVisible}
        text={t`Remove Access`}
        variant="inverted"
      />
      <Modal
        actions={
          confirmed
            ? undefined
            : [
                {
                  intent: 'danger',
                  onClick: removeAccess,
                  text: t`Remove Access`,
                  'aria-label': t`Remove Access`,
                },
                { onClick: toggleModalVisible, text: t`Cancel` },
              ]
        }
        id="remove-access-confirmation-modal"
        intent="danger"
        title={t`Remove Access?`}
        visible={modalVisible}
        onClose={confirmed ? undefined : toggleModalVisible}
      >
        <P shade={1}>
          <Trans>
            You are about to remove access to the{' '}
            <P as="span" weight="semibold">
              {customerName}
            </P>{' '}
            asset list for the{' '}
            <P as="span" weight="semibold">
              {sentinelTeamName}
            </P>{' '}
            team. Any appointments for this customer will be canceled, and{' '}
            <P as="span" weight="semibold">
              {sentinelTeamName}
            </P>{' '}
            team members will no longer be able to see their assets or manage
            alerts for it.
          </Trans>
        </P>
      </Modal>
    </>
  );
}

RemoveAccessButton.propTypes = {
  customer: buildPropTypes('customer').isRequired,
};

export default RemoveAccessButton;
