/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import wrapValidator from 'utils/wrapValidator';

import useRBAC from './useRBAC';
import { isValidRoleOrRoles } from './utils';

function RBAC({ allow, children, exclude, fallback = null }) {
  const { isAuthorized } = useRBAC({ allow, exclude });

  if (isAuthorized instanceof Error) {
    return null;
  }

  return isAuthorized ? children : fallback;
}

function invalidPropCombinationMessage(componentName) {
  return `Invalid prop combination supplied to \`${componentName}\`. Can not use both \`allow\` and \`exclude\` props.`;
}

function invalidRoleMessage(props, propName, componentName) {
  return `Invalid prop \`${propName}\` with value '${props[propName]}' supplied to \`${componentName}\`. Expected single ROLE or array of ROLES.`;
}

RBAC.propTypes = {
  allow: wrapValidator((props, propName, componentName) => {
    /**
     * The logic only searches for a match in _either_ the `allow` or `exclude` props. It may get
     * too confusing, and doesn't really serve a benefit, to attempt matching logic on both. This
     * validation is only applied to one of the two props to prevent double reporting of the error.
     */
    if (props.allow && props.exclude) {
      return new Error(invalidPropCombinationMessage(componentName));
    }

    if (props[propName] && !isValidRoleOrRoles(props[propName])) {
      return new Error(invalidRoleMessage(props, propName, componentName));
    }

    return null;
  }),
  children: PropTypes.element.isRequired,
  exclude: wrapValidator((props, propName, componentName) => {
    if (props[propName] && !isValidRoleOrRoles(props[propName])) {
      return new Error(invalidRoleMessage(props, propName, componentName));
    }

    return null;
  }),
  fallback: PropTypes.element,
};

export default RBAC;
