import useStorage from 'hooks/useStorage';

const defaultQueryParseOpts = {
  persistOnDB: false,
  persistOnUrl: true,
  typeCoercion: true,
};

interface UseQueryParamsOptions {
  persistOnDB?: boolean;
  persistOnUrl?: boolean;
  typeCoercion?: boolean;
}

export default function useQueryParams<T>(
  key: string,
  defaultValue: T,
  options: UseQueryParamsOptions = defaultQueryParseOpts,
) {
  const mergedOpts = {
    ...defaultQueryParseOpts,
    ...(options || {}),
  };
  return useStorage(key, defaultValue, mergedOpts);
}
