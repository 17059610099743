import { useMemo } from 'react';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';

import { buildDateRange } from 'utils/Appointments';

const assetAlertsQuery = loader('./assetAlerts.graphql');

export default function useAlertsQuery(
  {
    alertTypes,
    alertsText,
    scheduleMaintenanceStatus,
    faultStatus,
    customAlertStatus,
    sort,
    sortDirection,
    groupBy,
    assetTypes,
    makes,
    models,
    years,
    costCenters,
    workInProgress,
    customAlerts,
    customerIds,
    platformAccountIds,
    groupedAssetGroupingsIds,
    enabled,
    currentPage,
    assignees,
    platformCaseFleetStatus,
    platformCaseRepairStatus,
    serviceEventStatus,
    serviceRequestStatus,
    text,
    serviceEventCreatedBetween,
    serviceEventUpdatedBetween,
    serviceRequestCreatedBetween,
    serviceRequestUpdatedBetween,
    platformCaseCreatedBetween,
    platformCaseUpdatedBetween,
    serviceProviderUuids,
    serviceProviderCityIds,
  },
  opts = {},
) {
  const formatSort = useMemo(() => {
    if (!sort || sort === 'ALL') {
      return undefined;
    }

    const DIRECTION_MAPPING = {
      ascending: 'ASC',
      descending: 'DESC',
      unsorted: undefined,
    };

    return {
      column: sort,
      direction: DIRECTION_MAPPING[sortDirection] || 'ASC',
    };
  }, [sort, sortDirection]);

  const {
    data: {
      assets: {
        collection: alertsData = [],
        pagination = {
          page: 1,
          perPage: 1,
          totalEntries: 0,
          totalPages: 1,
        },
        exportUrl,
      } = {},
    } = {},
    ...rest
  } = useQuery(assetAlertsQuery, {
    fetchPolicy: 'cache-and-network',
    variables: {
      assignees,
      alerts: alertTypes,
      alertsText,
      sort: formatSort,
      groupBy: groupBy === 'ALL' ? undefined : groupBy,
      assetTypes,
      makes,
      models,
      years,
      costCenters,
      customAlerts,
      platformAccountIds,
      groupedAssetGroupingsIds,
      wip: workInProgress,
      customerIds,
      enabled,
      currentPage,
      platformCaseFleetStatus,
      platformCaseRepairStatus,
      serviceEventStatus,
      serviceRequestStatus,
      text,
      scheduleMaintenanceStatus,
      faultStatus,
      customAlertStatus,
      serviceEventCreatedBetween: buildDateRange(serviceEventCreatedBetween),
      serviceEventUpdatedBetween: buildDateRange(serviceEventUpdatedBetween),
      serviceRequestCreatedBetween: buildDateRange(
        serviceRequestCreatedBetween,
      ),
      serviceRequestUpdatedBetween: buildDateRange(
        serviceRequestUpdatedBetween,
      ),
      platformCaseCreatedBetween: buildDateRange(platformCaseCreatedBetween),
      platformCaseUpdatedBetween: buildDateRange(platformCaseUpdatedBetween),
      serviceProviderUuids,
      serviceProviderCityIds,
    },
    ...opts,
  });

  return {
    alertsData,
    pagination,
    exportUrl,
    queryResult: rest,
  };
}
