import React from 'react';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';

import Flex from '@decisiv/ui-components/lib/components/Flex';
import Grid from '@decisiv/ui-components/lib/components/Grid';
import { H4 } from '@decisiv/ui-components/lib/components/Typography';

import loadingGif from './loading.gif';

export default function Loading({ children, inline, imgDim }) {
  return (
    <Grid.Container paddingY={inline ? 0 : 4}>
      <Grid.Row alignment={['center', 'middle']}>
        <Grid.Column span="12">
          <Flex flexDirection={inline ? 'row' : 'column'} alignItems="center">
            <img
              src={loadingGif}
              alt={t`loading`}
              width={imgDim}
              height={imgDim}
            />
            <H4>{children}</H4>
          </Flex>
        </Grid.Column>
      </Grid.Row>
    </Grid.Container>
  );
}

Loading.propTypes = {
  children: PropTypes.node,
  inline: PropTypes.bool,
  imgDim: PropTypes.number,
};

Loading.defaultProps = {
  children: t`Loading...`,
  inline: false,
  imgDim: 40,
};
