import React from 'react';
import PropTypes from 'prop-types';

import PlainTextCell from 'components/PlainTextCell';

export default function IdCell({ rowData: { id, scheduledForDeletionAt } }) {
  return (
    <PlainTextCell
      color={scheduledForDeletionAt ? 'alaskanHusky' : undefined}
      topLine={id}
    />
  );
}

IdCell.propTypes = {
  rowData: PropTypes.shape({
    id: PropTypes.string,
    scheduledForDeletionAt: PropTypes.string,
  }).isRequired,
};
