import React, { useCallback, useMemo, useRef } from 'react';
import { useMutation } from '@apollo/client';
import toColorString from 'polished/lib/color/toColorString';
import { loader } from 'graphql.macro';
import { Trans, t } from '@lingui/macro';
import { format, parseISO } from 'date-fns';
import PropTypes from 'prop-types';

import colors from '@decisiv/design-tokens/lib/color';

import ExclamationTriangle from '@decisiv/iconix/lib/components/ExclamationTriangle';
import Revert from '@decisiv/iconix/lib/components/Revert';

import Flex from '@decisiv/ui-components/lib/components/Flex';
import Button from '@decisiv/ui-components/lib/components/Button';
import Tooltip from '@decisiv/ui-components/lib/components/Tooltip';
import { P } from '@decisiv/ui-components/lib/components/Typography';
import { useNotifications } from '@decisiv/ui-components/lib/components/Notifications';

import useSentinelTeam from 'hooks/useSentinelTeam';

import LogoImage from 'components/LogoImage';

import { useTeams } from 'pages/Teams/contexts/TeamsContext';

const restoreFromDeletionMutation = loader(
  './restoreTeamFromDeletionMutation.graphql',
);

const formatDate = (date) => {
  if (!date) return undefined;

  return format(parseISO(date), 'h:mm aa');
};

export default function NameCell({
  rowData: { name, applicationName, logo, id, scheduledForDeletionAt },
}) {
  const buttonRef = useRef(null);
  const { notify } = useNotifications();

  const { environment } = useTeams();

  const [restoreFromDeletion, { loading }] = useMutation(
    restoreFromDeletionMutation,
    {
      onError: (err) =>
        notify(
          {
            duration: 5000,
            onClose: () => undefined,
            intent: 'danger',
            title: t`Sentinel Team Not Restored`,
          },
          err.message,
        ),
    },
  );

  const timeToDelete = useMemo(
    () => formatDate(scheduledForDeletionAt),
    [scheduledForDeletionAt],
  );

  const showRecoverTeamButton =
    !!environment && environment !== 'production' && scheduledForDeletionAt;

  const deleteWarningString = t`Will be deleted at ${timeToDelete}.`;

  const { setSentinelTeam } = useSentinelTeam();

  const handleOnRestoreTeam = useCallback(() => {
    restoreFromDeletion({ variables: { id } });
  }, [restoreFromDeletion, id]);

  return (
    <Flex alignItems="center">
      <Flex marginRight={1}>
        <LogoImage src={logo?.url} />
      </Flex>
      <Flex flexDirection="column">
        <Button
          css={`
            height: inherit;
            text-transform: inherit;
            justify-content: flex-start;
            text-decoration: underline;
          `}
          onClick={() => setSentinelTeam({ variables: { id } })}
          text={name}
          disabled={scheduledForDeletionAt}
          variant="ghost"
        />
        <P as="span" size="small" shade={1}>
          {applicationName}
        </P>
        {!!showRecoverTeamButton && (
          <Flex>
            <Flex>
              <ExclamationTriangle
                color={toColorString(colors.status.danger.medium)}
              />
            </Flex>
            <P size="small" marginX={0.5} color="alaskanHusky">
              {deleteWarningString}
            </P>
            <Tooltip placement="right" zIndex={5} target={buttonRef}>
              <Trans>Recover Team</Trans>
            </Tooltip>
            <Button
              ref={buttonRef}
              icon={Revert}
              variant="ghost"
              disabled={loading}
              onClick={handleOnRestoreTeam}
            />
          </Flex>
        )}
      </Flex>
    </Flex>
  );
}

NameCell.propTypes = {
  rowData: PropTypes.shape({
    name: PropTypes.string.isRequired,
    applicationName: PropTypes.string.isRequired,
    logo: PropTypes.shape({
      url: PropTypes.string,
    }).isRequired,
    id: PropTypes.string.isRequired,
    scheduledForDeletionAt: PropTypes.string,
  }).isRequired,
};
