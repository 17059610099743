import React, { useState, useCallback } from 'react';
import { Trans, t } from '@lingui/macro';
import rem from 'polished/lib/helpers/rem';

import spacing from '@decisiv/design-tokens/lib/spacing';

import Flex from '@decisiv/ui-components/lib/components/Flex';
import Tab from '@decisiv/ui-components/lib/components/Tabs/Tab';
import Tabs from '@decisiv/ui-components/lib/components/Tabs';
import { H3 } from '@decisiv/ui-components/lib/components/Typography';

import Card from 'components/DashboardCard';

import AllActivityTab from './Tabs/AllActivity';
import AssignedActivity from './Tabs/AssignedActivity';
import MyActivityTab from './Tabs/MyActivity';
import WatchingActivityTab from './Tabs/WatchingActivity';

function RecentActivityCard() {
  const [disableTabs, setDisableTabs] = useState(false);

  const handleHideTabs = useCallback(() => {
    setDisableTabs(true);
  }, []);

  return (
    <Card
      css={`
        max-height: ${rem(spacing.base * 78)};
      `}
      marginY={0}
    >
      <Flex
        css={`
          height: 100%;
        `}
        flexDirection="column"
      >
        <H3 marginBottom={2}>
          <Trans>Recent Activity</Trans>
        </H3>
        <Tabs
          defaultSelectedId="all-activity"
          renderHiddenTabsContent={false}
          css={`
            overflow: hidden;
            width: 100%;
            height: 100%;

            & > [role='tabpanel'] {
              display: flex;
              flex-direction: column;
              overflow: hidden;
              height: 100%;

              &[hidden] {
                display: none;
              }
            }
          `}
        >
          <Tab id="all-activity" title={t`All`}>
            <AllActivityTab onNoData={handleHideTabs} />
          </Tab>
          <Tab
            id="watching-activity"
            title={t`Watching`}
            disabled={disableTabs}
          >
            <WatchingActivityTab />
          </Tab>
          <Tab
            id="assigned-activity"
            title={t`Assigned to Me`}
            disabled={disableTabs}
          >
            <AssignedActivity />
          </Tab>
          <Tab id="my-activity" title={t`My Activity`} disabled={disableTabs}>
            <MyActivityTab />
          </Tab>
        </Tabs>
      </Flex>
    </Card>
  );
}

export default React.memo(RecentActivityCard);
