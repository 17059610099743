import { QueryHookOptions, useQuery } from '@apollo/client';

import teamIdValidQuery from './teamIdValid.graphql';

export default function useTeamIdValidQuery(
  options?: QueryHookOptions<TeamIdValidQuery, TeamIdValidQueryVariables>,
) {
  return useQuery<TeamIdValidQuery, TeamIdValidQueryVariables>(
    teamIdValidQuery,
    options,
  );
}
