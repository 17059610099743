import { QueryHookOptions, useQuery } from '@apollo/client';

import apiUserValidQuery from './apiUserValid.graphql';

export default function useApiUserValidQuery(
  options?: QueryHookOptions<ApiUserValidQuery, ApiUserValidQueryVariables>,
) {
  return useQuery<ApiUserValidQuery, ApiUserValidQueryVariables>(
    apiUserValidQuery,
    options,
  );
}
