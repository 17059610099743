import isEmpty from 'lodash/isEmpty';
import React from 'react';

import UsersGroup from '@decisiv/iconix/lib/components/UsersGroup';

import Flex from '@decisiv/ui-components/lib/components/Flex';
import Grid from '@decisiv/ui-components/lib/components/Grid';
import { H1 } from '@decisiv/ui-components/lib/components/Typography';

import { t } from '@lingui/macro';

import EmptyResults from 'components/EmptyResults';
import Loading from 'components/Loading';
import PageHead from 'components/PageHead';
import Paper from 'components/Paper';

import { useTeams } from './contexts/TeamsContext';
import TeamsList from './TeamsList';
import CreateTeamModal from './CreateTeamModal';
import TeamModalProvider from './CreateTeamModal/contexts/TeamModalContext';

export default function TeamsPage() {
  const { loading, allSentinelTeams } = useTeams();

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <PageHead titleParts={[t`Teams`, t`Decisiv Super Admin`]} />
      <Paper
        css={`
          display: flex;
          flex-direction: column;
          min-height: 100%;
          min-width: 100%;
          width: fit-content;
        `}
        padding={0}
      >
        <Grid.Row margin={0}>
          <Grid.Column paddingX={2} paddingY={1}>
            <Flex alignItems="center" justifyContent="space-between">
              <H1 weight="regular">{t`Teams`}</H1>
              <TeamModalProvider>
                <CreateTeamModal />
              </TeamModalProvider>
            </Flex>
          </Grid.Column>
        </Grid.Row>
        <Grid.Container
          css={`
            flex: 1;
          `}
          padding={0}
        >
          <Grid.Row
            css={`
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
            `}
            margin={0}
          >
            <Grid.Column padding={0}>
              {isEmpty(allSentinelTeams) ? (
                <EmptyResults
                  color="licoriceMousse"
                  icon={UsersGroup}
                  subtitle={t`Click CREATE TEAM to start creating a team.`}
                  title={t`No Teams Yet`}
                />
              ) : (
                <TeamsList />
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid.Container>
      </Paper>
    </>
  );
}
