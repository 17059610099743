import React from 'react';
import PropTypes from 'prop-types';

import { Trans } from '@lingui/macro';

import Flex from '@decisiv/ui-components/lib/components/Flex';
import Link from '@decisiv/ui-components/lib/components/Link';
import Badge from '@decisiv/ui-components/lib/components/Badge';
import { P } from '@decisiv/ui-components/lib/components/Typography';

import ROUTE_NAMES from 'setup/Routing/routeNames';

import {
  badgeColorForAppointment,
  badgeIconForAppointment,
} from 'utils/Appointments';

import ServiceEventAction from './ServiceEventAction';

export default function Status({ value }) {
  const { appointment: tmpAppointment, cancelReasonDetails } = value;
  const { beforeId = null, afterId = null, status } = tmpAppointment;

  // needed different ids in the graphql query, so use whatever we get as id after
  const id = beforeId || afterId;
  const appointment = { ...tmpAppointment, id };

  return (
    <Flex>
      <Badge
        variant="square"
        aria-label={status}
        color={badgeColorForAppointment(appointment)}
        icon={badgeIconForAppointment(appointment)}
      />
      <Flex flexDirection="column" marginLeft={1} alignSelf="center">
        <Link to={ROUTE_NAMES.forServiceEvent(appointment)} text={id} />
        {status === 'COMPLETED' && (
          <P as="span" size="small" shade={1}>
            <Trans>Completed</Trans>
          </P>
        )}
        {['REQUESTED', 'CANCELED'].includes(status) && (
          <ServiceEventAction appointment={appointment} />
        )}
        {cancelReasonDetails && (
          <P as="span" size="small" shade={1}>
            {cancelReasonDetails}
          </P>
        )}
      </Flex>
    </Flex>
  );
}

Status.propTypes = {
  value: PropTypes.shape({
    appointment: PropTypes.shape({
      beforeId: PropTypes.string,
      afterId: PropTypes.string,
      status: PropTypes.string.isRequired,
      platformCase: PropTypes.shape({
        id: PropTypes.string,
        url: PropTypes.string,
        status: PropTypes.string,
        referenceNumber: PropTypes.string,
      }),
      platformServiceRequest: PropTypes.shape({
        id: PropTypes.string,
        url: PropTypes.string,
        status: PropTypes.string,
        referenceNumber: PropTypes.string,
      }),
    }).isRequired,
    cancelReasonDetails: PropTypes.string,
  }).isRequired,
};
