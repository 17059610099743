import * as React from 'react';
import PropTypes from 'prop-types';

import { P } from '@decisiv/ui-components/lib/components/Typography';

import { t } from '@lingui/macro';

import { formatCityAndState } from 'utils/Customer';

import AssetGroupingData from './AssetGroupingData';

function AssetGroupingLocation({ assetGrouping }) {
  return (
    <AssetGroupingData
      data={
        <P as="span" truncate>
          {formatCityAndState(assetGrouping?.city, assetGrouping?.state)}
        </P>
      }
      titleText={t`Location`}
    />
  );
}

AssetGroupingLocation.propTypes = {
  assetGrouping: PropTypes.shape({
    id: PropTypes.string.isRequired,
    city: PropTypes.string,
    state: PropTypes.string,
  }),
};

AssetGroupingLocation.defaultProps = {
  assetGrouping: undefined,
};

export default AssetGroupingLocation;
