import React from 'react';
import styled from 'styled-components';
import toColorString from 'polished/lib/color/toColorString';

import color from '@decisiv/design-tokens/lib/color';

import Flex from '@decisiv/ui-components/lib/components/Flex';

import WelcomePanel from './WelcomePanel';
import BusinessOverview from './BusinessOverview';
import Activity from './Activity';

const ContentWrapper = styled(Flex)`
  background-color: ${toColorString(color.base.fullMoon)};
`;

function DashboardPage() {
  return (
    <ContentWrapper flexDirection="column">
      <WelcomePanel />
      <BusinessOverview />
      <Activity />
    </ContentWrapper>
  );
}

export default DashboardPage;
