import { MutationHookOptions, useMutation } from '@apollo/client';

import createSentinelTeamMutation from './createSentinelTeam.graphql';

export default function useCreateSentinelTeamMutation(
  options?: MutationHookOptions<
    CreateSentinelTeamMutation,
    CreateSentinelTeamMutationVariables
  >,
) {
  return useMutation(createSentinelTeamMutation, {
    refetchQueries: ['SentinelTeams'],
    ...options,
  });
}
