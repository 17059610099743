import React from 'react';
import styled from 'styled-components';
import toColorString from 'polished/lib/color/toColorString';
import { Trans } from '@lingui/macro';

import { H3 } from '@decisiv/ui-components/lib/components/Typography';
import Flex from '@decisiv/ui-components/lib/components/Flex';
import color from '@decisiv/design-tokens/lib/color';

import { modifierToColors } from 'features/amazonConnect';

const DisabledSoftPhoneContainer = styled(Flex).attrs({
  flexGrow: 1,
  alignItems: 'center',
  justifyContent: 'center',
})`
  background-color: ${modifierToColors('offline').dark};
  color: ${toColorString(color.base.snowWhite)};
`;

export default function DisabledSoftPhone() {
  return (
    <DisabledSoftPhoneContainer>
      <H3>
        <Trans>Phone Disabled</Trans>
      </H3>
    </DisabledSoftPhoneContainer>
  );
}
