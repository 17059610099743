import { t } from '@lingui/macro';

import useSentinelTeam from 'hooks/useSentinelTeam';
import { useMemo } from 'react';
import useInvitation from './useInvitation';
import useAvailableAssetIdentifiers from '../pages/admin/Preferences/Assets/Fields/useAvailableAssetIdentifiers';

export const DEFAULT_ASSET_CONFIGURATION_VALUES = {
  primaryIdentifiers: ['UNIT_NUMBER', 'YEAR_MAKE_MODEL'],
  secondaryIdentifiers: ['SERIAL_NUMBER', 'VIN', 'TYPE'],
  otherIdentifiers: ['CHASSIS_ID'],
  __typename: 'AssetConfiguration',
} satisfies AssetConfiguration;

type AssetMapperValues =
  | keyof Pick<
      Asset,
      | 'chassisId'
      | 'serialNumber'
      | 'unitNumber'
      | 'vin'
      | 'engine'
      | 'assetType'
      | 'fleetInfo'
      | 'costCenter'
    >;

// this maps the identifier with the field in graphql
const ASSET_MAPPER: Record<
  Exclude<AssetIdentifiersEnum, 'FLEET_INFO' | 'COST_CENTER'>,
  AssetMapperValues | 'yearMakeModel'
> = {
  CHASSIS_ID: 'chassisId',
  SERIAL_NUMBER: 'serialNumber',
  UNIT_NUMBER: 'unitNumber',
  VIN: 'vin',
  TYPE: 'assetType',
  YEAR_MAKE_MODEL: 'yearMakeModel', // This is a placeholder to track the field.
  // FLEET_INFO: 'assetType',
};

const FLEET_ASSET_MAPPER: Record<
  AssetIdentifiersEnum,
  AssetMapperValues | 'yearMakeModel'
> = {
  ...ASSET_MAPPER,
  FLEET_INFO: 'fleetInfo',
  COST_CENTER: 'costCenter',
};

export const IDENTIFIER_LABEL = {
  YEAR_MAKE_MODEL: t`YMM`,
  CHASSIS_ID: t`Chassis ID`,
  SERIAL_NUMBER: t`Serial`,
  UNIT_NUMBER: t`Unit`,
  VIN: t`VIN`,
  // ENGINE_MODEL: t`Engine`,
  TYPE: t`Type`,
  FLEET_INFO: t`Fleet Info`,
  COST_CENTER: t`Cost Center`,
};

const objectKeys = <Obj extends object>(obj: Obj): (keyof Obj)[] => {
  return Object.keys(obj) as (keyof Obj)[];
};

type AssetConfigurationAsset = Pick<
  Asset,
  | 'chassisId'
  | 'serialNumber'
  | 'unitNumber'
  | 'vin'
  | 'engine'
  | 'assetType'
  | 'fleetInfo'
  | 'year'
  | 'make'
  | 'model'
  | 'costCenter'
>;

type Args = {
  asset: AssetConfigurationAsset;
  assetConfiguration?: AssetConfiguration;
};

export default function useAssetConfiguration({
  asset,
  assetConfiguration,
}: Args) {
  const { isFleet, assetConfiguration: currentTeamAssetConfiguration } =
    useSentinelTeam();

  const { isInvitation } = useInvitation();

  const assetConfigurationToUse: AssetConfiguration = useMemo(() => {
    if (isInvitation) return DEFAULT_ASSET_CONFIGURATION_VALUES;

    return assetConfiguration ?? currentTeamAssetConfiguration;
  }, [isInvitation, assetConfiguration, currentTeamAssetConfiguration]);

  const {
    primaryIdentifiers = [],
    secondaryIdentifiers = [],
    otherIdentifiers = [],
  } = assetConfigurationToUse ?? {};

  const MAPPER = isFleet ? FLEET_ASSET_MAPPER : ASSET_MAPPER;

  const identifierMapper =
    (innerAsset: AssetConfigurationAsset) => (id: AssetIdentifiersEnum) => {
      const { year, make, model } = innerAsset;

      const label = IDENTIFIER_LABEL[id];

      const field = (MAPPER as typeof FLEET_ASSET_MAPPER)[id];

      if (field === 'yearMakeModel') {
        return { id, label, text: `${year} ${make} ${model}` };
      }

      const text = innerAsset[field] || '—';

      return { id, label, text };
    };

  const { data: { assetConfigurationValues = {} } = {} } =
    useAvailableAssetIdentifiers();
  const costCenterAvailable = (assetConfigurationValues.other || []).includes(
    'COST_CENTER',
  );

  const primary = primaryIdentifiers.map(identifierMapper(asset));
  const secondary = secondaryIdentifiers.map(identifierMapper(asset));
  const others = otherIdentifiers.map(identifierMapper(asset));

  const unselected = objectKeys(MAPPER)
    .filter(
      (key) =>
        !primaryIdentifiers.includes(key) &&
        !secondaryIdentifiers.includes(key) &&
        !otherIdentifiers.includes(key),
    )
    .filter(
      (key) =>
        key !== 'COST_CENTER' || (costCenterAvailable && key === 'COST_CENTER'),
    )
    .map(identifierMapper(asset));

  return { primary, secondary, others, unselected };
}
