import styled, { css } from 'styled-components';

import rem from 'polished/lib/helpers/rem';
import toColorString from 'polished/lib/color/toColorString';

import spacing from '@decisiv/design-tokens/lib/spacing';
import color from '@decisiv/design-tokens/lib/color';

import Button from '@decisiv/ui-components/lib/components/Button';
import { P } from '@decisiv/ui-components/lib/components/Typography';

export const TextClamp = styled(P)`
  word-break: break-all;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${({ clamp, linesToClamp }) =>
    clamp ? linesToClamp : 'initial'};
`;

export const ExpandButton = styled(Button)`
  margin-left: auto;
  align-self: flex-start;
  margin-top: ${rem(spacing.base * 1.1)};

  ${({ direction }) =>
    direction === 'column' &&
    css`
      align-self: flex-start;
      margin-top: ${rem(spacing.base * 0.35)};
    `}
`;

export const Separator = styled.div`
  margin-top: ${rem(spacing.base * 0.3)};
  margin-bottom: ${rem(spacing.base * 0.2)};

  height: ${rem(spacing.base * 0.1)};
  width: 100%;

  background-color: ${toColorString(color.opacity.charcoal15)};
`;
