import React, { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import Loading from 'components/Loading';

import Teams from 'pages/Teams';
import DashboardPage from 'pages/Dashboard';

import InvitationsRequest from 'pages/InvitationsRequest';

import { useCurrentUserContext } from 'utils/User';
import { CurrentInvitationContext } from 'utils/Invitation';

import ROUTE_NAMES from 'setup/Routing/routeNames';
import useSentinelTeam from 'hooks/useSentinelTeam';
import SentinelRoute from '../SentinelRoute';
import SuperUserRoute from '../SuperUserRoute';
import InvitationRoute from '../InvitationRoute';

export default function RootPathRoute() {
  const { authenticationKey } = useContext(CurrentInvitationContext);
  const { isSuperUser, id: userId } = useCurrentUserContext();
  const { hasSentinelTeam } = useSentinelTeam();

  const location = useLocation();

  if (!userId && !authenticationKey) return <Loading />;

  if (authenticationKey) {
    return (
      <InvitationRoute>
        <InvitationsRequest />
      </InvitationRoute>
    );
  }

  if (isSuperUser && !hasSentinelTeam) {
    return (
      <SuperUserRoute>
        <Teams />
      </SuperUserRoute>
    );
  }

  if (hasSentinelTeam) {
    return (
      <SentinelRoute>
        <DashboardPage />
      </SentinelRoute>
    );
  }

  return (
    <Navigate
      to={ROUTE_NAMES.access_denied}
      state={{ from: location }}
      replace
    />
  );
}
