import styled from 'styled-components';

export const PageContainer = styled.div`
  display: flex;
  height: 100vh;
`;

export const NavColumnContainer = styled.div`
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const LeftNavContainer = styled.div`
  flex: 1;
`;

export const PrimaryContentContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  position: relative;
`;

export const TopNavContainer = styled.div`
  width: 100%;
`;
