import React from 'react';

import useAssetConfiguration from 'hooks/useAssetConfiguration';
import rem from 'polished/lib/helpers/rem';

import toColorString from 'polished/lib/color/toColorString';

import styled from 'styled-components';
import AssetInfoLine from 'components/AssetListLine';
import PropTypes from 'prop-types';

import Flex from '@decisiv/ui-components/lib/components/Flex';
import color from '@decisiv/design-tokens/lib/color';
import Badge from '@decisiv/ui-components/lib/components/Badge';
import Truck from '@decisiv/iconix/lib/components/Truck';
import Grid from '@decisiv/ui-components/lib/components/Grid';
import spacing from '@decisiv/design-tokens/lib/spacing';

const BoxAsset = styled(Grid.Container)`
  background-color: ${toColorString(color.base.fullMoon)};
  height: ${rem(spacing.base * 5.3)};
  border-radius: ${rem(spacing.base * 0.2)};
  padding: ${rem(spacing.base)};
  gap: ${rem(spacing.base)};
  flex: none;
  order: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
`;

export default function AssetInformation({ item: asset }) {
  const { primary } = useAssetConfiguration({
    asset,
  });
  return (
    <Flex flexDirection="column">
      <Flex alignItems="center" marginTop={0.05}>
        <BoxAsset>
          <Badge variant="square" icon={Truck} color="licoriceMousse" />
          <AssetInfoLine assetConfig={primary} lineColor="charcoal" />
        </BoxAsset>
      </Flex>
    </Flex>
  );
}

AssetInformation.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    year: PropTypes.string,
    make: PropTypes.string,
    model: PropTypes.string,
    unitNumber: PropTypes.string,
    vin: PropTypes.string,
    chassisId: PropTypes.string,
    serialNumber: PropTypes.string,
    assetType: PropTypes.string,
  }).isRequired,
};
