import { useQuery } from '@apollo/client';

import { useCurrentUserContext } from 'utils/User';
import { FEATURE_FLAGS } from './constants';

import validFeatureFlagQuery from './validFeatureFlag.graphql';

export default function useFeatureFlag(
  flag: ObjectValues<typeof FEATURE_FLAGS>,
) {
  const { isLoggedIn } = useCurrentUserContext();

  const { data: { validFeatureFlag: { valid = false } = {} } = {} } = useQuery<
    ValidFeatureFlagQuery,
    ValidFeatureFlagQueryVariables
  >(validFeatureFlagQuery, {
    skip: !isLoggedIn,
    fetchPolicy: 'cache-and-network',
    variables: { flag },
  });

  return valid;
}

export { FEATURE_FLAGS };
