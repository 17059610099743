import styled from 'styled-components';
import { t, Trans } from '@lingui/macro';

import Grid from '@decisiv/ui-components/lib/components/Grid';
import LinkToCase from 'components/LinkToCase';
import Loading from '@decisiv/ui-components/lib/components/Loading';
import { P } from '@decisiv/ui-components/lib/components/Typography';

import useAssetConfiguration from 'hooks/useAssetConfiguration';
import useSentinelTeam from 'hooks/useSentinelTeam';

import LinkToAppointment from './LinkToAppointment';
import useBuildUrl from './hooks/useBuildUrl';

const { Container, Row, Column } = Grid;

const InlineLinkToCase = styled(LinkToCase)`
  display: inline-flex;
`;

function serviceRequestLabel(
  source: ServiceRequestNotificationSource,
  linkToAppointment: React.ReactElement,
) {
  switch (source.status) {
    case 'PENDING':
      return <Trans>Sent request for Service Event {linkToAppointment}</Trans>;
    case 'ACCEPTED':
      return (
        <Trans>Accepted request for Service Event {linkToAppointment}</Trans>
      );
    case 'DECLINED':
      return (
        <Trans>Declined request for Service Event {linkToAppointment}</Trans>
      );
    case 'CANCELED':
      return (
        <Trans>Canceled request for Service Event {linkToAppointment}</Trans>
      );
    default:
      return null;
  }
}

interface ServiceRequestInfoProps {
  source: ServiceRequestNotificationSource;
  notification: TNotification;
  notificationColor: 'charcoal' | 'alaskanHusky';
  handleOpenModal: () => void;
  setModalData: () => void;
}

const LinkToServiceRequest = ({
  source,
}: Pick<ServiceRequestInfoProps, 'source'>) => {
  const { loading, data } = useBuildUrl({
    variables: { model: 'SERVICE_REQUEST', modelId: source.id.toString() },
  });

  if (loading) return <Loading size="small" />;

  return (
    <InlineLinkToCase
      showIcon
      aria-label={t`Link to Service Request`}
      to={data?.buildUrl}
      text={String(source?.referenceNumber)}
    />
  );
};

function ServiceRequestInfo({
  source,
  notification,
  notificationColor,
  handleOpenModal,
  setModalData,
}: ServiceRequestInfoProps) {
  const { appointment } = source;
  /* eslint-disable @typescript-eslint/no-non-null-assertion */
  const { asset } = appointment!;

  const {
    sentinelTeam: { assetConfiguration },
  } = notification;
  const {
    primary: [primaryIdentifier],
  } = useAssetConfiguration({ asset, assetConfiguration });
  const assetIdentifier = `${primaryIdentifier.label} ${primaryIdentifier.text}`;

  const assetGrouping = asset?.assetGrouping;

  const customerLocation = [assetGrouping?.city, assetGrouping?.state]
    .filter(Boolean)
    .join(', ');
  const customerNameLocation = `${assetGrouping?.name} (${customerLocation})`;

  const linkToAppointment = (
    <LinkToAppointment
      notification={notification}
      handleOpenModal={handleOpenModal}
      setModalData={setModalData}
    />
  );

  const linkToServiceRequest = <LinkToServiceRequest source={source} />;

  const { isFleet } = useSentinelTeam();

  const srLabel = serviceRequestLabel(source, linkToAppointment);

  return (
    <Container padding={0}>
      {srLabel && (
        <Row data-testid="srlabel">
          <Column>
            <P as="span" color={notificationColor}>
              {srLabel}
            </P>
          </Column>
        </Row>
      )}
      <Row>
        <Column>
          <P as="span" color={notificationColor} size="small">
            <Trans>Asset: </Trans>
          </P>{' '}
          <P as="span" color={notificationColor} size="small">
            <Trans>{assetIdentifier}</Trans>
          </P>
        </Column>
      </Row>
      <Row>
        <Column>
          <P as="span" color={notificationColor} size="small">
            {isFleet ? <Trans>Depot:</Trans> : <Trans>Customer:</Trans>}
          </P>{' '}
          <P as="span" color={notificationColor} size="small">
            <Trans>{customerNameLocation}</Trans>
          </P>
        </Column>
      </Row>
      <Row>
        <Column>
          <P as="span" color={notificationColor} size="small">
            <Trans>Service Request #: {linkToServiceRequest}</Trans>
          </P>
        </Column>
      </Row>
    </Container>
  );
}

export default ServiceRequestInfo;
