export const bffLoginLink = `${import.meta.env.VITE_BFF_API_URL}/login`;
export const bffLogoutLink = `${import.meta.env.VITE_BFF_API_URL}/logout`;
export const bffAPILink = `${import.meta.env.VITE_BFF_API_URL}/graphql`;

export function loginURL(returnURL) {
  return `${bffLoginLink}?redirect_to=${encodeURIComponent(returnURL)}`;
}

export function logoutURL(returnURL = window.location.origin) {
  return `${bffLogoutLink}?service=${encodeURIComponent(returnURL)}`;
}

export function sendToLogin(returnURL = window.location.href) {
  /* istanbul ignore next -- @preserve */
  const href = loginURL(returnURL);
  // eslint-disable-next-line no-console
  console.warn(`redirecting to ${href} for login...`);
  window.location.assign(href);
}
