import React, { useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';

import { t } from '@lingui/macro';

import ChevronDown from '@decisiv/iconix/lib/components/ChevronDown';
import ChevronUp from '@decisiv/iconix/lib/components/ChevronUp';

import useAssetConfiguration from 'hooks/useAssetConfiguration';

import Flex from '@decisiv/ui-components/lib/components/Flex';
import Tag from '@decisiv/ui-components/lib/components/Tag';

import LinkToCase from 'components/LinkToCase';

import { buildPropTypes } from 'utils/graphqlPropTypes';

import AssetInfoLine from 'components/AssetListLine';
import useInvitation from 'hooks/useInvitation';
import ToggleStatusPopover from '../../admin/components/ToggleStatusPopover';

export default function AssetRow({
  asset,
  enableAssets,
  canUpdate,
  handleShowDisableModal,
}) {
  const { id: assetId, enabled, webCaseUrl } = asset;

  const actionRef = useRef();
  const popoverRef = useRef(null);

  const [popoverVisible, setPopoverVisible] = useState(false);

  const { isInvitation } = useInvitation();

  const { primary, secondary } = useAssetConfiguration({
    asset,
  });

  const handleTogglePopover = useCallback(() => {
    setPopoverVisible((oldState) => {
      if (oldState) popoverRef.current?.hide();
      if (!oldState) popoverRef.current?.show();

      return !oldState;
    });
  }, []);

  const handleOnEnable = useCallback(async () => {
    await enableAssets([asset]);

    handleTogglePopover();
  }, [enableAssets, handleTogglePopover, asset]);

  const handleOnDisable = useCallback(() => {
    handleShowDisableModal(asset);

    handleTogglePopover();
  }, [asset, handleShowDisableModal, handleTogglePopover]);

  return (
    <Flex alignItems="center" flex={1} justifyContent="space-between">
      <Flex flexDirection="column">
        <AssetInfoLine
          assetConfig={primary}
          lineColor="charcoal"
          margin={0.5}
        />
        <AssetInfoLine
          assetConfig={secondary}
          lineColor="alaskanHusky"
          size="small"
        />
      </Flex>
      {canUpdate && (
        <Flex>
          <Tag
            action={handleTogglePopover}
            actionAttributes={{
              'aria-label': t`Toggle status for ${assetId}`,
            }}
            actionIcon={popoverVisible ? ChevronUp : ChevronDown}
            actionRef={actionRef}
            color={enabled ? 'success' : 'licoriceMousse'}
            text={enabled ? t`Enabled` : t`Disabled`}
          />
          <ToggleStatusPopover
            target={actionRef}
            ref={popoverRef}
            value={String(enabled)}
            enabledOptionText={t`Asset is visible and available for service events.`}
            disabledOptionText={t`Asset is hidden and not available for service events.`}
            onEnable={handleOnEnable}
            onDisable={handleOnDisable}
          />
          {!isInvitation && <LinkToCase to={webCaseUrl} readOnly iconOnly />}
        </Flex>
      )}
    </Flex>
  );
}

AssetRow.propTypes = {
  asset: buildPropTypes('asset').isRequired,
  canUpdate: PropTypes.bool.isRequired,
  enableAssets: PropTypes.func.isRequired,
  handleShowDisableModal: PropTypes.func.isRequired,
};
