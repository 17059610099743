import styled from 'styled-components';
import Button from '@decisiv/ui-components/lib/components/Button';
import Link from '@decisiv/ui-components/lib/components/Link';

import { t } from '@lingui/macro';

import ROUTE_NAMES from 'setup/Routing/routeNames';

import useSentinelTeam from 'hooks/useSentinelTeam';

const LinkButton = styled(Button)`
  display: inherit;
  height: fit-content;
  text-decoration: underline;
  padding: 0;
` as typeof Button;

interface LinkToAppointmentProps {
  notification: TNotification;
  handleOpenModal: () => void;
  setModalData: (data: unknown) => void;
}

function getAppointmentId(notification: TNotification) {
  const { source } = notification;

  if (source.__typename === 'PlatformServiceRequest') {
    return source?.appointment?.appointmentId;
  }

  return source.id;
}

function LinkToAppointment({
  handleOpenModal,
  notification,
  setModalData,
}: LinkToAppointmentProps) {
  const currentSentinelTeam = useSentinelTeam();

  const { sentinelTeam } = notification;

  const appointmentId = getAppointmentId(notification) ?? '';

  if (currentSentinelTeam?.id === sentinelTeam?.id) {
    return (
      <Link
        to={ROUTE_NAMES.forServiceEvent({ id: appointmentId.toString() })}
        text={appointmentId}
      />
    );
  }

  const handleSubmit = () => {
    setModalData(notification);
    handleOpenModal();
  };

  return (
    <LinkButton
      aria-label={t`Link for ${appointmentId}`}
      variant="ghost"
      text={appointmentId.toString()}
      onClick={handleSubmit}
    />
  );
}

export default LinkToAppointment;
