/* istanbul ignore file -- @preserve */

import isDemoMode from 'utils/isDemoMode';

import { initLocalStorage, initSessionStorage } from './clientStorage';
import * as serviceWorker from './serviceWorker';

function beforeFirstRender() {
  if (isDemoMode()) {
    // eslint-disable-next-line no-console
    console.info('=====> Sentinel UI running in DEMO MODE.');
  }

  initLocalStorage();
  initSessionStorage();

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers:
  // https://create-react-app.dev/docs/making-a-progressive-web-app/
  serviceWorker.unregister();
}

export default {
  beforeFirstRender,
  serviceWorker,
};
