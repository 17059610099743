import { useMemo, useState, useCallback } from 'react';
import isEmpty from 'lodash/isEmpty';

import { plural, t, Trans } from '@lingui/macro';

import ChevronLeft from '@decisiv/iconix/lib/components/ChevronLeft';
import Plus from '@decisiv/iconix/lib/components/Plus';

import { H4, P } from '@decisiv/ui-components/lib/components/Typography';
import Button from '@decisiv/ui-components/lib/components/Button';
import Flex from '@decisiv/ui-components/lib/components/Flex';
import TextField from '@decisiv/ui-components/lib/components/TextField';

import { useWizardModal } from 'components/WizardModal';

import useTeamIdValidQuery from 'pages/Teams/hooks/useTeamIdValidQuery';

import {
  DEALER_TEAM,
  FLEET,
  PLATFORM_ACCOUNT_TYPES,
} from 'utils/SentinelTeam/constants';

import useDebounce from 'utils/useDebounce';
import {
  generateSourceValidationError,
  generateApiUserText,
} from 'utils/SentinelTeam';

import useUpdateEffect from 'hooks/useUpdateEffect';
import useSentinelTeam from 'hooks/useSentinelTeam';
import TextFieldSelect from 'components/TextFieldSelect';

import {
  UsernameContainer,
  SourceItemsContainer,
  DisplaySourceIdsContainer,
} from './styles';
import SourceItem from './SourceItem';
import { useTeamModalContext } from '../contexts/TeamModalContext';

export default function TeamSettingsStep() {
  const { handlePrevPage } = useWizardModal();
  const {
    values,
    setValues,
    isFleet,
    sourceName,
    invalidEmailSources,
    sourceIdsWithErrors,
    isEditMode,
    sourceIdError,
    setSourceIdError,
  } = useTeamModalContext();
  const sentinelTeam = useSentinelTeam();

  const teamTypeTitle = {
    [DEALER_TEAM]: t`Dealer IDs`,
    [FLEET]: t`Fleet IDs`,
  } as const;

  const teamTypeSubtitle = {
    [DEALER_TEAM]: t`Enter the dealer account and/or group ID(s).`,
    [FLEET]: t`Enter the fleet account ID(s).`,
  } as const;

  const { sourceIds, teamType } = values;

  const [sourceType, setSourceType] = useState(
    teamType === DEALER_TEAM
      ? PLATFORM_ACCOUNT_TYPES.DEALER_GROUP
      : PLATFORM_ACCOUNT_TYPES.DEALER_ID,
  );
  const [username, setUsername] = useState(values.username);
  const [password, setPassword] = useState(values.password);
  const [ownerEmail, setOwnerEmail] = useState(values.ownerEmail);

  const [sourceId, setSourceId] = useState(() =>
    isFleet ? '' : sourceIds?.[0]?.value || '',
  );

  const debouncedSourceId = useDebounce(sourceId, 500);
  const debouncedUsername = useDebounce(username, 500);
  const debouncedPassword = useDebounce(password, 500);
  const debouncedOwnerEmail = useDebounce(ownerEmail, 500);

  const accountsText = useMemo(
    () => t`${sourceIds.length} ${sourceName} Accounts`,
    [sourceIds.length, sourceName],
  );

  const lowerCaseSourceName = useMemo(
    () => sourceName?.toLowerCase(),
    [sourceName],
  );

  const passwordWarning = useMemo(() => {
    let sourceIdsText = '';

    const accountText = plural(sourceIdsWithErrors.length, {
      one: 'account',
      other: 'accounts',
    });

    if (sourceIdsWithErrors.length > 1) {
      const commaIds = sourceIdsWithErrors
        .slice(0, sourceIdsWithErrors.length - 1)
        .map((src) => src.value);
      const suffixId = sourceIdsWithErrors
        .slice(sourceIdsWithErrors.length - 1, sourceIdsWithErrors.length)
        .map((src) => src.value);

      sourceIdsText = `${commaIds.join(', ')} and ${suffixId[0]}`;
    }
    if (sourceIdsWithErrors.length === 1) {
      const suffixId = sourceIdsWithErrors[0].value;

      sourceIdsText = suffixId;
    }

    return t`API user is not valid for ${lowerCaseSourceName} ${accountText} ${sourceIdsText}`;
  }, [lowerCaseSourceName, sourceIdsWithErrors]);

  const canShowEmailWarning = useMemo(
    () => invalidEmailSources.length > 0 && !isEmpty(ownerEmail),
    [invalidEmailSources.length, ownerEmail],
  );

  const emailWarning = useMemo(
    () =>
      t`The Team Owner must already be a user within any of the ${lowerCaseSourceName} accounts added to this team. Enter their username or email as it appears in the Portal.`,
    [lowerCaseSourceName],
  );

  const handleSourceIdChange = useCallback(
    (e) => {
      e.persist();
      setSourceId(e.target.value);
      setSourceIdError([]);
    },
    [setSourceIdError],
  );

  const { data, loading: teamValidationLoading } = useTeamIdValidQuery({
    variables: {
      attributes: {
        teamType,
        platformAccount: {
          sourceId: debouncedSourceId,
          sourceType,
        },
      },
    },
    skip: isEmpty(debouncedSourceId),
    fetchPolicy: 'network-only',
    onCompleted: ({ platformAccountIdValid: { errors, valid } }) => {
      if (!valid) {
        setSourceIdError(errors);
      }
    },
  });

  const teamIdValid = data?.platformAccountIdValid?.valid ?? false;
  const dealerName = data?.platformAccountIdValid?.sourceName ?? '';

  const handleOnAddSourceId = useCallback(() => {
    if (!sourceId) return;
    if (sourceIds.map(({ value }) => value).includes(sourceId)) return; // prevent duplicated ids

    setValues((oldState) => ({
      ...oldState,
      sourceIds: [
        ...oldState.sourceIds,
        {
          dealerName,
          teamType,
          value: sourceId,
          sourceType,
          valid: false,
          validated: false,
          errors: [],
        },
      ],
    }));
    setSourceId('');
    setSourceIdError([]);
  }, [
    setValues,
    sourceId,
    setSourceIdError,
    dealerName,
    sourceType,
    sourceIds,
    teamType,
  ]);

  const canAddSource = useMemo(
    () =>
      teamIdValid &&
      sourceIdError.length === 0 &&
      !sourceIds.map(({ value }) => value).includes(sourceId),
    [sourceIdError.length, teamIdValid, sourceIds, sourceId],
  );

  const sourceIdAlreadyInList = useMemo(
    () => sourceIds.some((i) => i.value === sourceId),
    [sourceId, sourceIds],
  );

  const sourceIdAlreadyInTeam = useMemo(
    () => sentinelTeam?.platformAccounts?.some((i) => i.sourceId === sourceId),
    [sentinelTeam, sourceId],
  );

  const { name: sentinelTeamName } = sentinelTeam;

  const handleUsernameChange = useCallback((e) => {
    e.persist();
    setUsername(e.target.value);
  }, []);

  const handlePasswordChange = useCallback((e) => {
    e.persist();
    setPassword(e.target.value);
  }, []);

  const handleOwnerEmailChange = useCallback((e) => {
    e.persist();
    setOwnerEmail(e.target.value);
  }, []);

  useUpdateEffect(() => {
    if (!isFleet || !sourceIdAlreadyInList) return;

    setSourceIdError((oldState) => ['SOURCE_IN_LIST', ...oldState]);
  }, [isFleet, sourceIdAlreadyInList]);

  useUpdateEffect(() => {
    if (!isEditMode || !sourceIdAlreadyInTeam) return;

    setSourceIdError((oldState) => ['GROUP_IN_USE', ...oldState]);
  }, [isEditMode, sourceIdAlreadyInTeam]);

  useUpdateEffect(() => {
    setValues((oldState) => ({
      ...oldState,
      username: debouncedUsername,
      sourceIds: oldState.sourceIds.map((src) => ({
        ...src,
        validated: false,
        errors: [],
        emailValid: false,
      })),
    }));
  }, [debouncedUsername]);

  useUpdateEffect(() => {
    setValues((oldState) => ({
      ...oldState,
      password: debouncedPassword,
      sourceIds: oldState.sourceIds.map((src) => ({
        ...src,
        validated: false,
        errors: [],
        emailValid: false,
      })),
    }));
  }, [debouncedPassword]);

  useUpdateEffect(() => {
    setValues((oldState) => ({
      ...oldState,
      ownerEmail: debouncedOwnerEmail,
      sourceIds: oldState.sourceIds.map((src) => ({
        ...src,
        validated: false,
        errors: [],
        emailValid: false,
      })),
    }));
  }, [debouncedOwnerEmail]);

  if (!teamType) return null;

  const apiInfoText = generateApiUserText(teamType);

  return (
    <Flex flexDirection="column" marginTop={1}>
      <Flex flexDirection="column">
        <H4 marginTop={2}>
          <Trans>API User</Trans>
        </H4>
        <P color="alaskanHusky" size="small" marginBottom={1}>
          {isEditMode ? (
            <Trans>
              This is the same API User from other existing accounts for{' '}
              {sentinelTeamName}. This information can be modified in
              Integrations.
            </Trans>
          ) : (
            <Trans>
              An API User is an artificial user that makes API calls on behalf
              of the team. The API user must be a unique user created in the
              Portal and given Dealer Administrator permissions to {apiInfoText}
              . Enter their login information for the Portal.
            </Trans>
          )}
        </P>
        <UsernameContainer
          warning={!isEditMode && sourceIdsWithErrors.length > 0}
        >
          <TextField
            readOnly={isEditMode}
            required={!isEditMode}
            label={t`Username`}
            value={username}
            onChange={handleUsernameChange}
          />
        </UsernameContainer>
        <Flex marginTop={0.5}>
          <TextField
            required
            label={t`Password`}
            value={password}
            onChange={handlePasswordChange}
            type="password"
            warningMessage={
              sourceIdsWithErrors.length > 0 ? passwordWarning : undefined
            }
          />
        </Flex>

        <H4 marginTop={1}>
          <Trans>{teamTypeTitle[teamType]}</Trans>
        </H4>
        <Flex alignItems="flex-start">
          <P size="small" shade={1}>
            {teamTypeSubtitle[teamType]}
          </P>
        </Flex>
        {teamType === DEALER_TEAM ? (
          <Flex flex={1} marginTop={0.5}>
            <TextFieldSelect
              name="dealerId"
              loading={teamValidationLoading}
              loadingMessage={t`Validating API User`}
              selectedOptionId={sourceType}
              onChangeSelectedOptionId={setSourceType}
              options={[
                {
                  id: PLATFORM_ACCOUNT_TYPES.DEALER_GROUP,
                  label: t`Dealer Group`,
                  value: PLATFORM_ACCOUNT_TYPES.DEALER_GROUP,
                },
                {
                  id: PLATFORM_ACCOUNT_TYPES.DEALER_ID,
                  label: t`Dealer Account`,
                  value: PLATFORM_ACCOUNT_TYPES.DEALER_ID,
                },
              ]}
              aria-label={t`${sourceName} ID`}
              label={t`${sourceName} ID`}
              value={sourceId}
              onChange={handleSourceIdChange}
              warningMessage={
                !teamValidationLoading
                  ? generateSourceValidationError(
                      sourceIdError?.[0],
                      sourceType,
                      debouncedSourceId,
                    ) ?? undefined
                  : undefined
              }
            />
            <Flex marginLeft={1}>
              <Button
                text={t`Add`}
                kind="secondary"
                disabled={!canAddSource}
                onClick={handleOnAddSourceId}
              />
            </Flex>
          </Flex>
        ) : (
          <Flex marginTop={1} alignItems="center">
            <TextField
              required
              hideLabel
              aria-label={t`${sourceName} ID`}
              label={t`${sourceName} ID`}
              value={sourceId}
              onChange={handleSourceIdChange}
              warningMessage={
                !teamValidationLoading
                  ? generateSourceValidationError(
                      sourceIdError?.[0],
                      teamType,
                      debouncedSourceId,
                    ) ?? undefined
                  : undefined
              }
            />
            {isFleet && (
              <Flex marginLeft={1} alignSelf="baseline">
                <Button
                  icon={Plus}
                  text={t`Add`}
                  aria-label={t`Add Fleet ID`}
                  kind="secondary"
                  onClick={handleOnAddSourceId}
                  disabled={!canAddSource}
                />
              </Flex>
            )}
          </Flex>
        )}
      </Flex>
      {sourceIds.length > 0 && (
        <DisplaySourceIdsContainer marginTop={1} flexDirection="column" display>
          <P color="alaskanHusky" size="small" marginBottom={1}>
            {accountsText}
          </P>
          <SourceItemsContainer
            flexDirection="column"
            data-testid="multi-source"
          >
            {sourceIds.map((source) => (
              <SourceItem key={source.value} source={source} />
            ))}
          </SourceItemsContainer>
        </DisplaySourceIdsContainer>
      )}
      {!isEditMode && (
        <>
          <H4 marginTop={2}>
            <Trans>Team Owner</Trans>
          </H4>
          <P color="alaskanHusky" size="small" marginBottom={1}>
            <Trans>
              The Team Owner must already be a user within any of the{' '}
              {lowerCaseSourceName} accounts added to this team. Enter their
              username or email as it appears in the Portal.
            </Trans>
          </P>
          <TextField
            required
            label={t`Username or Email`}
            onChange={handleOwnerEmailChange}
            value={ownerEmail}
            warningMessage={canShowEmailWarning ? emailWarning : undefined}
          />
          <Flex justifyContent="flex-start" marginTop={1.6}>
            <Button
              aria-label={t`Back`}
              text={t`Back`}
              variant="ghost"
              icon={ChevronLeft}
              iconPosition="left"
              onClick={handlePrevPage}
            />
          </Flex>
        </>
      )}
    </Flex>
  );
}
