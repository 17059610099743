import { t } from '@lingui/macro';
import React, { useContext, useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';

import Building from '@decisiv/iconix/lib/components/Building';
import BarChart from '@decisiv/iconix/lib/components/BarChart';
import Home from '@decisiv/iconix/lib/components/Home';
import Truck from '@decisiv/iconix/lib/components/Truck';
import Calendar from '@decisiv/iconix/lib/components/Calendar';
import Cog from '@decisiv/iconix/lib/components/Cog';
import UsersGroup from '@decisiv/iconix/lib/components/UsersGroup';

import LeftNav, {
  useStickyLeftNav,
} from '@decisiv/ui-components/lib/components/LeftNav';

import TopNav from 'components/TopNav';

import { ADMIN_ROLES, useRBAC } from 'features/rbac';
import ROUTE_NAMES from 'setup/Routing/routeNames';

import useSentinelTeam from 'hooks/useSentinelTeam';

import { useCurrentUserContext } from 'utils/User';
import { CurrentInvitationContext } from 'utils/Invitation';
import useFeatureFlag, { FEATURE_FLAGS } from 'utils/useFeatureFlag';

import { Outlet } from 'react-router-dom';
import FaviconManager from 'components/FaviconManager';
import ScrollingContent from './ScrollingContent';
import Versions from './Versions';

import {
  LeftNavContainer,
  NavColumnContainer,
  PageContainer,
  PrimaryContentContainer,
  TopNavContainer,
} from './styles';

const fleetAssetGroupingItem = {
  icon: Building,
  text: t`Depots`,
  path: ROUTE_NAMES.depots,
};
const customerAssetGroupingItem = {
  icon: Building,
  text: t`Customers`,
  path: ROUTE_NAMES.customers,
};

function Layout() {
  const { authenticationKey } = useContext(CurrentInvitationContext);

  const assetViewsEnabled = useFeatureFlag(FEATURE_FLAGS.ENABLE_ASSET_VIEWS);

  const variant = useMemo(
    () => (authenticationKey ? 'single-page' : 'primary'),
    [authenticationKey],
  );

  const { isDealerGroup, isFleet, isDealerTeam } = useSentinelTeam();
  const { id, needsSentinelTeam, availableAssetViews } =
    useCurrentUserContext();

  const { isAuthorized: isAdmin } = useRBAC({ allow: ADMIN_ROLES });
  const [{ isExpanded, navExpandedState }, handleChangeNavExpandedState] =
    useStickyLeftNav({
      getPreferredNavExpandedState: () =>
        memoryDB.getItem('navExpandedState') ?? undefined,
      setPreferredNavExpandedState: (state) =>
        memoryDB[state ? 'setItem' : 'removeItem']('navExpandedState', state),
    });

  const assetViews = useMemo(() => {
    if (isEmpty(availableAssetViews)) return undefined;

    return availableAssetViews.map((assetView) => ({
      text: assetView.name,
      path: `/assets/${assetView.slug}`,
    }));
  }, [availableAssetViews]);

  const assetViewItems = useMemo(
    () => (assetViews && assetViewsEnabled ? { items: assetViews } : {}),
    [assetViews, assetViewsEnabled],
  );

  const items = useMemo(() => {
    if (!id) {
      return [];
    }

    if (needsSentinelTeam) {
      return [
        {
          icon: UsersGroup,
          path: ROUTE_NAMES.superadmin.teams,
          text: t`Teams`,
        },
        {
          icon: BarChart,
          path: ROUTE_NAMES.superadmin.reports,
          text: t`Reports`,
        },
      ];
    }

    const assetGroupingItem = isFleet
      ? fleetAssetGroupingItem
      : customerAssetGroupingItem;
    const adminItems = isAdmin
      ? [
          {
            icon: Cog,
            text: t`Administration`,
            path: ROUTE_NAMES.admin.root,
            items: [
              {
                text: t`Account`,
                path: ROUTE_NAMES.admin.account,
              },
              {
                text: t`Members`,
                path: ROUTE_NAMES.admin.members,
              },
              (isDealerGroup || isDealerTeam) && {
                text: t`Service Locations`,
                path: ROUTE_NAMES.admin.service_locations,
              },

              {
                text: t`Integrations`,
                path: ROUTE_NAMES.admin.integrations,
              },
              {
                text: t`Preferences`,
                path: ROUTE_NAMES.admin.preferences,
              },
            ].filter(Boolean),
          },
        ]
      : [];

    return [
      {
        icon: Home,
        path: ROUTE_NAMES.dashboard,
        text: t`Home`,
      },
      {
        icon: Truck,
        path: ROUTE_NAMES.alerts,
        text: t`Assets`,
        ...assetViewItems,
      },
      {
        icon: Calendar,
        path: ROUTE_NAMES.service_events,
        text: t`Service Events`,
      },
      assetGroupingItem,
      {
        icon: BarChart,
        path: ROUTE_NAMES.reports,
        text: t`Reports`,
      },
      ...adminItems,
    ].filter(Boolean);
  }, [
    id,
    isAdmin,
    isFleet,
    needsSentinelTeam,
    isDealerGroup,
    isDealerTeam,
    assetViewItems,
  ]);

  return (
    <PageContainer>
      <FaviconManager />
      {variant === 'primary' && (
        <NavColumnContainer>
          <LeftNavContainer>
            <LeftNav
              currentPath={window.location.pathname}
              items={items}
              navExpandedState={navExpandedState}
              onChangeNavExpandedState={handleChangeNavExpandedState}
            />
          </LeftNavContainer>
          {isExpanded && <Versions />}
        </NavColumnContainer>
      )}
      <PrimaryContentContainer>
        <TopNavContainer>
          <TopNav />
        </TopNavContainer>
        <ScrollingContent>
          <Outlet />
        </ScrollingContent>
      </PrimaryContentContainer>
    </PageContainer>
  );
}

export default Layout;
