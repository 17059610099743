import React from 'react';
import { t } from '@lingui/macro';
import PropTypes from 'prop-types';

import PlainTextCell from 'components/PlainTextCell';

import {
  DEALER_GROUP,
  FLEET,
  SINGLE_LOCATION,
} from 'utils/SentinelTeam/constants';

const SOURCE_TYPE_LABELS = {
  [SINGLE_LOCATION]: t`Service Location`,
  [DEALER_GROUP]: t`Dealer Group`,
  [FLEET]: t`Fleet`,
};

export default function TeamTypeCell({
  rowData: { teamType, scheduledForDeletionAt },
}) {
  return (
    <PlainTextCell
      color={scheduledForDeletionAt ? 'alaskanHusky' : undefined}
      topLine={SOURCE_TYPE_LABELS[teamType]}
    />
  );
}

TeamTypeCell.propTypes = {
  rowData: PropTypes.shape({
    teamType: PropTypes.string,
    scheduledForDeletionAt: PropTypes.string,
  }).isRequired,
};
