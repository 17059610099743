import React from 'react';
import PropTypes from 'prop-types';

import PlainTextCell from 'components/PlainTextCell';

export default function PlatformAccountsCell({
  rowData: { platformAccounts, scheduledForDeletionAt },
}) {
  return (
    <PlainTextCell
      color={scheduledForDeletionAt ? 'alaskanHusky' : undefined}
      topLine={platformAccounts
        ?.map(({ sourceId }) => sourceId)
        .sort()
        .join(', ')}
    />
  );
}

PlatformAccountsCell.propTypes = {
  rowData: PropTypes.shape({
    platformAccounts: PropTypes.arrayOf(
      PropTypes.shape({ sourceId: PropTypes.string }),
    ),
    scheduledForDeletionAt: PropTypes.string,
  }).isRequired,
};
