import React from 'react';

/**
 * Component shown as an error boundary fallback should there be an error
 * during the top-most setup components in the React component tree (the
 * components in {@link SetupComponent}.
 *
 * This component cannot use our standard layout or grid components (because
 * the theme values aren't available for the grid), I18n (because the
 * I18n dictionaries are not loaded when this is rendered), etc.
 */
export default function InitializationErrorMessage() {
  return (
    <div style={{ textAlign: 'center', padding: '2rem' }}>
      <h1>Application Error</h1>
      <p>An error occurred while loading the application.</p>
      <p>
        <a href="/">Please try again.</a>
      </p>
    </div>
  );
}
