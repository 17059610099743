import React, { useMemo } from 'react';
import { t } from '@lingui/macro';
import { useQuery } from '@apollo/client';

import Grid from '@decisiv/ui-components/lib/components/Grid';

import DashboardSection from 'components/DashboardSection';
import Loading from 'components/Loading';

import AlertsProvider from 'pages/Alerts/AlertsContext';

import TotalAssetsCard from './TotalAssetsCard';
import TotalAssetGroupingsCard from './TotalAssetGroupingsCard';
import AssetsByStatusCard from './AssetsByStatusCard';
import ScheduledMaintenanceCard from './ScheduledMaintenanceCard';
import ServiceEventsCard from './ServiceEventsCard';
import AlertsByTypeCard from './AlertsByTypeCard';
import MostAlertsByDepotCard from './MostAlertsByDepotCard';

import businessOverviewPreferencesQuery from './businessOverviewPreferences.graphql';

const { Row } = Grid;

function BusinessOverview() {
  const {
    loading,
    data: {
      dashboardPreferences: {
        totals,
        assetsByStatus,
        serviceEvents,
        alertsByType,
        mostAlertsByDepot,
        scheduledMaintenance,
      } = {},
    } = {},
  } = useQuery(businessOverviewPreferencesQuery, {
    fetchPolicy: 'cache-and-network',
  });

  const lastRowActiveCards = useMemo(
    () =>
      [alertsByType, mostAlertsByDepot, scheduledMaintenance].filter(Boolean)
        .length,
    [alertsByType, mostAlertsByDepot, scheduledMaintenance],
  );

  if (loading) return <Loading />;

  return (
    <DashboardSection title={t`Business Overview`}>
      <>
        <Grid.Container padding={0}>
          {totals && (
            <Row>
              <Grid.Column span={6} marginTop={2}>
                <TotalAssetsCard />
              </Grid.Column>
              <Grid.Column span={6} marginTop={2}>
                <TotalAssetGroupingsCard />
              </Grid.Column>
            </Row>
          )}
          {(assetsByStatus || serviceEvents) && (
            <Row>
              {assetsByStatus && (
                <Grid.Column span={6} marginTop={2}>
                  <AlertsProvider>
                    <AssetsByStatusCard />
                  </AlertsProvider>
                </Grid.Column>
              )}
              {serviceEvents && (
                <Grid.Column span={6} marginTop={2}>
                  <ServiceEventsCard />
                </Grid.Column>
              )}
            </Row>
          )}
          {(alertsByType || mostAlertsByDepot || scheduledMaintenance) && (
            <Row>
              {alertsByType && (
                <Grid.Column span={4} marginTop={2}>
                  <AlertsByTypeCard
                    key={lastRowActiveCards}
                    showDividers={lastRowActiveCards === 1}
                  />
                </Grid.Column>
              )}
              {mostAlertsByDepot && (
                <Grid.Column span={4} marginTop={2}>
                  <MostAlertsByDepotCard />
                </Grid.Column>
              )}
              {scheduledMaintenance && (
                <Grid.Column span={4} marginTop={2}>
                  <ScheduledMaintenanceCard />
                </Grid.Column>
              )}
            </Row>
          )}
        </Grid.Container>
      </>
    </DashboardSection>
  );
}

export default BusinessOverview;
