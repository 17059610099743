import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import toColorString from 'polished/lib/color/toColorString';

import color from '@decisiv/design-tokens/lib/color';

import ArrowRight from '@decisiv/iconix/lib/components/ArrowRight';

import Flex from '@decisiv/ui-components/lib/components/Flex';

const ItemWrapper = styled(Flex).attrs({ flex: 1, padding: 1 })`
  height: 100%;
  background-color: ${toColorString(color.base.fullMoon)};
`;

export default function BeforeAfterRow({
  field,
  afterValue,
  beforeValue,
  renderData,
  ...props
}) {
  const showChange = beforeValue && afterValue;

  // TODO: filter this from the BE
  // ignoring because its a creating of a service rquest (no previous value), which is handled
  // by status history audits
  if (field === 'SERVICE_REQUESTS' && !beforeValue) {
    return null;
  }

  return (
    <Flex
      data-testid="before-after-row-container"
      flex={1}
      paddingRight={1}
      {...props}
    >
      <ItemWrapper>{renderData(beforeValue || afterValue)}</ItemWrapper>
      {showChange && (
        <>
          <Flex marginX={0.5} alignSelf="center">
            <ArrowRight
              size="small"
              color={toColorString(color.base.alaskanHusky)}
            />
          </Flex>
          <ItemWrapper>{renderData(afterValue)}</ItemWrapper>
        </>
      )}
    </Flex>
  );
}

/* eslint-disable react/forbid-prop-types */
BeforeAfterRow.propTypes = {
  field: PropTypes.string.isRequired,
  afterValue: PropTypes.any,
  beforeValue: PropTypes.any,
  renderData: PropTypes.func.isRequired,
};
/* eslint-enable react/forbid-prop-types */

BeforeAfterRow.defaultProps = {
  beforeValue: null,
  afterValue: undefined,
};
