import React from 'react';
import { t } from '@lingui/macro';

import { sizes, useBreakpointObserver } from '@decisiv/breakpoint-observer';

import Grid from '@decisiv/ui-components/lib/components/Grid';

import DashboardSection from 'components/DashboardSection';
import RecentActivityCard from './RecentActivityCard';
import TeamNotesCard from './TeamNotesCard';

const { Row } = Grid;

function Activity() {
  const breakpoints = {
    [sizes.MD]: 990,
  };

  const [observedElementRef, { breakpoint }] =
    useBreakpointObserver(breakpoints);

  return (
    <DashboardSection title={t`Activity`} marginTop={0}>
      <Row marginTop={1} ref={observedElementRef}>
        <Grid.Column marginY={1} span={{ XS: '12', MD: '6' }} size={breakpoint}>
          <RecentActivityCard />
        </Grid.Column>
        <Grid.Column marginY={1} span={{ XS: '12', MD: '6' }} size={breakpoint}>
          <TeamNotesCard />
        </Grid.Column>
      </Row>
    </DashboardSection>
  );
}

export default Activity;
