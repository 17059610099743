import { t } from '@lingui/macro';

export const VEHICLE_RELATION_TITLES = {
  FLEET_MANAGER: t`Fleet Manager`,
  OWNER: t`Owner`,
  DISPATCHER: t`Dispatcher`,
  DRIVER: t`Driver`,
};

export const VEHICLE_RELATION_OPTIONS = Object.keys(
  VEHICLE_RELATION_TITLES,
).map((title) => ({
  id: title,
  label: VEHICLE_RELATION_TITLES[title],
  value: title,
}));
