/* istanbul ignore file -- @preserve */

import { InMemoryCache } from '@apollo/client/cache';
import { possibleTypes } from './fragmentTypes';
import { typePolicies } from './typePolicies';

export default function buildCache() {
  return new InMemoryCache({
    possibleTypes,
    typePolicies,
  });
}
