import React from 'react';
import styled from 'styled-components';
import toColorString from 'polished/lib/color/toColorString';
import rem from 'polished/lib/helpers/rem';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import color from '@decisiv/design-tokens/lib/color';

import Building from '@decisiv/iconix/lib/components/Building';

import Badge from '@decisiv/ui-components/lib/components/Badge';

const LARGE_HEIGHT_PX = 60;
const MEDIUM_HEIGHT_PX = 36;

const getSize = (size) =>
  size === 'large' ? LARGE_HEIGHT_PX : MEDIUM_HEIGHT_PX;

const StyledImage = styled.img`
  background-color: ${toColorString(color.base.snowWhite)};
  border-radius: ${({ size }) => rem(getSize(size) / 2)};
  height: ${({ size }) => rem(getSize(size))};
  width: ${({ size }) => rem(getSize(size))};
  object-fit: cover;
`;

export default function LogoImage({ size, src, ...rest }) {
  if (isEmpty(src)) {
    return <Badge icon={Building} size={size} data-testid="logo-badge" />;
  }
  return <StyledImage size={size} src={src} {...rest} />;
}

LogoImage.propTypes = {
  size: PropTypes.oneOf(['medium', 'large']),
  src: PropTypes.string,
};

LogoImage.defaultProps = {
  size: 'medium',
  src: null,
};
