/**
 * A utility that allows renaming the keys of an object.
 *
 * @param {Object} obj Any object.
 * @param {Object} keyMap An object containing a mapping of key names in the format
 *                        { [oldKeyName]: 'newKeyName' }
 */
function mapKeys(obj, keyMap) {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    acc[keyMap[key] || key] = value;
    return acc;
  }, {});
}

export default mapKeys;
