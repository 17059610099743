import noop from 'lodash/noop';
import toColorString from 'polished/lib/color/toColorString';
import rem from 'polished/lib/helpers/rem';
import PropTypes from 'prop-types';
import React from 'react';

import color from '@decisiv/design-tokens/lib/color';
import spacing from '@decisiv/design-tokens/lib/spacing';

import RadioGroup from '@decisiv/ui-components/lib/components/RadioGroup';

function RadioButton({ disabled, label, name, onChange, selected, value }) {
  return (
    <RadioGroup
      css={`
        label {
          border-radius: 2px;
          border: 1px solid ${toColorString(color.base.quarterMoon)};
          padding: ${rem(spacing.base)};
          width: 100%;
          ${selected &&
          `background-color: ${toColorString(
            color.status.information.light,
          )};`};
        }
      `}
      disabled={disabled}
      items={[{ label, value }]}
      name={name}
      onChange={onChange}
      vertical
      value={selected ? value : ''}
    />
  );
}

RadioButton.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  selected: PropTypes.bool,
  value: PropTypes.string.isRequired,
};

RadioButton.defaultProps = {
  disabled: undefined,
  onChange: noop,
  selected: false,
};

export default RadioButton;
