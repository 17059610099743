import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import spacing from '@decisiv/design-tokens/lib/spacing';

import Flex from '@decisiv/ui-components/lib/components/Flex';
import Grid from '@decisiv/ui-components/lib/components/Grid';
import { H2 } from '@decisiv/ui-components/lib/components/Typography';

const DashboardContainer = styled(Flex).attrs({
  margin: 2,
  flexDirection: 'column',
})`
  &:last-child {
    margin-bottom: ${rem(spacing.base * 2)};
  }
`;

function DashboardSection({ title, children, ...props }) {
  return (
    <DashboardContainer {...props}>
      <H2>{title}</H2>
      <Grid.Container padding={0}>{children}</Grid.Container>
    </DashboardContainer>
  );
}

DashboardSection.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
};

export default DashboardSection;
