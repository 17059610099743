import PropTypes from 'prop-types';
import React from 'react';

import Flex from '@decisiv/ui-components/lib/components/Flex';
import { P } from '@decisiv/ui-components/lib/components/Typography';

export default function PlainTextCell({
  textAlign,
  topLine,
  bottomLine,
  color,
  ...props
}) {
  const text = topLine || '–';
  return (
    <Flex
      {...props}
      flexDirection="column"
      css={`
        whitespace: nowrap;
        width: 100%;
        text-align: ${textAlign};
      `}
    >
      <P color={color} as="span">
        {text}
      </P>
      <P color={color} as="span" size="small" shade={1}>
        {bottomLine}
      </P>
    </Flex>
  );
}

PlainTextCell.propTypes = {
  textAlign: PropTypes.oneOf(['left', 'start', 'center', 'end', 'right']),
  topLine: PropTypes.node,
  bottomLine: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  color: PropTypes.string,
};

PlainTextCell.defaultProps = {
  textAlign: 'start',
  topLine: undefined,
  bottomLine: '',
  color: undefined,
};
