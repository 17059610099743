import { Ref, forwardRef } from 'react';
import { t } from '@lingui/macro';

import Flex from '@decisiv/ui-components/lib/components/Flex';
import {
  InstanceRef,
  Target,
} from '@decisiv/ui-components/lib/components/Popover';
import RadioGroup from '@decisiv/ui-components/lib/components/RadioGroup';
import { P } from '@decisiv/ui-components/lib/components/Typography';

import { Popover } from './styles';

interface ToggleStatusPopoverProps {
  target: Target;
  value: string | number;
  enabledOptionText: string;
  disabledOptionText: string;
  onEnable: () => void;
  onDisable: () => void;
}

const ToggleStatusPopover = forwardRef(
  (
    {
      target,
      value,
      enabledOptionText,
      disabledOptionText,
      onDisable,
      onEnable,
    }: ToggleStatusPopoverProps,
    ref: Ref<InstanceRef>,
  ) => {
    return (
      <Popover
        ref={ref}
        target={target}
        manageEvents={false}
        zIndex={3}
        placement="bottom-end"
      >
        <Flex flexDirection="column" padding={1}>
          <RadioGroup
            name="enabled"
            items={[{ label: t`Enabled`, value: 'true' }]}
            onChange={onEnable}
            value={value}
          />
          <P marginLeft={3.5} marginBottom={1} size="small" shade={1}>
            {enabledOptionText}
          </P>
          <RadioGroup
            onChange={onDisable}
            name="disabled"
            items={[{ label: t`Disabled`, value: 'false' }]}
            value={value}
          />
          <P marginLeft={3.5} size="small" shade={1}>
            {disabledOptionText}
          </P>
        </Flex>
      </Popover>
    );
  },
);

ToggleStatusPopover.displayName = 'ToggleStatusPopover';

export default ToggleStatusPopover;
