import React from 'react';
import PropTypes from 'prop-types';

import { t } from '@lingui/macro';

import Tag from '@decisiv/ui-components/lib/components/Tag';
import Tooltip from '@decisiv/ui-components/lib/components/Tooltip';

import { parseDate } from 'utils/dates';

export default function AuditTag({ audit }) {
  const { discardedAt, editedAt } = audit;
  const date = discardedAt || editedAt;

  if (!date) return null;

  const text = discardedAt ? t`Delete` : t`Edited`;

  return (
    <Tooltip target={<Tag text={text} variant="outline" />}>
      {parseDate(date).join(', ')}
    </Tooltip>
  );
}

AuditTag.propTypes = {
  audit: PropTypes.shape({
    discardedAt: PropTypes.string,
    editedAt: PropTypes.string,
  }).isRequired,
};
