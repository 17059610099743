import { useQuery } from '@apollo/client';

import assetsFiltersQuery from './assetsFilters.graphql';

export default function useAssetsFiltersQuery(
  customerIds,
  platformAccountIds,
  groupedAssetGroupingsIds,
  text,
  opts = {},
) {
  const {
    data: {
      assetsFilters: {
        alerts: alertFilters = [],
        makes: makeFilters = [],
        models: modelFilters = [],
        years: yearFilters = [],
        costCenters: costCentersFilters = [],
        assetTypes: assetTypesFilters = [],
        workInProgress: wipFilters = [],
        assignees: assigneesFilters = [],
        platformCaseFleetStatus = [],
        platformCaseRepairStatus = [],
        serviceEventStatus = [],
        serviceRequestStatus = [],
        serviceProviders = [],
        serviceProviderCities = [],
      } = {},
    } = {},
    ...rest
  } = useQuery(assetsFiltersQuery, {
    fetchPolicy: 'cache-and-network',
    variables: {
      customerIds,
      platformAccountIds,
      groupedAssetGroupingsIds,
      enabled: 'ENABLED',
      text,
    },
    ...opts,
  });

  return {
    filters: {
      alertFilters,
      makeFilters,
      modelFilters,
      yearFilters,
      costCentersFilters,
      assetTypesFilters,
      wipFilters,
      assigneesFilters,
      platformCaseFleetStatus,
      platformCaseRepairStatus,
      serviceEventStatus,
      serviceRequestStatus,
      serviceProviders,
      serviceProviderCities,
    },
    queryResult: rest,
  };
}
