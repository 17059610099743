import React, { createContext, useContext, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import useQueryParams from 'hooks/useQueryParams';
import useSort from 'utils/useSort';

import useSentinelTeamsQuery from '../hooks/useSentinelTeamsQuery';
import useApiEnvironmentQuery from '../hooks/useApiEnvironmentQuery';

export const TeamsContext = createContext({});

export default function TeamsProvider({ children }) {
  const [pageParams, setPageParams, resetPageParams] = useQueryParams(
    'all-teams-pagination',
    {
      page: 1,
    },
  );

  const [{ sort }, { getSortProps }] = useSort();
  const { defaultSort, onSort } = getSortProps();

  const { data: { version: { environment } = {} } = {} } =
    useApiEnvironmentQuery({});

  const {
    data: {
      sentinelTeams: {
        collection: allSentinelTeams = [],
        pagination = {},
      } = {},
    } = {},
    loading,
  } = useSentinelTeamsQuery({
    fetchPolicy: 'cache-and-network',
    variables: { page: pageParams.page, sort },
  });

  const setPage = useCallback(
    (page) => {
      setPageParams({ page });
    },
    [setPageParams],
  );

  const values = useMemo(
    () => ({
      environment,
      allSentinelTeams,
      loading,
      pagination,
      setPage,
      resetPage: resetPageParams,
      defaultSort,
      onSort,
    }),
    [
      environment,
      allSentinelTeams,
      loading,
      pagination,
      setPage,
      resetPageParams,
      defaultSort,
      onSort,
    ],
  );

  return (
    <TeamsContext.Provider value={values}>{children}</TeamsContext.Provider>
  );
}

export function useTeams() {
  return useContext(TeamsContext);
}

TeamsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
