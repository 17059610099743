import { loader } from 'graphql.macro';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import DashboardCard from 'components/DashboardCard';
import Loading from 'components/Loading';
import Flex from '@decisiv/ui-components/lib/components/Flex';
import Button from '@decisiv/ui-components/lib/components/Button';
import Divider from 'components/Divider';
import ArrowRight from '@decisiv/iconix/lib/components/ArrowRight';

import routeNames from 'setup/Routing/routeNames';

import BarChartComponent from './BarChart';

import { buildActiveFiltersParams as activeAlertsParams } from '../AssetsByStatusCard/buildParams';
import barChartColorMap from './barCharColorMap';
import AlertsByTypeViewAllModal from './AlertsByTypeViewAllModal';

const allAlertTypesCount = loader('./allAlertTypesCount.graphql');
const allAlertTypesQuery = loader(
  '../AssetsByStatusCard/allAlertTypes.graphql',
);

const NUMBER_OF_ALERTS_TO_DISPLAY = 9;

const infoText = t`View assets by active alert types. Click on the panel links to view the assets
with the alert type filters automatically enabled.`;

export default function AlertsByTypeCard({ showDividers }) {
  const navigate = useNavigate();
  const { data: { assetsFilters: { alerts = [] } = {} } = {}, loading } =
    useQuery(allAlertTypesCount, { fetchPolicy: 'cache-and-network' });

  const [open, setOpen] = useState(false);

  const openAlertsByTypeModal = () => setOpen(true);
  const closeAlertsByTypeModal = () => setOpen(false);

  const totalAlerts = alerts.reduce((sum, { count = 0 }) => sum + count, 0);

  const chartData = alerts.map((alert, index) => {
    let percentage;
    if (totalAlerts) {
      percentage = Math.round((alert.count / totalAlerts) * 100);
    } else {
      percentage = 0;
    }

    return { ...alert, percentage, color: barChartColorMap(index) };
  });

  const truncatedAlertsData = chartData.slice(0, NUMBER_OF_ALERTS_TO_DISPLAY);

  const showAllAlertsModal = chartData.length > NUMBER_OF_ALERTS_TO_DISPLAY;

  const { data: activeFiltersData } = useQuery(allAlertTypesQuery, {
    variables: { enabled: 'ENABLED' },
  });

  const activeAlertsIds = activeFiltersData?.assetsFilters?.alerts.map(
    ({ id }) => id,
  );

  const onClickViewAll = () =>
    navigate(`${routeNames.alerts}${activeAlertsParams(activeAlertsIds)}`);

  return (
    <DashboardCard
      text={t`Assets by Active Alert Types`}
      buttonText={t`View All`}
      buttonAriaLabel={t`View All Assets`}
      infoText={infoText}
      onButtonClick={onClickViewAll}
      marginTop={0}
    >
      {loading ? (
        <Loading />
      ) : (
        <Flex flexDirection="column">
          <BarChartComponent
            alerts={truncatedAlertsData}
            totalAlertCount={totalAlerts}
            showDividers={showDividers}
          />
          {showAllAlertsModal && (
            <>
              <Divider direction="horizontal" marginY={1} />
              <Flex>
                <Button
                  variant="ghost"
                  text={t`View More Alerts Types`}
                  icon={ArrowRight}
                  iconPosition="right"
                  size="small"
                  onClick={openAlertsByTypeModal}
                />
                <AlertsByTypeViewAllModal
                  alerts={chartData}
                  open={open}
                  onClose={closeAlertsByTypeModal}
                  actions={[
                    { text: t`View All`, onClick: onClickViewAll },
                    {
                      text: t`Close`,
                      onClick: closeAlertsByTypeModal,
                    },
                  ]}
                />
              </Flex>
            </>
          )}
        </Flex>
      )}
    </DashboardCard>
  );
}

AlertsByTypeCard.propTypes = {
  showDividers: PropTypes.bool,
};

AlertsByTypeCard.defaultProps = {
  showDividers: false,
};
