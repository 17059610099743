import isEmpty from 'lodash/isEmpty';
import { t } from '@lingui/macro';

import { format, parseISO } from 'date-fns';

import {
  appointmentColors,
  appointmentHexColors,
  appointmentIcons,
  appointmentCancelReasonText,
  appointmentStatusLabelText,
} from './constants';

export const activeAppointments = (appointments) =>
  appointments.filter((a) => ['DRAFT', 'REQUESTED'].includes(a.status));

export const badgeColorForAppointment = (appointment) =>
  appointmentColors[appointment.status];

export const badgeIconForAppointment = (appointment) =>
  appointmentIcons[appointment.status];

export const hexColorForAppointment = ({ status }) =>
  appointmentHexColors[status];

export const labelForAppointmentCancelation = ({ cancelReason }) =>
  appointmentCancelReasonText[cancelReason];

export const labelForAppointmentStatus = ({ status }) =>
  appointmentStatusLabelText[status];

export const totalAlerts = (appointments) =>
  appointments.reduce((total, a) => total + a.alertRequests.length, 0);

export const appointmentTextForFilter = ({ attribute }) =>
  appointmentStatusLabelText[attribute];

export const formatDate = (date) => {
  if (!date) return null;

  return format(date, 'yyyy-MM-dd');
};

export const parseDate = (dateString) => {
  if (!dateString) return undefined;

  return parseISO(dateString, 'yyyy-MM-dd', new Date());
};

export const buildDateRange = (dateRange) => {
  if (isEmpty(dateRange)) return undefined;

  const [startDateString, endDateString] = dateRange;

  return {
    startDate: startDateString,
    endDate: endDateString,
  };
};

export const NETWORK_OPTIONS = {
  ALL: 'ALL',
  EXTERNAL: 'EXTERNAL',
  IN_NETWORK: 'IN_NETWORK',
};

export const handledAlertsAppointments = (appts) =>
  appts.filter(
    ({ alertRequests, status }) =>
      ['DRAFT', 'REQUESTED'].includes(status) && !isEmpty(alertRequests),
  );

export const handledAlertsCount = (appts) =>
  appts.reduce(
    (prev, { alertRequests }) =>
      isEmpty(alertRequests) ? prev : prev + alertRequests.length,
    0,
  );

export const providerNetworkOptions = [
  { label: t`All`, value: NETWORK_OPTIONS.ALL },
  {
    label: t`In-Network`,
    value: NETWORK_OPTIONS.IN_NETWORK,
  },
  {
    label: t`External`,
    value: NETWORK_OPTIONS.EXTERNAL,
  },
];

export const serviceEventOptions = [
  {
    label: 'With Active Alerts',
    value: 'WITH_ACTIVE_ALERTS',
    id: 'withactivealerts',
  },

  {
    label: 'Due Soon',
    value: 'DUE_SOON',
    id: 'duesoon',
  },
  {
    label: 'Overdue',
    value: 'OVERDUE',
    id: 'overdue',
  },
];

export const TABS = {
  ACTIVE: 'active',
  COMPLETED: 'completed',
  ARCHIVED: 'archived',
};

export const STATUS_BY_TAB = {
  active: ['DRAFT', 'REQUESTED'],
  completed: ['COMPLETED'],
  archived: ['CANCELED', 'COMPLETED'],
};

export const dateRangeValues = [
  { id: '0', label: t`Past 7 Days`, value: 7 },
  { id: '1', label: t`Past 30 Days`, value: 30 },
  { id: '2', label: t`Past 60 Days`, value: 60 },
  { id: '3', label: t`Past 90 Days`, value: 90 },
];
