/* istanbul ignore file -- @preserve */

import { FLEET } from 'utils/SentinelTeam/constants';

const ROUTE_NAMES = {
  dashboard: '/',
  alerts: '/assets',
  asset_view: '/assets/:slug',
  appointments: '/appointments',
  appointment: '/appointments/:appointmentId',
  service_events: '/service_events',
  service_event: '/service_events/:appointmentId',
  feature_flags: '/feature-flags',
  notification_preferences: '/notification-preferences',
  reports: '/reports',
  customer: '/customers/:assetGroupingId',
  customers: '/customers',
  depot: '/depot/:assetGroupingId',
  depots: '/depots',
  search: '/search',
  change_team: '/change_team',
  superadmin: {
    teams: '/',
    reports: '/monthly_reports',
  },
  // this route might exist until we support multi-tabs
  assetGrouping: '/assetGrouping/:assetGroupingId',
  admin: {
    root: '/admin',
    account: '/admin/account',
    integrations: '/admin/integrations',
    members: '/admin/members',
    preferences: '/admin/preferences',
    service_locations: '/admin/service_locations',
    customer: '/admin/customers/:assetGroupingId',
    customers: '/admin/customers',
    depot: '/admin/depot/:assetGroupingId',
    depots: '/admin/depots',
  },
  invitation: '/invitations/:authenticationKey',
  access_denied: '/access-denied',
  logged_out: '/logged-out',
  site_maintenance: '/site-maintenance',
  forAssetView: (slug: string) => ROUTE_NAMES.asset_view.replace(':slug', slug),
  forServiceEvent: ({ id }: Pick<Appointment, 'id'>) =>
    ROUTE_NAMES.service_event.replace(':appointmentId', id),
  forDepot: ({ id }: Pick<Customer, 'id'>) =>
    ROUTE_NAMES.depot.replace(':assetGroupingId', id),
  forCustomer: ({ id }: Pick<Customer, 'id'>) =>
    ROUTE_NAMES.customer.replace(':assetGroupingId', id),
  forAssetGrouping: (
    { id }: Pick<Customer, 'id'>,
    { teamType }: Pick<SentinelTeam, 'teamType'>,
  ) => {
    switch (teamType) {
      case FLEET:
        return ROUTE_NAMES.forDepot({ id });
      default:
        return ROUTE_NAMES.forCustomer({ id });
    }
  },
  forInvitation: (authKey: string) =>
    ROUTE_NAMES.invitation.replace(':authenticationKey', authKey),
};

export default ROUTE_NAMES;
