import color from '@decisiv/design-tokens/lib/color';
import styled from 'styled-components';
import { toColorString } from 'polished';

import AsteriskIcon from '@decisiv/iconix/lib/components/Asterisk';

export const Asterisk = styled(AsteriskIcon).attrs({
  size: 'medium',
  color: toColorString(color.status.information.medium),
})`
  vertical-align: text-top;
`;
