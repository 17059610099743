import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { useQuery } from '@apollo/client';

import { useMatch } from 'react-router-dom';
import ROUTE_NAMES from 'setup/Routing/routeNames';

export { INVITATION_STATUS } from './constants';

export const CurrentInvitationContext = React.createContext({});

export const INVITATION_KEY = 'X-DECISIV-INVITATION-KEY';

export const currentInvitationQuery = gql`
  query currentInvitation {
    currentInvitation {
      authenticationKey
      createdBy {
        id
        email
        firstName
        lastName
        name
        username
      }
      contact {
        id
        email
        firstName
        lastName
      }
      customer {
        id
        customerType
        name
        enableAllAssets
      }
      id
      sentinelTeam {
        id
        applicationName
        favicon {
          url
        }
        logo {
          url
        }
        name
        teamType
      }
      status
      updatedAt
    }
  }
`;

export function logout() {
  memoryDB.removeItem(INVITATION_KEY);
  window.location.href = '/';
}

export default function CurrentInvitationProvider({ children }) {
  const match = useMatch(ROUTE_NAMES.invitation);

  // If the url includes an authentication key, that key is the only one the UI should know about.
  // Set if in local storage.
  if (match?.params?.authenticationKey) {
    memoryDB.setItem(INVITATION_KEY, match.params.authenticationKey);
  }
  // Use this as the auth key for everything. It will be made available in
  // context and local storage. The local storage version is pulled for API request auth.
  const memoryDBAuthenticationKey = memoryDB.getItem(INVITATION_KEY);

  const {
    data: { currentInvitation = {} } = {},
    loading,
    error,
  } = useQuery(currentInvitationQuery, {
    skip: !(match?.params?.authenticationKey || memoryDBAuthenticationKey),
  });

  const value = useMemo(
    () => ({
      authenticationKey: memoryDBAuthenticationKey,
      ...currentInvitation,
      loading,
      error,
    }),
    [currentInvitation, loading, memoryDBAuthenticationKey, error],
  );

  return (
    <CurrentInvitationContext.Provider value={value}>
      {children}
    </CurrentInvitationContext.Provider>
  );
}

CurrentInvitationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
