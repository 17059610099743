import React, { useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';
import isNil from 'lodash/isNil';

import Briefcase from '@decisiv/iconix/lib/components/Briefcase';

import Tooltip from '@decisiv/ui-components/lib/components/Tooltip';
import ExternalLink from '@decisiv/iconix/lib/components/ExternalLink';

import useSentinelTeam from 'hooks/useSentinelTeam';
import LinkTag from 'components/LinkTag';

const CaseTag = ({ caseInfo, 'data-testid': dataTestId }) => {
  if (isNil(caseInfo)) return null;

  const { apiCaseId, status, fleetStatus, repairStatus, url } = caseInfo;

  const [tooltip, setTooltip] = useState({});
  const actionRef = useRef();
  const tooltipRef = useCallback(setTooltip, [tooltip, setTooltip]);

  const { caseStatusVisibility } = useSentinelTeam();

  const textValues =
    caseStatusVisibility
      ?.slice()
      ?.sort()
      ?.reverse()
      ?.reduce((acc, currValue, idx) => {
        const statusVal =
          currValue === 'FLEET_STATUS' ? fleetStatus : repairStatus || status;
        const statusText = statusVal || t`None...`;

        acc[idx] = statusText;

        return acc;
      }, []) || [];

  const text = `${apiCaseId} \u0387 ${textValues.join(' | ')}`;

  const actionAttributes = { 'aria-label': t`Go to case` };

  const redirectToCase = () => (url ? window.open(url, '_blank') : null);

  const disableTag = !url;

  return (
    <>
      {disableTag && (
        <Tooltip ref={tooltipRef} target={actionRef} zIndex={999}>
          {t`You do not have permission to access this link`}
        </Tooltip>
      )}
      <LinkTag
        data-testid={dataTestId}
        text={text}
        icon={Briefcase}
        variant="outline"
        color="information"
        actionRef={actionRef}
        disableTag={disableTag}
        action={redirectToCase}
        actionIcon={ExternalLink}
        actionAttributes={actionAttributes}
      />
    </>
  );
};

CaseTag.propTypes = {
  caseInfo: PropTypes.shape({
    id: PropTypes.string,
    url: PropTypes.string,
    status: PropTypes.string,
    fleetStatus: PropTypes.string,
    repairStatus: PropTypes.string,
    apiCaseId: PropTypes.number.isRequired,
  }).isRequired,
  'data-testid': PropTypes.string,
};

CaseTag.defaultProps = {
  'data-testid': undefined,
};

CaseTag.displayName = 'CaseTag';

export default React.memo(CaseTag);
