import React, { useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import { useLocation } from 'react-router-dom';

import styled, { css } from 'styled-components';
import Popover from '@decisiv/ui-components/lib/components/Popover';
import CaretDown from '@decisiv/iconix/lib/components/CaretDown';
import CaretUp from '@decisiv/iconix/lib/components/CaretUp';
import Check from '@decisiv/iconix/lib/components/Check';
import toColorString from 'polished/lib/color/toColorString';
import color from '@decisiv/design-tokens/lib/color';
import rem from 'polished/lib/helpers/rem';
import spacing from '@decisiv/design-tokens/lib/spacing';
import KDSTopNav from '@decisiv/ui-components/lib/components/TopNav';

import { setSessionValue } from 'utils/sessionStorage';

import { useCurrentUserContext } from 'utils/User';
import useSentinelTeam from 'hooks/useSentinelTeam';

const Wrapper = styled.div`
  user-select: none;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;

  & > div {
    display: flex;

    height: 100%;
  }
`;

const ChildrenWrapper = styled.div`
  display: flex;
  align-items: center;

  padding: 0 ${rem(spacing.base)};

  & > div {
    margin-right: ${rem(spacing.base * 2)};
  }

  ${({ clickable }) =>
    clickable &&
    css`
      cursor: pointer;

      &:focus {
        outline: none;
        box-shadow: ${`inset 0 0 0 ${rem(spacing.base * 0.2)} ${toColorString(
          color.opacity.fullMoon50,
        )}`};
      }
    `}
`;

export const TeamWrapper = styled.div`
  display: flex;
  cursor: pointer;

  position: relative;

  & > div {
    margin-right: ${rem(spacing.base * 4.6)};
  }

  & > svg {
    position: absolute;
    right: ${rem(spacing.base)};
    top: ${rem(spacing.base * 2.2)};
  }

  &:focus {
    box-shadow: ${`inset 0 0 0 ${rem(spacing.base * 0.2)} ${toColorString(
      color.opacity.fullMoon50,
    )}`};
    outline: none;
  }
`;

const Content = styled.div`
  margin-left: ${rem(spacing.base)};
  max-height: ${rem(spacing.base * 40)};
  overflow-y: auto;

  background: ${toColorString(color.base.midnight)};

  & > div {
    padding: ${rem(spacing.base * 1.2)} ${rem(spacing.base)};
  }
`;

const TeamsDropdown = ({ children }) => {
  const location = useLocation();

  const [popover, setPopover] = useState({});
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const popoverRef = useCallback(setPopover, [popover]);

  const { sentinelTeams, superuser } = useCurrentUserContext();
  const { setSentinelTeam, ...sentinelTeam } = useSentinelTeam();

  const popoverClickable = useMemo(() => {
    return sentinelTeams?.length > 1 && !superuser;
  }, [sentinelTeams, superuser]);

  const handleTeamClick = async (id) => {
    popover.hide();
    setSessionValue('route', location.pathname, sentinelTeam?.id);
    setSentinelTeam({ variables: { id } });
  };

  const handleKeyPress = (e, callback) => {
    if (e.keyCode === 32 || e.keyCode === 13) {
      callback();
    }
  };

  return (
    <Wrapper>
      <Popover
        ref={popoverRef}
        arrowColor="charcoal"
        placement="bottom-start"
        showArrow
        zIndex={10}
        target={({ isVisible, toggle, ref, ariaAttributes }) => (
          <ChildrenWrapper
            data-testid="children-wrapper"
            ref={ref}
            onClick={popoverClickable ? toggle : undefined}
            onKeyDown={(e) =>
              handleKeyPress(e, popoverClickable ? toggle : undefined)
            }
            {...ariaAttributes}
            tabIndex="0"
            clickable={popoverClickable}
          >
            <div>{children}</div>
            {popoverClickable &&
              (isVisible ? (
                <CaretUp data-testid="caret-up" size="small" />
              ) : (
                <CaretDown data-testid="caret-down" size="small" />
              ))}
          </ChildrenWrapper>
        )}
      >
        {popoverClickable && (
          <Content data-testid="content-wrapper">
            {sentinelTeams.map((team) => (
              <TeamWrapper
                key={team.id}
                tabIndex="0"
                onClick={() => handleTeamClick(team.id)}
                onKeyDown={(e) =>
                  handleKeyPress(e, () => handleTeamClick(team.id))
                }
              >
                <KDSTopNav.BrandInfo
                  logo={team?.logo?.url}
                  primaryText={team.name}
                  secondaryText={team.applicationName}
                />
                {sentinelTeam.id === team.id && <Check color="#fff" />}
              </TeamWrapper>
            ))}
          </Content>
        )}
      </Popover>
    </Wrapper>
  );
};

TeamsDropdown.propTypes = {
  children: PropTypes.node,
};

TeamsDropdown.defaultProps = {
  children: null,
};

export default TeamsDropdown;
