import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { t, Trans } from '@lingui/macro';
import toColorString from 'polished/lib/color/toColorString';

import color from '@decisiv/design-tokens/lib/color';

import Flex from '@decisiv/ui-components/lib/components/Flex';
import Pagination from '@decisiv/ui-components/lib/components/Pagination';
import SelectMenu from '@decisiv/ui-components/lib/components/SelectMenu';
import { P } from '@decisiv/ui-components/lib/components/Typography';

const PaginationFooter = forwardRef(
  (
    {
      page,
      perPage,
      setCurrentPage,
      setPerPage,
      textColor,
      title,
      totalEntries,
      totalOnPage,
      totalPages,
    },
    ref,
  ) => {
    const first = (page - 1) * perPage + 1;
    const last = (page - 1) * perPage + totalOnPage;

    return (
      <Flex alignItems="center" flex={1} ref={ref}>
        {setPerPage && (
          <Flex
            css={`
              border-right: 1px solid ${toColorString(color.opacity.charcoal15)};
            `}
            marginRight={1}
            paddingRight={1}
          >
            <SelectMenu
              defaultSelected={String(perPage)}
              label={t`Items per page:`}
              onChange={setPerPage}
              options={[
                { id: '20', label: t`20`, value: 20 },
                { id: '50', label: t`50`, value: 50 },
                { id: '100', label: t`100`, value: 100 },
              ]}
              size="small"
            />
          </Flex>
        )}
        <Flex>
          <P as="span" color={textColor} size="small" weight="semibold">
            {first} - {last}&nbsp;
          </P>
          <P as="span" color={textColor} size="small">
            <Trans>of</Trans>
          </P>
          <P as="span" color={textColor} size="small" weight="semibold">
            &nbsp;{totalEntries}&nbsp;
          </P>
          <P as="span" color={textColor} size="small">
            {title}
          </P>
        </Flex>
        <Flex flex={1} justifyContent="flex-end">
          <Pagination
            totalPages={totalPages}
            activePage={page}
            onPageChange={(newPage) => setCurrentPage(newPage)}
          />
        </Flex>
      </Flex>
    );
  },
);

PaginationFooter.propTypes = {
  page: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  setPerPage: PropTypes.func,
  textColor: PropTypes.oneOf([
    'charcoal',
    'alaskanHusky',
    'snowWhite',
    'quarterMoon',
    'halfMoon',
    'inherit',
  ]),
  title: PropTypes.string.isRequired,
  totalOnPage: PropTypes.number.isRequired,
  totalEntries: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
};

PaginationFooter.defaultProps = {
  setPerPage: undefined,
  textColor: 'charcoal',
};

export default PaginationFooter;
