import { t } from '@lingui/macro';
import { format, parseISO } from 'date-fns';
import isEmpty from 'lodash/isEmpty';

import { activeAppointments } from 'utils/Appointments';

export const COLUMN_NAMES = {
  FAULTS: 'FAULTS',
  RECALLS: 'RECALLS',
  COST_CENTER: 'COST_CENTER',
  SCHEDULED_MAINTENANCES: 'SCHEDULED_MAINTENANCES',
  WARRANTIES: 'WARRANTIES',
  CHASSIS_ID: 'CHASSIS_ID',
  COST_CENTERS: 'COST_CENTERS',
  SERIAL_NUMBER: 'SERIAL_NUMBER',
  UNIT_NUMBER: 'UNIT_NUMBER',
  VIN: 'VIN',
  TYPE: 'TYPE',
  FLEET_INFO: 'FLEET_INFO',
  WORK_IN_PROGRESS: 'WORK_IN_PROGRESS',
  ALERTS: 'ALERTS',
  LOCATION: 'LOCATION',
  ODOMETER: 'ODOMETER',
  ENGINE_HOURS: 'ENGINE_HOURS',
  CUSTOMER: 'CUSTOMER',
  YEAR: 'YEAR',
  MAKE: 'MAKE',
  MODEL: 'MODEL',
} as const;

const ASSET_TYPE_MAPPER = {
  0: t`Maintenance Facility Equipment`,
  1: t`Truck`,
  2: t`Tractor`,
  3: t`Semi-Trailer`,
  4: t`Full Trailer`,
  5: t`Tractor-Trailer Combination`,
  6: t`Service Vehicle`,
  7: t`Automobile`,
  8: t`Converters`,
  9: t`Terminal Equipment`,
  A: t`Airline Ground Support Equipment`,
  AH: t`Auxiliary Mounted Heater`,
  AP: t`Auxiliary Power Unit (APU)`,
  B: t`Bus`,
  C: t`Container`,
  CC: t`Container Rails (Canadian)`,
  CU: t`Container Rails (US)`,
  D: t`Agricultural Equipment`,
  E: t`Engineering and Construction Equipment`,
  F: t`Asphalt, Road Surfacing/Maintenance Equipment`,
  FE: t`Foreign Equipment`,
  G: t`Compaction Equipment`,
  H: t`Hoist and Derrick Equipment`,
  I: t`Crushing and Conveying Equipment`,
  J: t`Drilling Equipment`,
  K: t`Excavating Equipment`,
  L: t`Lifting Equipment`,
  LC: t`Lawn Care Equipment`,
  LT: t`Lease Tractor`,
  M: t`Material Handling Equipment`,
  MA: t`Motorcycle/ATV`,
  MW: t`Mower`,
  N: t`Marine Equipment`,
  O: t`Stationary Equipment`,
  P: t`Pile Driving Equipment`,
  Q: t`Pumping Equipment`,
  R: t`Refrigeration`,
  S: t`Semi Trailer (Canadian Size & Weight Limits)`,
  SH: t`Snow Handling Equipment`,
  T: t`Full Trailer (Canadian Size & Weight Limits)`,
  U: t`Mini Van`,
  V: t`Tunneling Equipment`,
  W: t`Mini Bus`,
  X: t`Power Delivery`,
  Y: t`Van`,
  Z: t`Other/Miscellaneous`,
  '': t`Unknown`,
} as const;

type AssetTypeMapper = typeof ASSET_TYPE_MAPPER;
type AssetTypeMapperKey = keyof AssetTypeMapper;

export default function assetTypeText(id: Maybe<AssetTypeMapperKey>) {
  if (!id) return t`Unknown`;

  return id in ASSET_TYPE_MAPPER ? ASSET_TYPE_MAPPER[id] : id;
}

export function assetAttributesForIdentifiers(asset: Asset) {
  const yearMakeModel = [asset?.year, asset?.make, asset?.model]
    .filter(Boolean)
    .join(' ');

  const assetType = assetTypeText(asset.assetType as AssetTypeMapperKey);

  return { ...asset, yearMakeModel, assetType };
}

// receiving the `data` from the `Assets` query, it will extract and transform the data
// so it can be shown on different parts of the application
export function getDataFromAssetQuery(data: { asset: Asset }) {
  if (!data?.asset) {
    return {
      cases: [],
      appointments: [],
      totalAppointmentsAndCases: 0,
      totalAppointmentsServiceRequestsAndCases: 0,
    };
  }

  const platformCaseCount = data?.asset.platformCases.length;

  if (isEmpty(data?.asset.appointments)) {
    return {
      totalAppointmentsServiceRequestsAndCases: platformCaseCount,
      totalAppointmentsAndCases: platformCaseCount,
      appointments: [],
      cases: data?.asset.platformCases,
    };
  }

  const assetActiveAppointments: Appointment[] = activeAppointments(
    data?.asset.appointments,
  );

  const serviceRequestsCount = assetActiveAppointments.reduce(
    (acc: number, appt) => {
      const hasServiceRequest = !!appt.platformServiceRequest;
      return acc + (hasServiceRequest ? 1 : 0);
    },
    0,
  );

  const totalAppointmentsAndCases =
    assetActiveAppointments.length + platformCaseCount;

  const totalAppointmentsServiceRequestsAndCases =
    totalAppointmentsAndCases + serviceRequestsCount;

  return {
    totalAppointmentsAndCases,
    totalAppointmentsServiceRequestsAndCases,
    appointments: assetActiveAppointments,
    cases: data?.asset.platformCases,
  };
}

export function formatISODate(
  date: string,
  dateFormat = 'd MMM yyyy, hh:mm aa',
) {
  if (!date) return undefined;
  return format(parseISO(date), dateFormat);
}
