import PropTypes from 'prop-types';
import React, { useReducer } from 'react';

import { useMutation } from '@apollo/client';

import Button from '@decisiv/ui-components/lib/components/Button';
import Grid from '@decisiv/ui-components/lib/components/Grid';
import { P } from '@decisiv/ui-components/lib/components/Typography';

import { t, Trans } from '@lingui/macro';

import RadioButton from 'components/RadioButton';

import { INVITATION_STATUS } from 'utils/Invitation';

import { STATUS, ENABLE_ALL_ASSETS } from './constants';

import updateInvitationResponseMutation from './updateInvitationResponse.graphql';

const SET_STATUS = 'SET_STATUS';
const SET_ENABLE_ALL_ASSETS = 'SET_ENABLE_ALL_ASSETS';

function InvitationRequestAcceptForm({ currentStatus }) {
  const [{ status, enableAllAssets }, dispatch] = useReducer(
    (currentState, action) => {
      switch (action.type) {
        case SET_STATUS:
          return { ...currentState, status: action.value };
        case SET_ENABLE_ALL_ASSETS:
          return { ...currentState, enableAllAssets: action.value };
        default:
          return currentState;
      }
    },
    {
      status:
        currentStatus === INVITATION_STATUS.REJECTED
          ? STATUS.ACCEPTED
          : undefined,
      enableAllAssets: undefined,
    },
  );

  const onChangeStatus = (event) => {
    dispatch({ type: SET_STATUS, value: event.target.value });
  };

  const onChangeEnableAllAssets = (event) => {
    dispatch({ type: SET_ENABLE_ALL_ASSETS, value: event.target.value });
  };

  const [updateInvitationResponse] = useMutation(
    updateInvitationResponseMutation,
  );

  return (
    <Grid.Container>
      {/* When editing a currently rejected invitation, the only option is accept so this part is hidden. */}
      {currentStatus !== INVITATION_STATUS.REJECTED && (
        <Grid.Row marginTop={4}>
          <Grid.Column span="6">
            <RadioButton
              label={t`Accept`}
              name="status"
              onChange={onChangeStatus}
              selected={status === STATUS.ACCEPTED}
              value={STATUS.ACCEPTED}
            />
          </Grid.Column>
          <Grid.Column span="6">
            <RadioButton
              label={t`Deny`}
              name="status"
              onChange={onChangeStatus}
              selected={status === STATUS.REJECTED}
              value={STATUS.REJECTED}
            />
          </Grid.Column>
        </Grid.Row>
      )}
      {status === STATUS.ACCEPTED && (
        <>
          <Grid.Row distribution="around" marginTop={4}>
            <Grid.Column>
              <P
                css={`
                  text-align: center;
                `}
                shade={1}
              >
                <Trans>
                  Enable access to all assets (current and future added)?
                </Trans>
              </P>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row marginTop={2}>
            <Grid.Column span="6">
              <RadioButton
                onChange={onChangeEnableAllAssets}
                label={t`Yes, enable all assets`}
                name="enableAllAssets"
                value={ENABLE_ALL_ASSETS.YES}
                selected={enableAllAssets === ENABLE_ALL_ASSETS.YES}
              />
            </Grid.Column>
            <Grid.Column span="6">
              <RadioButton
                onChange={onChangeEnableAllAssets}
                label={t`No, select assets`}
                name="enableAllAssets"
                value={ENABLE_ALL_ASSETS.NO}
                selected={enableAllAssets === ENABLE_ALL_ASSETS.NO}
              />
            </Grid.Column>
          </Grid.Row>
        </>
      )}
      <Grid.Row distribution="around" marginTop={4}>
        <Grid.Column span="0">
          <Button
            disabled={
              !(
                status === STATUS.REJECTED ||
                (status === STATUS.ACCEPTED && enableAllAssets)
              )
            }
            type="submit"
            text={
              status === STATUS.ACCEPTED &&
              enableAllAssets === ENABLE_ALL_ASSETS.NO
                ? t`Continue`
                : t`Submit`
            }
            onClick={() => {
              const input = {
                status,
                enableAllAssets: !(
                  status === STATUS.REJECTED ||
                  (status === STATUS.ACCEPTED &&
                    enableAllAssets === ENABLE_ALL_ASSETS.NO)
                ),
              };
              updateInvitationResponse({ variables: { input } });
            }}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid.Container>
  );
}

InvitationRequestAcceptForm.propTypes = {
  currentStatus: PropTypes.oneOf(Object.keys(INVITATION_STATUS)),
};

InvitationRequestAcceptForm.defaultProps = { currentStatus: undefined };

export default InvitationRequestAcceptForm;
