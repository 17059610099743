import React from 'react';
import { Trans, t } from '@lingui/macro';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

import Badge from '@decisiv/ui-components/lib/components/Badge';
import Button from '@decisiv/ui-components/lib/components/Button';
import Flex from '@decisiv/ui-components/lib/components/Flex';
import { H3 } from '@decisiv/ui-components/lib/components/Typography';

import Truck from '@decisiv/iconix/lib/components/Truck';

import Loading from 'components/Loading';

import Card from 'components/DashboardCard';
import ROUTE_NAMES from 'setup/Routing/routeNames';

const totalAssetsQuery = loader('./totalAssets.graphql');

const getAssetsCountText = (loading, totalEntries) => {
  if (loading) return '';

  return totalEntries === 0 ? '―' : totalEntries;
};

export default function TotalAssetsCard() {
  const navigate = useNavigate();
  const {
    loading,
    data: { assets: { pagination: { totalEntries } = {} } = {} } = {},
  } = useQuery(totalAssetsQuery);

  const onViewAll = () => navigate(ROUTE_NAMES.alerts);

  return (
    <Card marginTop={0}>
      <Flex flexDirection="column">
        <Flex alignItems="center" marginTop={1.25}>
          <Badge
            variant="square"
            icon={Truck}
            color={totalEntries === 0 ? 'licoriceMousse' : 'information'}
          />
          <Flex marginLeft={1} alignItems="center">
            <Trans>
              <H3 weight="semibold">
                {getAssetsCountText(loading, totalEntries)}
              </H3>
              <H3
                weight="medium"
                css={`
                  margin-left: 10px;
                  white-space: nowrap;
                `}
              >
                Total Assets
              </H3>
            </Trans>
            {loading && (
              <Loading inline imgDim={25}>
                <Trans>Loading Asset Information...</Trans>
              </Loading>
            )}
          </Flex>
        </Flex>
        {!loading && (
          <Flex justifyContent="end">
            <Button
              aria-label={t`View All Assets`}
              text={t`View All`}
              variant="ghost"
              onClick={onViewAll}
            />
          </Flex>
        )}
      </Flex>
    </Card>
  );
}
