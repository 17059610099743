import isNull from 'lodash/isNull';
import noop from 'lodash/noop';
import { useCallback, useState } from 'react';

import { useNotifications } from '@decisiv/ui-components/lib/components/Notifications';

import { t } from '@lingui/macro';

import api from 'setup/api';

const reader = new FileReader();

export default function useSentinelTeamForm(sentinelTeam = {}) {
  const { notify } = useNotifications();
  const [name, setName] = useState(sentinelTeam.name);
  const [applicationName, setApplicationName] = useState(
    sentinelTeam.applicationName,
  );
  const [logoUrl, setLogoUrl] = useState(sentinelTeam.logo?.url);
  const [logoFile, setLogoFile] = useState(null);
  const [logoChangesPending, setLogoChangesPending] = useState(false);

  const onSaveImage = useCallback(
    (file) => {
      setLogoChangesPending(true);
      // FIXME: add coverage for the image save process
      /* istanbul ignore else -- @preserve */
      if (isNull(file)) {
        setLogoFile(null);
        setLogoUrl(null);
      } else {
        setLogoFile(file);
        reader.readAsDataURL(file);
        reader.onload = () => {
          setLogoUrl(reader.result);
        };
      }
    },
    [setLogoFile, setLogoUrl],
  );

  const getSentinelTeamFormProps = useCallback(
    () => ({
      applicationName,
      name: name || '',
      handleApplicationNameChange: (e) => {
        setApplicationName(e.target.value);
      },
      handleNameChange: (e) => setName(e.target.value),
      handleSaveImage: onSaveImage,
      logoUrl,
    }),
    [applicationName, logoUrl, name, onSaveImage, setApplicationName, setName],
  );

  const resetProfile = useCallback(() => {
    setApplicationName(sentinelTeam.applicationName);
    setName(sentinelTeam.name);
    setLogoUrl(sentinelTeam.logo?.url);
    setLogoFile(null);
    setLogoChangesPending(false);
  }, [sentinelTeam, setApplicationName, setName]);

  const uploadImage = useCallback(
    async (sentinelTeamId, callback = noop) => {
      // FIXME: add coverage for the upload process
      /* istanbul ignore if -- @preserve */
      if (logoChangesPending) {
        const formData = new FormData();
        formData.append('logo', logoFile);

        const response = await api.put(
          `/service_group_logo_upload?id=${sentinelTeamId}`,
          isNull(logoFile) ? {} : formData,
        );

        if (response.status === 200) {
          callback(logoUrl);
        } else {
          callback(null);
          notify(
            {
              duration: 5000,
              onClose: noop,
              intent: 'danger',
              title: t`Logo Not Saved`,
            },
            t`An issue occurred while uploading your logo. Please try again.`,
          );
        }
      }
      callback(null);
    },
    [logoChangesPending, logoFile, logoUrl, notify],
  );

  return [
    { name, applicationName, logoUrl },
    { getSentinelTeamFormProps, resetProfile, uploadImage, onSaveImage },
  ];
}
