import { useEffect, useReducer } from 'react';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';

import { ALL } from '../AssetsFilterColumn';

const CLEAR_FILTERS = 'CLEAR_FILTERS';
const SET_PAGE = 'SET_PAGE';
const SET_PER_PAGE = 'SET_PER_PAGE';
const SET_QUERY = 'SET_QUERY';
const TOGGLE_FILTERS = 'TOGGLE_FILTERS';
const UPDATE_ENABLED = 'UPDATE_ENABLED';

const changeEnabledFilter = (state, action) => {
  const { appliedFilters } = state;

  appliedFilters.enabled = action.value;
  return { ...state, appliedFilters };
};

const toggleFilters = (state, action) => {
  const { appliedFilters: oldAppliedFilters } = state;

  if (isEmpty(action.value)) {
    // eslint-disable-next-line no-unused-vars
    const { [action.key]: omitted, ...appliedFilters } = oldAppliedFilters;
    return { ...state, appliedFilters };
  }

  return {
    ...state,
    appliedFilters: {
      ...oldAppliedFilters,
      [action.key]: action.value,
    },
  };
};

export function reducer(state, action) {
  switch (action.type) {
    case CLEAR_FILTERS:
      return { ...state, appliedFilters: { enabled: ALL } };
    case SET_PAGE:
      return { ...state, page: action.page };
    case SET_PER_PAGE:
      return { ...state, perPage: action.perPage };
    case SET_QUERY:
      return { ...state, query: action.query };
    case TOGGLE_FILTERS:
      return toggleFilters(state, action);
    case UPDATE_ENABLED:
      return changeEnabledFilter(state, action);
    default:
      return state;
  }
}

export default function useFilters() {
  const initialState = {
    query: '',
    appliedFilters: { enabled: ALL },
    page: 1,
    perPage: 20,
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    dispatch({ type: SET_PAGE, page: 1 });
  }, [state.query, state.appliedFilters]);

  const getAssetFiltersColumnProps = () => ({
    appliedFilters: omit(state.appliedFilters, 'enabled'),
    clearFilters: () => dispatch({ type: CLEAR_FILTERS }),
    onQueryChange: (query) => {
      if (query !== state.query) {
        dispatch({ type: SET_QUERY, query });
      }
    },
    onFilterChange: (key, value) => {
      dispatch({ type: TOGGLE_FILTERS, key, value });
    },
    onRadioChange: ({ target: { value } }) => {
      dispatch({ type: UPDATE_ENABLED, value });
    },
    radioValue: state.appliedFilters.enabled,
  });

  const getAssetsListProps = () => ({
    query: state.query,
    setCurrentPage: (page) => dispatch({ type: SET_PAGE, page }),
    setPerPage: (perPage) =>
      dispatch({ type: SET_PER_PAGE, perPage: Number(perPage) }),
  });

  return [state, { getAssetFiltersColumnProps, getAssetsListProps }];
}
