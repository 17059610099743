/* eslint-disable jsx-a11y/anchor-is-valid */

import { loader } from 'graphql.macro';
import { useCallback, useEffect } from 'react';

import { useQuery } from '@apollo/client';

import { P } from '@decisiv/ui-components/lib/components/Typography';
import Link from '@decisiv/ui-components/lib/components/Link';

import { t, Trans } from '@lingui/macro';

import { Container, LogoContainer, Logo, TextContainer, Links } from './styles';

import decisivLogo from './logo.png';

const versionQuery = loader('./version.graphql');

function Versions() {
  const { data } = useQuery(versionQuery);
  const year = new Date().getFullYear();

  // see https://docs.osano.com/developer-documentation-consent-javascript-api#mode
  const osanoEnabled =
    !!import.meta.env.VITE_OSANO_JS &&
    typeof Osano !== 'undefined' &&
    Osano.cm.mode !== 'debug';

  useEffect(() => {
    if (typeof Osano === 'undefined') {
      // eslint-disable-next-line no-console
      console.log('Osano has not been loaded');
    } else {
      const msg = `Osano is ${osanoEnabled ? 'ENABLED' : 'DISABLED'}
    JS URL: ${import.meta.env.VITE_OSANO_JS}
    Runtime mode: ${Osano?.cm.mode}`;

      // eslint-disable-next-line no-console
      console.log(msg);
    }
  }, [osanoEnabled]);

  const uiVersion = import.meta.env.VITE_VERSION;
  const apiVersion = data?.version.version;

  const openCookiePreferences = useCallback(() => {
    Osano.cm.showDrawer('osano-cm-dom-info-dialog-open');
  }, []);

  return (
    <Container>
      <LogoContainer>
        <Logo alt="logo" src={decisivLogo} />
      </LogoContainer>
      <TextContainer>
        <P size="small">
          <Trans>&copy; {year} Decisiv, Inc.</Trans>
        </P>
        <P size="small">
          <Trans>UI {uiVersion}</Trans>
        </P>
        <P size="small">
          <Trans>API {apiVersion}</Trans>
        </P>
      </TextContainer>
      <Links>
        <Link
          to="https://www.decisiv.com/data-governance-statement"
          text={t`Data Governance`}
        />
        |
        <Link
          to="https://www.decisiv.com/privacy-policy"
          text={t`Privacy Policy`}
        />
        |
        <Link
          to="https://www.decisiv.com/terms-of-use"
          text={t`Terms of Use`}
        />
        |
        <Link
          to="https://www.decisiv.com/cookie-policy"
          text={t`Cookie Policy`}
        />
        {osanoEnabled && (
          <>
            &amp;
            <Link
              to="#"
              text={t`Preferences`}
              onClick={openCookiePreferences}
            />
          </>
        )}
      </Links>
    </Container>
  );
}

export default Versions;
