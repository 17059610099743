import React, { useContext } from 'react';
import { t } from '@lingui/macro';
import { useQuery } from '@apollo/client';

import Button from '@decisiv/ui-components/lib/components/Button';
import Flex from '@decisiv/ui-components/lib/components/Flex';
import KDSTopNav from '@decisiv/ui-components/lib/components/TopNav';

import { CurrentInvitationContext, logout } from 'utils/Invitation';
import { useCurrentUserContext } from 'utils/User';

import useSentinelTeam from 'hooks/useSentinelTeam';
import userInfoQuery from 'utils/User/currentUserProfile.graphql';

import TeamsDropdown from './TeamsDropdown';
import BrandInfo from './BrandInfo';
import CurrentCallInfo from './CurrentCallInfo';
import SearchLink from './SearchLink';
import NotificationCenter from './NotificationCenter';
import UserMenu from './UserMenu';
import AmazonConnectMenu from './AmazonConnectMenu';
import CaseLinks from './CaseLinks';

function TopNav() {
  const { id: invitationId, authenticationKey } = useContext(
    CurrentInvitationContext,
  );
  const { id: userId } = useCurrentUserContext();

  const { hasSentinelTeam } = useSentinelTeam();

  const { loading, data } = useQuery(userInfoQuery, {
    fetchPolicy: 'cache-and-network',
    context: { ignoreInvitationAuth: true },
  });

  const showEndSession =
    invitationId || (!loading && data && authenticationKey);

  return (
    <KDSTopNav>
      <Flex
        css={`
          height: 100%;
        `}
        alignItems="center"
      >
        <TeamsDropdown>
          <BrandInfo />
          <CurrentCallInfo />
        </TeamsDropdown>
      </Flex>
      <Flex
        css={`
          height: 100%;
        `}
      >
        {userId && (
          <>
            <SearchLink />
            <NotificationCenter />
            <UserMenu />
            <AmazonConnectMenu />
            {hasSentinelTeam && <CaseLinks />}
          </>
        )}
        {showEndSession && (
          <Flex alignItems="center" marginRight={2}>
            <Button
              kind="secondary"
              onClick={logout}
              size="small"
              text={t`End Session`}
              variant="inverted"
            />
          </Flex>
        )}
      </Flex>
    </KDSTopNav>
  );
}

export default TopNav;
