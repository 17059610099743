import React, {
  useState,
  createContext,
  useMemo,
  useCallback,
  useContext,
} from 'react';
import isEqual from 'lodash/isEqual';

import useSentinelTeam from 'hooks/useSentinelTeam';
import useQueryParams from 'hooks/useQueryParams';

import { useAssetViewContext } from 'pages/AssetView/AssetViewContext';

export interface IGlobalFiltersContext {
  platformAccountIds: string[];
  setPlatformAccountIds: (ids: string[]) => void;
  assetGroupingIds: string[];
  setAssetGroupingIds: (ids: string[]) => void;
  groupedAssetGroupingsIds: string[];
  setGroupedAssetGroupingsIds: (ids: string[]) => void;
}

const defaultAppliedFilters = {
  groupedAssetGroupingsIds: [],
  setGroupedAssetGroupingsIds: () => null,
  assetGroupingIds: [],
  setAssetGroupingIds: () => null,
  platformAccountIds: [],
  setPlatformAccountIds: () => null,
} satisfies IGlobalFiltersContext;

export const GlobalFiltersContext = createContext<IGlobalFiltersContext>(
  defaultAppliedFilters,
);

interface GlobalFiltersProviderProps {
  children: React.ReactNode;
}

export default function GlobalFiltersProvider({
  children,
}: GlobalFiltersProviderProps) {
  const { id: teamId } = useSentinelTeam();

  // We can render GlobalFiltersProvider without AssetViewContext
  const { globalFiltersState = undefined } = useAssetViewContext() as {
    globalFiltersState: IGlobalFiltersContext | undefined;
  };

  /* const ab = useAlertsContext();

   * console.log('>>>>', ab);

   * const { setAppliedFilters } = ab; */

  const [defaultFilters, setDefaultFilters] = useState(
    globalFiltersState ?? defaultAppliedFilters,
  );

  const [filters, setFilters] = useQueryParams(
    `${teamId}-global-filters`,
    defaultFilters,
    { typeCoercion: false },
  );

  // Defines if filters have changed
  const isGlobalFiltersChanged = useMemo(
    () => !isEqual(defaultFilters, filters),
    [defaultFilters, filters],
  );

  const { assetGroupingIds, platformAccountIds, groupedAssetGroupingsIds } =
    filters;

  const setAssetGroupingIds = useCallback(
    (ids: string[]) => {
      setFilters({
        ...filters,
        assetGroupingIds: ids,
        groupedAssetGroupingsIds: [],
      });
    },
    [filters, setFilters],
  );

  const setPlatformAccountIds = useCallback(
    (ids: string[]) => {
      setFilters({
        ...filters,
        platformAccountIds: ids,
        assetGroupingIds: [],
        groupedAssetGroupingsIds: [],
      });
    },
    [filters, setFilters],
  );

  const setGroupedAssetGroupingsIds = useCallback(
    (ids: string[]) => {
      setFilters({
        ...filters,
        groupedAssetGroupingsIds: ids,
        assetGroupingIds: [],
      });
    },
    [filters, setFilters],
  );

  const resetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, [defaultFilters, setFilters]);

  const updateDefaultFilters = useCallback(() => {
    setDefaultFilters(filters);
  }, [filters]);

  const value = useMemo(
    () => ({
      assetGroupingIds,
      platformAccountIds,
      groupedAssetGroupingsIds,
      setAssetGroupingIds,
      setPlatformAccountIds,
      setGroupedAssetGroupingsIds,
      isGlobalFiltersChanged,
      defaultFilters,
      setDefaultFilters,
      updateDefaultFilters,
      resetFilters,
    }),
    [
      assetGroupingIds,
      setAssetGroupingIds,
      platformAccountIds,
      setPlatformAccountIds,
      groupedAssetGroupingsIds,
      setGroupedAssetGroupingsIds,
      isGlobalFiltersChanged,
      defaultFilters,
      updateDefaultFilters,
      resetFilters,
    ],
  );

  return (
    <GlobalFiltersContext.Provider value={value}>
      {children}
    </GlobalFiltersContext.Provider>
  );
}

export function useGlobalFiltersContext() {
  return useContext(GlobalFiltersContext);
}
