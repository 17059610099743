import React from 'react';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';

import Robot from '@decisiv/iconix/lib/components/Robot';

import Badge from '@decisiv/ui-components/lib/components/Badge';
import Avatar from '@decisiv/ui-components/lib/components/Avatar';

export default function AuditBadge({ audit, kind }) {
  const { name, email } =
    audit.createdBy || audit.changedBy || audit.performedBy || {};

  const isUser = name !== 'System' || email;

  if (isUser) return <Avatar email={email} name={name} kind={kind} />;

  return <Badge aria-label={t`System`} color="licoriceMousse" icon={Robot} />;
}

AuditBadge.propTypes = {
  audit: PropTypes.shape({
    createdBy: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      email: PropTypes.string,
    }),
    changedBy: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      email: PropTypes.string,
    }),
    performedBy: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      email: PropTypes.string,
    }),
  }).isRequired,
  kind: PropTypes.oneOf(['primary', 'secondary']),
};

AuditBadge.defaultProps = {
  kind: 'primary',
};
