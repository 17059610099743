import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';

const alert = {
  id: PropTypes.string.isRequired,
  severity: PropTypes.string,
  timestamp: PropTypes.string,
};

const customer = {
  id: PropTypes.string.isRequired,
  activeAppointmentsCount: PropTypes.number,
  city: PropTypes.string,
  name: PropTypes.string,
  primaryPhoneNumber: PropTypes.string,
  state: PropTypes.string,
};

const serviceProvider = {
  id: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  distance: PropTypes.number,
  location: PropTypes.shape({
    latitude: PropTypes.number.isRequired,
    longitude: PropTypes.number.isRequired,
  }),
  name: PropTypes.string.isRequired,
  primaryPhoneNumber: PropTypes.string,
  state: PropTypes.string.isRequired,
  zip: PropTypes.string,
};

const platformServiceLocation = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
};

const platformCase = {
  id: PropTypes.string.isRequired,
  apiCaseId: PropTypes.number.isRequired,
  status: PropTypes.string,
  url: PropTypes.string.isRequired,
  caseCreatedAt: PropTypes.string.isRequired,
  platformServiceLocation: PropTypes.shape(platformServiceLocation),
};

// #TODO: adjust this values when moving out of feature flag
const platformServiceRequest = {
  id: PropTypes.string.isRequired,
  referenceNumber: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  changedBy: PropTypes.string,
  changedAt: PropTypes.string,
  appointmentDate: PropTypes.string,
  declinedReason: PropTypes.string,
};

const assetBuilder = {
  id: PropTypes.string.isRequired,
  alerts: PropTypes.arrayOf(PropTypes.shape(alert)),
  assetType: PropTypes.string,
  chassisId: PropTypes.string,
  customer: PropTypes.shape(customer),
  engineHours: PropTypes.number,
  location: PropTypes.shape({
    latitude: PropTypes.number.isRequired,
    longitude: PropTypes.number.isRequired,
  }),
  make: PropTypes.string.isRequired,
  mileage: PropTypes.number,
  model: PropTypes.string.isRequired,
  platformCases: PropTypes.arrayOf(PropTypes.shape(platformCase)),
  serialNumber: PropTypes.string.isRequired,
  unitNumber: PropTypes.string,
  unresolvedAssets: PropTypes.arrayOf(PropTypes.shape(alert)),
  vin: PropTypes.string,
  year: PropTypes.string.isRequired,
};

const appointmentBuilder = {
  id: PropTypes.string.isRequired,
  alertRequests: PropTypes.arrayOf(PropTypes.shape(alert)),
  status: PropTypes.string.isRequired,
  overdue: PropTypes.bool.isRequired,
  createdAt: PropTypes.string,
  customRequests: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      note: PropTypes.string.isRequired,
    }),
  ),
  requestedTime: PropTypes.string,
  completionTime: PropTypes.string,
  firstAvailable: PropTypes.bool,
  serviceProvider: PropTypes.shape(serviceProvider),
  webCaseUrl: PropTypes.string,
  platformCase: PropTypes.shape(platformCase),
  platformServiceRequest: PropTypes.shape(platformServiceRequest),
};

const asset = {
  appointments: PropTypes.arrayOf(PropTypes.shape(appointmentBuilder)),
  ...assetBuilder,
};

const appointment = {
  asset: assetBuilder,
  ...appointmentBuilder,
};

const objects = {
  alert,
  appointment,
  asset,
  customer,
  platformCase,
  platformServiceRequest,
  serviceProvider,
};

export const buildPropTypes = (object, fields = []) => {
  const mappedFields = objects[object] || {};

  /* istanbul ignore next -- @preserve */
  if (isEmpty(mappedFields))
    // eslint-disable-next-line no-console
    console.error(Error('No object selected from builder.').stack);

  return PropTypes.shape(
    isEmpty(fields) ? mappedFields : pick(mappedFields, fields),
  );
};
