import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import Flex from '@decisiv/ui-components/lib/components/Flex';
import { P } from '@decisiv/ui-components/lib/components/Typography';

import { t, Trans } from '@lingui/macro';

import Modal from '@decisiv/ui-components/lib/components/Modal';

import loadingGif from 'components/Loading/loading.gif';
import useSentinelTeam from 'hooks/useSentinelTeam';

function Loading() {
  return <img height="32" width="32" src={loadingGif} alt={t`loading`} />;
}

export default function ConfirmDisableDepotModal({
  depot,
  loading,
  visible,
  handleClose,
  onDisableClick,
}) {
  const sentinelTeam = useSentinelTeam();

  const sentinelTeamName = useMemo(() => sentinelTeam.name, [sentinelTeam]);

  const depotName = useMemo(() => depot.name, [depot.name]);

  const modalProps = useMemo(() => {
    if (loading) {
      return {
        icon: Loading,
        intent: 'information',
        title: t`Disabling Depot...`,
      };
    }
    return {
      actions: [
        {
          intent: 'danger',
          text: t`Disable Depot`,
          disabled: loading,
          onClick: onDisableClick,
        },
        { text: t`Keep Depot`, onClick: handleClose },
      ],
      intent: 'danger',
      onClose: handleClose,
      title: t`Disable Depot?`,
    };
  }, [handleClose, loading, onDisableClick]);

  return (
    <Modal {...modalProps} visible={visible}>
      {loading ? (
        <P shade={1} marginTop={0.5}>
          <Trans>
            Please wait while we disable{' '}
            <P as="span" weight="semibold">
              {depotName}
            </P>{' '}
            for the{' '}
            <P as="span" weight="semibold">
              {sentinelTeamName}
            </P>{' '}
            team.
          </Trans>
        </P>
      ) : (
        <Flex flexDirection="column">
          <P shade={1} marginTop={0.5}>
            <Trans>
              You are about to disable{' '}
              <P as="span" weight="semibold">
                {depotName}
              </P>{' '}
              and all their assets from the{' '}
              <P as="span" weight="semibold">
                {sentinelTeamName}
              </P>{' '}
              team. Team members will no longer see scheduled maintenance alerts
              for assets that belong to this depot. Draft service requests will
              be canceled.
            </Trans>
          </P>
        </Flex>
      )}
    </Modal>
  );
}

ConfirmDisableDepotModal.propTypes = {
  sentinelTeamName: PropTypes.string,
  depot: PropTypes.shape({
    name: PropTypes.string,
    city: PropTypes.string,
    enabled: PropTypes.bool,
    state: PropTypes.string,
    primaryPhoneNumber: PropTypes.string,
  }).isRequired,
  visible: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onDisableClick: PropTypes.func.isRequired,
};

ConfirmDisableDepotModal.defaultProps = {
  sentinelTeamName: '',
};
