import React from 'react';
import PropTypes from 'prop-types';

import toColorString from 'polished/lib/color/toColorString';
import color from '@decisiv/design-tokens/lib/color';

import { Trans } from '@lingui/macro';

import Avatar from '@decisiv/ui-components/lib/components/Avatar';
import Grid from '@decisiv/ui-components/lib/components/Grid';
import Flex from '@decisiv/ui-components/lib/components/Flex';
import { P } from '@decisiv/ui-components/lib/components/Typography';

import ActiveAlertLink from '../../ActiveAlertLink';

export default function ListChartAsset({
  id,
  title,
  subtitle,
  count,
  percentage,
  enabled,
  ranking,
}) {
  return (
    <Grid.Row alignment="middle" marginTop={1}>
      <Grid.Column span="0" paddingTop={1} height="100%">
        <Flex alignItems="center">
          <P color="alaskanHusky" size="small" data-testid="ranking">
            {ranking}
          </P>
        </Flex>
      </Grid.Column>
      <Grid.Column
        span="1"
        paddingX={0}
        paddingTop={1}
        height="100%"
        css={`
          width: fit-content;
          max-width: fit-content;
        `}
      >
        <Avatar kind={enabled ? 'primary' : 'secondary'} name={title} />
      </Grid.Column>
      <Grid.Column
        css={`
          border-top: ${ranking > 1
            ? `1px solid ${toColorString(color.opacity.charcoal15)}`
            : undefined};
        `}
        height="100%"
        marginX={1}
        paddingLeft={0}
        paddingTop={1}
      >
        <Flex data-testid="information-data">
          <Flex flexDirection="column">
            <ActiveAlertLink assetGroupingIds={[id]} text={title} />
            <P color="alaskanHusky" size="small" weight="medium">
              {subtitle}
              {enabled || (
                <>
                  &nbsp;•
                  <P
                    as="span"
                    color="alaskanHusky"
                    size="small"
                    weight="regular"
                  >
                    &nbsp;(<Trans>Removed</Trans>)
                  </P>
                </>
              )}
            </P>
          </Flex>
          <Flex
            alignItems="center"
            css={`
              margin-left: auto;
            `}
          >
            <P>{count}</P>
            <Flex marginX={1}>
              <P color="alaskanHusky">•</P>
            </Flex>
            <P color="alaskanHusky">{`${percentage}%`}</P>
          </Flex>
        </Flex>
      </Grid.Column>
    </Grid.Row>
  );
}

ListChartAsset.propTypes = {
  percentage: PropTypes.number.isRequired,
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  enabled: PropTypes.bool,
  ranking: PropTypes.number.isRequired,
};

ListChartAsset.defaultProps = {
  enabled: true,
};
