/* istanbul ignore file -- @preserve */
import { useCallback, useContext, useMemo } from 'react';
import { parsePhoneNumber } from 'libphonenumber-js';
import debounce from 'lodash/debounce';

import { AmazonConnectContext } from './AmazonConnect';

/**
 * Hook to provide a function that can be called to initiate an outbound
 * call, along with information relevant to any in-progress calls.
 */
export default function useClickToCall({ phone }) {
  const phoneNumber = useMemo(() => {
    try {
      return parsePhoneNumber(phone, 'US').number;
    } catch (e) {
      return '';
    }
  }, [phone]);

  const {
    makeOutboundCall,
    contactNumber: currentCallNumber,
    contact,
  } = useContext(AmazonConnectContext);

  const isConnected =
    contact && contact.isConnected() && phoneNumber === currentCallNumber;
  const isInbound = contact && contact.isInbound();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const placeCall = useCallback(
    debounce(
      () => {
        if (!makeOutboundCall) return;

        makeOutboundCall(phoneNumber);
      },
      3000,
      { leading: true, trailing: false },
    ),
    [phone, makeOutboundCall],
  );

  return {
    currentCallNumber,
    enabled: !!makeOutboundCall,
    isConnected,
    isInbound,
    phoneNumber,
    placeCall,
  };
}
