// TODO: rework this whole utility module
import {
  parseQueryParams,
  updateQueryParams,
  removeQueryParamsArrayValue,
} from 'utils/url';

import compact from 'lodash/compact';
import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';

const CUSTOMER_KEY = 'customerIds';

const getTeamSession = (teamId) => JSON.parse(sessionDB.getItem(teamId));

const setSessionValue = (key, value, teamId) => {
  const teamObj = getTeamSession(teamId);

  let newTeamSession = teamObj;
  if (isEmpty(value)) {
    newTeamSession = omit(teamObj, [key]);
  } else {
    newTeamSession = { ...teamObj, [key]: value };
  }

  return sessionDB.setItem(teamId, JSON.stringify(newTeamSession));
};

const getSessionValue = (key, teamId) => {
  const teamObj = getTeamSession(teamId);
  return teamObj ? teamObj[key] : null;
};

const sessionIncludes = (key, value, teamId) => {
  const sessionArray = getSessionValue(key, teamId);
  return !isEmpty(sessionArray) && sessionArray.includes(value);
};

const persistSelectValue = (selectAttr, value, teamId) => {
  setSessionValue(selectAttr, value, teamId);
  updateQueryParams(window.location.search, selectAttr, value);
};

const persistFilterValue = (filterAttr, value, teamId) => {
  if (!teamId) return;

  const persistedFilters = getSessionValue(filterAttr, teamId) || [];

  const newValue = [...new Set([...persistedFilters, ...value])];

  setSessionValue(filterAttr, newValue, teamId);
};

const removeFilterValue = (filterAttr, value, teamId) => {
  const persistedFilters = getSessionValue(filterAttr, teamId);
  removeQueryParamsArrayValue(window.location.search, 'filters', value);

  setSessionValue(
    filterAttr,
    persistedFilters.filter((filter) => filter !== value),
    teamId,
  );
};

const getCustomerIds = (teamId) => {
  const customerIds = getSessionValue(CUSTOMER_KEY, teamId);

  if (!isEmpty(customerIds)) {
    persistSelectValue(CUSTOMER_KEY, customerIds, teamId);
  } else {
    persistSelectValue(CUSTOMER_KEY, [], teamId);
  }

  const customerIdsArr =
    Array.isArray(customerIds) && !isEmpty(customerIds) && compact(customerIds);
  return isEmpty(customerIdsArr) ? null : customerIdsArr;
};

const getFilters = (filterKey, searchParam, singleValue = false, teamId) => {
  const filters = parseQueryParams(window.location.search)[searchParam]?.split(
    ',',
  );
  const persistedFilters = getSessionValue(filterKey, teamId) || [];

  if (isEmpty(persistedFilters) && !isEmpty(filters)) {
    persistFilterValue(filterKey, filters, teamId);
    return singleValue && Array.isArray(filters) ? filters[0] : filters;
  }

  updateQueryParams(window.location.search, searchParam, persistedFilters);
  return singleValue && Array.isArray(persistedFilters)
    ? persistedFilters[0]
    : persistedFilters;
};

export {
  setSessionValue,
  getSessionValue,
  sessionIncludes,
  persistSelectValue,
  persistFilterValue,
  removeFilterValue,
  getCustomerIds,
  getFilters,
};
