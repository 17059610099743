import { VEHICLE_RELATION_TITLES } from './constants';

export { VEHICLE_RELATION_OPTIONS } from './constants';

export function titleForVehicleRelation(vehicleRelation) {
  return VEHICLE_RELATION_TITLES[vehicleRelation];
}

export function formatName(contact) {
  const { firstName, lastName } = contact;
  return [firstName, lastName].filter(Boolean).join(' ');
}
