import React from 'react';
import get from 'lodash/get';
import replace from 'lodash/replace';

import Lock from '@decisiv/iconix/lib/components/Lock';
import RoadBlock from '@decisiv/iconix/lib/components/RoadBlock';
import Unlink from '@decisiv/iconix/lib/components/Unlink';

import Button from '@decisiv/ui-components/lib/components/Button';
import Flex from '@decisiv/ui-components/lib/components/Flex';
import Grid from '@decisiv/ui-components/lib/components/Grid';
import Message from '@decisiv/ui-components/lib/components/Message';
import { P } from '@decisiv/ui-components/lib/components/Typography';

import { Trans, t } from '@lingui/macro';

import Loading from 'components/Loading';
import Page from 'components/Page';
import Paper from 'components/Paper';
import SwitchSessionBanner from 'components/SwitchSessionBanner';

import AssetGrouping from 'pages/AssetGrouping';
import InvitationHeader from 'pages/AssetGrouping/InvitationHeader';

import { CurrentInvitationContext, INVITATION_STATUS } from 'utils/Invitation';
import { parseDate } from 'utils/dates';
import { nameForUser } from 'utils/User';

import InvitationRequestAcceptForm from './InvitationRequestAcceptForm';

function InvitationsRequestPage() {
  const { createdBy, customer, id, status, loading, updatedAt, error } =
    React.useContext(CurrentInvitationContext);

  const [editing, setEditing] = React.useState(false);

  const messagePropsForStatus = React.useMemo(() => {
    if (
      get(error, 'graphQLErrors[0].extensions.detail') ===
      INVITATION_STATUS.TEAM_DISABLED
    ) {
      const teamName = replace(
        get(error, 'graphQLErrors[0].message', '—'),
        /disabled$/,
        '',
      );

      return {
        size: 'large',
        title: t`The Link You Followed Has Expired`,
        color: 'warning',
        icon: Unlink,
        children: (
          <>
            <Trans>Contact {teamName} Team Admin for more information.</Trans>
          </>
        ),
      };
    }

    if (!id) {
      return {
        size: 'large',
        title: t`Access Request not Found`,
        color: 'warning',
        icon: RoadBlock,
        children: (
          <>
            <Trans>
              The access request is invalid or was canceled. Make sure you have
              the correct URL.
            </Trans>
          </>
        ),
      };
    }

    const createdByName = nameForUser(createdBy);

    const customerName = customer.name;
    const invitationStatusMessageProps = {
      [INVITATION_STATUS.REJECTED]: {
        size: 'large',
        title: t`Access Request Denied`,
        color: 'warning',
        icon: Lock,
        children: (
          <>
            <Trans>
              <P as="span" weight="semibold">
                {customerName}
              </P>{' '}
              denied{' '}
              <P as="span" weight="semibold">
                {createdByName}
              </P>{' '}
              the option to access{' '}
              <P as="span" weight="semibold">
                {customerName}&apos;s
              </P>{' '}
              asset and alert information.
            </Trans>
            <br />
            <br />
            {parseDate(updatedAt).join(', ')}
          </>
        ),
      },
      [INVITATION_STATUS.UNANSWERED]: {
        size: 'large',
        title: t`Access Request`,
        icon: Lock,
        children: (
          <>
            <Trans>
              <P as="span" weight="semibold">
                {createdByName}
              </P>{' '}
              is requesting access to{' '}
              <P as="span" weight="semibold">
                {customerName}&apos;s
              </P>{' '}
              asset information, so the team can proactively address alert
              information and create service appointments for{' '}
              <P as="span" weight="semibold">
                {customerName}&apos;s
              </P>{' '}
              assets.
            </Trans>
            <br />
            <br />
            <Trans>
              The asset information requested includes scheduled maintenance,
              active recalls, expiring warranties, and recent faults.
            </Trans>
          </>
        ),
      },
    };

    return invitationStatusMessageProps[status];
  }, [createdBy, customer, id, status, updatedAt, error]);

  if (loading) {
    return <Loading />;
  }

  if (status === INVITATION_STATUS.ACCEPTED) {
    return (
      <AssetGrouping
        banner={
          <>
            <SwitchSessionBanner />
            <InvitationHeader customer={customer} />
          </>
        }
      />
    );
  }

  return (
    <Page css="min-height: 100%;" padding={0}>
      <SwitchSessionBanner />
      <Grid.Row margin={0} padding={0} paddingY={2}>
        <Grid.Column paddingX={2} padding={0}>
          <Paper padding={0}>
            <Flex justifyContent="center" padding={4}>
              <Grid.Container
                css={`
                  max-width: 480px;
                `}
              >
                <Grid.Row>
                  <Grid.Column>
                    <Message
                      css="max-width: 480px;"
                      {...messagePropsForStatus}
                    />
                  </Grid.Column>
                </Grid.Row>
                {status === INVITATION_STATUS.REJECTED && !editing && (
                  <Grid.Row distribution="around" marginTop={4}>
                    <Grid.Column span="0">
                      <Button
                        kind="secondary"
                        onClick={() => {
                          setEditing(true);
                        }}
                        text={t`Accept`}
                        type="button"
                      />
                    </Grid.Column>
                  </Grid.Row>
                )}
                {(status === INVITATION_STATUS.UNANSWERED ||
                  (status === INVITATION_STATUS.REJECTED && editing)) && (
                  <Grid.Row>
                    <Grid.Column>
                      <InvitationRequestAcceptForm currentStatus={status} />
                    </Grid.Column>
                  </Grid.Row>
                )}
              </Grid.Container>
            </Flex>
          </Paper>
        </Grid.Column>
      </Grid.Row>
    </Page>
  );
}

export default InvitationsRequestPage;
