import { color } from '@decisiv/design-tokens';
import { toColorString } from 'polished';

const colorSequence = [
  'carrotCake',
  'cookieMonster',
  'cottonCandy',
  'berryCrisp',
  'fizzyLime',
  'mamboMango',
  'roseGummy',
  'koolaidCake',
  'licoriceMousse',
];

const colorVariations = ['medium', 'dark', 'light'];

function barChartColorMap(index) {
  const colorIndex = index % colorSequence.length;
  const variationIndex =
    Math.floor(index / colorSequence.length) % colorVariations.length;
  const barFillColor = color.visualInterest[colorSequence[colorIndex]];
  const variation = colorVariations[variationIndex];
  return toColorString(barFillColor[variation]);
}

export default barChartColorMap;
