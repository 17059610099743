import React from 'react';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';

import BeforeAfterRow from 'components/BeforeAfterRow';
import StatusRow from 'components/StatusRow';

import {
  badgeColorForCustomer,
  badgeIconForCustomer,
  labelForCustomerStatus,
} from 'utils/Customer';

const DEPOT_ACTION_TEXT = {
  ADDED: t`enabled`,
  REMOVED: t`disabled`,
  UPDATED: t`updated the`,
};

const CUSTOMER_ACTION_TEXT = {
  ADDED: t`selected`,
  REMOVED: t`removed`,
  UPDATED: t`updated the`,
};

export function assetGroupingActionText(action, isFleet) {
  if (isFleet) return DEPOT_ACTION_TEXT[action];

  return CUSTOMER_ACTION_TEXT[action];
}

export function assetGroupingAttributeText(items, isFleet) {
  if (isFleet) return items.length ? t`depot status` : t`depot`;
  return items.length ? t`customer status` : t`customer`;
}

const renderStatus = ({ endTime, startTime, status }) => {
  return (
    <StatusRow
      badgeColor={badgeColorForCustomer({ status })}
      badgeIcon={badgeIconForCustomer({ status })}
      endTime={endTime}
      startTime={startTime}
      status={labelForCustomerStatus({ status })}
    />
  );
};

export default function AssetGroupingHistory({ item: { statusChange } }) {
  return <BeforeAfterRow {...statusChange} renderData={renderStatus} />;
}

AssetGroupingHistory.propTypes = {
  item: PropTypes.shape({
    statusChange: PropTypes.shape({}).isRequired,
  }).isRequired,
};
