import React from 'react';
import PropTypes from 'prop-types';

import CaseTag from 'components/CaseTag';
import ServiceRequestTag from 'components/ServiceRequestTag';

function ServiceEventAction({ appointment }) {
  const { platformCase, platformServiceRequest } = appointment;

  if (platformCase) {
    return <CaseTag caseInfo={platformCase} data-testid="casetag" />;
  }

  if (platformServiceRequest) {
    return (
      <ServiceRequestTag
        serviceRequest={platformServiceRequest}
        data-testid="srtag"
      />
    );
  }

  return null;
}

ServiceEventAction.propTypes = {
  appointment: PropTypes.shape({
    id: PropTypes.string.isRequired,
    platformCase: PropTypes.shape({
      id: PropTypes.string,
      url: PropTypes.string,
      status: PropTypes.string,
      referenceNumber: PropTypes.string,
    }),
    platformServiceRequest: PropTypes.shape({
      id: PropTypes.string,
      url: PropTypes.string,
      status: PropTypes.string,
      referenceNumber: PropTypes.string,
    }),
  }).isRequired,
};

export default ServiceEventAction;
