import { useState, useEffect, useCallback, useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';

import useUpdateEffect from 'hooks/useUpdateEffect';

import {
  sanitizeDefaultValues,
  parseLocationSearch,
  generateObjectFromSearch,
} from './utils';
import locationController from './LocationController';

interface UseStorageOptions {
  persistOnDB?: boolean;
  persistOnUrl?: boolean;
  storageObject?: any;
  typeCoercion?: boolean;
  persistFalsy?: boolean;
}

export default function useStorage<T>(
  key: string,
  defaultValue: T,
  {
    persistOnDB = true,
    persistOnUrl = false,
    storageObject = sessionDB,
    persistFalsy = true,
    typeCoercion = false,
  }: UseStorageOptions = {},
) {
  const [value, setValue] = useState<T>(() => {
    const searchValues = parseLocationSearch({ typeCoercion });

    const persistedQueryValues = persistOnUrl
      ? generateObjectFromSearch(searchValues, defaultValue)
      : {};

    const persistedDbJson = persistOnDB
      ? storageObject.getItem(key)
      : undefined;

    const persistedDbValues = persistedDbJson
      ? JSON.parse(persistedDbJson)
      : {};

    const valueToPersist = isEmpty(searchValues)
      ? persistedDbValues
      : persistedQueryValues;

    return { ...defaultValue, ...valueToPersist };
  });

  const valuesToRegister = useMemo(
    () => (value ? sanitizeDefaultValues(value, defaultValue) : {}),
    [value, defaultValue],
  );

  const updateStorage = useCallback(() => {
    if (!persistOnDB) return;

    if (value === undefined && !persistFalsy) {
      storageObject.removeItem(key);
      return;
    }
    storageObject.setItem(key, JSON.stringify(value));
  }, [key, persistFalsy, storageObject, value, persistOnDB]);

  useEffect(() => {
    locationController.register(key, defaultValue, valuesToRegister);

    return () => locationController.unregister(key);
  }, [key, valuesToRegister, defaultValue]);

  useUpdateEffect(() => {
    locationController.replaceLocationState();
  }, [valuesToRegister, key]);

  useEffect(() => {
    updateStorage();
  }, [updateStorage]);

  const reset = useCallback(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return [value, setValue, reset] as const;
}
