import React, { useContext, useMemo } from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import { CurrentInvitationContext } from 'utils/Invitation';
import ROUTE_NAMES from 'setup/Routing/routeNames';

export default function InvitationRoute({ children }) {
  const { authenticationKey } = useContext(CurrentInvitationContext);
  const params = useParams();

  const location = useLocation();

  const shouldRedirect = useMemo(
    () => !authenticationKey && !params.authenticationKey,
    [authenticationKey, params.authenticationKey],
  );

  if (shouldRedirect) {
    return (
      <Navigate
        to={ROUTE_NAMES.access_denied}
        state={{ from: location }}
        replace
      />
    );
  }

  return children;
}

InvitationRoute.propTypes = {
  children: PropTypes.node,
};

InvitationRoute.defaultProps = {
  children: null,
};
