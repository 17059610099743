import React from 'react';

import { t, Trans } from '@lingui/macro';

import ChevronRight from '@decisiv/iconix/lib/components/ChevronRight';
import ChevronLeft from '@decisiv/iconix/lib/components/ChevronLeft';

import { H4, P } from '@decisiv/ui-components/lib/components/Typography';
import Button from '@decisiv/ui-components/lib/components/Button';
import Flex from '@decisiv/ui-components/lib/components/Flex';

import SentinelTeamForm from 'components/SentinelTeamForm';
import TeamKeyForm from 'components/TeamKeyForm';
import { useWizardModal } from 'components/WizardModal';
import { useTeamModalContext } from '../contexts/TeamModalContext';

export const NEW_LOGO_INPUT = 'new-logo-image';

export default function TeamProfileStep() {
  const { handleNextPage, handlePrevPage } = useWizardModal();
  const { values, setValues, canGoNext, logoUrl, onSaveImage } =
    useTeamModalContext();

  const { teamKey, applicationName, teamName } = values;

  const handleTeamNameChange = ({ currentTarget: { value } }) => {
    setValues((oldState) => ({ ...oldState, teamName: value }));
  };

  const handleApplicationNameChange = ({ currentTarget: { value } }) => {
    setValues((oldState) => ({ ...oldState, applicationName: value }));
  };

  const handleTeamKeyChange = (value) => {
    setValues((oldState) => ({ ...oldState, teamKey: value }));
  };

  return (
    <Flex flexDirection="column" marginTop={1}>
      <H4 marginTop={1}>
        <Trans>Team Profile</Trans>
      </H4>
      <P color="alaskanHusky" size="small" marginBottom={1}>
        <Trans>
          Configure the team’s profile. Team Admins can change these settings
          later.
        </Trans>
      </P>
      <SentinelTeamForm
        name={teamName}
        handleNameChange={handleTeamNameChange}
        applicationName={applicationName}
        handleApplicationNameChange={handleApplicationNameChange}
        handleSaveImage={onSaveImage}
        logoUrl={logoUrl}
        stretch
      />
      <H4 marginTop={2}>
        <Trans>Team Key</Trans>
      </H4>
      <P color="alaskanHusky" size="small" marginBottom={1}>
        <Trans>
          Unique identifier added as a prefix to all appointment numbers.
        </Trans>
      </P>
      <TeamKeyForm
        handleTeamKeyChange={handleTeamKeyChange}
        teamKey={teamKey}
        stretch
      />
      <Flex marginTop={1.6}>
        <Button
          aria-label={t`Back`}
          text={t`Back`}
          variant="ghost"
          icon={ChevronLeft}
          iconPosition="left"
          onClick={handlePrevPage}
        />
        <Button
          css="margin-left: auto;"
          aria-label={t`Continue`}
          text={t`Continue`}
          variant="ghost"
          icon={ChevronRight}
          iconPosition="right"
          onClick={handleNextPage}
          disabled={!canGoNext(2)}
        />
      </Flex>
    </Flex>
  );
}
