import isEmpty from 'lodash/isEmpty';

export function buildActiveFiltersParams(filtersIds) {
  if (isEmpty(filtersIds)) return '';

  const filters = filtersIds.reduce(
    (obj, id) => {
      const { builtin, custom } = obj;
      if (Number.isNaN(Number(id))) {
        return { ...obj, builtin: [...builtin, id] };
      }

      return { ...obj, custom: [...custom, id] };
    },
    { custom: [], builtin: [] },
  );

  const { builtin, custom } = filters;
  const builtinParams = `alertTypes=${builtin.join(',')}`;
  const customAlertsParams = isEmpty(custom)
    ? ''
    : `&customAlerts=${custom.join(',')}`;

  return `?${builtinParams}${customAlertsParams}`;
}
