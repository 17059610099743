import { plural, t } from '@lingui/macro';

const ACTION_TEXT = {
  DISABLED: t`disabled`,
  ENABLED: t`enabled`,
};

export function assetActionText(action) {
  return ACTION_TEXT[action];
}

export function assetAttributeText(items) {
  const count = items.length;
  return plural(count, {
    one: 'an asset',
    other: `${count} assets`,
  });
}
