import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { t, Trans } from '@lingui/macro';
import sumBy from 'lodash/sumBy';
import without from 'lodash/without';
import rem from 'polished/lib/helpers/rem';

import spacing from '@decisiv/design-tokens/lib/spacing';

import Search from '@decisiv/iconix/lib/components/Search';

import Accordion from '@decisiv/ui-components/lib/components/Accordion';
import Button from '@decisiv/ui-components/lib/components/Button';
import Checkbox from '@decisiv/ui-components/lib/components/Checkbox';
import Flex from '@decisiv/ui-components/lib/components/Flex';
import Grid from '@decisiv/ui-components/lib/components/Grid';
import RadioGroup from '@decisiv/ui-components/lib/components/RadioGroup';
import TextField from '@decisiv/ui-components/lib/components/TextField';
import { P } from '@decisiv/ui-components/lib/components/Typography';

import Paper from 'components/Paper';

import useDebounce from 'utils/useDebounce';
import assetTypeText from 'utils/Asset';

export const ALL = 'ALL';
const DISABLED = 'DISABLED';
const ENABLED = 'ENABLED';
const FILTER_TITLES = {
  assetTypes: t`Asset Type`,
  years: t`Year`,
  makes: t`Make`,
  models: t`Model`,
};

const count = (filters) => (filters?.length ? ` (${filters.length})` : '');

export default function AssetsFilterColumn({
  appliedFilters,
  canUpdate,
  clearFilters,
  filters,
  onFilterChange,
  onQueryChange,
  onRadioChange,
  radioValue,
}) {
  const totalFilterCount = sumBy(Object.values(appliedFilters), 'length');
  const [query, setQuery] = useState('');
  const debounceText = useDebounce(query, 500);
  const handleChange = useCallback(
    ({ target: { id, checked } }, key) => {
      const newFilters = appliedFilters[key] || [];
      onFilterChange(
        key,
        checked ? newFilters.concat(id) : without(newFilters, id),
      );
    },
    [appliedFilters, onFilterChange],
  );
  const items = useMemo(
    () =>
      Object.keys(filters)
        .filter((key) => key !== 'enabledDisabled')
        .filter((key) => Array.isArray(filters[key]) && filters[key].length)
        .map((key) => ({
          PanelComponent: () => (
            <div
              css={`
                > :not(:last-child) {
                  margin-bottom: ${rem(spacing.base)};
                }
              `}
            >
              {filters[key].map(({ name }) => {
                const fallbackName = name || `unknown-${key}`;

                return (
                  <Checkbox
                    id={fallbackName}
                    checked={appliedFilters[key]?.includes(fallbackName)}
                    key={`${key}-${name}`}
                    label={key === 'assetTypes' ? assetTypeText(name) : name}
                    onChange={(e) => handleChange(e, key)}
                    size="small"
                  />
                );
              })}
            </div>
          ),
          id: key,
          title: FILTER_TITLES[key] + count(appliedFilters[key]),
        })),
    [appliedFilters, filters, handleChange],
  );

  const radioGroupItems = useMemo(() => {
    const totalCount = filters?.enabledDisabled?.find(
      ({ value }) => value === ALL,
    )?.count;
    const enabledCount = filters?.enabledDisabled?.find(
      ({ value }) => value === ENABLED,
    )?.count;
    const disabledCount = filters?.enabledDisabled?.find(
      ({ value }) => value === DISABLED,
    )?.count;

    return [
      {
        label: t`All (${totalCount})`,
        value: ALL,
        disabled: !totalCount,
      },
      {
        label: t`Enabled (${enabledCount})`,
        value: ENABLED,
        disabled: !enabledCount,
      },
      {
        label: t`Disabled (${disabledCount})`,
        value: DISABLED,
        disabled: !disabledCount,
      },
    ];
  }, [filters]);

  useEffect(() => {
    onQueryChange(debounceText.length >= 3 ? debounceText : '');
  }, [debounceText, onQueryChange]);

  return (
    <Grid.Container padding={0}>
      <Grid.Row padding={0}>
        <Paper padding={2}>
          <Grid.Row>
            <TextField
              helpMessage={t`Enter at least three characters to start searching for an asset (Model, VIN, serial, chassis ID, or unit #)`}
              icon={Search}
              label="Search"
              onChange={(e) => setQuery(e.target.value)}
              value={query}
            />
          </Grid.Row>
        </Paper>
      </Grid.Row>
      {canUpdate && (
        <Grid.Row padding={0} marginTop={2}>
          <Paper padding={0}>
            <Flex flexDirection="column" paddingX={2} paddingY={1}>
              <P color="alaskanHusky" marginBottom={1} size="small">
                <Trans>ASSETS</Trans>
              </P>
              <RadioGroup
                name={t`asset-filters`}
                items={radioGroupItems}
                onChange={onRadioChange}
                value={radioValue}
                vertical
              />
            </Flex>
          </Paper>
        </Grid.Row>
      )}
      <Grid.Row padding={0} marginTop={2}>
        <Paper padding={0}>
          <Flex
            paddingX={2}
            paddingY={1}
            alignItems="center"
            justifyContent="space-between"
          >
            <P color="alaskanHusky" size="small">
              <Trans>FILTERS</Trans>
              {!!totalFilterCount && ` (${totalFilterCount})`}
            </P>
            <Button
              disabled={!totalFilterCount}
              onClick={clearFilters}
              size="small"
              text={t`Clear All`}
              variant="ghost"
            />
          </Flex>
          <Accordion controls="hide" items={items} size="medium" />
        </Paper>
      </Grid.Row>
    </Grid.Container>
  );
}

AssetsFilterColumn.propTypes = {
  appliedFilters: PropTypes.shape({
    assetTypes: PropTypes.arrayOf(PropTypes.string),
    makes: PropTypes.arrayOf(PropTypes.string),
    models: PropTypes.arrayOf(PropTypes.string),
    years: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  canUpdate: PropTypes.bool,
  clearFilters: PropTypes.func.isRequired,
  filters: PropTypes.shape({
    assetTypes: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })),
    makes: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })),
    models: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })),
    years: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })),
    enabledDisabled: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  onFilterChange: PropTypes.func.isRequired,
  onQueryChange: PropTypes.func.isRequired,
  onRadioChange: PropTypes.func.isRequired,
  radioValue: PropTypes.string,
};

AssetsFilterColumn.defaultProps = {
  canUpdate: false,
  filters: {},
  radioValue: undefined,
};
