import includes from 'lodash/includes';
import lowerCase from 'lodash/lowerCase';

const TRUTHY_VALUES = ['true', 'yes', 't', 'y'];

/**
 * Checks a given environment variable value to determine if it contains
 * a "truthy" configuration value (true, yes, t, or y).
 * @param {String} key - the name of the environment variable
 * @returns {boolean} `true` if the env var is truthy, `false` otherwise.
 */
export default function isConfigEnabled(key) {
  return includes(TRUTHY_VALUES, lowerCase(key));
}
