import toColorString from 'polished/lib/color/toColorString';
import moment from 'moment-timezone';

import color from '@decisiv/design-tokens/lib/color';

import Bullhorn from '@decisiv/iconix/lib/components/Bullhorn';
import Building from '@decisiv/iconix/lib/components/Building';
import Calendar from '@decisiv/iconix/lib/components/Calendar';
import Truck from '@decisiv/iconix/lib/components/Truck';

import { t } from '@lingui/macro';

import { alertTypeText } from 'utils/Alerts';
import {
  hexColorForAppointment,
  labelForAppointmentStatus,
} from 'utils/Appointments';
import { formatCityAndState } from 'utils/Customer';

const alertTypeColors = {
  PREVENTATIVE_MAINTENANCE: 'cookieMonster',
  RECALLS: 'carrotCake',
  FAULTS: 'berryCrisp',
  WARRANTIES: 'cottonCandy',
};

const calculateTotal = (data) => data.reduce((total, d) => total + d.count, 0);

const isNo = (attr) => attr.match(/no/i);

const getBarChartColor = (attr) =>
  toColorString(
    color.visualInterest.cookieMonster[isNo(attr) ? 'light' : 'medium'],
  );

const getColor = (reportName, attr) => {
  if (reportName.match(/alerts/i)) {
    return toColorString(color.visualInterest[alertTypeColors[attr]].medium);
  }
  return hexColorForAppointment({ status: attr });
};

const getTitle = (reportName, attr) => {
  if (reportName.match(/alerts/i)) {
    return alertTypeText(attr.toLowerCase(), 2);
  }
  return labelForAppointmentStatus({ status: attr });
};

const getStackedBarTitle = (reportName, attr) => {
  if (reportName.match(/withalerts/i)) {
    return isNo(attr) ? t`No Alert` : t`Alert`;
  }
  return isNo(attr) ? t`No Event` : t`Event`;
};

const emptySubtitle = t`Try removing or editing some of your filters to expand your report.`;

export const emptyAlertsProps = {
  emptySubtitle,
  emptyTitle: t`No Alerts Found`,
  icon: Bullhorn,
};

export const emptyAssetsProps = {
  emptySubtitle,
  emptyTitle: t`No Assets Found`,
  icon: Truck,
};

export const emptyAppointmentsProps = {
  emptySubtitle,
  emptyTitle: t`No Events Found`,
  icon: Calendar,
};

export const emptyCustomersProps = (isFleet) => ({
  emptySubtitle,
  emptyTitle: isFleet ? t`No Depots Found` : t`No Customers Found`,
  icon: Building,
});

export const emptyServiceProviderProps = {
  emptySubtitle,
  emptyTitle: t`No Service Providers Found`,
  icon: Building,
};

const replacement = (name) =>
  name === 'totalAlertsOverTime' ? 'alert_label_' : 'appointment_status_';

const mapDatum = (name, data, customAttrFn) => {
  const reportData = data[name]?.reportData || [];

  const total = calculateTotal(reportData);

  return {
    downloadUrl: data[name]?.reportUrl,
    results: reportData.map((d) => {
      return {
        count: d.count,
        percentage: Math.round((d.count / total) * 100),
        ...customAttrFn(d),
      };
    }),
    total,
  };
};

export const mapData = ({
  data = {},
  listReports,
  stackedBarReports,
  pieReports,
  timeReports,
}) => {
  const mappedData = {};

  listReports.forEach((name) => {
    mappedData[name] = mapDatum(name, data, (d) => ({
      title: d.source.name,
      subtitle: formatCityAndState(d.source.city, d.source.state),
      enabled: d.source.enabled,
      id: d.source.id,
    }));
  });

  stackedBarReports?.forEach((name) => {
    mappedData[name] = mapDatum(name, data, (d) => ({
      attribute: `${d.attribute}-${name}`,
      color: getBarChartColor(d.attribute),
      title: getStackedBarTitle(name, d.attribute),
    }));
  });

  pieReports?.forEach((name) => {
    mappedData[name] = mapDatum(name, data, (d) => ({
      attribute: `${d.attribute}-${name}`,
      color: getColor(name, d.attribute),
      title: getTitle(name, d.attribute),
    }));
  });

  timeReports?.forEach((name) => {
    let total = 0;
    mappedData[name] = {
      downloadUrl: data[name]?.reportUrl,
      results: data[name]?.reportData.map((d) => {
        const label = d.name.replace(replacement(name), '').toUpperCase();
        const lineColor = getColor(name, label);
        let lineTotal = 0;
        const title = getTitle(name, label);

        return {
          values: d.values.map((value) => {
            lineTotal += value.y;
            total += value.y;
            return {
              attribute: d.name,
              color: lineColor,
              count: value.y,
              title,
              x: moment.tz(moment(value.x), moment.tz.guess()),
            };
          }),
          total: lineTotal,
        };
      }),
      total,
    };
  });

  return mappedData;
};
