import React, { useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import includes from 'lodash/includes';
import isNull from 'lodash/isNull';

import Flex from '@decisiv/ui-components/lib/components/Flex';
import { P } from '@decisiv/ui-components/lib/components/Typography';

import { plural, Plural, t, Trans } from '@lingui/macro';

import RadioGroup from '@decisiv/ui-components/lib/components/RadioGroup';
import Modal from '@decisiv/ui-components/lib/components/Modal';

import { buildPropTypes } from 'utils/graphqlPropTypes';
import { CurrentInvitationContext } from 'utils/Invitation';

export default function ConfirmDisableAssetModal({
  assets,
  handleClose,
  handleDisableAssets,
  visible,
}) {
  const { sentinelTeam } = useContext(CurrentInvitationContext);
  const asset = assets[0];
  const assetCount = assets.length;
  const name =
    assetCount > 1
      ? t`${assetCount} Assets`
      : [asset?.year, asset?.make, asset?.model].filter(Boolean).join(' ');

  const [disableActiveAppointments, setDisableActiveAppointments] =
    useState(null);
  const totalAppointments = useMemo(
    () =>
      assets.reduce(
        (acc, { appointments }) =>
          acc +
          appointments.filter(({ status }) => includes(['REQUESTED'], status))
            .length,
        0,
      ),
    [assets],
  );
  const sentinelTeamName = sentinelTeam?.name;
  const infoMessage = sentinelTeam
    ? plural(assetCount, {
        one: `Any service events for it will be canceled, and ${sentinelTeamName} team members will no longer be able to see this asset or manage alerts for it.`,
        other: `Any service events for them will be canceled, and ${sentinelTeamName} team members will no longer be able to see these assets or manage alerts for them.`,
      })
    : plural(assetCount, {
        one: 'Any Draft service events for it will be canceled, and team members will no longer be able to manage alerts for it.',

        other:
          'Any Draft service events for them will be canceled, and team members will no longer be able to manage alerts for them.',
      });

  return (
    <Modal
      actions={[
        {
          intent: 'danger',
          text: plural(assetCount, {
            one: 'Disable Asset',
            other: 'Disable Assets',
          }),
          disabled:
            totalAppointments !== 0 && isNull(disableActiveAppointments),
          onClick: () =>
            handleDisableAssets(
              assets,
              totalAppointments,
              disableActiveAppointments,
            ),
        },
        {
          text: plural(assetCount, {
            one: 'Keep Asset',
            other: 'Keep Assets',
          }),
          onClick: handleClose,
        },
      ]}
      intent="danger"
      onClose={handleClose}
      title={plural(assetCount, {
        one: 'Disable Asset?',
        other: 'Disable Assets?',
      })}
      visible={visible}
    >
      <Flex flexDirection="column">
        <P shade={1} marginTop={0.5}>
          <Trans>
            You are about to disable{' '}
            <P as="span" weight="semibold">
              {name}.
            </P>
          </Trans>{' '}
          {infoMessage}
        </P>
        {totalAppointments !== 0 && (
          <>
            <br />
            <P shade={1} marginBottom={2}>
              <Plural
                value={assetCount}
                one={
                  <Trans>
                    This asset has{' '}
                    <P as="span" weight="semibold">
                      # In Progress service events
                    </P>
                    . It can remain active or you can cancel it.
                  </Trans>
                }
                other={
                  <Trans>
                    The assets altogether have{' '}
                    <P as="span" weight="semibold">
                      # In Progress service events
                    </P>
                    . They can remain active or you can cancel them.
                  </Trans>
                }
              />
            </P>
            <RadioGroup
              name="radio"
              onChange={(event) => {
                setDisableActiveAppointments(event.target.value);
              }}
              onClick={(event) => {
                event.stopPropagation();
              }}
              items={[
                {
                  label: plural(totalAppointments, {
                    one: 'Keep Service Event Active',
                    other: 'Keep Service Events Active',
                  }),
                  value: 'false',
                },
                {
                  label: plural(totalAppointments, {
                    one: 'Cancel Service Event',
                    other: 'Cancel Service Events',
                  }),
                  value: 'true',
                },
              ]}
              value={disableActiveAppointments}
              vertical
            />
          </>
        )}
      </Flex>
    </Modal>
  );
}

ConfirmDisableAssetModal.propTypes = {
  assets: PropTypes.arrayOf(
    buildPropTypes('asset', ['appointment', 'customer']),
  ).isRequired,
  handleClose: PropTypes.func.isRequired,
  handleDisableAssets: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};
