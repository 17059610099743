import get from 'lodash/get';
import set from 'lodash/set';
import { ApolloLink } from '@apollo/client';

const GQL_REQUEST_BREADCRUMB = 'GraphQL Request';
const GQL_RESPONSE_BREADCRUMB = 'GraphQL Response';

/**
 * Take the operation info from a GraphQL query or mutation request
 * and turn it into a Bugsnag breadcrumb.
 * @param {String} operationName
 * @param {Object} variables
 * @return {{operationName: String, variables: Object}}
 */
const makeRequestBreadcrumb = ({ operationName, variables }) => ({
  operationName,
  variables,
});

/**
 * Take the operation info and response data from a GraphQL query or
 * mutation response and turn it into a Bugsnag breadcrumb.
 * @param {String} operationName
 * @param {Object} variables
 * @param {Object} data
 * @param {Object[]|undefined} errors
 * @return {{operationName: String, variables: Object, data: Object, errors: Object[]}}
 */
const makeResponseBreadcrumb = (
  { operationName, variables },
  { data, errors },
) => ({
  operationName,
  variables,
  data,
  errors,
});

/**
 * Factory function to create a new ApolloLink that observes all
 * queries / mutations and created Bugsnag breadcrumbs for the
 * request and the response, including operation name, variables,
 * and response data/errors.
 */
const create = (bugsnagClient) =>
  new ApolloLink((operation, forward) => {
    const requestBreadcrumb = makeRequestBreadcrumb(operation);
    bugsnagClient.leaveBreadcrumb(GQL_REQUEST_BREADCRUMB, requestBreadcrumb);
    return forward(operation).map((data) => {
      const responseBreadcrumb = makeResponseBreadcrumb(operation, data);
      bugsnagClient.leaveBreadcrumb(
        GQL_RESPONSE_BREADCRUMB,
        responseBreadcrumb,
      );
      return data;
    });
  });

const bugsnagBreadcrumbLinkFactory = { create };

function isManualBreadcrumb(bc) {
  return get(bc, 'type') === 'manual';
}

function getBreadcrumbMetadata(bc) {
  return get(bc, 'metaData', {});
}

function getBreadcrumbName(bc) {
  return get(bc, 'name');
}

export function beforeSendFlattenBreadcrumbs(report) {
  // eslint-disable-next-line no-param-reassign
  report.breadcrumbs = report.breadcrumbs.map((bc) => {
    if (isManualBreadcrumb(bc)) {
      const { variables, data, errors } = getBreadcrumbMetadata(bc);

      // eslint-disable-next-line default-case
      switch (getBreadcrumbName(bc)) {
        case GQL_RESPONSE_BREADCRUMB:
          set(bc, 'metaData.data', JSON.stringify(data));
          set(bc, 'metaData.errors', JSON.stringify(errors));

        // falls through
        case GQL_REQUEST_BREADCRUMB:
          set(bc, 'metaData.variables', JSON.stringify(variables));
      }
    }
    return bc;
  });
}

export default bugsnagBreadcrumbLinkFactory;
