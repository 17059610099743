import PropTypes from 'prop-types';
import React from 'react';

import Flex from '@decisiv/ui-components/lib/components/Flex';

import SendRequestButton from 'components/SendRequestButton';

function SendRequestAction({ assetGrouping, kind }) {
  return assetGrouping?.customerType === 'DEPOT' ? (
    <Flex>
      <SendRequestButton customer={assetGrouping} kind={kind} size="medium" />
    </Flex>
  ) : null;
}

SendRequestAction.propTypes = {
  kind: PropTypes.oneOf(['primary', 'secondary']),
  assetGrouping: PropTypes.shape({
    id: PropTypes.string.isRequired,
    customerType: PropTypes.string.isRequired,
  }),
};

SendRequestAction.defaultProps = {
  assetGrouping: undefined,
  kind: undefined,
};

export default SendRequestAction;
