import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import { spacing } from '@decisiv/design-tokens';

import Flex from '@decisiv/ui-components/lib/components/Flex';

export const HeaderContainer = styled(Flex)`
  gap: ${rem(spacing.base * 0.5)};

  height: ${rem(spacing.base * 3)};
`;
