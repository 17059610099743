import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';

import Flex from '@decisiv/ui-components/lib/components/Flex';
import { H4 } from '@decisiv/ui-components/lib/components/Typography';

import loadingGif from 'components/Loading/loading.gif';

export default function UpdatingOverlay({ updating, children }) {
  return (
    <>
      {updating && (
        <div
          css={`
            position: absolute;
            z-index: 2;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
          `}
        >
          <Flex alignItems="center" flexDirection="column" marginTop={10}>
            <img src={loadingGif} alt="loading" />
            <H4 marginTop={1}>
              <Trans>Updating...</Trans>
            </H4>
          </Flex>
        </div>
      )}
      <div
        css={`
          opacity: ${updating ? 0.25 : 1};
        `}
      >
        {children}
      </div>
    </>
  );
}

UpdatingOverlay.propTypes = {
  children: PropTypes.node.isRequired,
  updating: PropTypes.bool.isRequired,
};
