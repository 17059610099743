import { useEffect, useState, useMemo } from 'react';
import { formatDistance, parseISO } from 'date-fns';

export default function useFormatTimeDistance({
  startDate,
  endDate,
  timeout,
  addSuffix,
  live,
}) {
  const [, setCount] = useState(0);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const actualDate = new Date(Date.now());
  const parsedStartDate = parseISO(startDate);
  const parsedEndDate = useMemo(() => {
    if (endDate) {
      return parseISO(endDate);
    }
    return actualDate;
  }, [actualDate, endDate]);

  const formattedDistance = useMemo(
    () =>
      formatDistance(parsedStartDate, parsedEndDate, {
        addSuffix,
      }),
    [parsedStartDate, parsedEndDate, addSuffix],
  );

  useEffect(() => {
    let interval;

    if (live) {
      interval = setInterval(() => {
        setCount((oldState) => oldState + 1);
      }, timeout || 1000);
    }

    return () => {
      clearTimeout(interval);
    };
  }, [live, timeout]);

  return formattedDistance;
}
