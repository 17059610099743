import React from 'react';
import PropTypes from 'prop-types';

import PlainTextCell from 'components/PlainTextCell';

export default function ApiUserCell({
  rowData: { apiUsername, scheduledForDeletionAt },
}) {
  return (
    <PlainTextCell
      color={scheduledForDeletionAt ? 'alaskanHusky' : undefined}
      topLine={apiUsername}
    />
  );
}

ApiUserCell.propTypes = {
  rowData: PropTypes.shape({
    apiUsername: PropTypes.string,
    scheduledForDeletionAt: PropTypes.string,
  }).isRequired,
};
