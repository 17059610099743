import React, { useMemo } from 'react';
import { t, Trans } from '@lingui/macro';
import styled from 'styled-components';
import useSentinelTeam from 'hooks/useSentinelTeam';

import Badge from '@decisiv/ui-components/lib/components/Badge';
import Flex from '@decisiv/ui-components/lib/components/Flex';
import { H3, P } from '@decisiv/ui-components/lib/components/Typography';
import Briefcase from '@decisiv/iconix/lib/components/Briefcase';

import ROUTE_NAMES from 'setup/Routing/routeNames';

import Card from 'components/DashboardCard';

import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

import Loading from 'components/Loading';
import Divider from 'components/Divider';

import isNull from 'lodash/isNull';
import { Grid } from './styles';

const dashboardScheduledMaintenanceByStatusQuery = loader(
  './dashboardScheduledMaintenanceByStatus.graphql',
);

const Paper = styled(Flex).attrs({ padding: 2 })`
  width: 100%;
  box-shadow: 0px 1px 4px rgba(37, 38, 46, 0.4);
  border-radius: 2px;
`;

const getCountText = (loading, total) => {
  if (loading) return '';

  return isNull(total) ? '―' : total;
};

const getBadgeColor = (total) => {
  return isNull(total) ? 'licoriceMousse' : 'information';
};

const infoText = t`View active Scheduled Maintenance alerts. Click on this panel for all assets with the active scheduled maintenance filter enabled.`;

export default function ScheduledMaintenanceCard() {
  const navigate = useNavigate();
  const {
    loading,
    data: {
      dashboardScheduledMaintenanceByStatus: { total, due, overdue } = {},
    } = {},
  } = useQuery(dashboardScheduledMaintenanceByStatusQuery);

  const { alertConfiguration } = useSentinelTeam();

  // The button is only enabled if the total is not null and the alert is enabled
  const isViewAllDisabled = useMemo(() => {
    const scheduledMaintenanceAlert = alertConfiguration.filter(
      (alert) => alert.id === 'ScheduledMaintenance',
    );

    return !total && !scheduledMaintenanceAlert[0].enabled;
  }, [alertConfiguration, total]);

  const onClickViewAll = () =>
    navigate(`${ROUTE_NAMES.alerts}?alertTypes=preventative_maintenance`);

  return (
    <Card
      text={t`Scheduled Maintenance`}
      buttonText={t`View All`}
      buttonAriaLabel={t`View All Assets with Scheduled Maintenance`}
      buttonDisabled={isViewAllDisabled}
      infoText={infoText}
      onButtonClick={onClickViewAll}
      marginTop={0}
    >
      {loading && <Loading />}
      {!loading && (
        <Flex flexDirection="row" marginBottom={1}>
          <Paper marginY={1}>
            <Grid>
              <Flex alignItems="center">
                <Badge
                  variant="square"
                  icon={Briefcase}
                  color={getBadgeColor(total)}
                />
              </Flex>
              <Flex flexDirection="column" marginLeft={1}>
                <H3 weight="regular">{getCountText(loading, total)}</H3>

                <P weight="medium">
                  <Trans>Scheduled Maintenance Alerts</Trans>
                </P>
              </Flex>
              <Divider
                css={`
                  height: auto;
                `}
                marginX={1}
              />
              <Flex flexDirection="column" marginLeft={1}>
                <H3 weight="regular">{getCountText(loading, due)}</H3>
                <P weight="medium">
                  <Trans>Due</Trans>
                </P>
              </Flex>
              <Flex flexDirection="column" marginLeft={1}>
                <H3 weight="regular">{getCountText(loading, overdue)}</H3>
                <P weight="medium">
                  <Trans>Overdue</Trans>
                </P>
              </Flex>
            </Grid>
          </Paper>
        </Flex>
      )}
    </Card>
  );
}
