import { useMemo, useState } from 'react';
import { t } from '@lingui/macro';
import uniqBy from 'lodash/uniqBy';

import Grid from '@decisiv/iconix/lib/components/Grid';
import Link from '@decisiv/iconix/lib/components/Link';

import Flex from '@decisiv/ui-components/lib/components/Flex';
import Loading from '@decisiv/ui-components/lib/components/Loading';
import Menu, { MenuItem } from '@decisiv/ui-components/lib/components/Menu';
import Popover from '@decisiv/ui-components/lib/components/Popover';

import useUserCaseLinksQuery from './useUserCaseLinksQuery';

import { Button } from './styles';

export default function CaseLinks() {
  const [active, setActive] = useState(false);

  const { data, loading } = useUserCaseLinksQuery();

  const portalLinks = useMemo(
    () => data?.currentUserProfile?.user?.portalUrls || [],
    [data?.currentUserProfile?.user?.portalUrls],
  );

  const items = useMemo(
    () =>
      uniqBy(
        portalLinks.map((portalLink) => {
          const productName = portalLink.productName.toUpperCase();

          return {
            id: portalLink.id,
            text: t`OPEN ${productName} PORTAL`,
            icon: Link,
            onSelect: () => {
              window.open(portalLink.url, '_blank');
            },
          } satisfies MenuItem;
        }),
        'text',
      ),
    [portalLinks],
  ) satisfies MenuItem[];

  const handleToggleActive = () => {
    setActive((oldState) => !oldState);
  };

  return (
    <Popover
      onShow={handleToggleActive}
      onHide={handleToggleActive}
      target={
        <Button aria-label={t`Show Portal links`} active={active}>
          <Grid />
        </Button>
      }
      showArrow={false}
    >
      {loading ? (
        <Flex padding={2}>
          <Loading title="Loading..." size="small" />
        </Flex>
      ) : (
        <Menu items={items} />
      )}
    </Popover>
  );
}
