import { useRef, useMemo, useCallback, ElementRef } from 'react';
import { t, Trans } from '@lingui/macro';
import { useQuery, useMutation } from '@apollo/client';

import Cog from '@decisiv/iconix/lib/components/Cog';

import Button from '@decisiv/ui-components/lib/components/Button';
import Flex from '@decisiv/ui-components/lib/components/Flex';
import Popover from '@decisiv/ui-components/lib/components/Popover';
import Tooltip from '@decisiv/ui-components/lib/components/Tooltip';
import { P } from '@decisiv/ui-components/lib/components/Typography';
import { ToggleOption } from '@decisiv/ui-components/lib/components/Toggle/types';

import { useCurrentUserContext } from 'utils/User';

import Divider from 'components/Divider';
import Loading from 'components/Loading';
import PageHead from 'components/PageHead';
import PageHeader from 'components/PageHeader';

import useSentinelTeam from 'hooks/useSentinelTeam';

import TeamOnboarding from './TeamOnboarding';

import dashboardPreferencesQuery from './dashboardPreferences.graphql';
import updateDashboardPreferencesMutation from './updatePreference.graphql';

import {
  Wrapper,
  PopoverWrapper,
  StyledCheckbox,
  StyledToggle,
} from './styles';
import LackOfPermissionsAlert from './LackOfPermissionsAlert';

type PreferencesType = { id: string; label: string; checked: boolean }[];

function WelcomePanel() {
  const { name, email, isAdmin } = useCurrentUserContext();
  const { isFleet } = useSentinelTeam();

  const buttonRef = useRef<ElementRef<typeof Button>>(null);

  const { loading, data: { dashboardPreferences } = {} } =
    useQuery<DashboardPreferencesQuery>(dashboardPreferencesQuery);

  const [updatePreference] = useMutation<
    UpdateDashboardPreferenceMutation,
    UpdateDashboardPreferenceMutationVariables
  >(updateDashboardPreferencesMutation, {
    refetchQueries: ['DashboardPreferences'],
  });

  const welcomeUser = name || email;

  const preferencesLabels = useMemo<Record<string, string>>(
    () => ({
      totals: t`Totals`,
      assetsByStatus: t`Assets by Status`,
      serviceEvents: t`Service Events`,
      alertsByType: t`Assets by Active Alert Types`,
      mostAlertsByDepot: isFleet
        ? t`Most Alerts by Depot`
        : t`Most Alerts by Customer`,
      scheduledMaintenance: t`Scheduled Maintenance`,
    }),
    [isFleet],
  );

  // only render available labels in `preferencesLabels`
  const preferences = useMemo<PreferencesType>(() => {
    if (!dashboardPreferences) return [];

    return Object.entries(dashboardPreferences).reduce<PreferencesType>(
      (prev, [key, val]) => {
        if (!Object.keys(preferencesLabels).includes(key)) return prev;

        return [
          ...prev,
          {
            id: key,
            label: preferencesLabels[key],
            checked: !!val,
          },
        ];
      },
      [],
    );
  }, [dashboardPreferences, preferencesLabels]);

  const toggleOptions = [
    {
      id: 'hide',
      text: '',
    },
    {
      id: 'show',
      text: '',
    },
  ] satisfies [ToggleOption, ToggleOption];

  const onClickCheckbox = useCallback(
    (preference: string, checked: boolean) => () =>
      updatePreference({
        variables: {
          input: { attributes: { [preference]: !checked } },
        },
      }),
    [updatePreference],
  );

  const onChangeToggle = (id: string) => {
    updatePreference({
      variables: {
        input: { attributes: { showOnboarding: id === 'show' } },
      },
    });
  };

  if (loading) return <Loading />;

  const showTeamOnboarding = dashboardPreferences?.showOnboarding;

  return (
    <Wrapper>
      <PageHead titleParts={[t`Dashboard`]} />
      <Flex justifyContent="space-between">
        <PageHeader title={t`Welcome ${welcomeUser}`} />
        <Flex marginRight={1.5} alignSelf="center">
          <Button
            ref={buttonRef}
            icon={Cog}
            size="small"
            variant="ghost"
            aria-label={t`Dashboard Preferences`}
          />
          <Tooltip placement="left-start" target={buttonRef}>
            {t`Preferences`}
          </Tooltip>

          <Popover target={buttonRef} placement="bottom-end">
            <PopoverWrapper>
              <P shade={1}>
                <Trans>Selected panels would be visible in the dashboard</Trans>
              </P>
              <Flex flexDirection="column" paddingY={1}>
                {preferences?.map(({ id: preference, label, checked }) => (
                  <StyledCheckbox
                    size="small"
                    key={preference}
                    label={label}
                    checked={checked}
                    onChange={onClickCheckbox(preference, checked)}
                  />
                ))}
              </Flex>
              {isAdmin && (
                <>
                  <Divider direction="horizontal" />
                  <Flex marginTop={1}>
                    <P as="span">
                      <Trans>Show Team Onboarding panel</Trans>
                    </P>
                    <StyledToggle
                      size="small"
                      name="show-onboarding"
                      selected={
                        dashboardPreferences?.showOnboarding ? 'show' : 'hide'
                      }
                      onChange={onChangeToggle}
                      options={toggleOptions}
                    />
                  </Flex>
                </>
              )}
            </PopoverWrapper>
          </Popover>
        </Flex>
      </Flex>
      <Divider direction="horizontal" />
      <LackOfPermissionsAlert />
      {showTeamOnboarding && <TeamOnboarding />}
    </Wrapper>
  );
}

export default WelcomePanel;
