/* istanbul ignore file -- @preserve */
/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';

export default function useIsTruncated(element, deps = []) {
  const determineIsTruncated = useCallback(() => {
    if (!element.current) return false;
    return element.current.scrollHeight > element.current.clientHeight;
  }, [element]);

  const [isTruncated, setIsTruncated] = useState(determineIsTruncated());

  useEffect(() => {
    const resizeListener = () => setIsTruncated(determineIsTruncated());
    window.addEventListener('resize', resizeListener);
    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, [determineIsTruncated]);

  useEffect(() => {
    setIsTruncated(determineIsTruncated());
  }, [determineIsTruncated, ...deps]);

  return isTruncated;
}
