import toColorString from 'polished/lib/color/toColorString';
import rem from 'polished/lib/helpers/rem';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';

import { sizes, useBreakpointObserver } from '@decisiv/breakpoint-observer';

import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';

import color from '@decisiv/design-tokens/lib/color';
import spacing from '@decisiv/design-tokens/lib/spacing';

import BarChart from '@decisiv/iconix/lib/components/BarChart';
import Bullhorn from '@decisiv/iconix/lib/components/Bullhorn';
import Calendar from '@decisiv/iconix/lib/components/Calendar';

import Tag from '@decisiv/ui-components/lib/components/Tag';
import Flex from '@decisiv/ui-components/lib/components/Flex';
import Link from '@decisiv/ui-components/lib/components/Link';
import { H2 } from '@decisiv/ui-components/lib/components/Typography';
import Checkbox from '@decisiv/ui-components/lib/components/Checkbox';
import Tooltip from '@decisiv/ui-components/lib/components/Tooltip';
import { useNotifications } from '@decisiv/ui-components/lib/components/Notifications';

import { t, Trans } from '@lingui/macro';

import { CUSTOMER_KEY } from 'components/Filters/hooks/useFilters';
import LinkToCase from 'components/LinkToCase';
import Paper from 'components/Paper';

import ROUTE_NAMES from 'setup/Routing/routeNames';

import { useCurrentUserContext } from 'utils/User';
import {
  isFleet as isFleetLocation,
  CUSTOMER_ACCEPTED_STATUSES,
  CUSTOMER_STATUS,
} from 'utils/Customer';

import useSentinelTeam from 'hooks/useSentinelTeam';

import MaintenanceEventsModal from './MaintenanceEventsModal';
import AssetGroupingLocation from './AssetGroupingLocation';
import AssetGroupingNumber from './AssetGroupingNumber';
import AssetGroupingPhone from './AssetGroupingPhone';
import AssetGroupingStatus from './AssetGroupingStatus';
// import CancelRequestAction from './CancelRequestAction';
import RemoveCustomerAction from './RemoveCustomerAction';
import SendRequestAction from './SendRequestAction';
import ToggleDepotStatusButton from '../ToggleDepotStatusButton';

const enableMaintenanceServiceEventsMutation = loader(
  './enableMaintenanceServiceEvents.graphql',
);

export default function OverviewCard({ assetGrouping }) {
  const [showMaintenanceModal, setShowMaintenanceModal] = useState(false);

  const { notify } = useNotifications();

  const { isAdmin, isSuperUser } = useCurrentUserContext();
  const { isFleet } = useSentinelTeam();

  const {
    enabled,
    name,
    maintenanceServiceEventsEnabled,
    maintenanceFeatureFlag,
  } = assetGrouping;

  const isCustomerAccepted = useMemo(
    () => CUSTOMER_ACCEPTED_STATUSES.includes(assetGrouping.status),
    [assetGrouping.status],
  );

  const canRenderMaintenanceEventsOption = useMemo(
    () =>
      !isFleet &&
      isFleetLocation(assetGrouping) &&
      isCustomerAccepted &&
      (isSuperUser || isAdmin),
    [assetGrouping, isAdmin, isCustomerAccepted, isFleet, isSuperUser],
  );

  const actionsForStatus = useMemo(() => {
    if (isFleet && isAdmin) {
      return (
        <>
          <ToggleDepotStatusButton depot={assetGrouping} />{' '}
        </>
      );
    }
    const components = {
      [CUSTOMER_STATUS.NEEDS_ACTION]: (
        <>
          <SendRequestAction kind="primary" assetGrouping={assetGrouping} />
          <RemoveCustomerAction customer={assetGrouping} />
        </>
      ),
      [CUSTOMER_STATUS.REQUEST_PENDING]: (
        <>
          <SendRequestAction assetGrouping={assetGrouping} />
          {/* <CancelRequestAction customer={customer}/> */}
          <RemoveCustomerAction customer={assetGrouping} />
        </>
      ),
      [CUSTOMER_STATUS.REQUEST_ACCEPTED]: (
        <>
          <RemoveCustomerAction customer={assetGrouping} />
        </>
      ),
      [CUSTOMER_STATUS.WITH_ASSETS_ENABLED]: (
        <>
          <RemoveCustomerAction customer={assetGrouping} />
        </>
      ),
      [CUSTOMER_STATUS.REQUEST_DENIED]: (
        <>
          <SendRequestAction assetGrouping={assetGrouping} />
          {/* <CancelRequestAction customer={customer}/> */}
          <RemoveCustomerAction customer={assetGrouping} />
        </>
      ),
    };

    return components[assetGrouping.status];
  }, [assetGrouping, isAdmin, isFleet]);

  const AssetGroupingTitle = React.useCallback(({ text }) => {
    return (
      <H2 as="h1" weight="regular">
        {text}
      </H2>
    );
  }, []);

  const [observedElementRef, { breakpoint }] = useBreakpointObserver({
    [sizes.SM]: 688,
  });

  const [updateMaintenanceServiceEvents] = useMutation(
    enableMaintenanceServiceEventsMutation,
    {
      refetchQueries: 'AssetGrouping',
      onError: () => {
        notify(
          {
            duration: 5000,
            onClose: () => undefined,
            intent: 'danger',
            title: t`Maintenance Events Not Saved`,
          },
          t`An issue occurred while updating maintenance events option. Please try again.`,
        );
      },
    },
  );

  return (
    <>
      <Paper
        ref={observedElementRef}
        css={`
          border-bottom: 1px solid ${toColorString(color.opacity.charcoal15)};
        `}
        id="customer-overview"
      >
        <Flex alignItems="start" flexWrap="wrap" padding={0.5}>
          <Flex flex={1} padding={0.5}>
            <AssetGroupingTitle text={assetGrouping?.name} />
            {isFleet && (
              <Flex marginTop={0.5} marginLeft={1}>
                <Tag
                  text={enabled ? t`Enabled` : t`Disabled`}
                  variant="outline"
                />
              </Flex>
            )}
          </Flex>
          <Flex
            flex={1}
            justifyContent={breakpoint === sizes.XS ? 'flex-start' : 'flex-end'}
            flexBasis={breakpoint === sizes.XS ? '100%' : undefined}
            order={breakpoint === sizes.XS ? 3 : undefined}
            padding={0.5}
            css={`
              > ${Flex} {
                margin-right: ${breakpoint === sizes.XS
                  ? rem(spacing.base / 2)
                  : 0};
                margin-left: ${breakpoint === sizes.XS
                  ? 0
                  : rem(spacing.base / 2)};
              }
            `}
          >
            {actionsForStatus}
          </Flex>
          <Flex
            alignItems="center"
            css={`
              > ${Flex} {
                margin-right: ${rem(spacing.base * 2)};
              }
            `}
            flexBasis="100%"
            flexWrap="wrap"
            flex={1}
            padding={0.5}
          >
            {!isFleet && <AssetGroupingStatus assetGrouping={assetGrouping} />}
            <AssetGroupingLocation assetGrouping={assetGrouping} />
            <AssetGroupingPhone assetGrouping={assetGrouping} />
            <AssetGroupingNumber assetGrouping={assetGrouping} />
          </Flex>
          {canRenderMaintenanceEventsOption && (
            <>
              <Flex
                alignItems="center"
                css={`
                  > ${Flex} {
                    margin-right: ${rem(spacing.base * 2)};
                  }
                `}
                flexBasis="100%"
                flexWrap="wrap"
                flex={1}
                padding={0.5}
              >
                <Tooltip
                  visible={!maintenanceServiceEventsEnabled ? undefined : false}
                  target={
                    <Checkbox
                      disabled={!maintenanceServiceEventsEnabled}
                      aria-label={t`Check Maintenance Events`}
                      label={t`Enable adding records of maintenance events`}
                      checked={maintenanceFeatureFlag}
                      onChange={({ target: { checked } }) => {
                        if (checked) {
                          setShowMaintenanceModal(true);
                        } else {
                          updateMaintenanceServiceEvents({
                            variables: { id: assetGrouping.id, enabled: false },
                          });
                        }
                      }}
                      size="small"
                    />
                  }
                >
                  <Trans>
                    Contact your Decisiv representative to enable this option
                  </Trans>
                </Tooltip>
              </Flex>
              <MaintenanceEventsModal
                assetGroupingName={name}
                visible={showMaintenanceModal}
                onClose={() => setShowMaintenanceModal(false)}
                onEnable={(checked) =>
                  updateMaintenanceServiceEvents({
                    variables: { id: assetGrouping.id, enabled: checked },
                  })
                }
              />
            </>
          )}
        </Flex>
      </Paper>

      {isCustomerAccepted && (
        <Paper
          alignItems="center"
          paddingX={2}
          paddingY={1.5}
          width="100%"
          css={`
            border-bottom: 1px solid ${toColorString(color.opacity.charcoal15)};
            a {
              margin-right: ${rem(spacing.base * 2)};
            }
          `}
          id="quick-links"
        >
          <Flex direction="column">
            <Link
              to={`${ROUTE_NAMES.alerts}?${CUSTOMER_KEY}=${assetGrouping.id}`}
              text={t`View Alerts`}
              icon={Bullhorn}
            />
            <Link
              to={`${ROUTE_NAMES.service_events}?${CUSTOMER_KEY}=${assetGrouping.id}`}
              text={t`View Service Events`}
              icon={Calendar}
            />
            <Link
              to={`${ROUTE_NAMES.reports}?${CUSTOMER_KEY}=${assetGrouping.id}`}
              text={t`View Reports`}
              icon={BarChart}
            />
            <LinkToCase
              iconPosition="left"
              text={isFleet ? t`View Depot in Case` : t`View Customer in Case`}
              to={assetGrouping.webCaseUrl}
            />
          </Flex>
        </Paper>
      )}
    </>
  );
}

OverviewCard.propTypes = {
  assetGrouping: PropTypes.shape({
    id: PropTypes.string.isRequired,
    status: PropTypes.string,
    enabled: PropTypes.bool,
    name: PropTypes.string,
    customerType: PropTypes.string.isRequired,
    webCaseUrl: PropTypes.string,
    maintenanceServiceEventsEnabled: PropTypes.bool,
    maintenanceFeatureFlag: PropTypes.bool,
  }),
};

OverviewCard.defaultProps = {
  assetGrouping: undefined,
};
