import PropTypes from 'prop-types';
import React from 'react';

import ROLES from './roles';

export const RBACContext = React.createContext({ roles: ROLES });

export default function RBACProvider({ children, role }) {
  return (
    <RBACContext.Provider value={{ role, roles: ROLES }}>
      {children}
    </RBACContext.Provider>
  );
}

RBACProvider.propTypes = {
  children: PropTypes.element.isRequired,
  role: PropTypes.oneOf(Object.keys(ROLES)),
};

RBACProvider.defaultProps = {
  role: undefined,
};
