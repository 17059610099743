import range from 'lodash/range';
import moment from 'moment-timezone';
import { sub, isBefore } from 'date-fns';

const currentYear = moment().year();

export const monthSelectOptions = range(1, 13).map((month) => {
  const firstOfMonth = moment(
    `${currentYear}-${String(month).padStart(2, '0')}-01`,
  );
  return {
    id: firstOfMonth.format('MM'),
    label: firstOfMonth.format('MMMM'),
    value: firstOfMonth.format('MM'),
  };
});

export const daySelectOptions = range(1, 32).map((day) => ({
  id: String(day),
  label: String(day),
  value: String(day),
}));

export const yearSelectOptions = range(currentYear, currentYear + 6).map(
  (year) => ({
    id: String(year),
    label: String(year),
    value: String(year),
  }),
);

export const hourSelectOptions = range(0, 24).map((hour) => ({
  id: String(hour),
  label: moment(
    `${currentYear}-01-01T${String(hour).padStart(2, '0')}:00:00`,
  ).format('h A'),
  value: String(hour),
}));

export const minuteSelectOptions = range(0, 60).map((minute) => ({
  id: String(minute),
  label: String(minute).padStart(2, '0'),
  value: String(minute),
}));

export function parseDate(dateString, timezone = null) {
  const date = moment.tz(dateString || moment(), timezone || moment.tz.guess());
  return [
    date.format('D MMM YYYY'),
    date.format(`h:mm A${timezone ? ' z' : ''}`),
  ];
}

export function daysLeft(dateString, timezone = null) {
  const date = moment.tz(dateString, timezone || moment.tz.guess());
  return date.diff(moment.tz(moment(), moment.tz.guess()), 'days');
}

export function isBefore5Days(date) {
  return isBefore(date, sub(new Date(), { days: 5 }));
}
