import React, { useMemo, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useCurrentUserContext } from 'utils/User';
import ROUTE_NAMES from 'setup/Routing/routeNames';
import { INVITATION_KEY } from 'utils/Invitation';

import useSentinelTeam from 'hooks/useSentinelTeam';
import availableAssetViewsQuery from './availableAssetViews.graphql';

export default function SentinelRoute({ children }) {
  const { isSuperUser } = useCurrentUserContext();
  const { hasSentinelTeam } = useSentinelTeam();
  const location = useLocation();

  const shouldRedirect = useMemo(() => !hasSentinelTeam, [hasSentinelTeam]);

  const { refetch: refetchAssetViews } = useQuery(availableAssetViewsQuery, {
    fetchPolicy: 'network-only',
    skip: memoryDB.getItem(INVITATION_KEY),
  });

  useEffect(() => {
    if (!shouldRedirect) refetchAssetViews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.pathname, shouldRedirect]);

  if (shouldRedirect) {
    const path = isSuperUser ? '/' : ROUTE_NAMES.access_denied;

    return <Navigate to={path} state={{ from: location }} replace />;
  }

  return <>{children}</>;
}

SentinelRoute.propTypes = {
  children: PropTypes.node,
};

SentinelRoute.defaultProps = {
  children: null,
};
