/* istanbul ignore file -- @preserve */

import { TypePolicies } from '@apollo/client';

export const typePolicies: TypePolicies = {
  Appointment: {
    fields: {
      history: {
        keyArgs: ['filters'],
        merge(existing = {}, incoming) {
          const { collection: existingColection = [] } = existing;
          const { collection: incomingCollection = [] } = incoming;

          // use incoming as default and overwrite the collection value
          return {
            ...incoming,
            collection: [...existingColection, ...incomingCollection],
          };
        },
      },
      notes: {
        keyArgs: false,
        // This functionality only applies to collections with infinite scroll.
        merge(existing = {}, incoming) {
          const { collection: existingColection = [] } = existing;
          const { collection: incomingCollection = [] } = incoming;

          // use incoming as default and overwrite the collection value
          return {
            ...incoming,
            collection: [...existingColection, ...incomingCollection],
          };
        },
      },
    },
  },
  Customer: {
    fields: {
      history: {
        keyArgs: false,
        // This functionality only applies to collections with infinite scroll.
        merge(existing = {}, incoming) {
          const { collection: existingColection = [] } = existing;
          const { collection: incomingCollection = [] } = incoming;

          // use incoming as default and overwrite the collection value
          return {
            ...incoming,
            collection: [...existingColection, ...incomingCollection],
          };
        },
      },
    },
  },
  Query: {
    fields: {
      notifications: {
        keyArgs: ['filters'],
        merge(
          existing: NotificationsQuery['notifications'],
          incoming: NotificationsQuery['notifications'],
        ): NotificationsQuery['notifications'] {
          const { collection: existingColection = [] } = existing ?? {};
          const { collection: incomingCollection = [] } = incoming;

          return {
            ...incoming,
            collection: existingColection.concat(incomingCollection),
          };
        },
      },
    },
  },
};
