import rem from 'polished/lib/helpers/rem';
import styled from 'styled-components';
import toColorString from 'polished/lib/color/toColorString';

import color from '@decisiv/design-tokens/lib/color';
import spacing from '@decisiv/design-tokens/lib/spacing';

import Flex from '@decisiv/ui-components/lib/components/Flex';
import Checkbox from '@decisiv/ui-components/lib/components/Checkbox';
import Toggle from '@decisiv/ui-components/lib/components/Toggle';

export const Wrapper = styled.div`
  background-color: ${toColorString(color.base.snowWhite)};
`;

export const PopoverWrapper = styled(Flex).attrs({
  padding: 1,
  flexDirection: 'column',
})`
  width: ${rem(spacing.base * 30)};
`;

export const StyledCheckbox = styled(Checkbox)`
  color: ${toColorString(color.interaction.pacificOcean)};
  margin: ${rem(spacing.base * 0.5)} 0;

  &:last-child {
    margin-bottom: 0;
  }
` as typeof Checkbox;

export const StyledToggle = styled(Toggle)`
  margin-left: auto;
`;
