import React from 'react';
import PropTypes from 'prop-types';

import isEmpty from 'lodash/isEmpty';
import compact from 'lodash/compact';

import Envelope from '@decisiv/iconix/lib/components/Envelope';

import Flex from '@decisiv/ui-components/lib/components/Flex';
import Link from '@decisiv/ui-components/lib/components/Link';
import { P } from '@decisiv/ui-components/lib/components/Typography';

import ClickToCallButton from 'components/ClickToCallButton';
import PlainTextCell from 'components/PlainTextCell';

export default function BillingContactCell({
  rowData: { billingContact, scheduledForDeletionAt },
}) {
  const name = compact([
    billingContact?.firstName,
    billingContact?.lastName,
  ]).join(' ');
  const email = billingContact?.email;
  const phone = billingContact?.phone;

  const color = scheduledForDeletionAt ? 'alaskanHusky' : undefined;

  return isEmpty(compact([name, email, phone])) ? (
    <PlainTextCell color={color} />
  ) : (
    <Flex flexDirection="column">
      {name && (
        <P color={color} as="span">
          {name}
        </P>
      )}
      {email && (
        <Link
          icon={Envelope}
          size="small"
          text={email}
          to={`mailto:${email}`}
        />
      )}
      {phone && (
        <ClickToCallButton
          css={`
            width: fit-content;
          `}
          name={name}
          phone={phone}
          size="small"
        />
      )}
    </Flex>
  );
}

BillingContactCell.propTypes = {
  rowData: PropTypes.shape({
    billingContact: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
    }),
    scheduledForDeletionAt: PropTypes.string,
  }).isRequired,
};
