import styled from 'styled-components';
import toColorString from 'polished/lib/color/toColorString';
import rem from 'polished/lib/helpers/rem';

import color from '@decisiv/design-tokens/lib/color';
import spacing from '@decisiv/design-tokens/lib/spacing';

import Grid from '@decisiv/ui-components/lib/components/Grid';
import Flex from '@decisiv/ui-components/lib/components/Flex';

export const Container = styled(Grid.Column).attrs({ padding: 2, margin: 1 })`
  height: 100%;
  background-color: ${toColorString(color.base.snowWhite)};

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
`;

export const Header = styled(Flex)``;

export const HeaderTextContent = styled(Flex)``;

export const Content = styled(Flex)``;

export const InfoTextContainer = styled(Flex)`
  display: block;

  max-width: ${rem(spacing.base * 20)};

  word-wrap: break-word;
`;
