import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { useCurrentUserContext } from 'utils/User';
import { Navigate, useLocation } from 'react-router-dom';
import ROUTE_NAMES from 'setup/Routing/routeNames';
import useSentinelTeam from 'hooks/useSentinelTeam';

export default function SuperUserRoute({ children }) {
  const { isSuperUser } = useCurrentUserContext();
  const { hasSentinelTeam } = useSentinelTeam();
  const location = useLocation();

  const shouldRedirect = useMemo(
    () => !isSuperUser || (isSuperUser && hasSentinelTeam),
    [isSuperUser, hasSentinelTeam],
  );

  if (shouldRedirect) {
    return (
      <Navigate
        to={ROUTE_NAMES.access_denied}
        state={{ from: location }}
        replace
      />
    );
  }

  return children;
}

SuperUserRoute.propTypes = {
  children: PropTypes.node,
};

SuperUserRoute.defaultProps = {
  children: null,
};
