import toColorString from 'polished/lib/color/toColorString';
import styled from 'styled-components';

import KDSColors from '@decisiv/design-tokens/lib/color';

import Flex from '@decisiv/ui-components/lib/components/Flex';

const Vr = styled(Flex)`
  background-color: ${({ color }) =>
    color || toColorString(KDSColors.opacity.charcoal15)};
  height: ${({ height }) => height || '100%'};
  width: 1px;
`;

export default Vr;
