/* istanbul ignore file -- @preserve */
import { onError } from '@apollo/client/link/error';
import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { navigate } from 'setup/Routing/RootNavigate';

import { sendToLogin } from 'bff';

import { reportGraphQLErrors } from 'setup/Bugsnag';

import { INVITATION_KEY } from 'utils/Invitation';
import ROUTE_NAMES from 'setup/Routing/routeNames';
import { translateRouteForTeamType } from 'setup/Routing/Routes/routeMap';
import { setSentinelTeam } from 'contexts/SentinelTeamContext';

export const AUTHENTICATION_ERROR = '401';
export const AUTHORIZATION_ERROR = '403';
export const NOT_FOUND_ERROR = '404';
export const SEE_OTHER_ERROR = '303';

const SENTINEL_AUTHENTICATION_ERROR_DETAIL = 'AUTHENTICATION_ERROR';

function findErrorByCode(errors, code) {
  return find(errors, (err) => get(err, 'extensions.code') === code);
}

function hasInvitationAuthError(errors) {
  if (memoryDB.getItem(INVITATION_KEY)) {
    const authErrors = errors.filter(
      ({ extensions: { code } }) =>
        code === AUTHENTICATION_ERROR ||
        code === AUTHORIZATION_ERROR ||
        code === NOT_FOUND_ERROR,
    );

    return !isEmpty(authErrors);
  }

  return false;
}

const defaultInvitationAuthErrorHandler = () => {
  const errPagePath = ROUTE_NAMES.forInvitation(
    memoryDB.getItem(INVITATION_KEY),
  );

  if (window.location.pathname !== errPagePath) {
    navigate(errPagePath);
  }
};

const defaultAuthErrorHandler = (error) => {
  // eslint-disable-next-line no-console
  console.warn('auth error - sending to login URL', error);
  sendToLogin();
};

const defaultAuthorizationErrorHandler = (error) => {
  navigate(ROUTE_NAMES.access_denied, { state: { error } });
};

const defaultTeamRedirectHandler = async (error) => {
  // eslint-disable-next-line no-console
  console.warn('changing to a new Sentinel Team:', error);

  const sentinelTeam = error.extensions.detail;
  const currentRoute = `${window.location.pathname}${window.location.search}`;

  const {
    data: {
      setCurrentSentinelTeam: {
        user: {
          sentinelTeam: { teamType: newTeamType },
        },
      },
    },
  } = await setSentinelTeam({ variables: { id: sentinelTeam?.id } });

  navigate(translateRouteForTeamType(currentRoute, newTeamType));
};

const defaultHandleNetworkError = (networkError) => {
  // eslint-disable-next-line no-console
  console.error(`[Network error]: ${networkError}`);
};

export default function buildErrorLink({
  onInvitationAuthError = defaultInvitationAuthErrorHandler,
  onAuthenticationFailure = defaultAuthErrorHandler,
  onAuthorizationFailure = defaultAuthorizationErrorHandler,
  onTeamRedirect = defaultTeamRedirectHandler,
  onNetworkError = defaultHandleNetworkError,
  onGenericGraphQLError = reportGraphQLErrors,
} = {}) {
  function handleInvitationAuthError(errors) {
    if (hasInvitationAuthError(errors)) {
      if (onInvitationAuthError) onInvitationAuthError();
      return true;
    }

    return false;
  }

  function handleAuthenticationError(errors) {
    // If an authentication error is present in the array of errors,
    // it means the user isn't signed in or was signed out. If the user
    // signs out in this app, they should never hit this error. SSO means
    // they can sign out in a different app too though, perhaps while this
    // app is still open. This code really handles that scenario.
    const authError = findErrorByCode(errors, AUTHENTICATION_ERROR);

    if (authError) {
      if (
        authError?.extensions?.detail ===
          SENTINEL_AUTHENTICATION_ERROR_DETAIL &&
        onAuthenticationFailure
      )
        onAuthenticationFailure(authError);
      return true;
    }

    return false;
  }

  function handleAuthorizationError(errors) {
    const authError = findErrorByCode(errors, AUTHORIZATION_ERROR);

    if (authError) {
      if (onAuthorizationFailure) onAuthorizationFailure(authError);
      return true;
    }

    return false;
  }

  function handleTeamRedirectError(errors) {
    const seeOtherError = findErrorByCode(errors, SEE_OTHER_ERROR);

    if (seeOtherError && seeOtherError.extensions?.detail?.id) {
      if (onTeamRedirect) onTeamRedirect(seeOtherError);
      return true;
    }

    return false;
  }

  function handleOtherGraphQLError(data) {
    if (onGenericGraphQLError) onGenericGraphQLError(data);
    return true;
  }

  return onError((data) => {
    const { graphQLErrors, networkError } = data;
    if (graphQLErrors) {
      // eslint-disable-next-line no-unused-expressions
      handleInvitationAuthError(graphQLErrors) ||
        handleAuthenticationError(graphQLErrors) ||
        handleAuthorizationError(graphQLErrors) ||
        handleTeamRedirectError(graphQLErrors) ||
        handleOtherGraphQLError(data);
    }
    if (networkError) {
      if (onNetworkError) onNetworkError(networkError);
    }
  });
}
