import customersFiltersQuery from 'pages/AssetGroupings/Tabs/Filters/customersFilters.graphql';

export function updateCustomerFilters(customer, cache, newStatus) {
  try {
    const data = cache.readQuery({
      query: customersFiltersQuery,
    });

    if (data?.customersFilters) {
      const { customers } = data.customersFilters;

      const oldStatus = customer.status;

      const oldFilterIndex = customers.findIndex(
        (c) => c.attribute === oldStatus,
      );
      customers[oldFilterIndex] = {
        ...customers[oldFilterIndex],
        count: customers[oldFilterIndex].count - 1,
      };
      const newFilterIndex = customers.findIndex(
        (c) => c.attribute === newStatus,
      );
      customers[newFilterIndex] = {
        ...customers[newFilterIndex],
        count: customers[newFilterIndex].count + 1,
      };

      cache.writeQuery({
        query: customersFiltersQuery,
        data: {
          customersFilters: {
            ...data.customersFilters,
            customers,
          },
        },
      });
    }
  } catch (e) {
    /* istanbul ignore next -- @preserve */
    // eslint-disable-next-line
    console.error(e);
  }
}
