import { t } from '@lingui/macro';

import {
  DEALER_GROUP,
  FLEET,
  SINGLE_LOCATION,
  DEALER_TEAM,
  DEALER_ID,
} from './constants';

export function isSingleLocation(sourceType?: string) {
  return sourceType === SINGLE_LOCATION;
}

export function isDealerGroup(sourceType?: string) {
  return sourceType === DEALER_GROUP;
}

export function isDealerTeam(sourceType?: string) {
  return sourceType === DEALER_TEAM;
}

export function isFleet(sourceType?: string) {
  return sourceType === FLEET;
}

export const SOURCE_NAME = {
  [SINGLE_LOCATION]: t`Dealer`,
  [DEALER_ID]: t`Dealer`,
  [DEALER_GROUP]: t`Dealer Group`,
  [FLEET]: t`Fleet`,
  [DEALER_TEAM]: t`Dealer Account or Group`,
} as const;

export const ALT_SOURCE_NAME = {
  [SINGLE_LOCATION]: t`dealer`,
  [DEALER_ID]: t`dealer account`,
  [DEALER_GROUP]: t`dealer group`,
  [FLEET]: t`fleet`,
  [DEALER_TEAM]: t`dealer`,
} as const;

function noTransitionTokenError(sourceName: string, sourceId: string) {
  return t`${sourceName} ${sourceId} must have at least one transition token. Open the location through the Portal to create one on the Platform API page.`;
}

export type UserValidationError =
  | ApiUserValidError
  | GroupIdError
  | 'INTERNAL_ERROR'
  | 'NO_TEAM';

export function generateUserValidationError(
  error: UserValidationError,
  sourceType: SentinelTeamSourceEnum | PlatformAccountSourceEnum,
  sourceId: string,
) {
  const sourceName = SOURCE_NAME[sourceType];

  switch (error) {
    case 'INVALID_CREDENTIALS':
      return t`API user is not valid`;
    case 'NO_DEALER_GROUP_ACCESS':
      return t`API user must already be a part of ${sourceName} ${sourceId}.`;
    case 'NO_TEAM':
      return t`${sourceName} ID must be valid.`;
    case 'INTERNAL_ERROR':
      return t`Internal Error`;
    case 'NO_TRANSITION_TOKEN':
      return noTransitionTokenError(sourceName, sourceId);
    default:
      return null;
  }
}

const SOURCE_DESCRIPTION = {
  [DEALER_ID]: t`dealer ids`,
  [DEALER_GROUP]: t`dealer groups`,
  [SINGLE_LOCATION]: t`service provider dealers`,
  [FLEET]: t`fleets`,
};

export type SourceValidationError =
  | ApiUserValidError
  | GroupIdError
  | 'SOURCE_IN_LIST'
  | 'GROUP_IN_LIST'
  | 'GROUP_IN_USE';

export function generateSourceValidationError(
  error: SourceValidationError,
  sourceType: SentinelTeamSourceEnum | PlatformAccountSourceEnum,
  sourceId: string,
) {
  const altSourceName = ALT_SOURCE_NAME[sourceType];

  const descriptionForSource = SOURCE_DESCRIPTION[sourceType];
  const sourceName = SOURCE_NAME[sourceType];

  switch (error) {
    case 'SOURCE_IN_LIST':
      return t`Source ID already in the list.`;
    case 'INVALID_GROUP_ID':
      return t`${sourceName} ID must match an existing ${altSourceName} in Portal.`;
    case 'GROUP_IN_USE':
      return t`${sourceName} ID already taken`;
    case 'NO_TRANSITION_TOKEN':
      return noTransitionTokenError(sourceName, sourceId);
    case 'INVALID_DEALER_TYPE':
      return t`${sourceName} ${sourceId} is not a valid type of ${altSourceName}. Only ${descriptionForSource} are allowed.`;
    default:
      return null;
  }
}

const API_USER_TEXT = {
  [SINGLE_LOCATION]: t`the service location`,
  [DEALER_GROUP]: t`all locations within the team’s Dealer Group`,
  [FLEET]: t`all fleet accounts added to the team`,
  [DEALER_TEAM]: t`all locations within the team’s Dealer(s)`,
} as const;

export function generateApiUserText(sourceType: SentinelTeamSourceEnum) {
  return API_USER_TEXT[sourceType];
}

export const ASSET_CONFIGURATION_VALUE = {
  CHASSIS_ID: t`Chassis ID`,
  COST_CENTER: t`Cost Center`,
  SERIAL_NUMBER: t`Serial`,
  UNIT_NUMBER: t`Unit`,
  VIN: t`VIN`,
  YEAR_MAKE_MODEL: t`Year Make Model (YMM)`,
  ENGINE_MODEL: t`Engine Model`,
  TYPE: t`Type`,
  FLEET_INFO: t`Fleet Info`,
} as const;

export function generatePlatformAccountName(
  sourceType: PlatformAccountSourceEnum,
  sourceId: string,
) {
  switch (sourceType) {
    case DEALER_ID:
      return t`Dealer ID ${sourceId}`;
    case DEALER_GROUP:
      return t`Dealer Group ${sourceId}`;
    default:
      return sourceId;
  }
}
