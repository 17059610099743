/* istanbul ignore file -- @preserve */
import { ApolloLink } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';

import get from 'lodash/get';

import observeMutation from 'features/apolloMutationsObserver';

/**
 * A custom Apollo link middleware that tracks and notifies
 * about mutations lifecycle status.
 * @type {ApolloLink}
 */
const mutationsObserverLink = new ApolloLink((operation, forward) => {
  const definition = getMainDefinition(operation.query);

  if (get(definition, 'operation') === 'mutation') {
    return observeMutation(operation, forward);
  }

  return forward(operation);
});

export default mutationsObserverLink;
