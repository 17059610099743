import React from 'react';
import { t, Trans } from '@lingui/macro';
import PropTypes from 'prop-types';

import { useNavigate } from 'react-router-dom';

import Button from '@decisiv/ui-components/lib/components/Button';
import Flex from '@decisiv/ui-components/lib/components/Flex';
import { H3 } from '@decisiv/ui-components/lib/components/Typography';

import useSentinelTeam from 'hooks/useSentinelTeam';

export default function TotalAssignedToMe({ count }) {
  const navigate = useNavigate();
  const { isFleet } = useSentinelTeam();
  const navigateTo = isFleet
    ? '/depots?onlyAssignedToMe=true'
    : '/customers?onlyAssignedToMe=true';

  const onViewAll = () => navigate(navigateTo);

  const assignedToMeCount = count === 0 ? '―' : count;

  const viewAllLabel = isFleet
    ? t`View All Depots Assigned to Me`
    : t`View All Customers Assigned to Me`;

  return (
    <Flex flex={1} flexDirection="column">
      <Flex marginLeft={1} alignItems="center">
        <H3 weight="semibold">{assignedToMeCount}</H3>
        <H3
          weight="medium"
          css={`
            margin-left: 10px;
            white-space: nowrap;
          `}
        >
          <Trans>Assigned to Me</Trans>
        </H3>
      </Flex>
      <Flex justifyContent="end">
        <Button
          aria-label={viewAllLabel}
          text={t`View All`}
          variant="ghost"
          onClick={onViewAll}
        />
      </Flex>
    </Flex>
  );
}

TotalAssignedToMe.propTypes = {
  count: PropTypes.number.isRequired,
};
