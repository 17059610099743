import React, { useMemo } from 'react';
import { loader } from 'graphql.macro';
import PropTypes from 'prop-types';
import rem from 'polished/lib/helpers/rem';
import { useQuery } from '@apollo/client';

import spacing from '@decisiv/design-tokens/lib/spacing';

import Flex from '@decisiv/ui-components/lib/components/Flex';
import Grid from '@decisiv/ui-components/lib/components/Grid';
import { H3, P } from '@decisiv/ui-components/lib/components/Typography';

import { Trans } from '@lingui/macro';

import Loading from 'components/Loading';
import Paper from 'components/Paper';
import Paginated from 'components/Paginated';

import useSentinelTeam from 'hooks/useSentinelTeam';

import AssetInformation from './AssetInformation';
import { assetActionText, assetAttributeText } from './AssetHistory';
import AssetGroupingHistory, {
  assetGroupingActionText,
  assetGroupingAttributeText,
} from './AssetGroupingHistory';
import InvitationHistory, {
  invitationActionText,
  invitationAttributeText,
} from './InvitationHistory';

const assetGroupingHistoryQuery = loader('./assetGroupingHistory.graphql');

const paginatedAttrs = {
  CustomerAssetHistory: {
    actionText: assetActionText,
    attributeText: assetAttributeText,
    Component: AssetInformation,
  },
  CustomerHistory: {
    actionText: assetGroupingActionText,
    attributeText: assetGroupingAttributeText,
    Component: AssetGroupingHistory,
  },
  CustomerInvitationHistory: {
    actionText: invitationActionText,
    attributeText: invitationAttributeText,
    Component: InvitationHistory,
  },
};

const actionText = ({ change: { __typename, action, items } }, isFleet) => {
  const attrs =
    __typename === 'CustomerHistory'
      ? [action, isFleet, items]
      : [action, items];

  return paginatedAttrs[__typename]?.actionText(...attrs);
};

const attributeText = ({ change: { __typename, items } }, isFleet) => {
  const attrs = __typename === 'CustomerHistory' ? [items, isFleet] : [items];

  return paginatedAttrs[__typename]?.attributeText(...attrs);
};

const renderActionText = (isFleet) => (datum) => {
  return (
    <>
      {actionText(datum, isFleet)}
      &nbsp;
      <P as="span" weight="semibold">
        {attributeText(datum, isFleet)}.
      </P>
    </>
  );
};

const renderDatum = ({ change: { __typename, action, items } }) => {
  const { Component } = paginatedAttrs[__typename] || {};
  if (items.length && Component) {
    return (
      <Flex
        css={`
          > :not(:last-child) {
            margin-bottom: ${rem(spacing.base * 0.5)};
          }
        `}
        flexDirection="column"
      >
        {items.map((item) => {
          const key =
            item?.id ||
            item?.contact?.id ||
            item?.statusChange?.afterValue?.endTime;

          return <Component action={action} key={key} item={item} />;
        })}
      </Flex>
    );
  }
  return null;
};

export default function ActivityCard({ assetGroupingId }) {
  const { isFleet } = useSentinelTeam();

  const {
    loading,
    fetchMore,
    data: {
      assetGrouping: {
        history: { pagination = {}, collection: history = [] } = {},
      } = {},
    } = {},
  } = useQuery(assetGroupingHistoryQuery, {
    notifyOnNetworkStatusChange: true,
    variables: {
      assetGroupingId,
      page: 1,
    },
  });

  const getPaginationProps = useMemo(
    () => ({
      ...pagination,
      onClick: () => {
        fetchMore({ variables: { page: pagination?.page + 1 } });
      },
      showPagination: pagination?.page < pagination?.totalPages,
    }),
    [pagination, fetchMore],
  );

  if (loading) return <Loading />;

  const filteredHistory = isFleet
    ? history.filter(
        ({ change: { __typename } }) => __typename !== 'CustomerHistory',
      )
    : history;

  return (
    <Paper>
      <Grid.Row>
        <Grid.Column paddingX={2} paddingY={1}>
          <H3 as="h2">
            <Trans>Activity</Trans>
          </H3>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column paddingX={2} paddingY={2}>
          <Paginated
            data={filteredHistory}
            renderDatum={renderDatum}
            actionText={renderActionText(isFleet)}
            getPaginationProps={getPaginationProps}
          />
        </Grid.Column>
      </Grid.Row>
    </Paper>
  );
}

ActivityCard.propTypes = {
  assetGroupingId: PropTypes.string.isRequired,
};
