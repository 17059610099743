import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import toColorString from 'polished/lib/color/toColorString';

import spacing from '@decisiv/design-tokens/lib/spacing';
import color from '@decisiv/design-tokens/lib/color';

export const Button = styled.button<{ active?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0 ${rem(spacing.base * 2.2)};

  border: none;

  color: ${toColorString(color.base.snowWhite)};
  background-color: ${toColorString(color.status.information.dark)};

  ${({ active }) =>
    !active &&
    css`
      &:hover,
      &:active {
        color: ${toColorString(color.status.information.dark)};
        background-color: ${toColorString(color.status.information.light)};
      }
    `}

  ${({ active }) =>
    active &&
    css`
      color: ${toColorString(color.base.charcoal)};
      background-color: ${toColorString(color.base.snowWhite)};
    `}
`;
