import React, { useMemo } from 'react';
import { t } from '@lingui/macro';

import Alert from '@decisiv/ui-components/lib/components/Alert';
import Flex from '@decisiv/ui-components/lib/components/Flex';
import Table from '@decisiv/ui-components/lib/components/Table';

import PaginationFooter from 'components/PaginationFooter';

import useFooter from 'utils/useFooter';

import { useTeams } from './contexts/TeamsContext';

import { SERVICE_GROUPS_SORT_COLUMNS } from './constants';

import ActionsCell from './Cells/ActionsCell';
import NameCell from './Cells/NameCell';
import IdCell from './Cells/IdCell';
import CreatedAtCell from './Cells/CreatedAtCell';
import TeamKeyCell from './Cells/TeamKeyCell';
import TeamTypeCell from './Cells/TeamTypeCell';
import PlatformAccountsCell from './Cells/PlatformAccountsCell';
import ApiUserCell from './Cells/ApiUserCell';
import OwnerCell from './Cells/OwnerCell';
import BillingContactCell from './Cells/BillingContactCell';
import EnabledAssetsCell from './Cells/EnabledAssetsCell';

function generateDeletionAlert(sentinelTeamNames) {
  if (!sentinelTeamNames || sentinelTeamNames.length === 0) return undefined;
  if (sentinelTeamNames.length === 1) {
    const teamName = sentinelTeamNames[0];

    return t`${teamName} team will be deleted soon.`;
  }

  const commaSeparatedTeams = sentinelTeamNames
    .slice(0, sentinelTeamNames.length - 1)
    .join(', ');
  const lastTeam = sentinelTeamNames.at(-1);

  return t`${commaSeparatedTeams} and ${lastTeam} teams will be deleted soon.`;
}

export default function TeamsList() {
  const { defaultSort, onSort, allSentinelTeams, setPage, pagination } =
    useTeams();

  const columns = useMemo(
    () => [
      {
        DataCell: NameCell,
        getCellKey: ({ id }) => `team-name-${id}`,
        name: SERVICE_GROUPS_SORT_COLUMNS.NAME,
        sortBy: (direction) =>
          onSort(SERVICE_GROUPS_SORT_COLUMNS.NAME, direction),
        title: t`Name`,
        'aria-label': t`Sort By Name`,
        fixed: true,
      },
      {
        DataCell: IdCell,
        getCellKey: ({ id }) => `team-id-${id}`,
        name: SERVICE_GROUPS_SORT_COLUMNS.ID,
        sortBy: (direction) =>
          onSort(SERVICE_GROUPS_SORT_COLUMNS.ID, direction),
        title: t`ID`,
        'aria-label': t`Sort By Id`,
      },
      {
        DataCell: CreatedAtCell,
        getCellKey: ({ id }) => `team-createdAt-${id}`,
        name: SERVICE_GROUPS_SORT_COLUMNS.CREATED_AT,
        sortBy: (direction) =>
          onSort(SERVICE_GROUPS_SORT_COLUMNS.CREATED_AT, direction),
        title: t`Created`,
        'aria-label': t`Sort By Created At`,
      },
      {
        DataCell: TeamKeyCell,
        getCellKey: ({ id }) => `team-key-${id}`,
        name: SERVICE_GROUPS_SORT_COLUMNS.TEAM_KEY,
        sortBy: (direction) =>
          onSort(SERVICE_GROUPS_SORT_COLUMNS.TEAM_KEY, direction),
        title: t`Key`,
        'aria-label': t`Sort By Team Key`,
      },
      {
        DataCell: TeamTypeCell,
        getCellKey: ({ id }) => `team-type-${id}`,
        name: SERVICE_GROUPS_SORT_COLUMNS.TEAM_TYPE,
        sortBy: (direction) =>
          onSort(SERVICE_GROUPS_SORT_COLUMNS.TEAM_TYPE, direction),
        title: t`Type`,
        'aria-label': t`Sort By Type`,
      },
      {
        DataCell: PlatformAccountsCell,
        getCellKey: ({ id }) => `team-portal-${id}`,
        name: SERVICE_GROUPS_SORT_COLUMNS.DEALER_GROUP_ID,
        sortBy: (direction) =>
          onSort(SERVICE_GROUPS_SORT_COLUMNS.DEALER_GROUP_ID, direction),
        title: t`Portal ID`,
        'aria-label': t`Sort By Portal Id`,
      },
      {
        DataCell: ApiUserCell,
        getCellKey: ({ id }) => `team-apiUsername-${id}`,
        name: SERVICE_GROUPS_SORT_COLUMNS.API_USER,
        sortBy: (direction) =>
          onSort(SERVICE_GROUPS_SORT_COLUMNS.API_USER, direction),
        title: t`API User`,
        'aria-label': t`Sort By API User`,
      },
      {
        DataCell: OwnerCell,
        getCellKey: ({ id }) => `team-owner-${id}`,
        name: SERVICE_GROUPS_SORT_COLUMNS.OWNER_EMAIL,
        sortBy: (direction) =>
          onSort(SERVICE_GROUPS_SORT_COLUMNS.OWNER_EMAIL, direction),
        title: t`Owner`,
        'aria-label': t`Sort By Owner Email`,
      },
      {
        DataCell: BillingContactCell,
        getCellKey: ({ id }) => `team-billing-contact-${id}`,
        name: 'BILLING_CONTACT',
        title: t`Billing Contact`,
      },
      {
        DataCell: EnabledAssetsCell,
        getCellKey: ({ id }) => `team-enabled-assets-${id}`,
        name: SERVICE_GROUPS_SORT_COLUMNS.ENABLED_ASSET_COUNT,
        sortBy: (direction) =>
          onSort(SERVICE_GROUPS_SORT_COLUMNS.ENABLED_ASSET_COUNT, direction),
        title: t`Enabled Assets`,
      },
      {
        DataCell: ActionsCell,
        getCellKey: ({ id }) => `team-state-${id}`,
        name: SERVICE_GROUPS_SORT_COLUMNS.STATE,
        sortBy: (direction) =>
          onSort(SERVICE_GROUPS_SORT_COLUMNS.STATE, direction),
        title: t`State`,
        'aria-label': t`Sort By State`,
        fixed: true,
      },
    ],
    [onSort],
  );

  const { footerHeight, footerRef } = useFooter();

  const teamsWithAlerts = useMemo(
    () =>
      allSentinelTeams
        .filter((sentinelTeam) => sentinelTeam.scheduledForDeletionAt)
        .map((sentinelTeam) => sentinelTeam.name),
    [allSentinelTeams],
  );

  const alertText = useMemo(
    () => generateDeletionAlert(teamsWithAlerts),
    [teamsWithAlerts],
  );

  return (
    <>
      <Flex paddingLeft={2.2} paddingRight={2} marginBottom={1.3}>
        {alertText && <Alert intent="danger" title={alertText} />}
      </Flex>
      <div
        css={`
          position: absolute;
          height: calc(100% - ${footerHeight}px);
          width: 100%;
        `}
      >
        <Table
          columns={columns}
          data={allSentinelTeams}
          defaultSort={defaultSort}
          getRowKey={({ id }) => id}
          height="100%"
          footer={() => (
            <PaginationFooter
              ref={footerRef}
              title={t`Teams`}
              setCurrentPage={setPage}
              totalOnPage={allSentinelTeams.length}
              page={pagination.page}
              perPage={pagination.perPage}
              totalEntries={pagination.totalEntries}
              totalPages={pagination.totalPages}
            />
          )}
          scroll={['x', 'y']}
        />
      </div>
    </>
  );
}
