export const SINGLE_LOCATION: SentinelTeamSourceEnum =
  'SINGLE_SERVICE_LOCATION';
export const DEALER_GROUP: SentinelTeamSourceEnum = 'DEALER_GROUP';
export const FLEET: SentinelTeamSourceEnum = 'FLEET';
export const DEALER_TEAM: SentinelTeamSourceEnum = 'DEALER_TEAM';
export const DEALER_ID = 'DEALER_ID';

export const QA = 'QA';
export const DEMO = 'DEMO';
export const POC = 'POC';
export const PAYING_CUSTOMER = 'PAYING_CUSTOMER';

export const MANAGED_CARE = 'MANAGED_CARE';
export const LEASING_AND_RENTAL = 'LEASING_AND_RENTAL';
export const MIXED = 'MIXED';

export const PLATFORM_ACCOUNT_TYPES = {
  DEALER_ID: 'DEALER_ID',
  DEALER_GROUP: 'DEALER_GROUP',
} as const;
