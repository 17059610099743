import React from 'react';
import PropTypes from 'prop-types';

import { Navigate } from 'react-router-dom';

export default function Redirect({ to, from }) {
  return <Navigate to={to} state={{ from }} replace />;
}

Redirect.propTypes = {
  to: PropTypes.string.isRequired,
  from: PropTypes.string,
};

Redirect.defaultProps = {
  from: undefined,
};
