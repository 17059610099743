/* istanbul ignore file -- @preserve */

import React, { Suspense } from 'react';
import { matchPath, Route } from 'react-router-dom';

import { FLEET } from 'utils/SentinelTeam/constants';

import RecursiveNestedWrapper from 'components/RecursiveNestedWrapper';
import Loading from 'components/Loading';

import ROUTE_NAMES from '../routeNames';

import FleetRoute from './FleetRoute';
import SentinelAdminRoute from './SentinelAdminRoute';
import SentinelRoute from './SentinelRoute';
import SuperUserRoute from './SuperUserRoute';
import NonFleetRoute from './NonFleetRoute';
import DealerGroupOrDealerTeamRoute from './DealerGroupOrDealerTeamRoute';
import DecisivRoute from './DecisivRoute';
import TeamMemberRoute from './TeamMemberRoute';
import Redirect from './Redirect';
import InvitationRoute from './InvitationRoute';

/* NORMAL ROUTES */
const Alerts = React.lazy(() => import('pages/Alerts'));
const AssetView = React.lazy(() => import('pages/AssetView'));
const Appointment = React.lazy(() => import('pages/Appointment'));
const Appointments = React.lazy(() => import('pages/Appointments'));
const Reports = React.lazy(() => import('pages/Reports'));
const Search = React.lazy(() => import('pages/Search'));

/* ADMIN */
const CompletionChecklist = React.lazy(() =>
  import('pages/admin/CompletionChecklist'),
);
const Account = React.lazy(() => import('pages/admin/Account'));
const Integrations = React.lazy(() => import('pages/admin/Integrations'));
const Members = React.lazy(() => import('pages/admin/Members'));
const Preferences = React.lazy(() => import('pages/admin/Preferences'));

/* SUPER ADMIN */
const Teams = React.lazy(() => import('pages/Teams'));
const ScheduledReports = React.lazy(() => import('pages/ScheduledReports'));

/* CONDITIONALS */
const AssetGroupings = React.lazy(() => import('pages/AssetGroupings'));
const AssetGrouping = React.lazy(() => import('pages/AssetGrouping'));
const ServiceLocations = React.lazy(() =>
  import('pages/admin/ServiceLocations'),
);
const FeatureFlags = React.lazy(() => import('pages/FeatureFlags'));
const NotificationPreferences = React.lazy(() =>
  import('pages/NotificationPreferences'),
);

const InvitationsRequest = React.lazy(() => import('pages/InvitationsRequest'));

const SENTINEL_ROUTES = {
  wrapper: [SentinelRoute],
  routes: [
    { path: ROUTE_NAMES.alerts, component: Alerts },
    { path: ROUTE_NAMES.asset_view, component: AssetView },
    { path: ROUTE_NAMES.service_event, component: Appointment },
    { path: ROUTE_NAMES.service_events, component: Appointments },
    { path: ROUTE_NAMES.reports, component: Reports },
    { path: ROUTE_NAMES.search, component: Search },
    // this route might exist until we support multi-tabs
    { path: ROUTE_NAMES.assetGrouping, component: AssetGrouping },
  ],
};

const SENTINEL_ADMIN_ROUTES = {
  wrapper: [SentinelRoute, SentinelAdminRoute],
  routes: [
    { path: ROUTE_NAMES.admin.root, component: CompletionChecklist },
    { path: ROUTE_NAMES.admin.account, component: Account },
    { path: ROUTE_NAMES.admin.integrations, component: Integrations },
    { path: ROUTE_NAMES.admin.members, component: Members },
    { path: ROUTE_NAMES.admin.preferences, component: Preferences },
  ],
};

const NO_TEAM_ROUTES = {
  wrapper: [SuperUserRoute],
  routes: [
    { path: ROUTE_NAMES.superadmin.teams, component: Teams },
    { path: ROUTE_NAMES.superadmin.reports, component: ScheduledReports },
  ],
};

const FLEET_ROUTES = {
  wrapper: [SentinelRoute, FleetRoute],
  routes: [
    { path: ROUTE_NAMES.depots, component: AssetGroupings },
    { path: ROUTE_NAMES.depot, component: AssetGrouping },
  ],
};

const NON_FLEET_ROUTES = {
  wrapper: [SentinelRoute, NonFleetRoute],
  routes: [
    { path: ROUTE_NAMES.customers, component: AssetGroupings },
    { path: ROUTE_NAMES.customer, component: AssetGrouping },
  ],
};

const DEALER_GROUP_OR_DEALER_TEAM_ROUTES = {
  wrapper: [SentinelRoute, DealerGroupOrDealerTeamRoute],
  routes: [
    { path: ROUTE_NAMES.admin.service_locations, component: ServiceLocations },
  ],
};

const DECISIV_ONLY_ROUTES = {
  wrapper: [DecisivRoute],
  routes: [{ path: ROUTE_NAMES.feature_flags, component: FeatureFlags }],
};

const SENTINEL_TEAM_MEMBER_ONLY_ROUTES = {
  wrapper: [TeamMemberRoute],
  routes: [
    {
      path: ROUTE_NAMES.notification_preferences,
      component: NotificationPreferences,
    },
  ],
};

const INVITATION_ROUTES = {
  wrapper: [InvitationRoute],
  routes: [{ path: ROUTE_NAMES.invitation, component: InvitationsRequest }],
};

const ROUTE_MAP = [
  SENTINEL_ROUTES,
  SENTINEL_ADMIN_ROUTES,
  NO_TEAM_ROUTES,
  FLEET_ROUTES,
  NON_FLEET_ROUTES,
  DEALER_GROUP_OR_DEALER_TEAM_ROUTES,
  DECISIV_ONLY_ROUTES,
  SENTINEL_TEAM_MEMBER_ONLY_ROUTES,
  INVITATION_ROUTES,
];

export function translateRouteForTeamType(route, teamType) {
  // result of `matchPath` will be null, or something like this:
  // {
  //   params: {
  //     assetGroupingId: '205',
  //   },
  //   pathname: '/depot/205',
  //   pathnameBase: '/depot/205',
  //   pattern: {
  //     path: '/depot/:assetGroupingId',
  //     caseSensitive: false,
  //     end: true,
  //   },
  // };

  if (FLEET === teamType) {
    const customerRouteMatch = matchPath(ROUTE_NAMES.customer, route);
    if (customerRouteMatch) {
      return ROUTE_NAMES.forDepot({
        id: customerRouteMatch.params.assetGroupingId,
      });
    }
  } else {
    const depotRouteMatch = matchPath(ROUTE_NAMES.depot, route);

    if (depotRouteMatch) {
      return ROUTE_NAMES.forCustomer({
        id: depotRouteMatch.params.assetGroupingId,
      });
    }
  }

  return route;
}

export const ROUTES = ROUTE_MAP.reduce((accumulator, currentValue) => {
  const routes = currentValue.routes.map((route) => {
    const components = [...currentValue.wrapper, route.component];

    return (
      <Route
        key={route.path}
        path={route.path}
        element={
          <Suspense fallback={Loading}>
            <RecursiveNestedWrapper components={components} />
          </Suspense>
        }
      />
    );
  });

  return [...accumulator, ...routes];
}, []);

const REDIRECT_MAP = [
  { from: ROUTE_NAMES.appointments, to: ROUTE_NAMES.service_events },
  { from: ROUTE_NAMES.appointment, to: ROUTE_NAMES.service_event },
  { from: ROUTE_NAMES.admin.customers, to: ROUTE_NAMES.customers },
  { from: ROUTE_NAMES.admin.customer, to: ROUTE_NAMES.customer },
  { from: ROUTE_NAMES.admin.depots, to: ROUTE_NAMES.depots },
  { from: ROUTE_NAMES.admin.depot, to: ROUTE_NAMES.depot },
  { from: '/dashboard', to: '/' },
];

export const REDIRECTS = REDIRECT_MAP.map((routeToRedirect) => (
  <Route
    key={routeToRedirect.from}
    path={routeToRedirect.from}
    element={<Redirect to={routeToRedirect.to} from={routeToRedirect.from} />}
  />
));
