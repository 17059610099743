import React from 'react';

import { t } from '@lingui/macro';

import Search from '@decisiv/iconix/lib/components/Search';

import KDSTopNav from '@decisiv/ui-components/lib/components/TopNav';

import ROUTE_NAMES from 'setup/Routing/routeNames';

import { useCurrentUserContext } from 'utils/User';

function SearchLink() {
  const { needsSentinelTeam } = useCurrentUserContext();

  if (needsSentinelTeam) {
    return null;
  }

  return (
    <KDSTopNav.Link
      aria-label={t`Search`}
      icon={Search}
      to={ROUTE_NAMES.search}
    />
  );
}

export default SearchLink;
