import camelCase from 'lodash/camelCase';
import isEmpty from 'lodash/isEmpty';
import reject from 'lodash/reject';
import startCase from 'lodash/startCase';

import {
  CUSTOMER_TYPE,
  customerColors,
  customerIcons,
  customerStatusLabelText,
} from './constants';

export {
  CUSTOMER_ACCEPTED_STATUSES,
  CUSTOMER_STATUS,
  CUSTOMER_TYPE,
  CUSTOMER_PENDING_STATUSES,
} from './constants';

export const isFleet = ({ customerType }: Pick<Customer, 'customerType'>) =>
  customerType === CUSTOMER_TYPE.DEPOT;

export const formatCityAndState = (city?: string, state?: string) => {
  const attrs = [startCase(camelCase(city)), state];
  return reject(attrs, (a) => isEmpty(a)).join(', ');
};

export const badgeColorForCustomer = ({ status }: Pick<Customer, 'status'>) =>
  customerColors[status];

export const badgeIconForCustomer = ({ status }: Pick<Customer, 'status'>) =>
  customerIcons[status];

export const labelForCustomerStatus = ({ status }: Pick<Customer, 'status'>) =>
  customerStatusLabelText[status];
