import React, { useMemo } from 'react';

import toColorString from 'polished/lib/color/toColorString';

import color from '@decisiv/design-tokens/lib/color';

import Asterisk from '@decisiv/iconix/lib/components/Asterisk';

import { P } from '@decisiv/ui-components/lib/components/Typography';

import { Trans } from '@lingui/macro';

export default function Description() {
  const asteriskIcon = useMemo(
    () => (
      <Asterisk
        color={toColorString(color.status.information.medium)}
        css={`
          vertical-align: text-top;
        `}
        size="medium"
      />
    ),
    [],
  );

  return (
    <P color="alaskanHusky" marginTop={0.5}>
      <Trans>
        Complete the required fields {asteriskIcon}. When you’re done a welcome
        email will be sent to the Team Owner configured in the Settings.
      </Trans>
    </P>
  );
}
