/* istanbul ignore file -- @preserve */
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import React from 'react';

import { ApolloProvider as RootApolloProvider } from '@apollo/client';

import Robot from '@decisiv/iconix/lib/components/Robot';
import Wifi from '@decisiv/iconix/lib/components/Wifi';

import { useNotifications } from '@decisiv/ui-components/lib/components/Notifications';

import { Trans, t } from '@lingui/macro';

import { useNavigate } from 'react-router-dom';

import ErrorPage from 'pages/ErrorPage';

import { ErrorBoundary } from 'setup/Bugsnag';
import ROUTE_NAMES from 'setup/Routing/routeNames';

import buildApolloClient from './apolloClient';

const NO_INTERNET_ERROR = 'NO_INTERNET_ERROR';
const GENERAL_ERROR = 'GENERAL_ERROR';

function useErrorNotifications() {
  const navigate = useNavigate();
  const { notify } = useNotifications();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onNetworkError = React.useCallback(
    debounce(
      (networkError) => {
        if (networkError?.statusCode === 503) {
          const requestedRouteWithQuery = window.location.href.replace(
            window.location.origin,
            '',
          );
          navigate(ROUTE_NAMES.site_maintenance, {
            replace: true,
            state: { target: requestedRouteWithQuery },
          });
          return undefined;
        }

        if (!window.navigator.onLine) {
          notify((currentNotifications) => {
            if (
              isEmpty(
                currentNotifications.filter(
                  ([{ id }]) => id === NO_INTERNET_ERROR,
                ),
              )
            ) {
              return [
                {
                  onClose: noop,
                  id: NO_INTERNET_ERROR,
                  icon: Wifi,
                  intent: 'danger',
                  title: t`No Internet Connection`,
                },
                <Trans>Check your internet connection and try again.</Trans>,
              ];
            }
            return undefined;
          });
        } else {
          notify((currentNotifications) => {
            if (
              isEmpty(
                currentNotifications.filter(([{ id }]) => id === GENERAL_ERROR),
              )
            ) {
              return [
                {
                  onClose: noop,
                  id: GENERAL_ERROR,
                  icon: Robot,
                  intent: 'danger',
                  title: t`Something Went Wrong`,
                },
                <Trans>
                  An issue occurred behind the scenes. Please try again.
                </Trans>,
              ];
            }
            return undefined;
          });
        }
        return undefined;
      },
      5000,
      { leading: true, trailing: false },
    ),
    [],
  );

  return { onNetworkError };
}

export default function ApolloProvider({ children, ...props }) {
  const { onNetworkError } = useErrorNotifications(props);

  const apolloClient = React.useMemo(() => {
    return buildApolloClient({ onNetworkError });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RootApolloProvider client={apolloClient} {...props}>
      <ErrorBoundary FallbackComponent={ErrorPage}>{children}</ErrorBoundary>
    </RootApolloProvider>
  );
}

ApolloProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
