export const mutationType = {
  updateAppointment: 'updateAppointment',
  updateDeferral: 'updateDeferral',
};

export const mutationStatus = {
  complete: 'complete', // mutation never called or called and succeeded
  loading: 'loading', // mutation in progress
  error: 'error', // mutation failed
};
