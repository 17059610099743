import { loader } from 'graphql.macro';
import noop from 'lodash/noop';
import isEmpty from 'lodash/isEmpty';
import rem from 'polished/lib/helpers/rem';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import Sticky from 'react-sticky-el';
import { useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import spacing from '@decisiv/design-tokens/lib/spacing';

import Breadcrumbs from '@decisiv/ui-components/lib/components/Breadcrumbs';
import Grid from '@decisiv/ui-components/lib/components/Grid';
import { P } from '@decisiv/ui-components/lib/components/Typography';

import { Trans, t } from '@lingui/macro';

import ROUTE_NAMES from 'setup/Routing/routeNames';

import {
  boundaryElement,
  scrollElement,
} from 'components/Layout/ScrollingContent';
import Loading from 'components/Loading';
import Page from 'components/Page';

import {
  CUSTOMER_ACCEPTED_STATUSES,
  isFleet as isFleetAssetGrouping,
} from 'utils/Customer';
import { useCurrentUserContext } from 'utils/User';

import useInvitation from 'hooks/useInvitation';

import useSentinelTeam from 'hooks/useSentinelTeam';
import AssetsFiltersAndList from './AssetsFiltersAndList';
import ActivityCard from './ActivityCard';
import AssetGroupingPageHead from './AssetGroupingPageHead';
import NotFound from './NotFound';
import OverviewCard from './OverviewCard';

const assetGroupingQuery = loader('./assetGrouping.graphql');

export default function AssetGroupingPage({ banner, setScrollToTop }) {
  const { assetGroupingId } = useParams();

  const user = useCurrentUserContext();
  const { invitation, isInvitation } = useInvitation();

  const { isAdmin } = user;
  const { isFleet: isFleetLocation } = useSentinelTeam();

  const parsedAssetGroupingId = invitation.customer?.id || assetGroupingId;

  const {
    data: { assetGrouping = {} } = {},
    error,
    loading,
  } = useQuery(assetGroupingQuery, {
    variables: { assetGroupingId: parsedAssetGroupingId },
    fetchPolicy: 'cache-and-network',
  });

  const canUpdate = useMemo(() => {
    if (isInvitation) return true;

    return (
      isAdmin &&
      assetGrouping?.enabled &&
      (isFleetLocation ||
        (!isFleetLocation && !isFleetAssetGrouping(assetGrouping)))
    );
  }, [isAdmin, isInvitation, assetGrouping, isFleetLocation]);

  const viewAssets = useMemo(
    () =>
      user.id ||
      (isInvitation &&
        CUSTOMER_ACCEPTED_STATUSES.includes(assetGrouping?.status)),
    [assetGrouping, isInvitation, user.id],
  );

  const handleSticky = useCallback(
    (bool) => {
      setScrollToTop(bool);
    },
    [setScrollToTop],
  );

  if (loading && isEmpty(assetGrouping)) {
    return (
      <>
        <AssetGroupingPageHead />
        <Loading />
      </>
    );
  }

  if (error) {
    if (
      (error?.graphQLErrors || []).find((err) => {
        return err?.extensions?.code === 'NOT_FOUND';
      })
    ) {
      return <NotFound />;
    }

    const errorMessage = error.message;
    return (
      <>
        <AssetGroupingPageHead />
        <P>
          <Trans>
            <strong>Error:</strong> {errorMessage}
          </Trans>
        </P>
      </>
    );
  }

  return (
    <>
      <AssetGroupingPageHead assetGrouping={assetGrouping} />
      <Page padding={0}>
        {user.id && (
          <Grid.Row marginX={0} paddingX={1} paddingY={0.5}>
            <Breadcrumbs
              css={`
                margin-left: ${rem(spacing.base)};
              `}
              current={assetGrouping.name}
              path={[
                isFleetLocation
                  ? { to: ROUTE_NAMES.depots, text: t`Depots` }
                  : {
                      to: ROUTE_NAMES.customers,
                      text: t`Customers`,
                    },
              ]}
            />
          </Grid.Row>
        )}
        {banner || (
          <Sticky
            boundaryElement={`.${boundaryElement}`}
            onFixedToggle={handleSticky}
            scrollElement={`.${scrollElement}`}
            stickyStyle={{ zIndex: 2 }}
          >
            <OverviewCard assetGrouping={assetGrouping} />
          </Sticky>
        )}
        <Grid.Container paddingX={3} paddingY={2}>
          <AssetsFiltersAndList
            canUpdate={canUpdate}
            viewAssets={viewAssets}
            assetGrouping={assetGrouping}
            assetGroupingId={parsedAssetGroupingId}
          />
          {isAdmin && (
            <Grid.Row paddingY={1}>
              <Grid.Column
                padding={0}
                offset={viewAssets ? '3' : '0'}
                span={viewAssets ? '9' : '12'}
              >
                <ActivityCard assetGroupingId={assetGroupingId} />
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid.Container>
      </Page>
    </>
  );
}

AssetGroupingPage.propTypes = {
  banner: PropTypes.node,
  setScrollToTop: PropTypes.func,
};

AssetGroupingPage.defaultProps = {
  banner: undefined,
  setScrollToTop: noop,
};
