import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import isObjectLike from 'lodash/isObjectLike';
import isString from 'lodash/isString';
import trim from 'lodash/trim';

function isBlank(value) {
  return (
    isNil(value) ||
    (isString(value) && trim(value) === '') ||
    (isObjectLike(value) && isEmpty(value))
  );
}

/**
 * Remove from the object any properties that are null,
 * undefined, or empty strings. Boolean `false` values
 * and numeric `0` values are preserved.
 *
 * Operates recursively: any nested objects will have
 * blank properties removed as well, and any object
 * which winds up with no remaining properties will
 * also be removed.
 */
export default function removeBlankProperties(obj, depth = 0) {
  return Object.fromEntries(
    Object.entries(obj)
      .map(([k, v]) =>
        isObjectLike(v) ? [k, removeBlankProperties(v, depth + 1)] : [k, v],
      )
      .filter(([, v]) => !isBlank(v)),
  );
}
