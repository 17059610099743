import styled, { css } from 'styled-components';
import toColorString from 'polished/lib/color/toColorString';
import rem from 'polished/lib/helpers/rem';

import spacing from '@decisiv/design-tokens/lib/spacing';
import color from '@decisiv/design-tokens/lib/color';

import Flex from '@decisiv/ui-components/lib/components/Flex';

export const UsernameContainer = styled.div<{ warning: boolean }>`
  ${({ warning }) =>
    warning &&
    css`
      div:first-child {
        > div:first-child {
          border-color: ${toColorString(color.status.warning.medium)};
        }
      }
    `}
`;

export const SourceItemsContainer = styled(Flex)`
  flex-direction: column;

  max-height: ${rem(spacing.base * 21.6)};
  overflow-y: auto;

  & > div > button {
    height: auto;
  }

  & > div:not(:last-child) {
    margin-bottom: 2px;
  }
`;

export const DisplaySourceIdsContainer = styled(Flex)<{ display: boolean }>`
  display: ${(props) => (props.display ? 'flex' : 'none')};
`;
