import styled from 'styled-components';
import toColorString from 'polished/lib/color/toColorString';
import rem from 'polished/lib/helpers/rem';

import colors from '@decisiv/design-tokens/lib/color';
import spacing from '@decisiv/design-tokens/lib/spacing';

import Flex from '@decisiv/ui-components/lib/components/Flex';
import { P } from '@decisiv/ui-components/lib/components/Typography';

export const Container = styled(Flex).attrs({
  flexDirection: 'column',
  padding: 1,
})`
  background: ${toColorString(colors.base.midnight)};
  width: ${rem(spacing.base * 21)};
`;

export const LogoContainer = styled.a`
  display: flex;

  width: ${rem(spacing.base * 7)};
`;

export const Logo = styled.img`
  width: 100%;
  height: 100%;
`;

export const TextContainer = styled(Flex).attrs({
  flexDirection: 'column',
  marginTop: 0.5,
})`
  ${P} {
    color: ${toColorString(colors.base.quarterMoon)};
    font-size: ${rem(spacing.base * 0.8)};
  }
`;

export const Links = styled.span`
  display: flex;
  flex-wrap: wrap;

  color: ${toColorString(colors.base.quarterMoon)};
  font-size: ${rem(spacing.base * 0.8)};

  gap: ${rem(spacing.base * 0.2)};

  & > a:link,
  & > a:visited {
    border: none;
    font-size: ${rem(spacing.base * 0.8)};
    color: ${toColorString(colors.base.snowWhite)};
  }
`;
