import React from 'react';

import Popover from '@decisiv/ui-components/lib/components/Popover';

import isDemoMode from 'utils/isDemoMode';

import useSentinelTeam from 'hooks/useSentinelTeam';

import AmazonConnectButton from './AmazonConnectButton';
import AmazonConnectView from './AmazonConnectView';

export default function AmazonConnectMenu() {
  const sentinelTeam = useSentinelTeam();
  const amazonConnectInstance = isDemoMode()
    ? undefined
    : sentinelTeam?.amazonConnectInstance;

  if (!amazonConnectInstance) {
    return null;
  }

  return (
    <Popover
      placement="bottom-end"
      showArrow={false}
      alwaysRender
      target={({ ariaAttributes, isVisible, ref, toggle }) => (
        <AmazonConnectButton
          isVisible={isVisible}
          onClick={toggle}
          ref={ref}
          {...ariaAttributes}
        />
      )}
      zIndex={101}
    >
      <AmazonConnectView amazonConnectInstance={amazonConnectInstance} />
    </Popover>
  );
}
