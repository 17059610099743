import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import toColorString from 'polished/lib/color/toColorString';

import { spacing } from '@decisiv/design-tokens';
import color from '@decisiv/design-tokens/lib/color';

import { P } from '@decisiv/ui-components/lib/components/Typography';

import Flex from '@decisiv/ui-components/lib/components/Flex';

const stepNumberSize = spacing.base * 2.6;

const statusColor = (active: boolean, completed: boolean) => {
  if (completed) return toColorString(color.status.success.medium);
  if (active) return toColorString(color.status.information.medium);

  return toColorString(color.visualInterest.licoriceMousse.medium);
};

interface StepStatus {
  active: boolean;
  completed: boolean;
}

const StepNumber = styled(Flex).attrs({
  alignItems: 'center',
  justifyContent: 'center',
})<StepStatus>`
  width: ${rem(stepNumberSize)};
  height: ${rem(stepNumberSize)};
  text-align: center;
  border-radius: ${rem(stepNumberSize)};
  color: ${toColorString(color.base.snowWhite)};
  border: ${rem(spacing.base * 0.1)} solid
    ${({ active, completed }) => statusColor(active, completed)};
  background-color: ${({ active, completed }) =>
    statusColor(active, completed)};
`;

export const StepTitleContainer = styled(Flex).attrs({
  marginLeft: 0.5,
  flex: 1,
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
})<StepStatus>`
  border-bottom: ${rem(spacing.base * 0.2)} solid
    ${({ active, completed }) => statusColor(active, completed)};
`;

export const StepTitle = styled(P).attrs({
  shade: 1,
  color: 'alaskanHusky',
})`
  text-transform: uppercase;
`;

export default StepNumber;
