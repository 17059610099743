import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import noop from 'lodash/noop';

import Button from '@decisiv/ui-components/lib/components/Button';
import Flex from '@decisiv/ui-components/lib/components/Flex';
import Tooltip from '@decisiv/ui-components/lib/components/Tooltip';

import { t, Trans } from '@lingui/macro';
import useSentinelTeam from 'hooks/useSentinelTeam';

import SendInvitationModal from 'components/SendInvitationModal';

export default function SendRequestButton({
  customer,
  handleRefetch,
  icon,
  kind,
  size,
}) {
  const { isTeamEnabled } = useSentinelTeam();
  const [showModal, setShowModal] = useState(false);

  const handleClose = useCallback(() => {
    setShowModal(false);
  }, []);

  return (
    <>
      <Tooltip
        target={
          <Flex>
            <Button
              icon={icon}
              kind={kind}
              onClick={() => setShowModal(true)}
              size={size}
              text={t`Send Request`}
              disabled={!isTeamEnabled}
            />
          </Flex>
        }
        enabled={!isTeamEnabled}
        zIndex={100}
      >
        <Trans>Unable to perform this action on disabled teams</Trans>
      </Tooltip>
      <SendInvitationModal
        customer={customer}
        handleClose={handleClose}
        refetchList={handleRefetch}
        visible={showModal}
      />
    </>
  );
}

SendRequestButton.propTypes = {
  customer: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  kind: PropTypes.oneOf(['primary', 'secondary']),
  handleRefetch: PropTypes.func,
  icon: PropTypes.func,
  size: PropTypes.oneOf(['small', 'medium']),
};

SendRequestButton.defaultProps = {
  handleRefetch: noop,
  icon: undefined,
  size: 'small',
  kind: undefined,
};
