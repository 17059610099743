import UserArrowRight from '@decisiv/iconix/lib/components/UserArrowRight';

import Modal from '@decisiv/ui-components/lib/components/Modal';
import { P } from '@decisiv/ui-components/lib/components/Typography';

import { t, Trans } from '@lingui/macro';

import useSentinelTeam from 'hooks/useSentinelTeam';
import ROUTE_NAMES from 'setup/Routing/routeNames';

import { setSessionValue } from 'utils/sessionStorage';

type Notification = {
  id: string;
  sentinelTeam: SentinelTeam;
  source: NotificationDataSource;
};

interface BulkChangeTeamChangeConfirmationModalProps {
  visible: boolean;
  onClose(): void;
  notification: Notification;
}

function BulkChangeTeamChangeConfirmationModal({
  notification,
  visible,
  onClose,
}: BulkChangeTeamChangeConfirmationModalProps) {
  const currentSentinelTeam = useSentinelTeam();

  if (!notification) return null;

  const { setSentinelTeam } = currentSentinelTeam;
  const { sentinelTeam } = notification;

  const handleSubmit = () => {
    setSessionValue(
      'route',
      `${ROUTE_NAMES.service_events}?tab=archived&archived=ONLY_ARCHIVED`,
      sentinelTeam.id,
    );
    setSentinelTeam({ variables: { id: sentinelTeam.id } });
  };
  const currentTeamName = currentSentinelTeam?.name;
  const nameOfNewSentinelTeam = sentinelTeam.name;

  return (
    <Modal
      actions={[
        { text: t`Continue`, onClick: handleSubmit },
        { text: t`Stay`, onClick: onClose },
      ]}
      color="information"
      icon={UserArrowRight}
      onClose={onClose}
      title={t`Continue to ${nameOfNewSentinelTeam}?`}
      visible={visible}
      zIndex={100}
    >
      <P shade={1}>
        {currentTeamName ? (
          <Trans>
            You are currently viewing team{' '}
            <P as="span" weight="semibold">
              {currentTeamName}
            </P>
            .
          </Trans>
        ) : (
          <Trans>
            You are currently viewing{' '}
            <P as="span" weight="semibold">
              All Teams
            </P>
            .
          </Trans>
        )}{' '}
        <Trans>
          You will be taken to{' '}
          <P as="span" weight="semibold">
            {nameOfNewSentinelTeam}
          </P>
          .
        </Trans>
      </P>
    </Modal>
  );
}

export default BulkChangeTeamChangeConfirmationModal;
