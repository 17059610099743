import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Truck from '@decisiv/iconix/lib/components/Truck';
import Building from '@decisiv/iconix/lib/components/Building';
import Calendar from '@decisiv/iconix/lib/components/Calendar';

import Flex from '@decisiv/ui-components/lib/components/Flex';
import Link from '@decisiv/ui-components/lib/components/Link';
import { P } from '@decisiv/ui-components/lib/components/Typography';

import Separator from 'components/Separator';

import useAssetConfiguration from 'hooks/useAssetConfiguration';
import useSentinelTeam from 'hooks/useSentinelTeam';
import ROUTE_NAMES from 'setup/Routing/routeNames';

const CenteredP = styled(P).attrs({
  shade: 1,
  size: 'small',
})`
  display: flex;
  align-items: center;
  white-space: nowrap;
`;

export default function AuditAsset({ audit }) {
  const { asset, appointment } = audit;

  const { assetConfiguration } = useSentinelTeam();
  const { primary } = useAssetConfiguration({ asset, assetConfiguration });

  const { assetGrouping: { name: assetGroupingName } = {} } = asset;

  return (
    <Flex alignItems="center" flexWrap="wrap">
      {appointment && (
        <Flex>
          <CenteredP marginRight={0.5}>
            <Calendar size="small" />
          </CenteredP>
          <Link
            text={appointment.id}
            to={ROUTE_NAMES.forServiceEvent(appointment)}
            size="small"
          />
          <P shade={1} size="small" marginX={0.5}>
            |
          </P>
        </Flex>
      )}
      <Flex>
        {primary.map(({ id, label, text }, index) => {
          return (
            <Flex key={id} alignItems="center">
              {index > 0 && <Separator />}
              {index === 0 && (
                <CenteredP marginRight={0.5}>
                  <Truck size="small" />
                </CenteredP>
              )}
              <CenteredP>{`${label} ${text}`}</CenteredP>
            </Flex>
          );
        })}
      </Flex>
      <P shade={1} size="small" marginX={0.5}>
        |
      </P>
      <Flex>
        <CenteredP marginRight={0.5}>
          <Building size="small" />
        </CenteredP>
        <CenteredP marginRight={0.5}>{assetGroupingName}</CenteredP>
      </Flex>
    </Flex>
  );
}

AuditAsset.propTypes = {
  audit: PropTypes.shape({
    asset: PropTypes.shape({
      id: PropTypes.string,
      year: PropTypes.string,
      make: PropTypes.string,
      model: PropTypes.string,
      unitNumber: PropTypes.string,
      serialNumber: PropTypes.string,
      assetType: PropTypes.string,
      assetGrouping: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
    }),
    appointment: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};
