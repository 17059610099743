import { useCallback } from 'react';

import { t, Trans } from '@lingui/macro';

import ChevronRight from '@decisiv/iconix/lib/components/ChevronRight';

import { H4, P } from '@decisiv/ui-components/lib/components/Typography';
import Button from '@decisiv/ui-components/lib/components/Button';
import Flex from '@decisiv/ui-components/lib/components/Flex';
import RadioGroup from '@decisiv/ui-components/lib/components/RadioGroup';

import {
  FLEET,
  DEALER_TEAM,
  QA,
  DEMO,
  POC,
  PAYING_CUSTOMER,
  MANAGED_CARE,
  LEASING_AND_RENTAL,
  MIXED,
} from 'utils/SentinelTeam/constants';

import { useWizardModal } from 'components/WizardModal';

import { useTeamModalContext } from '../contexts/TeamModalContext';

import { Asterisk } from './styles';

export const NEW_LOGO_INPUT = 'new-logo-image';

export function isTruthy<T>(value?: T | undefined | null | false): value is T {
  return !!value;
}

type RadioGroupItem<T> = {
  label: string;
  value: T;
};

const teamPurposeItems: RadioGroupItem<SentinelTeamPurposeEnum>[] = [
  { label: t`QA`, value: QA },
  { label: t`Demo`, value: DEMO },
  { label: t`POC`, value: POC },
  { label: t`Paying Customer`, value: PAYING_CUSTOMER },
];

const teamSegmentItems: RadioGroupItem<SentinelTeamSegmentEnum>[] = [
  { label: t`Managed Care`, value: MANAGED_CARE },
  { label: t`Leasing and Rental`, value: LEASING_AND_RENTAL },
  { label: t`Mixed`, value: MIXED },
];

export default function TeamTypeStep() {
  const { handleNextPage } = useWizardModal();
  const { values, setValues, canGoNext, isEditMode, setSourceIdError } =
    useTeamModalContext();

  const teamTypeItems = [
    {
      label: t`Dealer Team`,
      value: DEALER_TEAM,
    },
    { label: t`Fleet`, value: FLEET },
  ].filter(isTruthy);

  const { teamType, teamPurpose, teamSegment } = values;

  const setTeamType = useCallback(
    (e) => {
      e.persist();
      setValues((oldState) => ({
        ...oldState,
        teamType: e.target.value,
        sourceIds: [],
      }));
      setSourceIdError([]);
    },
    [setValues, setSourceIdError],
  );

  const setTeamPurpose = useCallback(
    (e) => {
      e.persist();
      setValues((oldState) => ({
        ...oldState,
        teamPurpose: e.target.value,
      }));
    },
    [setValues],
  );

  const setTeamSegment = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.persist();
      setValues((oldState) => ({
        ...oldState,
        teamSegment: e.target.value as SentinelTeamSegmentEnum,
      }));
    },
    [setValues],
  );

  return (
    <Flex flexDirection="column" marginTop={1}>
      {!isEditMode && (
        <Flex flexDirection="column">
          <H4 marginTop={1}>
            <Trans>Team Type</Trans>
          </H4>
          <P color="alaskanHusky" size="small" marginBottom={1}>
            <Trans>
              Select which type of team you are creating. You will not be able
              to change it later.
            </Trans>
            <Asterisk />
          </P>
          <RadioGroup
            name="teamType"
            items={teamTypeItems}
            onClick={(e: React.MouseEvent<HTMLInputElement>) => {
              e.stopPropagation();
            }}
            onChange={setTeamType}
            value={teamType}
          />
        </Flex>
      )}
      <Flex marginTop={1} flexDirection="column">
        <H4 marginTop={1}>
          <Trans>Team Purpose</Trans>
        </H4>
        <P color="alaskanHusky" size="small" marginBottom={1}>
          <Trans>Select the Team Purpose.</Trans>
        </P>
        <RadioGroup
          name="teamPurpose"
          items={teamPurposeItems}
          onClick={(e: React.MouseEvent<HTMLInputElement>) => {
            e.stopPropagation();
          }}
          onChange={setTeamPurpose}
          value={teamPurpose}
        />
      </Flex>
      <Flex marginTop={1} flexDirection="column">
        <H4 marginTop={1}>
          <Trans>Customer Segment</Trans>
        </H4>
        <P color="alaskanHusky" size="small" marginBottom={1}>
          <Trans>Select the Customer Segment for this team.</Trans>
        </P>
        <RadioGroup
          name="teamSegment"
          items={teamSegmentItems}
          onClick={(e: React.MouseEvent<HTMLInputElement>) => {
            e.stopPropagation();
          }}
          onChange={setTeamSegment}
          value={teamSegment}
        />
      </Flex>
      {!isEditMode && (
        <Flex justifyContent="flex-end" marginTop={1.6}>
          <Button
            aria-label={t`Continue`}
            text={t`Continue`}
            variant="ghost"
            icon={ChevronRight}
            iconPosition="right"
            onClick={handleNextPage}
            disabled={!canGoNext(1)}
          />
        </Flex>
      )}
    </Flex>
  );
}
