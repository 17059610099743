import rem from 'polished/lib/helpers/rem';
import * as React from 'react';
import PropTypes from 'prop-types';

import spacing from '@decisiv/design-tokens/lib/spacing';

import Flex from '@decisiv/ui-components/lib/components/Flex';
import Tag from '@decisiv/ui-components/lib/components/Tag';
import { P } from '@decisiv/ui-components/lib/components/Typography';

import { t } from '@lingui/macro';

import useSentinelTeam from 'hooks/useSentinelTeam';
import { isFleet } from 'utils/Customer';

import AssetGroupingData from './AssetGroupingData';

function AssetGroupingNumber({ assetGrouping }) {
  const { isFleet: isFleetTeam } = useSentinelTeam();

  return (
    <AssetGroupingData
      data={
        <Flex flexWrap="nowrap">
          <P
            as="span"
            css={`
              margin-right: ${rem(spacing.base / 2)};
            `}
          >
            {(isFleet ? assetGrouping?.dmsId : assetGrouping?.customerNumber) ||
              '—'}
          </P>
          {!isFleetTeam && isFleet(assetGrouping) && (
            <Tag text={t`Registered`} variant="outline" />
          )}
        </Flex>
      }
      titleText={isFleet ? t`Depot#` : t`Cust#`}
    />
  );
}

AssetGroupingNumber.propTypes = {
  assetGrouping: PropTypes.shape({
    id: PropTypes.string.isRequired,
    customerType: PropTypes.string.isRequired,
    dmsId: PropTypes.string,
    customerNumber: PropTypes.string,
  }),
};

AssetGroupingNumber.defaultProps = {
  assetGrouping: undefined,
};

export default AssetGroupingNumber;
