export const alwaysValid = () => null;

/**
 * Prop type validators should not run in production. All custom validators
 * must always pass in prod. This validator wrapper simplifies that logic.
 *
 * @param {Function} validator A custom prop types validator
 */
export default function wrapValidator(validator) {
  return (...args) => {
    if (import.meta.env.MODE !== 'production') {
      return validator(...args);
    }
    return alwaysValid;
  };
}
