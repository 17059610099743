import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import toColorString from 'polished/lib/color/toColorString';

import colors from '@decisiv/design-tokens/lib/color';

import spacing from '@decisiv/design-tokens/lib/spacing';
import Flex from '@decisiv/ui-components/lib/components/Flex';

interface DividerProps {
  direction: 'horizontal' | 'vertical';
}

const StyledDivider = styled(Flex)<DividerProps>`
  width: ${({ direction }) =>
    direction === 'horizontal' ? '100%' : rem(spacing.base * 0.1)};
  height: ${({ direction }) =>
    direction === 'horizontal'
      ? rem(spacing.base * 0.1)
      : rem(spacing.base * 3.2)};
  background: ${({ color }) =>
    color || toColorString(colors.opacity.charcoal15)};
`;

export default StyledDivider;
