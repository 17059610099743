import moment from 'moment-timezone';

/**
 * Groups notifications by date in the user's timezone. Returns tuples containing the label for
 * the group (today, yesterday, DD Mon YEAR) and the notifications for that group in order.
 *
 * @param {*} notifications
 * @returns [[label, Notifications[]]]
 */

type Notifications = NotificationsQuery['notifications']['collection'];

function groupNotifications(notifications?: Notifications) {
  if (!notifications) return [];

  const groupedNotifications = notifications.reduce<[string, Notifications][]>(
    (acc, notification) => {
      const lastGroup = acc.slice(acc.length - 1)[0];
      const [lastGroupLabel, lastGroupNotifications] = lastGroup || [];

      if (
        lastGroupLabel &&
        moment
          .tz(lastGroupLabel, moment.tz.guess())
          .isSame(moment.tz(notification.createdAt, moment.tz.guess()), 'day')
      ) {
        return acc
          .slice(0, acc.length - 1)
          .concat([
            [lastGroupLabel, lastGroupNotifications.concat(notification)],
          ]);
      }

      return acc.concat([
        [
          moment
            .tz(notification.createdAt, moment.tz.guess())
            .startOf('day')
            .toISOString(),
          [notification],
        ],
      ]);
    },
    [],
  );

  return groupedNotifications;
}

export default groupNotifications;
