import { QueryHookOptions, useQuery } from '@apollo/client';
import query from './notifications.graphql';

const useNotificationsQuery = (
  options?: QueryHookOptions<NotificationsQuery, NotificationsQueryVariables>,
) => {
  return useQuery<NotificationsQuery, NotificationsQueryVariables>(
    query,
    options,
  );
};

export default useNotificationsQuery;
