import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { t, Trans } from '@lingui/macro';
import { loader } from 'graphql.macro';
import { useMutation, useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

import MoneyCheck from '@decisiv/iconix/lib/components/MoneyCheck';
import ChevronRight from '@decisiv/iconix/lib/components/ChevronRight';
import Truck from '@decisiv/iconix/lib/components/Truck';
import User from '@decisiv/iconix/lib/components/User';
import Rocket from '@decisiv/iconix/lib/components/Rocket';

import Badge from '@decisiv/ui-components/lib/components/Badge';
import Button from '@decisiv/ui-components/lib/components/Button';
import Flex from '@decisiv/ui-components/lib/components/Flex';
import { H3, P } from '@decisiv/ui-components/lib/components/Typography';

import Loading from 'components/Loading';
import useSentinelTeam from 'hooks/useSentinelTeam';
import { useCurrentUserContext } from 'utils/User';

const teamConfigCompletionQuery = loader('./teamConfigCompletion.graphql');
const hideTeamOnboardingMutation = loader('./hideTeamOnboarding.graphql');

const parseCompletionChecklist = (checklist = []) =>
  checklist.reduce((prev, { key, completed }) => {
    if (completed) return [...prev, key];

    return prev;
  }, []);

function OnboardingStep({ text, badgeIcon, badgeColor, url }) {
  const navigate = useNavigate();
  const onClick = () => navigate(url);

  return (
    <Flex alignItems="center">
      <Badge size="medium" color={badgeColor} icon={badgeIcon} />
      <P shade={1} marginLeft={1} marginRight={0.5}>
        {text}
      </P>
      <Flex marginRight={2}>
        <Button
          variant="ghost"
          icon={ChevronRight}
          onClick={onClick}
          aria-label={text}
        />
      </Flex>
    </Flex>
  );
}

OnboardingStep.propTypes = {
  url: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  badgeColor: PropTypes.string.isRequired,
  badgeIcon: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};

function TeamOnboarding() {
  const { isAdmin } = useCurrentUserContext();
  const { isFleet } = useSentinelTeam();

  const { loading, data } = useQuery(teamConfigCompletionQuery);

  const [hideTeamOnboarding] = useMutation(hideTeamOnboardingMutation, {
    refetchQueries: ['DashboardPreferences'],
  });

  const { sentinelTeam: { completionChecklist = [] } = {} } = data || {};

  const completedKeys = parseCompletionChecklist(completionChecklist);

  const stepColor = useCallback(
    (key) => (completedKeys.includes(key) ? 'success' : 'information'),
    [completedKeys],
  );

  const steps = useMemo(
    () => [
      {
        text: t`Configure the team profile picture`,
        color: stepColor('LOGO'),
        icon: Rocket,
        url: '/admin/account',
      },
      isFleet
        ? {
            text: t`Enable Depots and Assets`,
            color: stepColor('DEPOTS'),
            icon: Truck,
            url: '/depots',
          }
        : {
            text: t`Enable Customers and Assets`,
            color: stepColor('CUSTOMERS'),
            icon: Truck,
            url: '/customers',
          },
      {
        text: t`Add billing contact information`,
        color: stepColor('BILLING_INFORMATION'),
        icon: MoneyCheck,
        url: '/admin/account',
      },
      {
        text: t`Add at least one team member`,
        color: stepColor('MEMBERS'),
        icon: User,
        url: '/admin/members',
      },
    ],
    [isFleet, stepColor],
  );

  if (loading) return <Loading />;

  if (!isAdmin) return null;

  const onHide = () => hideTeamOnboarding();

  return (
    <Flex padding={2} paddingTop={1} flexDirection="column">
      <Flex justifyContent="space-between">
        <H3>
          <Trans>Team Onboarding</Trans>
        </H3>
        <Button
          aria-label={t`Hide team onboarding panel`}
          variant="ghost"
          size="small"
          text={t`hide`}
          onClick={onHide}
        />
      </Flex>

      <Flex marginTop={2} marginRight={10} flexDirection="horizontal">
        {steps.map(({ text, color: badgeColor, icon, url }) => (
          <OnboardingStep
            key={text}
            text={text}
            url={url}
            badgeIcon={icon}
            badgeColor={badgeColor}
          />
        ))}
      </Flex>
    </Flex>
  );
}

export default TeamOnboarding;
