import { t, Trans } from '@lingui/macro';
import styled from 'styled-components';

import Grid from '@decisiv/ui-components/lib/components/Grid';
import { P } from '@decisiv/ui-components/lib/components/Typography';

import useAssetConfiguration from 'hooks/useAssetConfiguration';
import useSentinelTeam from 'hooks/useSentinelTeam';

import LinkToCase from 'components/LinkToCase';
import { Loading } from '@decisiv/ui-components';
import LinkToAppointment from './LinkToAppointment';
import useBuildUrl from './hooks/useBuildUrl';

function notificationLabel(
  { source }: { source: AppointmentNotificationSource },
  linkToAppointment: React.ReactElement,
) {
  const { status, overdue, platformCase, platformServiceRequest } = source;

  switch (status) {
    case 'DRAFT':
      return <Trans>Changed Service Event {linkToAppointment} to Draft</Trans>;
    case 'COMPLETED':
      return <Trans>Completed Service Event {linkToAppointment}</Trans>;
    case 'REQUESTED':
      if (overdue) {
        return <Trans>Overdue Service Event {linkToAppointment}</Trans>;
      }
      if (platformCase) {
        return (
          <Trans>Created case for Service Event {linkToAppointment}</Trans>
        );
      }
      if (platformServiceRequest) {
        return (
          <Trans>Sent request for Service Event {linkToAppointment}</Trans>
        );
      }

      return <Trans>Requested Service Event {linkToAppointment}</Trans>;
    case 'CANCELED':
      return <Trans>Canceled Service Event {linkToAppointment}</Trans>;
    default:
      return null;
  }
}

const InlineLinkToCase = styled(LinkToCase)`
  display: inline-flex;
`;

const RequestedElement = ({
  source,
  notificationColor,
}: {
  source: AppointmentNotificationSource;
  notificationColor: 'charcoal' | 'alaskanHusky';
}) => {
  const { status, overdue, platformCase, platformServiceRequest } = source;

  const { loading, data } = useBuildUrl({
    skip: !platformServiceRequest && !platformCase,
    variables: {
      model: platformServiceRequest ? 'SERVICE_REQUEST' : 'PLATFORM_CASE',
      modelId:
        (platformServiceRequest
          ? platformServiceRequest.id
          : platformCase?.id) ?? '',
    },
  });

  // skip notifications without service request or case data
  if (!platformServiceRequest && !platformCase) return null;

  // skip overdue and non draft/requested appointment notifications
  if (!['DRAFT', 'REQUESTED'].includes(status) || overdue) return null;

  const requestedElementText = platformServiceRequest
    ? t`Service Request`
    : t`Case`;

  const label = platformServiceRequest
    ? t`Link to Service Request`
    : t`Link to Case`;

  const text = platformServiceRequest
    ? platformServiceRequest?.referenceNumber
    : platformCase?.apiCaseId;

  const linkToRequest = (
    <InlineLinkToCase
      to={data?.buildUrl}
      aria-label={label}
      text={String(text)}
    />
  );

  return (
    <Grid.Row>
      <Grid.Column>
        <P as="span" color={notificationColor} size="small">
          {loading ? (
            <Loading size="small" />
          ) : (
            <Trans>
              {requestedElementText} #: {linkToRequest}
            </Trans>
          )}
        </P>
      </Grid.Column>
    </Grid.Row>
  );
};

interface AppointmentInfoProps {
  source: AppointmentNotificationSource;
  notification: TNotification;
  notificationColor: 'alaskanHusky' | 'charcoal';
  handleOpenModal: () => void;
  setModalData: () => void;
}

export default function AppointmentInfo({
  source,
  handleOpenModal,
  notification,
  notificationColor,
  setModalData,
}: AppointmentInfoProps) {
  const asset = source?.asset;

  const {
    sentinelTeam: { assetConfiguration },
  } = notification;

  const assetGrouping = asset?.assetGrouping;
  const customerLocation = [assetGrouping?.city, assetGrouping?.state]
    .filter(Boolean)
    .join(', ');
  const customerNameLocation = `${assetGrouping?.name} (${customerLocation})`;

  const { isFleet } = useSentinelTeam();

  const {
    primary: [primaryIdentifier],
  } = useAssetConfiguration({ asset, assetConfiguration });

  const assetIdentifier = `${primaryIdentifier.label} ${primaryIdentifier.text}`;

  return (
    <Grid.Container padding={0}>
      <Grid.Row>
        <Grid.Column>
          <P as="span" color={notificationColor}>
            {notificationLabel(
              { source },
              <LinkToAppointment
                notification={notification}
                handleOpenModal={handleOpenModal}
                setModalData={setModalData}
              />,
            )}
          </P>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <P as="span" color={notificationColor} size="small">
            <Trans>Asset:</Trans>
          </P>{' '}
          <P as="span" color={notificationColor} size="small">
            <Trans>{assetIdentifier}</Trans>
          </P>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <P as="span" color={notificationColor} size="small">
            {isFleet ? <Trans>Depot:</Trans> : <Trans>Customer:</Trans>}
          </P>{' '}
          <P as="span" color={notificationColor} size="small">
            <Trans>{customerNameLocation}</Trans>
          </P>
        </Grid.Column>
      </Grid.Row>
      <RequestedElement source={source} notificationColor={notificationColor} />
    </Grid.Container>
  );
}
