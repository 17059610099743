import React, { useMemo } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useCurrentUserContext } from 'utils/User';
import ROUTE_NAMES from 'setup/Routing/routeNames';
import useSentinelTeam from 'hooks/useSentinelTeam';

export default function TeamMemberRoute({ children }) {
  const { sentinelTeams } = useCurrentUserContext();
  const sentinelTeam = useSentinelTeam();
  const location = useLocation();

  const isSentinelTeamMember = useMemo(
    () => sentinelTeams.some((team) => team.id === sentinelTeam.id),
    [sentinelTeam, sentinelTeams],
  );

  if (!isSentinelTeamMember) {
    return (
      <Navigate
        to={ROUTE_NAMES.access_denied}
        state={{ from: location }}
        replace
      />
    );
  }

  return children;
}

TeamMemberRoute.propTypes = {
  children: PropTypes.node,
};

TeamMemberRoute.defaultProps = {
  children: null,
};
