import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import rem from 'polished/lib/helpers/rem';
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';

import spacing from '@decisiv/design-tokens/lib/spacing';

import ChevronDown from '@decisiv/iconix/lib/components/ChevronDown';
import ChevronUp from '@decisiv/iconix/lib/components/ChevronUp';

import Flex from '@decisiv/ui-components/lib/components/Flex';
import Modal from '@decisiv/ui-components/lib/components/Modal';
import Popover from '@decisiv/ui-components/lib/components/Popover';
import RadioGroup from '@decisiv/ui-components/lib/components/RadioGroup';
import Tag from '@decisiv/ui-components/lib/components/Tag';
import { P } from '@decisiv/ui-components/lib/components/Typography';

import { t, Trans } from '@lingui/macro';

const updateSentinelTeamStatusMutation = loader(
  './updateSentinelTeamStatus.graphql',
);

export default function EnabledFormTag({
  teamId,
  enabled,
  name: sentinelTeamName,
  preventClick,
}) {
  const [updateStatus] = useMutation(updateSentinelTeamStatusMutation);
  const [showModal, setShowModal] = useState(false);
  const [popover, setPopover] = useState({});
  const actionRef = useRef();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const popoverRef = useCallback(setPopover, [popover]);

  const handleClose = useCallback(() => setShowModal(false), [setShowModal]);

  const runMutation = (bool) => {
    updateStatus({
      variables: {
        id: teamId,
        enabled: bool,
      },
    });
    handleClose();
  };

  return (
    <>
      <Tag
        action={preventClick ? () => undefined : () => popover.toggle()}
        actionAttributes={{
          'aria-label': t`Toggle State for ${sentinelTeamName}`,
        }}
        actionIcon={popover.isVisible ? ChevronUp : ChevronDown}
        actionRef={actionRef}
        color={enabled ? 'success' : undefined}
        text={enabled ? t`Enabled` : t`Disabled`}
      />
      <Popover
        css={`
          max-width: ${rem(spacing.base * 33)};
        `}
        ref={popoverRef}
        target={actionRef}
        manageEvents={false}
        zIndex={3}
        placement="bottom-end"
      >
        <Flex flexDirection="column" padding={1}>
          <RadioGroup
            name="enabled"
            items={[{ label: t`Enabled`, value: 'true' }]}
            onChange={() => {
              popover.toggle();
              runMutation(true);
            }}
            onClick={(event) => {
              event.stopPropagation();
            }}
            value={String(enabled)}
          />
          <P
            marginLeft={3.5}
            marginBottom={1}
            size="small"
            color="alaskanHusky"
          >
            <Trans>
              All team members can access and work on service events within the
              team.
            </Trans>
          </P>
          <RadioGroup
            onChange={() => {
              popover.toggle();
              setShowModal(true);
            }}
            onClick={(event) => {
              event.stopPropagation();
            }}
            name="disabled"
            items={[{ label: t`Disabled`, value: 'false' }]}
            value={String(enabled)}
          />
          <P marginLeft={3.5} size="small" color="alaskanHusky">
            <Trans>
              Access to the team and its service events is restricted to Super
              Admins only.
            </Trans>
          </P>
        </Flex>
      </Popover>
      <Modal
        actions={[
          {
            intent: 'danger',
            text: t`Disable Team`,
            onClick: () => runMutation(false),
          },
          { text: t`Keep Team`, onClick: handleClose },
        ]}
        intent="danger"
        onClose={handleClose}
        title={t`Disable "${sentinelTeamName}" Team?`}
        visible={showModal}
      >
        <P color="alaskanHusky">
          <Trans>
            All service events will remain active, but the owner and its members
            will lose access to the team and its service events.
          </Trans>
        </P>
      </Modal>
    </>
  );
}

EnabledFormTag.propTypes = {
  enabled: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  teamId: PropTypes.string.isRequired,
  preventClick: PropTypes.bool,
};

EnabledFormTag.defaultProps = {
  preventClick: undefined,
};
