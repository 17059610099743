import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import Badge from '@decisiv/ui-components/lib/components/Badge';
import Flex from '@decisiv/ui-components/lib/components/Flex';
import { P } from '@decisiv/ui-components/lib/components/Typography';
import { t } from '@lingui/macro';

import useFormatTimeDistance from 'hooks/useFormatTimeDistance';

export default function StatusRow({
  badgeColor,
  badgeIcon,
  children,
  endTime,
  startTime,
  status,
}) {
  const duration = useFormatTimeDistance({
    startDate: startTime,
    endDate: endTime,
  });

  const shouldRenderStatus = useMemo(
    () => !!startTime && !!endTime,
    [endTime, startTime],
  );

  return (
    <Flex>
      <Badge
        variant="square"
        aria-label={status}
        color={badgeColor}
        icon={badgeIcon}
      />
      <Flex flexDirection="column" marginLeft={1}>
        <P>{status}</P>
        <P color="alaskanHusky" size="small">
          {shouldRenderStatus && t`In Status ${duration}`}
        </P>

        {children}
      </Flex>
    </Flex>
  );
}

StatusRow.propTypes = {
  badgeColor: PropTypes.string.isRequired,
  badgeIcon: PropTypes.func.isRequired,
  children: PropTypes.node,
  endTime: PropTypes.string,
  startTime: PropTypes.string,
  status: PropTypes.string.isRequired,
};

StatusRow.defaultProps = {
  children: undefined,
  endTime: undefined,
  startTime: undefined,
};
