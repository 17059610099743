import React from 'react';
import { t, Trans } from '@lingui/macro';
import toColorString from 'polished/lib/color/toColorString';
import { useQuery } from '@apollo/client';

import userInfoQuery from 'utils/User/currentUserProfile.graphql';

import color from '@decisiv/design-tokens/lib/color';

import ArrowRight from '@decisiv/iconix/lib/components/ArrowRight';

import Button from '@decisiv/ui-components/lib/components/Button';
import Flex from '@decisiv/ui-components/lib/components/Flex';
import Grid from '@decisiv/ui-components/lib/components/Grid';
import Message from '@decisiv/ui-components/lib/components/Message';

import { logout } from 'utils/Invitation';

export default function SwitchSessionBanner() {
  const { loading, data } = useQuery(userInfoQuery, {
    fetchPolicy: 'cache-and-network',
    context: { ignoreInvitationAuth: true },
  });

  if (!loading && data) {
    // query has completed and a user was returned
    return (
      <Grid.Container paddingTop={2}>
        <Grid.Row paddingX={2}>
          <Flex
            alignItems="center"
            css={`
              background-color: ${toColorString(color.base.snowWhite)};
            `}
            flex={1}
            justifyContent="space-between"
            padding={1}
          >
            <Message color="warning" size="medium" title={t`Attention`}>
              <Trans>
                You are viewing the customer request. Click CONTINUE TO SRM
                SENTINEL to end this session and return to your team.
              </Trans>
            </Message>
            <Button
              icon={ArrowRight}
              iconPosition="right"
              onClick={logout}
              size="small"
              text={t`Continue to SRM Sentinel`}
            />
          </Flex>
        </Grid.Row>
      </Grid.Container>
    );
  }

  // still loading, or there's no current user...
  return null;
}
