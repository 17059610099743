import React from 'react';
import PropTypes from 'prop-types';
import groupBy from 'lodash/groupBy';
import moment from 'moment-timezone';

import Robot from '@decisiv/iconix/lib/components/Robot';

import Avatar from '@decisiv/ui-components/lib/components/Avatar';
import Badge from '@decisiv/ui-components/lib/components/Badge';
import Button from '@decisiv/ui-components/lib/components/Button';
import Flex from '@decisiv/ui-components/lib/components/Flex';
import Grid from '@decisiv/ui-components/lib/components/Grid';
import Tag from '@decisiv/ui-components/lib/components/Tag';
import Tooltip from '@decisiv/ui-components/lib/components/Tooltip';
import { P, H4 } from '@decisiv/ui-components/lib/components/Typography';

import { t } from '@lingui/macro';

import { parseDate } from 'utils/dates';

function tagInfo({ discardedAt, editedAt }) {
  const date = discardedAt || editedAt;

  if (date) {
    return {
      date: parseDate(date).join(', '),
      text: discardedAt ? t`Deleted` : t`Edited`,
    };
  }

  return null;
}

const dateTitle = (date) => {
  const zonedDate = parseDate(date)[0];
  const today = parseDate(moment())[0];
  const yesterday = parseDate(moment().subtract(1, 'days'))[0];
  if (today === zonedDate) {
    return t`Today`;
  }
  if (yesterday === zonedDate) {
    return t`Yesterday`;
  }

  return zonedDate;
};

export default function Paginated({
  actionText,
  data,
  getPaginationProps,
  header,
  renderDatum,
}) {
  const groupedByDate = groupBy(data, (datum) =>
    dateTitle(datum.createdAt || datum.changedAt || datum.performedAt),
  );

  return (
    <Grid.Container padding={0}>
      {header && (
        <Grid.Row paddingX={1}>
          <Grid.Column padding={0}>{header}</Grid.Column>
        </Grid.Row>
      )}
      {Object.keys(groupedByDate).map((date) => {
        return (
          <React.Fragment key={date}>
            <H4 color="alaskanHusky" marginBottom={2} marginLeft={4.6}>
              {date}
            </H4>
            {groupedByDate[date].map((datum) => {
              const { email, name } =
                datum.createdBy || datum.changedBy || datum.performedBy || {};
              const { change } = datum;
              const key = datum?.id || JSON.stringify(change);

              const tag = tagInfo(datum);
              return (
                <Flex
                  flex={1}
                  marginBottom={2}
                  key={`paginated-row-${date}-${key}`}
                >
                  {name === 'System' && !email ? (
                    <Badge
                      aria-label={t`System`}
                      color="licoriceMousse"
                      icon={Robot}
                    />
                  ) : (
                    <Avatar email={email} name={name} />
                  )}
                  <Flex flex={1} flexDirection="column" marginLeft={1}>
                    <P color="alaskanHusky" size="small">
                      {
                        parseDate(
                          datum.createdAt ||
                            datum.changedAt ||
                            datum.performedAt,
                        )[1]
                      }
                    </P>
                    <Flex marginBottom={0.5}>
                      <P marginRight={1}>
                        <P as="span" weight="semibold">
                          {name || email || '–'}&nbsp;
                        </P>
                        {typeof actionText === 'function'
                          ? actionText(datum)
                          : actionText}
                      </P>
                      {tag && (
                        <Tooltip
                          target={<Tag text={tag.text} variant="outline" />}
                        >
                          {tag.date}
                        </Tooltip>
                      )}
                    </Flex>
                    {renderDatum(datum)}
                  </Flex>
                </Flex>
              );
            })}
          </React.Fragment>
        );
      })}
      {getPaginationProps.showPagination && (
        <Flex marginLeft={4.6} marginBottom={2}>
          <Button
            {...getPaginationProps}
            kind="secondary"
            size="small"
            text={t`Load More`}
          />
        </Flex>
      )}
    </Grid.Container>
  );
}

Paginated.propTypes = {
  actionText: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
    .isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      createdAt: PropTypes.string,
      createdBy: PropTypes.shape({
        email: PropTypes.string,
        name: PropTypes.string,
      }),
      editedAt: PropTypes.string,
    }),
  ).isRequired,
  getPaginationProps: PropTypes.shape({
    showPagination: PropTypes.bool.isRequired,
  }).isRequired,
  header: PropTypes.element,
  renderDatum: PropTypes.func.isRequired,
};

Paginated.defaultProps = {
  header: null,
};
