import toColorString from 'polished/lib/color/toColorString';

import color from '@decisiv/design-tokens/lib/color';

import Archive from '@decisiv/iconix/lib/components/Archive';
import Calendar from '@decisiv/iconix/lib/components/Calendar';
import Check from '@decisiv/iconix/lib/components/Check';
import Pencil from '@decisiv/iconix/lib/components/Pencil';

import { t } from '@lingui/macro';

export const appointmentHexColors = {
  CANCELED: toColorString(color.visualInterest.licoriceMousse.medium),
  COMPLETED: toColorString(color.status.success.medium),
  DRAFT: toColorString(color.status.warning.medium),
  REQUESTED: toColorString(color.status.information.medium),
};

export const appointmentColors = {
  CANCELED: 'licoriceMousse',
  COMPLETED: 'success',
  DRAFT: 'warning',
  REQUESTED: 'information',
};

export const appointmentIcons = {
  CANCELED: Archive,
  COMPLETED: Check,
  DRAFT: Pencil,
  REQUESTED: Calendar,
};

export const appointmentCancelReasonText = {
  ALERT_DELETED: t`Appointment automatically canceled because the alert was removed from the asset.`,
  ASSET_DELETED: t`Appointment automatically canceled because the asset no longer exists in the system.`,
  CREATED_IN_ERROR: t`Created in Error`,
  CUSTOMER_CANCELED: t`Customer Canceled`,
  CUSTOMER_NO_SHOW: t`Customer No Show`,
  SERVICE_PROVIDER_CANCELED: t`Service Provider Canceled`,
  OTHER: t`Other`,
};

export const appointmentStatusLabelText = {
  DRAFT: t`Draft`,
  REQUESTED: t`In Progress`,
  COMPLETED: t`Completed`,
  CANCELED: t`Canceled`,
};

export const serviceRequestStatuses = {
  PENDING: 'PENDING',
  ACCEPTED: 'ACCEPTED',
  DECLINED: 'DECLINED',
  CANCELED: 'CANCELED',
};

export const serviceRequestStatusLabelText = (isFleet) => ({
  ACCEPTED: t`Accepted by Service Provider`,
  DECLINED: t`Declined by Service Provider`,
  CANCELED: isFleet
    ? t`Canceled in Case by the Fleet`
    : t`Canceled by Customer`,
});

export const serviceRequestStatusesText = {
  PENDING: t`Pending`,
  ACCEPTED: t`Accepted`,
  DECLINED: t`Declined`,
  CANCELED: t`Canceled`,
  COMPLETED: t`Completed`,
};

export const completedCaseStatuses = {
  COMPLETE: t`Complete`,
  COMPLETE_GONE: t`Complete (Gone)`,
  COMPLETE_HERE: t`Complete (Here)`,
};

export const caseStatuses = {
  ...completedCaseStatuses,
  ROAD_TEST: t`Road Test`,
  DIAGNOSING: t`Diagnosing`,
  OPEN: t`Open`,
  CHECKED_IN: t`Checked In`,
};
