import { ApolloLink } from '@apollo/client';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import makeSentinelTeamIdentity from '../utils/makeSentinelTeamIdentity';
import makeVisitorIdentity from '../utils/makeVisitorIdentity';
import mapKeys from '../utils/mapKeys';

const visitorKeyMap = {
  email: 'id',
};

/* istanbul ignore next -- @preserve */
function getPendoOperation(pendo, visitor) {
  if (pendo.getVisitorId() === visitor.email) return pendo.identify;

  return pendo.initialize;
}

/**
 * Checks the props for expected user and role data and, if present, runs
 * the Pendo initialization.
 *
 * Exported for testing only.
 *
 * @param {{initialize: Function}} pendo The global Pendo object
 * @param {Object} props The props from Apollo Link forwarding
 * @return {boolean} True if initialization ran, false otherwise.
 */
export function pendoInitializer(pendo, props) {
  /*
   * We're looking for response data that looks like this...
   *
   *  {
   *    "data": {
   *      "currentUserProfile": {
   *        "__typename": "UserProfile"
   *        "user": {
   *          "__typename": "User"
   *          "name": "",
   *          "email": "ddaniell@decisiv.net",
   *          "firstName": "David",
   *          "lastName": "Daniell",
   *          "username": "ddaniell",
   *          "uuid": "4386c33e-730f-11e7-a18b-12c5baa3e8d4",
   *          "role": {
   *            "__typename": "Role"
   *            "name": "user",
   *          },
   *          "sentinelTeam": {
   *            "__typename": "SentinelTeam"
   *            "id": "1",
   *            "name": "Bergey's",
   *          },
   *        },
   *      },
   *    },
   *  }
   *
   * See src/utils/User/userInfo.graphql for the query GQL.
   */

  const data = get(props, 'data');

  const visitor = makeVisitorIdentity(get(data, 'currentUserProfile.user'));

  if (isEmpty(visitor)) {
    return false;
  }

  const account = makeSentinelTeamIdentity(
    get(data, 'currentUserProfile.user.sentinelTeam'),
  );

  // Initialize Pendo now that we have user info.
  // Note that changing the logged-in user does not require
  // a re-initialize of Pendo, since the logout/login process
  // re-loads the entire application anyway.
  // The values passed for data in the `visitor` and `account`
  // objects should be strings, numbers, or booleans.
  try {
    const pendoOperation = getPendoOperation(pendo, visitor);

    pendoOperation({
      visitor: {
        ...mapKeys(visitor, visitorKeyMap),
        // email is also used as the ID for pendo.
        email: visitor.email,
        isTeamOwner:
          get(data, 'currentUserProfile.user.id') ===
          get(data, 'currentUserProfile.user.sentinelTeam.owner.id'),
      },
      account,
    });

    return true;
  } catch (e) {
    return false;
  }
}

/* istanbul ignore next -- @preserve */
/**
 * Apollo Link Afterware factory. Creates an Apollo Link Afterware that analyzes
 * response data for a `user`, then initializes Pendo with the user's information.
 * @param {boolean} isPendoEnabled a config based setting for turning Pendo on.
 * @returns {function} Apollo Link Afterware
 */
const create = (isPendoEnabled) => {
  // set a flag within this closure that prevents Pendo from being
  // initialized multiple times.
  let isPendoInitialized = false;

  return new ApolloLink((operation, forward) => {
    const isUpdateTeamMutation =
      get(operation, 'operationName') === 'setCurrentSentinelTeam';

    if (isPendoEnabled && (!isPendoInitialized || isUpdateTeamMutation)) {
      return forward(operation).map((props) => {
        isPendoInitialized = pendoInitializer(pendo, props);
        return props;
      });
    }

    return forward(operation);
  });
};

const initializePendoLinkFactory = { create };

export default initializePendoLinkFactory;
