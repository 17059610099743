import React, { useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import capitalize from 'lodash/capitalize';
import { t } from '@lingui/macro';

import Tooltip from '@decisiv/ui-components/lib/components/Tooltip';
import ExternalLink from '@decisiv/iconix/lib/components/ExternalLink';
import Send from '@decisiv/iconix/lib/components/Send';

import LinkTag from 'components/LinkTag';

const statusColorMapper = {
  PENDING: 'warning',
  ACCEPTED: 'success',
  DECLINED: 'danger',
  CANCELED: 'licoriceMousse',
};

const ServiceRequestTag = ({ serviceRequest, 'data-testid': dataTestId }) => {
  const [tooltip, setTooltip] = useState({});
  const actionRef = useRef();
  const tooltipRef = useCallback(setTooltip, [tooltip, setTooltip]);

  const { referenceNumber, status, url } = serviceRequest;
  const redirectToCase = () => (url ? window.open(url, '_blank') : null);

  const statusText = capitalize(status);
  const text = status
    ? t`${referenceNumber} \u0387 ${statusText}`
    : t`${referenceNumber} \u0387 None...`;

  const actionAttributes = { 'aria-label': t`Go to case` };

  const disableTag = !url;

  return (
    <>
      {disableTag && (
        <Tooltip ref={tooltipRef} target={actionRef} zIndex={999}>
          {t`You do not have permission to access this link`}
        </Tooltip>
      )}
      <LinkTag
        data-testid={dataTestId}
        text={text}
        icon={Send}
        variant="outline"
        actionRef={actionRef}
        disableTag={disableTag}
        action={redirectToCase}
        actionIcon={ExternalLink}
        color={statusColorMapper[status]}
        actionAttributes={actionAttributes}
      />
    </>
  );
};

ServiceRequestTag.propTypes = {
  serviceRequest: PropTypes.shape({
    id: PropTypes.string,
    url: PropTypes.string,
    status: PropTypes.string.isRequired,
    referenceNumber: PropTypes.string.isRequired,
  }).isRequired,
  'data-testid': PropTypes.string,
};

ServiceRequestTag.defaultProps = {
  'data-testid': undefined,
};

ServiceRequestTag.displayName = 'ServiceRequestTag';

export default React.memo(ServiceRequestTag);
