/* istanbul ignore file -- @preserve */
import { ApolloLink } from '@apollo/client';

import { INVITATION_KEY } from 'utils/Invitation';

const invitationAuthLink = new ApolloLink((operation, forward) => {
  if (operation.getContext().ignoreInvitationAuth) {
    return forward(operation);
  }

  const invitationKey = memoryDB.getItem(INVITATION_KEY);

  if (invitationKey) {
    operation.setContext({
      headers: {
        'X-DECISIV-INVITATION-KEY': invitationKey,
      },
    });
  }

  return forward(operation);
});

export default invitationAuthLink;
