import React, { useCallback, useRef, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import rem from 'polished/lib/helpers/rem';
import PropTypes from 'prop-types';

import { spacing } from '@decisiv/design-tokens';

import Trash from '@decisiv/iconix/lib/components/Trash';

import Button from '@decisiv/ui-components/lib/components/Button';
import Flex from '@decisiv/ui-components/lib/components/Flex';
import Tooltip from '@decisiv/ui-components/lib/components/Tooltip';

import EnabledFormTag from 'components/EnabledFormTag';
import { useTeams } from 'pages/Teams/contexts/TeamsContext';
import DeleteTeamModal from './DeleteTeamModal';

export default function ActionsCell({
  rowData: { id, name, enabled, scheduledForDeletionAt },
}) {
  const buttonRef = useRef(null);

  const [deleteTeamModalVisible, setTeamModalVisible] = useState(false);

  const handleOnDeleteModalToggle = useCallback(() => {
    setTeamModalVisible((oldState) => !oldState);
  }, []);

  const { environment } = useTeams();

  const shouldShowDeleteButton = !!environment && !environment.match(/prod/i);

  return (
    <Flex
      css={`
        gap: ${rem(spacing.base * 2)};
      `}
      alignItems="center"
    >
      <EnabledFormTag
        teamId={id}
        enabled={enabled}
        name={name}
        preventClick={!!scheduledForDeletionAt}
      />
      {shouldShowDeleteButton && (
        <>
          <Flex ref={buttonRef}>
            <Button
              icon={Trash}
              variant="ghost"
              disabled={!!scheduledForDeletionAt}
              aria-label={t`Delete Sentinel Team ${id}`}
              onClick={handleOnDeleteModalToggle}
            />
          </Flex>
          <Tooltip target={buttonRef} zIndex={10} placement="left">
            <Trans>Delete Team</Trans>
          </Tooltip>
          {deleteTeamModalVisible && (
            <DeleteTeamModal
              teamId={id}
              teamName={name}
              onClose={handleOnDeleteModalToggle}
            />
          )}
        </>
      )}
    </Flex>
  );
}

ActionsCell.propTypes = {
  rowData: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    enabled: PropTypes.bool,
    scheduledForDeletionAt: PropTypes.string,
  }).isRequired,
};
