import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';

import Grid from '@decisiv/ui-components/lib/components/Grid';
import Link from '@decisiv/ui-components/lib/components/Link';
import { P } from '@decisiv/ui-components/lib/components/Typography';

import { INVITATION_STATUS } from 'utils/Invitation';
import ROUTE_NAMES from 'setup/Routing/routeNames';
import useSentinelTeam from 'hooks/useSentinelTeam';

function labelForStatus(status, linkToCustomer) {
  switch (status) {
    case INVITATION_STATUS.ACCEPTED:
      return <Trans>Accepted Request Sent to {linkToCustomer}</Trans>;
    case INVITATION_STATUS.REJECTED:
      return <Trans>Denied Request Sent to {linkToCustomer}</Trans>;
    default:
      return null;
  }
}

export default function InvitationInfo({ notificationColor, source }) {
  const { customer } = source?.invitation;
  const { teamType } = useSentinelTeam();
  return (
    <Grid.Container padding={0}>
      <Grid.Row>
        <Grid.Column>
          <P as="span" color={notificationColor}>
            {labelForStatus(
              source?.status,
              <Link
                to={ROUTE_NAMES.forAssetGrouping(customer, { teamType })}
                text={source.invitation.customer.name}
              />,
            )}
          </P>
        </Grid.Column>
      </Grid.Row>
    </Grid.Container>
  );
}

InvitationInfo.propTypes = {
  source: PropTypes.shape({
    invitation: PropTypes.shape({
      customer: PropTypes.shape({
        name: PropTypes.string,
      }),
    }),
    status: PropTypes.string,
  }).isRequired,
  notificationColor: PropTypes.string.isRequired,
};
